import { Form, Input } from "antd";
import styled from "styled-components";
import Flex from "../general/Flex";
import Typography from "./Typography.jsx";
import HeightIcon from "../SvgComponents/HeightIcon";
import DepthIcon from "../SvgComponents/ParcelDepth";
import LengthIcon from "../SvgComponents/LengthIcon";
import ButtonPrimary from "./ButtonPrimary";
import { useState } from "react";

const StyledVWeightInput = styled.div`
  .editable-box {
    display: none;
    width: 420px;
    height: 228px;
    background: #ffffff;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    position: absolute;
    right: 17px;
    z-index: 999;
    padding: 16px;
    &.active {
      display: block;
    }
  }
  .footer {
    button {
      width: 100%;
    }
  }
`;

export default function VWeightInput({ form }) {
  const [active, setActive] = useState(false);
  const [newVWeight, setNewVWeight] = useState("");

  const handleChange = () => {
    const heightValue = form?.getFieldValue("height");
    const lengthValue = form?.getFieldValue("length");
    const widthValue = form?.getFieldValue("width");
    if (heightValue && lengthValue && widthValue) {
      let newData =
        (Number(heightValue) * Number(lengthValue) * Number(widthValue)) / 5000;
      setNewVWeight(newData);
    } else {
      setNewVWeight("");
    }
  };
  const handleUpdate = () => {
    form.setFieldValue("total_v_weight", newVWeight);
    setActive(false);
  };
  return (
    <StyledVWeightInput>
      <Form.Item name="total_v_weight">
        <Input
          readOnly={true}
          onFocus={() => setActive(true)}
          style={{ cursor: "pointer" }}
        />
      </Form.Item>
      <div className={`editable-box ${active ? "active" : ""}`}>
        <Flex justifyContent={"space-between"}>
          <div className={"item"}>
            <Typography
              variant={"p"}
              text={"Height"}
              color={"#3B5166"}
              size={"14px"}
              weight={"500"}
              padding={"0 0 4px 0"}
            />
            <Form.Item name={"height"}>
              <Input
                className="input-prefix-suffix"
                type={"number"}
                prefix={<HeightIcon />}
                suffix="mm"
                style={{ width: 120 }}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className={"item"}>
            <Typography
              variant={"p"}
              text={"Depth"}
              color={"#3B5166"}
              size={"14px"}
              weight={"500"}
              padding={"0 0 4px 0"}
            />
            <Form.Item name={"width"}>
              <Input
                className="input-prefix-suffix"
                type={"number"}
                prefix={<DepthIcon />}
                suffix="mm"
                style={{ width: 120 }}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className={"item"}>
            <Typography
              variant={"p"}
              text={"Length"}
              color={"#3B5166"}
              size={"14px"}
              weight={"500"}
              padding={"0 0 4px 0"}
            />
            <Form.Item name={"length"}>
              <Input
                className="input-prefix-suffix"
                prefix={<LengthIcon />}
                suffix="mm"
                type={"number"}
                style={{ width: 120 }}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
        </Flex>
        <div>
          <Typography
            variant={"p"}
            text={"New V Weight"}
            color={"#3B5166"}
            size={"14px"}
            weight={"500"}
            padding={"8px 0 4px 0"}
          />
          <Input
            placeholder={"New V Weight"}
            readOnly={true}
            value={newVWeight}
            suffix={"kg"}
            style={{ width: "100%" }}
          />
        </div>
        <div className="footer">
          <ButtonPrimary text={"Update"} click={handleUpdate} />
        </div>
      </div>
    </StyledVWeightInput>
  );
}
