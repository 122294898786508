import styled from "styled-components";
import { useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import MembersSearch from "./Search.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import NotSearchResult from "../../components/SvgComponents/NotSearchResult.jsx";
import AddMembersModal from "./AddMembersModal.jsx";
import CreateRoleModal from "./CreateRoleModal.jsx";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";
import { TableMembers } from "./TableMembers.jsx";
import { Pagination } from "antd";
import { Loading } from "../../components/general/Loading";

const StyledCustomers = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;

  .total {
    color: #5dba2f;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
  }

  .customers-top {
    @media (max-width: 10010px) {
      flex-wrap: wrap !important;
    }
  }

  .ant-table-thead > tr > th {
    background: #e7e9ec;
  }

  .ant-table-wrapper {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    max-height: 70vh;
    width: 100%;
    background: #ffffff;
    margin-top: 16px;
    margin-bottom: 24px;
    overflow: auto;
  }

  .role-td {
    background: #edf5fb;
    border-radius: 16px;
    width: min-content;
    padding: 2px 10px 2px 10px;
    white-space: nowrap;
  }

  .inactive-status {
    background: #ffeced;
    color: #fc4447;
  }

  .active-status {
    background: #eff8ea;
    color: #5dba2f;
  }
`;

export default function Members({ permissions }) {
  const [searchKey, setSearchKey] = useState("user_info");
  const [searchValue, setSearchValue] = useState();
  const [query, setQuery] = useState({ is_parent: 1, has_role: 1 });
  const [addMembers, setAddMembers] = useState(false);
  const [createRole, setCreateRole] = useState(false);
  const handleSearch = () => {
    if (searchValue) {
      setQuery({ is_parent: 1, has_role: 1, [searchKey]: searchValue });
    } else {
      setQuery({ is_parent: 1, has_role: 1 });
    }
  };
  const { usersList = [], refetch, meta, isLoading } = usersAutocomplete(query);
  return (
    <StyledCustomers>
      <div className="page-title">
        <Typography
          text="Members and roles"
          color="#262626"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <Flex
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        className={"customers-top"}
      >
        <MembersSearch
          setSearchKey={setSearchKey}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          handleSearch={handleSearch}
          refetch={refetch}
        />
        <Flex alignItems={"center"}>
          <ButtonSecondary
            style={{ height: 35 }}
            text="Add member"
            background="#5B6D7F"
            color="white"
            click={() => setAddMembers(true)}
          />
        </Flex>
      </Flex>
      {isLoading ? (
        <Loading />
      ) : usersList?.length > 0 ? (
        <TableMembers
          usersList={usersList}
          setAddMembers={setAddMembers}
          isLoading={isLoading}
          permissions={permissions}
        />
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ height: "80vh" }}
        >
          <NotSearchResult />
          <Typography
            variant="h2"
            text="No members found"
            size="20px"
            weight="500"
            lineHeight={"30px"}
            color="#3B5166"
            padding="16px 0 0 0"
            style={{ maxWidth: 400 }}
            align="center"
          />
          <Typography
            variant="h2"
            text={
              searchValue
                ? `Your search “${searchValue}” did not match any information. Please try again.`
                : "Once there will be members they will appear here"
            }
            size="20px"
            weight="400"
            style={{ maxWidth: 500 }}
            lineHeight={"30px"}
            color="#5B6D7F"
            align="center"
          />
          {searchValue ? (
            <ButtonSecondary
              text="Reset"
              click={() => {
                setSearchValue("");
                setQuery({ is_parent: 1, has_role: 1 });
              }}
            />
          ) : (
            ""
          )}
        </Flex>
      )}
      <AddMembersModal
        refetch={refetch}
        addMembers={addMembers}
        setAddMembers={setAddMembers}
        setCreateRole={setCreateRole}
      />
      <CreateRoleModal createRole={createRole} setCreateRole={setCreateRole} />
      <div className={"new-pagination"}>
        <Pagination
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page) => setQuery({ ...query, page })}
          defaultPageSize={meta?.per_page || 10}
          defaultCurrent={meta?.current_page || 1}
        />
      </div>
    </StyledCustomers>
  );
}
