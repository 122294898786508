import styled from "styled-components";
import { Drawer } from "antd";
import Flex from "../../components/general/Flex";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import EditIcon from "../../components/SvgComponents/EditIcon";
import ConsignmentDetailsTable from "./DetailsTable";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon";
import { useEffect, useState } from "react";
import EditConsignment from "./Edit";
import Loader from "../../components/moleculs/Loader.jsx";
import dayjs from "dayjs";
import NotifyUser from "./notify-user.jsx";
import { DownloadExcelService } from "../../services/downloadExcel.js";
import { SendToHubPopover } from "./SendToHubPopover.jsx";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledModal = styled.div`
  .forwarder {
    .info-block {
      width: 49% !important;

      .info-item {
        margin-right: 52px;

        &:not(:first-child) {
          padding-left: 12px;
          border-left: 1px solid #e7e9ec;
        }
      }
    }
  }

  .information {
    margin: 17px 0;

    .info-item {
      margin-right: 52px;

      &:not(:first-child) {
        padding-left: 12px;
        border-left: 1px solid #e7e9ec;
      }
    }
  }

  .info-block {
    background: white;
    padding: 16px;
    border-radius: 12px;
  }

  .notify-user {
    margin: 17px 0;
    background: #ffffff;
    border: 1px solid #e7e9ec;
    border-radius: 12px;
    padding: 16px;

    .input-item {
      margin-right: 16px;
    }
  }

  .footer {
    border-top: 1px solid #f5f5f5;
    margin-top: 16px;
    padding-top: 16px;

    .footer-item {
      padding-left: 8px;
      padding-right: 8px;

      &:not(:last-child) {
        border-right: 1px solid #e7e9ec;
      }
    }
  }

  .ant-select-multiple .ant-select-selection-item {
    background: #edf5fb;
    border-radius: 10px;
  }
`;

export default function ConsignmentModal({
  modalOpen,
  setModalOpen,
  consignments,
  loading,
  reFetchConsignment = () => {},
  reFetchList = () => {},
  permissions,
}) {
  const [consignment, setConsignment] = useState({});
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (!modalOpen) {
      setEditable(false);
    }
    if (consignments) {
      setConsignment(consignments);
    }
  }, [modalOpen, consignments]);
  const editParcel = (data) => {
    setConsignment(data);
  };

  const importExel = async () => {
    await DownloadExcelService(consignment.id);
  };
  let weight = 0;
  let v_weight = 0;
  consignment?.boxes?.forEach((box) => {
    weight += Number(box.total_weight);
    v_weight += Number(box.total_v_weight);
  });
  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => setModalOpen(false)}
      open={modalOpen}
      width="80%"
      header={false}
    >
      {loading ? (
        <Loader />
      ) : (
        <StyledModal>
          <div>
            {!editable ? (
              <>
                <header>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex>
                      <div style={{ paddingRight: 9 }}>
                        {/*<UsFlag />*/}
                        <img
                          src={consignment?.warehouse?.round_flag}
                          width={24}
                          height={28}
                        />
                      </div>
                      <img
                        src={consignment?.dispatch?.icon}
                        width={28}
                        height={28}
                      />
                      {/*<FlyIcon />*/}
                      <Typography
                        text="Consignment"
                        variant="p"
                        size="22px"
                        weight="500"
                        color={primaryTextColor}
                        padding="0 8px 0 13px"
                      />
                      <Typography
                        text={consignment?.id || ""}
                        variant="p"
                        size="22px"
                        weight="500"
                        color="#3B5166"
                      />
                    </Flex>
                    <Flex>
                      {/*<div className="mr-16">*/}
                      {/*  <ButtonPrimary text="Set Missed Declaration Prices" />*/}
                      {/*</div>*/}
                      <SendToHubPopover id={consignment?.id} />
                      {PermissionFinder(permissions, "parcel-edit") ? (
                        <ButtonSecondary
                          text="Edit"
                          icon={<EditIcon />}
                          click={() => setEditable(true)}
                        />
                      ) : null}
                    </Flex>
                  </Flex>
                </header>
                <div className="information info-block">
                  <Flex>
                    <div className="info-item">
                      <Typography
                        text="Consignment name"
                        variant="p"
                        color="#3B5166"
                        size="14px"
                        weight="500"
                        padding="0 0 4px 0"
                      />
                      <Typography
                        text={consignment?.name || ""}
                        variant="p"
                        color={primaryTextColor}
                        size="14px"
                        weight="400"
                      />
                    </div>
                    <div className="info-item">
                      <Typography
                        text="On the way date"
                        variant="p"
                        color="#3B5166"
                        size="14px"
                        weight="500"
                        padding="0 0 4px 0"
                      />
                      <Typography
                        text={
                          consignment?.on_way_date
                            ? dayjs(consignment?.on_way_date).format(
                                "DD.MM.YYYY"
                              )
                            : ""
                        }
                        variant="p"
                        color={primaryTextColor}
                        size="14px"
                        weight="400"
                      />
                    </div>
                    {consignment?.estimated_date_to ? (
                      <div className="info-item">
                        <Typography
                          text="Estimated delivery date to"
                          variant="p"
                          color="#3B5166"
                          size="14px"
                          weight="500"
                          padding="0 0 4px 0"
                        />
                        <Typography
                          text={
                            consignment?.estimated_date_to
                              ? dayjs(consignment?.estimated_date_to).format(
                                  "DD.MM.YYYY"
                                )
                              : ""
                          }
                          variant="p"
                          color={primaryTextColor}
                          size="14px"
                          weight="400"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="info-item">
                      <Typography
                        text="Air way bill"
                        variant="p"
                        color="#3B5166"
                        size="14px"
                        weight="500"
                        padding="0 0 4px 0"
                      />
                      <Typography
                        text={consignment?.air_waybill || ""}
                        variant="p"
                        color={primaryTextColor}
                        size="14px"
                        weight="400"
                      />
                    </div>
                  </Flex>
                </div>
                <Flex justifyContent="space-between" className="forwarder">
                  <div className="info-block mr-16">
                    <Flex>
                      <div className="info-item">
                        <Typography
                          text="Freight Forwarder"
                          variant="p"
                          color="#3B5166"
                          size="14px"
                          weight="500"
                          padding="0 0 4px 0"
                        />
                        <Typography
                          text={consignment?.freight_forwarder || ""}
                          variant="p"
                          color={primaryTextColor}
                          size="14px"
                          weight="400"
                        />
                      </div>
                      <div className="info-item">
                        <Typography
                          text="Comment"
                          variant="p"
                          color="#3B5166"
                          size="14px"
                          weight="500"
                          padding="0 0 4px 0"
                        />
                        <Typography
                          text={consignment?.comment || ""}
                          variant="p"
                          color={primaryTextColor}
                          size="14px"
                          weight="400"
                        />
                      </div>
                    </Flex>
                  </div>
                  <div className="info-block">
                    <Flex>
                      <div className="info-item">
                        <Typography
                          text="Weight"
                          variant="p"
                          color="#3B5166"
                          size="14px"
                          weight="500"
                          padding="0 0 4px 0"
                        />
                        <Typography
                          text={weight ? weight.toFixed(2) + " kg" : "kg"}
                          variant="p"
                          color={primaryTextColor}
                          size="14px"
                          weight="400"
                        />
                      </div>
                      <div className="info-item">
                        <Typography
                          text="V Weight"
                          variant="p"
                          color="#3B5166"
                          size="14px"
                          weight="500"
                          padding="0 0 4px 0"
                        />
                        <Typography
                          text={v_weight ? v_weight.toFixed(2) + " kg" : "kg"}
                          variant="p"
                          color={primaryTextColor}
                          size="14px"
                          weight="400"
                        />
                      </div>
                    </Flex>
                  </div>
                </Flex>
                <NotifyUser consignment={consignment} />
              </>
            ) : (
              <EditConsignment
                editParcel={editParcel}
                setEditable={setEditable}
                consignment={consignment}
                reFetchConsignment={reFetchConsignment}
              />
            )}
            <ConsignmentDetailsTable
              boxDetails={consignment?.boxes}
              reFetchConsignment={reFetchConsignment}
              reFetchList={reFetchList}
            />
          </div>
          <div className="footer">
            <Flex alignItems="center" justifyContent="space-between">
              <ButtonSecondary
                text="Excel"
                icon={<DownloadIcon />}
                click={importExel}
              />
              <div>
                <Flex alignItems="center">
                  <Flex className="footer-item">
                    <Typography
                      text="Total Items"
                      variant="p"
                      color="#5B6D7F"
                      size="14px"
                      weight="500"
                    />
                    <Typography
                      text={consignment?.boxes?.length || "0"}
                      variant="p"
                      color="#8E9BA7"
                      size="14px"
                      weight="400"
                      padding="0 0 0 8px"
                    />
                  </Flex>
                  <Flex className="footer-item">
                    <Typography
                      text="Weight"
                      variant="p"
                      color="#5B6D7F"
                      size="14px"
                      weight="500"
                    />
                    <Typography
                      text={`${weight?.toFixed(2) || "0"} kg`}
                      variant="p"
                      color="#8E9BA7"
                      size="14px"
                      weight="400"
                      padding="0 0 0 8px"
                    />
                  </Flex>
                  <Flex className="footer-item">
                    <Typography
                      text="V weight"
                      variant="p"
                      color="#5B6D7F"
                      size="14px"
                      weight="500"
                    />
                    <Typography
                      text={`${v_weight?.toFixed(2) || "0"} kg`}
                      variant="p"
                      color="#8E9BA7"
                      size="14px"
                      weight="400"
                      padding="0 0 0 8px"
                    />
                  </Flex>
                </Flex>
              </div>
            </Flex>
          </div>
        </StyledModal>
      )}
    </Drawer>
  );
}
