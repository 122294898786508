import { useMutation, useQueryClient } from "react-query";
import { membersAndRoles } from "../../services/members-and-roles.js";

export const useDeleteRole = (onSuccess = () => {}, onError = () => {}) => {
  const client = useQueryClient();
  return useMutation(membersAndRoles.deleteRole, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["delete-role"]);
    },
  });
};
