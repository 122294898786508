export default function PreviewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M18.6666 2.3335H9.33325C4.66659 2.3335 2.33325 4.66683 2.33325 9.3335V24.5002C2.33325 25.1418 2.85825 25.6668 3.49992 25.6668H18.6666C23.3333 25.6668 25.6666 23.3335 25.6666 18.6668V9.3335C25.6666 4.66683 23.3333 2.3335 18.6666 2.3335Z"
        stroke="#3B5166"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0618 9.14684L9.00675 15.2018C8.77342 15.4352 8.55176 15.8902 8.50509 16.2168L8.17842 18.5268C8.06176 19.3668 8.64509 19.9502 9.48509 19.8335L11.7951 19.5068C12.1217 19.4602 12.5768 19.2385 12.8101 19.0052L18.8651 12.9502C19.9034 11.9118 20.4051 10.6985 18.8651 9.15851C17.3251 7.60684 16.1118 8.09684 15.0618 9.14684Z"
        stroke="#3B5166"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1985 10.0103C14.7118 11.8419 16.1468 13.2886 17.9902 13.8019"
        stroke="#3B5166"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
