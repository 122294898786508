import { slidesApi } from "../../services/slides-sort.js";
import { useQuery } from "react-query";

export const useFetchSlides = () => {
  const {
    isError,
    isLoading,
    refetch,
    data: slides,
  } = useQuery("get-slides", () => slidesApi.fetchSlides(), {
    staleTime: Infinity,
    select: ({ data }) => {
      return data?.data;
    },
  });
  return { isLoading, slides, refetch };
};
