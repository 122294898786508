import React, { useLayoutEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_georgiaHigh from "@amcharts/amcharts4-geodata/georgiaHigh";
import Loader from "../../components/moleculs/Loader";
import { georgianRegions } from "../../constants.js";
import Typography from "../../components/moleculs/Typography.jsx";
import styled from "styled-components";
import Flex from "../../components/general/Flex.jsx";

const StyledRegistrationByRegion = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  margin: 40px 0;
  position: relative;

  .filter {
    position: absolute;
    top: 24px;
    right: 24px;
    color: #b3bbc4;
    @media (max-width: 768px) {
      top: 10px;
      right: 10px;
    }
  }

  #MapChart {
    height: 500px;
    width: 80%;
    @media (max-width: 768px) {
      width: 100% !important;
      height: auto !important;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 10px;
    }
  }
  .map-right-bar {
    @media (max-width: 768px) {
      flex-direction: column !important;
      align-items: baseline !important;
    }
  }
`;
const MapChart = ({ dashboardData, filterType }) => {
  const [sortedNewData, setSortedNewData] = useState();

  useLayoutEffect(() => {
    if (dashboardData) {
      am4core.useTheme(am4themes_animated);

      let chart = am4core.create("MapChart", am4maps.MapChart);
      chart.width = am4core.percent(100);
      chart.height = am4core.percent(100);
      chart.geodata = am4geodata_georgiaHigh;

      // Set the projection to Mercator
      chart.projection = new am4maps.projections.Mercator();

      // Create the polygon series for regions
      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;

      // Configure the appearance of each polygon
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}: {value}"; // Display name and value in tooltip
      polygonTemplate.fill = am4core.color("#AECFEE"); // Set default fill color for regions
      polygonTemplate.nonScalingStroke = true;

      const APIData = dashboardData?.data?.registration_by_region_info;

      // Convert object to array of key-value pairs
      const arr = Object.entries(APIData);

      const colors = [
        "#010413",
        "#02123C",
        "#072669",
        "#042E95",
        "#083BB7",
        "#214AAC",
        "#2959CA",
        "#3167E8",
        "#6C98F4",
        "#ABC7FD",
        "#D4E1FF",
      ];

      // Sort the array based on "total" values
      let sortedArr = arr.sort(function (a, b) {
        return b[1].total - a[1].total;
      });

      let newData = sortedArr.map((region, index) => {
        let id = georgianRegions.find((i) => i.name === region[0]);

        return {
          id: id?.shortName || "",
          name: region[0],
          value: region[1].total || 0,
          color: am4core.color(colors[index]),
        };
      });

      setSortedNewData(newData);

      let georgiaData = [
        {
          id: "GE-AB",
          name: "Abkhazia",
          value: 100,
          color: am4core.color("#094fb7"),
        },
        {
          id: "GE-AJ",
          name: "Ajaria",
          value: 75,
          color: am4core.color("#01295d"),
        },
        {
          id: "GE-TB",
          name: "Tbilisi",
          value: 150,
          color: am4core.color("rgba(33, 63, 91, 1)"),
        },
        // Add more data for other regions in Georgia with different colors
      ];

      // Set the data for the polygon series
      polygonSeries.data = newData;

      // Apply custom colors to each region

      // Enable tooltips
      polygonSeries.tooltip.label.interactionsEnabled = true;
      polygonSeries.tooltip.keepTargetHover = true;

      // Apply custom colors to each region
      polygonSeries.mapPolygons.template.propertyFields.fill = "color";

      // Enable tooltips
      polygonSeries.tooltip.label.interactionsEnabled = true;
      polygonSeries.tooltip.keepTargetHover = true;
      chart.seriesContainer.draggable = false;
      chart.seriesContainer.resizable = false;
      chart.chartContainer.wheelable = false;
    }
    // ... rest of the code
  }, [dashboardData]);
  return (
    <StyledRegistrationByRegion>
      <div className="filter">
        <Typography
          xsSize={"12px"}
          text={`This ${filterType}`}
          variant={"p"}
          size={"14px"}
          weight={"400"}
          color={"#B3BBC4"}
        />
      </div>
      <Typography
        variant={"h5"}
        text={"Regions"}
        color={"#262626"}
        size={"24px"}
        weight={"500"}
        padding={"0 0 15px 0"}
      />
      {!dashboardData ? (
        <Loader />
      ) : (
        <>
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            className={"map-right-bar"}
          >
            <div id="MapChart"></div>
            <div>
              <ul>
                {sortedNewData?.map((item, index) =>
                  item?.value ? (
                    <li>
                      <Flex alignItems={"center"}>
                        <div
                          className="cube"
                          style={{
                            width: 20,
                            height: 20,
                            background: item.color,
                            borderRadius: 5,
                            marginRight: 10,
                          }}
                        ></div>
                        {`${item.name} - ${item.value}`}
                      </Flex>
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </Flex>
        </>
      )}
    </StyledRegistrationByRegion>
  );
};

export default MapChart;
