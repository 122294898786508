import { useQuery } from "react-query";
import { actionsApi } from "../services/actions-history.js";
import { subjectTypesApi } from "../services/subject-types.js";

export const useActionsHistory = (filter) => {
  const { isError, isLoading, refetch, data } = useQuery(
    ["actions-history", filter],
    () => actionsApi.fetchActionsHistory(filter),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        return data?.data;
      },
      // enabled: !!query,
    }
  );
  return { isLoading, actionList: data?.data, meta: data?.meta, refetch };
};

export const useSubjectTypes = () => {
  const { data: subject_types } = useQuery(
    "subject-types",
    () => subjectTypesApi.fetchSubjectTypes(),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) =>
        data?.data?.map((type, i) => ({
          key: i,
          value: type.subject_type,
          label: type.subject_type?.split("\\")?.pop(),
          ...type,
        })),
    }
  );
  return { subject_types };
};
