import styled from "styled-components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import ButtonCancel from "../../components/moleculs/ButtonCancel";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonGray from "../../components/moleculs/ButtonGray";
import BlockUser from "../../components/modals/BlockUser";
import ChangePassword from "../../components/modals/ChangePassword";
import GetAccountDeails from "../../hooks/customers/useGetAccountDetails.hook.jsx";
import { UnblockUserService } from "../../services/customers.js";
import ButtonUnlock from "../../components/moleculs/UnlockBtn";

const StyledCustomerSettings = styled.div`
  .item {
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 16px;
    padding-top: 16px;
  }
`;

export default function CustomerSingleSettings() {
  const [status, setStatus] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState(false);
  const { id } = useParams();
  const { accountDeails, refetch } = GetAccountDeails(id);
  const [loading, setLoading] = useState(false);

  const handleUnlockUser = () => {
    setLoading(true);
    UnblockUserService(accountDeails?.current_blocked_account?.id).then(() => {
      refetch();
      setLoading(false);
    });
  };

  return (
    <StyledCustomerSettings>
      <BlockUser status={status} setStatus={setStatus} />
      <ChangePassword setStatus={setPasswordStatus} status={passwordStatus} />
      <div className="item">
        <Typography
          variant="p"
          text="Block user"
          color="#3B5166"
          size="14px"
          weight="500"
          padding="0 0 4px 0"
        />
        <Typography
          variant="p"
          text={
            accountDeails?.current_blocked_account
              ? "You can unblock user"
              : "If there is a problem with a user you can block user"
          }
          color={primaryTextColor}
          size="14px"
          weight="400"
          padding="0 0 10px 0"
        />
        {accountDeails?.current_blocked_account ? (
          <ButtonUnlock
            text="Unblock user"
            click={() => handleUnlockUser()}
            loading={loading}
          />
        ) : (
          <ButtonCancel text="Block user" click={() => setStatus(true)} />
        )}
      </div>
      <div className="item">
        <Typography
          variant="p"
          text="Change password"
          color="#3B5166"
          size="14px"
          weight="500"
          padding="0 0 4px 0"
        />
        <Typography
          variant="p"
          text="Change password to 123456789"
          color={primaryTextColor}
          size="14px"
          weight="400"
          padding="0 0 10px 0"
        />
        <ButtonGray text="Change" click={() => setPasswordStatus(true)} />
      </div>
    </StyledCustomerSettings>
  );
}
