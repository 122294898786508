import styled from "styled-components";
import React from "react";
import Typography from "../../components/moleculs/Typography.jsx";

const StyledCustomizedPricing = styled.div`
  padding-top: 24px;
`;

const CustomizedPricing = () => {
  return (
    <StyledCustomizedPricing>
      <div className="page-title">
        <Typography
          text="Customized pricing"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
    </StyledCustomizedPricing>
  );
};

export default CustomizedPricing;
