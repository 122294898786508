import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import dayjs from "dayjs";
import { Table } from "antd";
import DeleteIcon from "../../components/SvgComponents/DeleteIcon.jsx";
import { useState } from "react";
import { WarningIcon } from "../../components/SvgComponents/WarningIcon.jsx";
import DeleteModal from "../../components/modals/DeleteModal.jsx";
import { updatePrimeUserStatusHook } from "../../hooks/updatePrimeUserStatus.hook.js";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import prime from "../../assets/images/prime.svg";
import { order_icon } from "../../assets/images/prime/index.js";
import { useNavigate } from "react-router-dom";

export const TablePrimeUsers = ({
  usersList,
  reFetch = () => {},
  page = 1,
}) => {
  const [isDelete, setIsDelete] = useState(false);
  const [primeUser, setPrimeUser] = useState(null);
  const navigate = useNavigate();

  const { mutate } = updatePrimeUserStatusHook(
    (data) => {
      openNotification("Delete Prime", "Prime successfully deleted");
      reFetch();
      setIsDelete(false);
    },
    (e) => {
      openNotificationError("Delete Prime", "something went wrong");
    }
  );

  const deletePrime = (id) => {
    const newValues = { status: 0, id: id };
    mutate(newValues);
  };

  const redirectUser = (index) => {
    const user = usersList.find((user) => user?.key === index);
    if (user?.id) {
      navigate(`/customer/${user?.id}`);
    }
  };

  const columns = [
    {
      title: "Full name",
      dataIndex: "full_name",
      key: "name",
    },
    {
      title: "Total orders",
      dataIndex: "total_orders",
      key: "total_orders",
      sorter: (a, b) => a.count - b.count,
    },
    {
      title: "Orders",
      dataIndex: "orders",
      key: "orders",
    },

    {
      title: "Created date",
      dataIndex: "created_date",
      key: "created_date",
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
    },
  ];

  const dataSource = usersList?.map((el, i) => ({
    key: i,
    count: el?.orders_count,
    created: dayjs(el?.prime_start_at).format("DD.MM.YYYY"),
    full_name: (
      <Flex>
        <Typography
          text={i + (page - 1) * 15 + 1}
          variant={"p"}
          size={"14px"}
        />
        <Typography
          text={el?.full_name + " " + el?.recipient?.user_code || ""}
          variant={"p"}
          size={"14px"}
          margin={"0 16px 0 16px"}
          onClick={() => redirectUser(el?.id)}
        />
        <img alt={"prime"} src={prime} />
      </Flex>
    ),
    orders: (
      <Flex>
        {el?.orders_count_by_country?.length
          ? el?.orders_count_by_country?.map((item, index) => (
              <Flex
                key={index}
                alignItems={"center"}
                style={{ margin: "0 0 0 8px" }}
              >
                <img
                  alt={`country${index}`}
                  width={24}
                  height={24}
                  src={`https://devbackadmin.onex.ge/storage/images/warehouses/${item?.round_flag}`}
                />
                <Typography
                  margin={"0 0 0 8px"}
                  weight={400}
                  variant={"p"}
                  color={"#5B6D7F"}
                  text={item?.total}
                  size={"14px"}
                />
              </Flex>
            ))
          : "-"}
      </Flex>
    ),
    total_orders: (
      <Flex alignItems={"center"}>
        <img alt={"order"} src={order_icon} />
        <Typography
          text={el?.orders_count || "-"}
          className={"role-td"}
          align={"start"}
          variant={"p"}
          size={"12px"}
          margin={"0 0 0 8px"}
          color={"#3B5166"}
        />
      </Flex>
    ),
    created_date: (
      <Flex>
        <Typography
          text={
            el?.prime_start_at
              ? dayjs(el?.prime_start_at).format("DD.MM.YYYY")
              : ""
          }
          variant={"p"}
          size={"14px"}
        />
        <Flex
          style={{ margin: "0 0 0 auto", cursor: "pointer" }}
          onClick={(e) => {
            setIsDelete(true);
            setPrimeUser(el?.id);
            e.stopPropagation();
          }}
        >
          <DeleteIcon />
        </Flex>
      </Flex>
    ),
  }));

  // if (sort) {
  //   dataSource.sort((a, b) => {
  //     if (a.count > b.count) {
  //       return 1;
  //     }
  //     if (a.count < b.count) {
  //       return -1;
  //     }
  //   });
  // }

  return (
    <Flex justifyContent="space-between" direction={"column"}>
      <DeleteModal
        icon={
          <WarningIcon
            style={{ background: "#FFF5EB", borderRadius: "28px" }}
            size={"42px"}
          />
        }
        setStatus={setIsDelete}
        status={isDelete}
        title={"Delete prime user"}
        description={"Are you sure you want to delete prime"}
        setDelete={() => deletePrime(primeUser)}
      />
      <Table
        dataSource={dataSource}
        columns={columns}
        style={{ width: "100%" }}
        height={"60vh"}
        pagination={false}
        onRow={(record, rowIndex) => ({
          onClick: () => redirectUser(rowIndex),
        })}
      />
    </Flex>
  );
};
