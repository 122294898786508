import styled from "styled-components";
import Typography from "./Typography.jsx";

const StyledTag = styled.div`
  background: ${({ background }) => background};
  padding: 4px 13px;
  border-radius: 14px;
  margin: ${({ margin }) => margin};
  width: fit-content;
`;

export default function Tag({
  text,
  background,
  color,
  transform = "unset",
  margin,
  weight,
  size = "14px",
}) {
  return (
    <StyledTag
      background={background}
      color={color}
      margin={margin}
      weight={weight}
    >
      <Typography
        variant="span"
        text={text}
        size={size}
        weight={weight || 400}
        color={color}
        transform={transform}
      />
    </StyledTag>
  );
}
