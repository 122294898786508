import styled from "styled-components";
import { useEffect, useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import CustomersSearch from "./Search";
import CustomersTable from "./Table";
import useGetRecipients from "../../hooks/customers/useGetRecipients.hook.jsx";
import Flex from "../../components/general/Flex";

const StyledCustomers = styled.div`
  padding-top: 24px;

  .total {
    color: #5dba2f;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
  }

  .customers-top {
    @media (max-width: 10010px) {
      flex-wrap: wrap !important;
    }
  }
`;

export default function Customers() {
  const [searchKey, setSearchKey] = useState("user_info");
  const [searchValue, setSearchValue] = useState();
  const [query, setQuery] = useState({ is_parent: 1 });

  const handleSearch = () => {
    if (searchValue) {
      setQuery({ [searchKey]: searchValue });
    } else {
      setQuery({ is_parent: 1 });
    }
  };

  useEffect(() => {
    let search = window.location.search;
    if (search) {
      const searchKeys = search.split("=");
      const searchKey = searchKeys[1].split("&")[0];
      const searchValue = searchKeys[2];
      setQuery({ [searchKey]: searchValue });
    }
  }, []);

  const { recipients, refetch, meta } = useGetRecipients(query);
  return (
    <StyledCustomers>
      <div className="page-title">
        <Typography
          text="Customers"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        className={"customers-top"}
      >
        <CustomersSearch
          setSearchKey={setSearchKey}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          handleSearch={handleSearch}
          setQuery={setQuery}
          refetch={refetch}
        />
        <Flex alignItems={"center"}>
          <h4 style={{ marginRight: 10, fontWeight: "normal" }}>
            Registered users
          </h4>
          <div className="total">{meta?.total || 0}</div>
        </Flex>
      </Flex>
      <CustomersTable
        recipients={recipients}
        meta={meta}
        setQuery={setQuery}
        query={query}
      />
    </StyledCustomers>
  );
}
