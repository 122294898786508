import { Drawer, Form, Input, Switch } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import Flex from "../../components/general/Flex.jsx";
import ButtonCancel from "../../components/moleculs/ButtonCancel.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import TabList from "../../components/tab/TabList.jsx";
import OnexTooltip from "../../helpers/tooltip.jsx";
import InfoIcon from "../../components/SvgComponents/InfoIcon.jsx";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import { useIsActiveSlide } from "../../hooks/slides/useIsActiveSlide.hook.js";
import DeleteButton from "../../components/moleculs/DeleteButton.jsx";
import AlertCircleIcon from "../../components/SvgComponents/AlertCircleIcon";
import { useDeleteSlider } from "../../hooks/slides/useDeleteSlider.hook.js";
import DeleteModal from "../../components/modals/DeleteModal.jsx";
import { WarningIcon } from "../../components/SvgComponents/WarningIcon";
import UploadImages from "../../components/moleculs/UploadImages.jsx";
import { sliderInitialValue } from "./initial-value-slider-form.js";
import { useCreateAndEditSlid } from "../../hooks/slides/useCreateAndEditSlide.hook.js";
import { useCheckEmptyArrItem } from "../../helpers/useCheckEmptyArrItem.js";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledCreateSlide = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .style_form {
    border-radius: 12px;
    background: #ffffff;
    padding: 16px;
  }
`;

export default function CreateSlideDrawer({
  createSlider,
  setCreateSlider,
  reFetchSlider = () => {},
  setSlider = () => {},
  slider,
  languages,
  permissions,
}) {
  const [languageId, setLanguageId] = useState(slider?.language_id || 1);
  const [imageUrl, setImageUrl] = useState(null);
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const sliderWatch = Form.useWatch("details", form);
  const isTrueAllItems = useCheckEmptyArrItem(sliderWatch);

  useEffect(() => {
    if (typeof slider === "object") {
      slider?.slidersMl?.forEach((ml, i) => {
        Object.entries(ml).forEach(([key, val]) => {
          form.setFieldValue(["details", +ml?.language_id - 1, key], val);
        });
        setImageUrl((pre) => ({ ...pre, [+ml?.language_id]: ml?.image }));
      });
      setStatus(slider.is_active);
      if (slider?.language_id) {
        setLanguageId(slider.language_id);
        form.setFieldValue(
          ["details", slider?.language_id - 1, "language_id"],
          slider?.language_id
        );
      }
    }
  }, [slider]);
  const { mutate } = useCreateAndEditSlid(
    (data) => {
      setCreateSlider(false);
      openNotification(data?.data?.message, "Success");
      reFetchSlider();
      form.resetFields();
      setLanguageId(1);
    },
    (e) => {
      Object.entries(e?.response?.data?.data).forEach(([key, val]) => {
        openNotificationError(val[0], "Something went wrong");
      });
    }
  );
  const createSlide = (values) => {
    if (values) {
      const formData = new FormData();
      values?.details?.map((detail, index) => {
        if (
          detail.language_id &&
          detail.title &&
          detail.description &&
          detail.image &&
          detail.url
        ) {
          Object.entries(detail).forEach(([key, value]) => {
            formData.append(`details[${index}][${key}]`, value);
          });
        }
      });

      if (typeof slider === "object") {
        formData.append("_method", "put");
      }
      mutate({ id: slider?.id, formData });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const closeEditModal = () => {
    setCreateSlider(false);
    form.resetFields();
    setImageUrl(null);
    setSlider(false);
    setLanguageId(1);
  };

  const { mutate: mutateIsActive, isLoading } = useIsActiveSlide(
    (data) => {
      openNotification(data?.data?.message, "Success");
      setStatus(data?.data?.data?.is_active);
      reFetchSlider();
    },
    (e) => {
      if (e) {
        openNotificationError(
          "Action failed.",
          e?.response?.data?.data?.message
        );
      }
    }
  );

  const { mutate: deleteSlider } = useDeleteSlider(
    (data) => {
      openNotification(data?.data?.message, "Success");
      setCreateSlider(false);
      setImageUrl(null);
      setOpen(false);
      form.resetFields();
      reFetchSlider();
      setSlider(false);
      setLanguageId(1);
    },
    (e) => {
      console.log(e);
      if (e) {
        openNotificationError("Activate", "something went wrong");
      }
    }
  );

  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => closeEditModal()}
      open={createSlider}
      width="1112px"
      header={false}
    >
      <DeleteModal
        icon={<WarningIcon />}
        setStatus={setOpen}
        status={open}
        title={"Delete slide"}
        description={"Are you sure you want to delete slide"}
        setDelete={() => deleteSlider(slider?.id)}
      />
      <StyledCreateSlide>
        <Form
          initialValues={sliderInitialValue}
          form={form}
          name="createSlide"
          layout="vertical"
          onFinish={createSlide}
          onFinishFailed={onFinishFailed}
        >
          <div className="header">
            <Typography
              text={slider ? "Edit slide" : "Create slide"}
              variant="p"
              color={primaryTextColor}
              size="22px"
              weight="500"
              padding={"0 0 16px 0"}
            />
            <Flex alignItems={"center"}>
              {slider ? (
                <>
                  {PermissionFinder(permissions, "slider-delete") ? (
                    <DeleteButton
                      margin={"0 16px 0 0"}
                      text={"Delete Slide"}
                      icon={<AlertCircleIcon />}
                      click={() => {
                        setOpen(true);
                      }}
                      type="button"
                    />
                  ) : null}

                  <Typography
                    variant={"p"}
                    text={status ? "Active" : "Inactive"}
                    margin={"0 8px 0 0"}
                    size={"14px"}
                  />
                  <Switch
                    loading={isLoading}
                    checked={status}
                    style={{ margin: "0 16px 0 0" }}
                    onChange={(val) => {
                      mutateIsActive({ id: slider?.id, is_active: val });
                    }}
                  />
                </>
              ) : null}

              <ButtonCancel
                text="Cancel"
                margin="0 16px 0 0"
                type="button"
                click={closeEditModal}
              />
              <ButtonPrimary
                text={slider ? "Save" : "Create"}
                htmlType="submit"
                click={() => createSlide()}
              />
            </Flex>
          </div>
          <Form.List name={"details"}>
            {(fields, { add, remove }) => {
              return (
                <div>
                  <TabList
                    data={languages}
                    isTrueAllItems={isTrueAllItems}
                    onChange={(key) => {
                      setLanguageId(key);
                      form.setFieldValue(
                        ["details", key - 1, "language_id"],
                        key
                      );
                    }}
                    value={languageId}
                  />

                  <div className={"style_form"}>
                    <Form.Item
                      name={[languageId - 1, "image"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Image.",
                        },
                      ]}
                      label={
                        <div style={{ display: "flex", padding: "16px 0" }}>
                          Image
                          <OnexTooltip
                            content={
                              <span>
                                Dimensions{" "}
                                <span style={{ fontWeight: 700 }}>
                                  456 × 348px
                                </span>{" "}
                                <br />
                                SVG, JPG, PNG, WEBP
                              </span>
                            }
                            direction={"right"}
                          >
                            <InfoIcon
                              color={"#5B6D7F"}
                              style={{ margin: "0 0 0 4px" }}
                            />
                          </OnexTooltip>
                        </div>
                      }
                    >
                      <UploadImages
                        imageUrl={imageUrl ? imageUrl[languageId] : ""}
                        setImageUrl={(img) =>
                          setImageUrl({ ...imageUrl, [languageId]: img })
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name={[languageId - 1, "title"]}
                      label="Slide title"
                      rules={[
                        {
                          required: true,
                          message: "Missing Title.",
                        },
                      ]}
                    >
                      <Input
                        style={{ minHeight: "52px" }}
                        placeholder={"Slide title"}
                        maxLength={32}
                        showCount={true}
                      />
                    </Form.Item>
                    <div style={{ margin: "32px 0 0 0" }}>
                      <Form.Item
                        name={[languageId - 1, "description"]}
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Missing Description.",
                          },
                        ]}
                      >
                        <Input
                          style={{ minHeight: "52px" }}
                          placeholder={"Description"}
                          maxLength={100}
                          showCount={true}
                        />
                      </Form.Item>
                    </div>
                    <Flex
                      className="mt-16"
                      style={{ width: "100%", margin: "32px 0 0 0" }}
                    >
                      <div className="mr-16" style={{ width: "100%" }}>
                        <Form.Item
                          name={[languageId - 1, "url"]}
                          label={"URL"}
                          rules={[
                            {
                              required: true,
                              message: "Missing URL.",
                            },
                          ]}
                          // rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder={"URL"} />
                        </Form.Item>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Form.Item
                          name={[languageId - 1, "button_name"]}
                          label={"Button name"}
                          rules={[
                            {
                              required: true,
                              message: "Missing Button Name.",
                            },
                          ]}
                          // rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder={"Button name"} />
                        </Form.Item>
                      </div>
                    </Flex>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </StyledCreateSlide>
    </Drawer>
  );
}
