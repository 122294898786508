import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Input, Row, Modal, Form } from "antd";
import UploadImages from "../../components/moleculs/UploadImages.jsx";
import { PlusOutlined } from "@ant-design/icons";
import CreateChildStory from "./CreateChildStory";
import CropModal from "./CropModal";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";

const StyledMain = styled.div`
  margin-top: 30px;
  .ant-upload {
    img {
      object-fit: contain;
    }
  }
`;
const TabItem = ({ form, language_id, index, editId }) => {
  const uploadRef = useRef(null);

  const [src, setSrc] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleReplace = () => {
    uploadRef.current.click();
    setOpenModal(false);
  };
  useEffect(() => {
    if (editId) {
      setTimeout(() => {
        const image = form.getFieldValue(["main", index, "image"]);
        setSrc(image);
      }, 1500);
    } else {
      setSrc(null);
    }
  }, [form, index, editId]);
  return (
    <>
      <Form.Item
        hidden
        name={["main", index, "language_id"]}
        initialValue={language_id}
      />
      <StyledMain>
        <div className="style_form main">
          <Row style={{ alignItems: "center" }}>
            <Col lg={3}>
              <Typography
                text={"Main story"}
                variant={"p"}
                color={"#3B5166"}
                size={"16px"}
                padding={"0 0 10px 0"}
                weight={"500"}
              />
              <Form.Item
                name={["main", index, "image"]}
                rules={[{ required: true, message: "Required" }]}
              >
                <UploadImages
                  maxWidth={"1500"}
                  maxHeight={"575"}
                  imageUrl={src || ""}
                  initialImage={src || ""}
                  ref={uploadRef}
                  setImageUrl={(img) => {
                    setSrc(img);
                    setOpenModal(true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={21}>
              <Typography
                text={"Main story description"}
                variant={"p"}
                color={"#3B5166"}
                size={"16px"}
                padding={"0 0 10px 0"}
                weight={"500"}
              />
              <Form.Item
                name={["main", index, "description"]}
                rules={[{ required: true, message: "Required" }]}
              >
                <Input
                  placeholder={"Main story description (internal)"}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Modal
          footer={false}
          title="Select photo crop"
          open={openModal}
          maskClosable={false}
          onCancel={() => {
            setSrc(null);
            setOpenModal(false);
          }}
          width="316px"
        >
          <CropModal
            src={src}
            onDone={() => {
              setOpenModal(false);
            }}
            onCancel={() => {
              form.setFieldValue(["main", index, "image"], null);
              setSrc(null);
              setOpenModal(false);
            }}
            onReplace={handleReplace}
          />
        </Modal>
      </StyledMain>

      <Form.List name={language_id}>
        {(fields, { add, remove }) => {
          return (
            <>
              <Flex alignItems={"center"}>
                <ButtonSecondary
                  text={"Add story"}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    add();
                  }}
                />
                <Typography
                  variant={"span"}
                  text={`${fields?.length} of 20 story added`}
                  color={"#5B6D7F"}
                  size={"14px"}
                  weight={"400"}
                  padding={"0 0 0 8px"}
                />
              </Flex>
              {fields.map((field, index) => (
                <CreateChildStory
                  key={field.key}
                  form={form}
                  index={index}
                  language_id={language_id}
                  onDelete={() => remove(field.name)}
                />
              ))}
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default TabItem;
