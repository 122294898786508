import styled from "styled-components";
import { Col, Row } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import TopRightArrowIcon from "../../components/SvgComponents/TopRightArrow";
import UserIcon from "../../components/SvgComponents/UserIcon";
import LeftBottomArrowIcon from "../../components/SvgComponents/LeftBottomArrow";
import Loader from "../../components/moleculs/Loader";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon";
import { DownloadUnverified } from "../../services/dashboard.js";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import TotalVerifiedPercent from "./TotalVerifiedPercent";
import { useEffect, useState } from "react";

const StyledDashboardTotalCards = styled.div`
  .total-card {
    background: #ffffff;
    border: 1.5px solid #f5f5f5;
    border-radius: 12px;
    padding: 24px;
    min-height: 145px;
    @media (max-width: 768px) {
      min-height: auto;
    }

    .unverified-parts {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .unverified-parts-item {
        &:nth-child(1) {
          margin-bottom: 25px;
        }
      }
    }

    &.dark {
      background: #0a2540 !important;
    }

    .download {
      background: #ffffff;
      border: 1px solid #e7e9ec;
      border-radius: 8px;
      padding: 5px 18px 0px 18px;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      .total-card-flex {
        align-items: center;
        //flex-direction: column !important;
      }
    }
  }

  .total-cards {
    @media (max-width: 991px) {
      margin-bottom: 25px;
    }
  }

  .download-excel {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export default function DashboardTotalCards({
  dashboardData,
  isLoading,
  filterType,
  customRange,
  filters,
  currentWeekDay,
}) {
  const [unverifiedTotalSum, setUnverifiedTotalSum] = useState(0);
  const handleDownloadUnverified = () => {
    DownloadUnverified(filters, dashboardData.data).then((res) => {
      console.log(res);
    });
  };

  useEffect(() => {
    setUnverifiedTotalSum(
      dashboardData?.data?.all_info?.incompleted_total +
        dashboardData?.data?.all_info?.unverified_total
    );
  }, [dashboardData]);

  return (
    <StyledDashboardTotalCards>
      {isLoading ? (
        <Loader />
      ) : (
        <Row gutter={[24, 24]} className={"total-cards"}>
          <Col lg={24} md={24} xs={24}>
            <div className="total-card dark">
              <Typography
                variant={"h5"}
                text={dashboardData?.data?.all_info?.all_total}
                color={"#FFFFFF"}
                size={"36px"}
                weight={"500"}
                xsSize={"36px"}
              />
              <Typography
                variant={"p"}
                size={"14px"}
                weight={"400"}
                text={"Total registered users"}
                color={"#FFFFFF"}
                padding={"15px 0 18px 0"}
                xsPadding={"10px 0 10px 0"}
              />
              <Flex
                justifyContent={"space-between"}
                className={"total-card-flex"}
              >
                <div>
                  {dashboardData?.data?.all_info?.all_percentage_expression ? (
                    <>
                      <Flex alignItems={"center"}>
                        {Math.sign(
                          dashboardData?.data?.all_info
                            ?.all_percentage_expression
                        ) === -1 ? (
                          <LeftBottomArrowIcon />
                        ) : (
                          <TopRightArrowIcon />
                        )}
                        <Typography
                          variant={"h5"}
                          text={`${dashboardData?.data?.all_info?.all_percentage_expression}%`}
                          color={`${
                            Math.sign(
                              dashboardData?.data?.all_info
                                ?.all_percentage_expression
                            ) === -1
                              ? "#FC4447"
                              : "#5DBA2F"
                          }`}
                          size={"14px"}
                          weight={"400"}
                          padding={"0 16px 0 4px"}
                        />
                      </Flex>
                      <Typography
                        variant={"span"}
                        text={`${
                          Math.sign(
                            dashboardData?.data?.all_info?.all_sum_expression
                          ) === -1
                            ? dashboardData?.data?.all_info?.all_sum_expression
                            : "+" +
                              dashboardData?.data?.all_info?.all_sum_expression
                        } since  ${
                          filterType === "day"
                            ? "last " + currentWeekDay
                            : "last " + filterType
                        }`}
                        size={"12px"}
                        color={"#FFFFFF"}
                        weight={"400"}
                      />
                    </>
                  ) : null}
                </div>
                <UserIcon />
              </Flex>
            </div>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Typography
                    variant={"h5"}
                    text={`${dashboardData?.data?.all_info?.completed_total}`}
                    color={"#262626"}
                    size={"36px"}
                    weight={"500"}
                    xsSize={"36px"}
                  />
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Total verified"}
                    color={"#8E9BA7"}
                    padding={"15px 0 18px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                  <div
                    justifyContent={"space-between"}
                    className={"total-card-flex"}
                  >
                    <div>
                      {dashboardData?.data?.all_info
                        ?.completed_percentage_expression ? (
                        <>
                          <Flex alignItems={"center"}>
                            {Math.sign(
                              dashboardData?.data?.all_info
                                ?.completed_percentage_expression
                            ) === -1 ? (
                              <LeftBottomArrowIcon />
                            ) : (
                              <TopRightArrowIcon />
                            )}
                            <Typography
                              variant={"h5"}
                              text={`${dashboardData?.data?.all_info?.completed_percentage_expression}%`}
                              color={`${
                                Math.sign(
                                  dashboardData?.data?.all_info
                                    ?.completed_percentage_expression
                                ) === -1
                                  ? "#FC4447"
                                  : "#5DBA2F"
                              }`}
                              size={"14px"}
                              weight={"400"}
                              padding={"0 16px 0 4px"}
                            />
                          </Flex>
                          <Typography
                            variant={"span"}
                            text={`${
                              Math.sign(
                                dashboardData?.data?.all_info
                                  ?.completed_sum_expression
                              ) === -1
                                ? dashboardData?.data?.all_info
                                    ?.completed_sum_expression
                                : "+" +
                                  dashboardData?.data?.all_info
                                    ?.completed_sum_expression
                            } since  ${
                              filterType === "day"
                                ? "last " + currentWeekDay
                                : "last " + filterType
                            }`}
                            size={"12px"}
                            color={"#8E9BA7"}
                            weight={"400"}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div>
                  <TotalVerifiedPercent dashboardData={dashboardData} />
                </div>
              </Flex>
            </div>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Typography
                    variant={"h5"}
                    text={`${unverifiedTotalSum}`}
                    color={"#262626"}
                    size={"36px"}
                    weight={"500"}
                    xsSize={"36px"}
                  />
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Total unverified"}
                    color={"#8E9BA7"}
                    padding={"15px 0 18px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                  <Flex
                    justifyContent={"space-between"}
                    className={"total-card-flex"}
                  >
                    <div>
                      {dashboardData?.data?.all_info
                        ?.unverified_percentage_expression ? (
                        <>
                          <Flex alignItems={"center"}>
                            {Math.sign(
                              dashboardData?.data?.all_info
                                ?.unverified_percentage_expression
                            ) === -1 ? (
                              <LeftBottomArrowIcon />
                            ) : (
                              <TopRightArrowIcon />
                            )}
                            <Typography
                              variant={"h5"}
                              text={`${dashboardData?.data?.all_info?.unverified_percentage_expression}%`}
                              color={`${
                                Math.sign(
                                  dashboardData?.data?.all_info
                                    ?.unverified_percentage_expression
                                ) === -1
                                  ? "#FC4447"
                                  : "#5DBA2F"
                              }`}
                              size={"14px"}
                              weight={"400"}
                              padding={"0 16px 0 4px"}
                            />
                          </Flex>
                          <Typography
                            variant={"span"}
                            text={`${
                              Math.sign(
                                dashboardData?.data?.all_info
                                  ?.unverified_sum_expression
                              ) === -1
                                ? dashboardData?.data?.all_info
                                    ?.unverified_sum_expression
                                : "+" +
                                  dashboardData?.data?.all_info
                                    ?.unverified_sum_expression
                            }  since  ${
                              filterType === "day"
                                ? "last " + currentWeekDay
                                : "last " + filterType
                            }`}
                            size={"12px"}
                            color={"#8E9BA7"}
                            weight={"400"}
                          />
                        </>
                      ) : null}
                    </div>
                  </Flex>
                </div>
                <div>
                  <div
                    className="download-excel"
                    onClick={() => handleDownloadUnverified()}
                  >
                    <ButtonSecondary
                      text="Download list"
                      icon={<DownloadIcon />}
                    />
                  </div>
                  <div className={"unverified-parts"}>
                    <Flex className={"unverified-parts-item"}>
                      <Typography
                        variant={"span"}
                        text={"Incomplete"}
                        color={"#8E9BA7"}
                        size={"14px"}
                        weight={"400"}
                        padding={"0 8px 0 0"}
                      />
                      <Typography
                        variant={"span"}
                        text={dashboardData?.data?.all_info?.incompleted_total}
                        color={"#0A2540"}
                        size={"16px"}
                        weight={"600"}
                      />
                    </Flex>
                    <Flex className={"unverified-parts-item"}>
                      <Typography
                        variant={"span"}
                        text={"Not verified"}
                        color={"#8E9BA7"}
                        size={"14px"}
                        weight={"400"}
                        padding={"0 8px 0 0"}
                      />
                      <Typography
                        variant={"span"}
                        text={dashboardData?.data?.all_info?.unverified_total}
                        color={"#0A2540"}
                        size={"16px"}
                        weight={"600"}
                      />
                    </Flex>
                  </div>
                </div>
              </Flex>
            </div>
          </Col>
        </Row>
      )}
    </StyledDashboardTotalCards>
  );
}
