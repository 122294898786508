import styled from "styled-components";
import { Input } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import InfoIcon from "../SvgComponents/InfoIcon";
import Flex from "../general/Flex";
import FilledInfoIcon from "../SvgComponents/FilledInfoIcon";
import CloseIcon from "../SvgComponents/CloseIcon";
import Typography from "../moleculs/Typography.jsx";
import ButtonSecondary from "../moleculs/ButtonSecondary";
import ButtonGray from "../moleculs/ButtonGray";
import { BlockUserService } from "../../services/customers.js";
import GetAccountDeails from "../../hooks/customers/useGetAccountDetails.hook.jsx";

const { TextArea } = Input;
const StyledBlockUserModal = styled.div`
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  width: 400px;
  padding: 24px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  .header {
    margin-bottom: 20px;
    svg {
      cursor: pointer;
    }
  }
  .footer {
    margin-top: 24px;
    button {
      width: 48%;
    }
  }
`;

const StyledOverlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(77, 77, 77, 0.39);
  z-index: 99;
`;

export default function BlockUser({ status, setStatus }) {
  const [reason, setReason] = useState();
  const { id } = useParams();
  const { accountDeails, refetch } = GetAccountDeails(id);
  const [loading, setLoading] = useState(false);

  const handleBlockUser = () => {
    setLoading(true);
    BlockUserService(id, reason)
      .then(() => {
        refetch();
        setStatus(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    status && (
      <>
        <StyledOverlay className="overlay" />

        <StyledBlockUserModal>
          <div className="header">
            <Flex alignItems="center" justifyContent="space-between">
              <FilledInfoIcon />
              <CloseIcon />
            </Flex>
          </div>
          <div className="body">
            <Typography
              variant="p"
              text="Block user"
              color="#101828"
              size="18px"
              weight="500"
              padding="0 0 8px 0"
            />
            <Typography
              variant="p"
              text="Please write ban reason"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <TextArea
              placeholder="Ban reason"
              rows="3"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className="footer">
            <Flex justifyContent="space-between" alignItems="center">
              <ButtonSecondary text="Cancel" click={() => setStatus(false)} />
              <ButtonGray
                text="Confirm"
                click={() => handleBlockUser()}
                loading={loading}
              />
            </Flex>
          </div>
        </StyledBlockUserModal>
      </>
    )
  );
}
