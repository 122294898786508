import styled from "styled-components";
import { AutoComplete, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import Flex from "../../components/general/Flex";
import ShowLessIcon from "../../components/SvgComponents/ShowLessIcon";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import { GetPickupPoints } from "../../services/pickupPoints";
import { GetParcels } from "../../services/parcels";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";
import useCategories from "../../hooks/categories/useCategories.hook.jsx";
import useGetWarehouses from "../../hooks/warehouses/useWarehouses.hook.jsx";
import useSmartServices from "../../hooks/smartServices/useSmartServices.hook.jsx";
import DangerousIcon from "../../components/SvgComponents/DangerousIcon.jsx";
import MissingIcon from "../../components/SvgComponents/MissingIcon.jsx";

const { RangePicker } = DatePicker;
const StyledOrdersTopActions = styled.div`
  background: white;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 24px;

  .fly-sea {
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    span.ant-select-selection-placeholder {
      padding-top: 5px;
    }
  }

  .show-less {
    background: transparent;
    border: none;
    outline: none;
  }

  .actions-btns {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 36px;

    button {
      margin: 0 0 0 8px;
      height: 36px;
      align-items: center;
      display: flex;
    }
  }

  .row {
    height: ${({ more }) => (more ? "100%" : " 52px")};
    overflow: hidden;
  }

  &
    :where(.css-dev-only-do-not-override-1ipetx6).ant-select-multiple
    .ant-select-selection-overflow {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .show-more-icon {
    svg {
      transition: 200ms;
      transform: ${({ more }) => (more ? "rotate(0)" : "rotate(180deg)")};
    }
  }
`;

export default function OrdersTopActions({ setFilters }) {
  const [more, setMore] = useState(false);
  const [pickupPointOptions, setPickupPointOptions] = useState();
  const [parcelOptions, setParcelOptions] = useState();
  const [userInfo, setUserInfo] = useState(null);
  const [hasCost, setHasCost] = useState(false);
  const { usersList = [] } = usersAutocomplete(
    userInfo && userInfo?.user_info?.trim()?.length === 0 ? null : userInfo
  );
  const { categories } = useCategories();
  const [date, setDate] = useState();
  const [form] = Form.useForm();
  const [selectedParcel, setSelectedParcel] = useState();
  const { data: warehouses = [], isLoading } = useGetWarehouses();
  const { smartServices = [] } = useSmartServices();
  const [dispatch, setDispatch] = useState(null);

  const [selectedLabel, setSelectedLabel] = useState("");

  const handleSelect = (value, option) => {
    setSelectedLabel(option.label);
    form.setFieldsValue({ pickup_point_id: value });
  };

  const handleChange = (value) => {
    setSelectedLabel(value);
  };

  const handleFinish = (values) => {
    let newValues = {};
    if (date) {
      values["start_date"] = date[0];
      values["end_date"] = date[1];
    }
    Object.entries(values).forEach(([key, val]) => {
      if (val) {
        newValues[key] = val;
      }
    });
    if (newValues["parcel_id"]) {
      newValues["parcel_id"] = selectedParcel.key;
    }

    setFilters(newValues);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSearchPickupPoint = (value) => {
    setPickupPointOptions([]);
    if (value) {
      GetPickupPoints().then((res) => {
        let newOptions = res.data.data.data.map((pickupPoint, index) => {
          return {
            key: index,
            label: pickupPoint.address + " " + pickupPoint.name,
            value: pickupPoint.id,
          };
        });
        setPickupPointOptions(newOptions);
      });
    }
  };

  const handleSearchParcel = (value) => {
    setParcelOptions([]);
    if (value) {
      GetParcels({ name: value }).then((res) => {
        let newOptions = res.data.data.data.map((parcel) => {
          return {
            label: parcel.name,
            value: parcel.name,
            key: parcel.id,
          };
        });
        setParcelOptions(newOptions);
      });
    }
  };

  const handleChangeDate = (values) => {
    setDate(values);
  };

  const handleResetForm = () => {
    form.resetFields();
    setFilters({});
  };

  return (
    <StyledOrdersTopActions more={more}>
      <div>
        <Form
          form={form}
          labelCol={8}
          wrapperCol={4}
          layout="vertical"
          // initialValues={[{ orderStatus: "status1" }]}
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={16} className="row">
            <Col lg={4}>
              <Form.Item name="user_info">
                <AutoComplete
                  placeholder="Full name or GA"
                  onSearch={(val) => {
                    setUserInfo({ user_info: val });
                  }}
                  options={usersList}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="tracking_code" rules={[{ min: 7 }]}>
                <Input placeholder="Tracking code" />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="parcel_id">
                <AutoComplete
                  placeholder="Parcel"
                  onSearch={handleSearchParcel}
                  options={parcelOptions}
                  onSelect={(val, all) => setSelectedParcel(all)}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item>
                <RangePicker onChange={(_, value) => handleChangeDate(value)} />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="order_status">
                <Select placeholder="Order Status" style={{ height: 36 }}>
                  <Select.Option value="at_warehouse">
                    At Warehouse
                  </Select.Option>
                  <Select.Option value="on_the_way">On the way</Select.Option>
                  <Select.Option value="scan">Scanned</Select.Option>
                  <Select.Option value="in_georgia">In georgia</Select.Option>
                  <Select.Option value="received">Received</Select.Option>
                  <Select.Option value="refund">Refunded</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="warehouse_id">
                <Select
                  placeholder="All Warehouses"
                  options={warehouses}
                  style={{ height: 36 }}
                  onSelect={(_, el) => {
                    setDispatch(el?.dispatches);
                    form.setFieldValue("dispatch_type", null);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="is_declared">
                <Select placeholder="Declaration" style={{ height: 36 }}>
                  <Select.Option value="1">Declared</Select.Option>
                  <Select.Option value="0">Not Declared</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={2} className="fly-sea">
              <Form.Item name="dispatch_type">
                <Select style={{ height: 36 }}>
                  {dispatch?.map((item, index) => (
                    <Select.Option value={item.type} key={index}>
                      <div style={{ paddingTop: 2 }}>
                        <Flex alignItems={"center"}>
                          <img src={item?.icon} alt="dispatch" width={24} />
                          <span style={{ paddingLeft: 5 }}>{item.name}</span>
                        </Flex>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="comment">
                <Input placeholder="Comment" />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item name="pickup_point_id" style={{ display: "none" }}>
                <input />
              </Form.Item>
              <AutoComplete
                filterOption={(input, option) =>
                  option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Pickup Point"
                onSearch={handleSearchPickupPoint}
                options={pickupPointOptions}
                style={{ height: 36, width: "100%" }}
                optionLabelProp="label"
                onSelect={handleSelect}
                onChange={handleChange}
                value={selectedLabel}
              />
            </Col>

            <Col lg={4}>
              <Form.Item name="category_id">
                <Select placeholder="Select category" style={{ height: 36 }}>
                  {categories?.data?.data?.map((category, index) => (
                    <Select.Option value={category.id} key={index}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="services">
                <Select
                  style={{ height: 36 }}
                  placeholder="Service type"
                  options={[{ value: null, label: "All" }, ...smartServices]}
                  mode={"multiple"}
                  onSelect={(val, _) => {
                    if (!val) {
                      const newVal = smartServices?.map((sm) => sm.id);
                      form.setFieldValue("services", newVal);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="has_invoice">
                <Select
                  style={{ height: 36 }}
                  placeholder={"Invoice"}
                  options={[
                    { value: 1, label: "Has invoice" },
                    { value: 0, label: "Hasn't invoice" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="indicator">
                <Select
                  style={{ height: 36 }}
                  placeholder={"Indicators"}
                  options={[
                    {
                      value: "lost",
                      label: (
                        <Flex
                          alignItems={"center"}
                          style={{ color: "#5B6D7F" }}
                        >
                          {" "}
                          <MissingIcon margin={"0 8px 0 0"} />
                          Lost{" "}
                        </Flex>
                      ),
                    },
                    {
                      value: "dangerous",
                      label: (
                        <Flex
                          alignItems={"center"}
                          style={{ color: "#5B6D7F" }}
                        >
                          {" "}
                          <DangerousIcon margin={"0 8px 0 0"} />
                          Dangerous{" "}
                        </Flex>
                      ),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="cost">
                <Input placeholder={"Onex cost"} style={{ height: 36 }} />
              </Form.Item>
            </Col>

            <Col>
              <Input.Group compact>
                <Form.Item name="has_additional_cost">
                  <Select
                    style={{ width: 120, height: 36 }}
                    placeholder={"Add cost"}
                    options={[
                      { value: 0, label: "No add cost" },
                      { value: 1, label: "Add cost" },
                    ]}
                    onSelect={(val) => {
                      setHasCost(val);
                      form.setFieldValue("additional_cost", "");
                    }}
                  />
                </Form.Item>
                <Form.Item name="additional_cost">
                  <Input
                    placeholder={"Cost amount"}
                    disabled={!hasCost}
                    type={"number"}
                  />
                </Form.Item>
              </Input.Group>
            </Col>
          </Row>
          <div className="actions-btns">
            <button className="show-less" onClick={() => setMore(!more)}>
              <Flex alignItems="center">
                <div className="show-more-icon">
                  <ShowLessIcon />
                </div>
                <Typography
                  variant="span"
                  text={more ? "Show less" : "Show more"}
                  color="rgba(91, 109, 127, 1)"
                  padding="0 0 0 15px"
                />
              </Flex>
            </button>
            <ButtonSecondary text="Reset" click={() => handleResetForm()} />
            <ButtonPrimary
              text="Search"
              htmlType="submit"
              icon={<SearchIcon />}
            />
          </div>
        </Form>
      </div>
    </StyledOrdersTopActions>
  );
}
