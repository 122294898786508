import { Drawer, Form, Input, Switch } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import Flex from "../../components/general/Flex.jsx";
import ButtonCancel from "../../components/moleculs/ButtonCancel.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import TabList from "../../components/tab/TabList.jsx";
import OnexTooltip from "../../helpers/tooltip.jsx";
import InfoIcon from "../../components/SvgComponents/InfoIcon.jsx";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import { useIsActiveBlog } from "../../hooks/blog/useIsActiveBlog.hook.js";
import DeleteButton from "../../components/moleculs/DeleteButton.jsx";
import AlertCircleIcon from "../../components/SvgComponents/AlertCircleIcon";
import { useDeleteBlog } from "../../hooks/blog/useDeleteBlog.hook.js";
import DeleteModal from "../../components/modals/DeleteModal.jsx";
import { WarningIcon } from "../../components/SvgComponents/WarningIcon";
import { useCreateAndEditBlog } from "../../hooks/blog/useCreateAndEditBlog.hook.js";
import UploadImages from "../../components/moleculs/UploadImages.jsx";
import { blogInitialValue } from "./initial-value-blog-form.js";
import { useCheckEmptyArrItem } from "../../helpers/useCheckEmptyArrItem.js";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledCreateBlog = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .style_form {
    border-radius: 12px;
    background: #ffffff;
    padding: 16px;
  }
`;

export default function CreateBlogDrawer({
  reFetchBlogs = () => {},
  setBlog = () => {},
  blog,
  languages,
  permissions,
}) {
  const [languageId, setLanguageId] = useState(1);
  const [imageUrl, setImageUrl] = useState(null);
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const blogs = Form.useWatch("details", form);
  const isTrueAllItems = useCheckEmptyArrItem(blogs);

  useEffect(() => {
    if (typeof blog === "object") {
      blog?.blogsMl?.forEach((ml, i) => {
        Object.entries(ml).forEach(([key, val]) => {
          form.setFieldValue(["details", +ml?.language_id - 1, key], val);
        });
        setImageUrl((pre) => ({ ...pre, [+ml?.language_id]: ml?.image }));
      });
      setStatus(blog.is_active);
      if (blog?.language_id) {
        setLanguageId(blog.language_id);
        form.setFieldValue(
          ["details", blog?.language_id - 1, "language_id"],
          blog?.language_id
        );
      }
    }
  }, [blog]);

  const { mutate } = useCreateAndEditBlog(
    (data) => {
      setBlog(false);
      setImageUrl(null);
      openNotification(data?.data?.message);
      reFetchBlogs();
      form.resetFields();
      setLanguageId(1);
    },
    (e) => {
      Object.entries(e?.response?.data?.data).forEach(([key, val]) => {
        openNotificationError(val[0], "Something went wrong");
      });
    }
  );
  const createBlogs = (values) => {
    const formData = new FormData();
    if (values) {
      values?.details?.map((detail, index) => {
        if (isTrueAllItems[index]) {
          Object.entries(detail).forEach(([key, value]) => {
            if (value) {
              formData.append(`details[${index}][${key}]`, value);
            }
          });
        }
      });

      if (typeof blog === "object") {
        formData.append("_method", "put");
      }
      mutate({ id: blog?.id, formData });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const closeEditModal = () => {
    form.resetFields();
    setImageUrl(null);
    setBlog(false);
    setLanguageId(1);
  };

  const { mutate: mutateIsActive, isLoading } = useIsActiveBlog(
    (data) => {
      openNotification(data?.data?.message);
      setStatus(data?.data?.data?.is_active);
      reFetchBlogs();
    },
    (e) => {
      openNotificationError(
        "Blog Activate",
        e?.response?.data?.message || "something went wrong"
      );
    }
  );

  const { mutate: deleteBlog } = useDeleteBlog(
    (data) => {
      openNotification(data?.data?.message, "Success");
      setImageUrl(null);
      setOpen(false);
      form.resetFields();
      reFetchBlogs();
      setBlog(false);
      setLanguageId(1);
    },
    (e) => {
      openNotificationError("Activate", "something went wrong");
    }
  );

  return (
    <Drawer
      title=""
      placement="right"
      onClose={closeEditModal}
      open={blog}
      width="1112px"
      header={false}
    >
      <DeleteModal
        icon={<WarningIcon />}
        setStatus={setOpen}
        status={open}
        title={"Delete blog"}
        description={"Are you sure you want to delete blog"}
        setDelete={() => deleteBlog(blog?.id)}
      />
      <StyledCreateBlog>
        <Form
          initialValues={blogInitialValue}
          form={form}
          name="createBlog"
          layout="vertical"
          onFinish={createBlogs}
          onFinishFailed={onFinishFailed}
        >
          <div className="header">
            <Typography
              text={typeof blog === "object" ? "Edit blog" : "Create blog"}
              variant="p"
              color={primaryTextColor}
              size="22px"
              weight="500"
              padding={"0 0 16px 0"}
            />
            <Flex alignItems={"center"}>
              {typeof blog === "object" ? (
                <>
                  {PermissionFinder(permissions, "blog-delete") ? (
                    <DeleteButton
                      margin={"0 16px 0 0"}
                      text={"Delete Blog"}
                      icon={<AlertCircleIcon />}
                      click={() => {
                        setOpen(true);
                      }}
                      type="button"
                    />
                  ) : null}

                  <Typography
                    variant={"p"}
                    text={status ? "Active" : "Inactive"}
                    margin={"0 8px 0 0"}
                    size={"14px"}
                  />
                  <Switch
                    loading={isLoading}
                    checked={status}
                    style={{ margin: "0 16px 0 0" }}
                    onChange={(val) => {
                      mutateIsActive({ id: blog?.id, is_active: val });
                    }}
                  />
                </>
              ) : null}

              <ButtonCancel
                text="Cancel"
                margin="0 16px 0 0"
                type="button"
                click={closeEditModal}
              />
              <ButtonPrimary
                text={blog ? "Save" : "Create"}
                htmlType="submit"
                // click={() => createBlog()}
              />
            </Flex>
          </div>
          <Form.List name={"details"}>
            {(fields, { add, remove }) => {
              return (
                <div>
                  <TabList
                    data={languages}
                    isTrueAllItems={isTrueAllItems}
                    onChange={(key) => {
                      setLanguageId(key);
                      form.setFieldValue(
                        ["details", key - 1, "language_id"],
                        key
                      );
                    }}
                    value={languageId}
                  />

                  <div className={"style_form"}>
                    <Form.Item
                      name={[languageId - 1, "image"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Images.",
                        },
                      ]}
                      label={
                        <div style={{ display: "flex", padding: "16px 0" }}>
                          Image
                          <OnexTooltip
                            content={
                              <span>
                                Dimensions{" "}
                                <span style={{ fontWeight: 700 }}>
                                  315 × 315px
                                </span>{" "}
                                <br />
                                SVG, JPG, PNG
                              </span>
                            }
                            direction={"right"}
                          >
                            <InfoIcon
                              color={"#5B6D7F"}
                              style={{ margin: "0 0 0 4px" }}
                            />
                          </OnexTooltip>
                        </div>
                      }
                    >
                      <UploadImages
                        imageUrl={imageUrl ? imageUrl[languageId] : ""}
                        setImageUrl={(img) =>
                          setImageUrl({ ...imageUrl, [languageId]: img })
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name={[languageId - 1, "title"]}
                      label="Blog title"
                      rules={[
                        {
                          required: true,
                          message: "Missing Blog Title.",
                        },
                      ]}
                    >
                      <Input
                        style={{ minHeight: "52px" }}
                        placeholder={"Blog title"}
                        maxLength={32}
                        showCount={true}
                      />
                    </Form.Item>
                    <div style={{ margin: "32px 0 0 0" }}>
                      <Form.Item
                        name={[languageId - 1, "description"]}
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Missing Description.",
                          },
                        ]}
                      >
                        <Input
                          style={{ minHeight: "52px" }}
                          placeholder={"Description"}
                          maxLength={100}
                          showCount={true}
                        />
                      </Form.Item>
                    </div>
                    <Flex
                      className="mt-16"
                      style={{ width: "100%", margin: "32px 0 0 0" }}
                    >
                      <div className="mr-16" style={{ width: "100%" }}>
                        <Form.Item
                          name={[languageId - 1, "url"]}
                          label={"URL"}
                          rules={[
                            {
                              required: true,
                              message: "Missing URL.",
                            },
                          ]}
                        >
                          <Input placeholder={"URL"} />
                        </Form.Item>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Form.Item
                          name={[languageId - 1, "button_name"]}
                          label={"Button name"}
                          rules={[
                            {
                              required: true,
                              message: "Missing Button name.",
                            },
                          ]}
                        >
                          <Input placeholder={"Button name"} />
                        </Form.Item>
                      </div>
                    </Flex>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </StyledCreateBlog>
    </Drawer>
  );
}
