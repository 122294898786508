import axios from "../libs/axios.js";

export const blogApi = {
  async sortBlog(data) {
    return await axios.post("/blogs/sort-priority", data);
  },
  async fetchBlog() {
    return await axios.get("/blogs");
  },
  async fetchLanguages() {
    return await axios.get("/languages");
  },

  async isActiveBlog(data) {
    return await axios.put(`blogs/is-active/${data?.id}`, {
      is_active: data?.is_active,
    });
  },
  async deleteBlog(id) {
    return await axios.delete(`blogs/${id}`);
  },
  async createAndEditBlog({ id, formData }) {
    if (id) {
      return await axios.post(`/blogs/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      return await axios.post("/blogs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
  },
};
