import { useQuery } from "react-query";
import { GetSingleExpected } from "../../services/expected.js";

export default function useSingleExpected(id) {
  const { data, refetch } = useQuery(
    ["single-expected", id],
    () => GetSingleExpected(id),
    {
      enabled: !!id,
      staleTime: Infinity,
    }
  );

  return { data, refetch };
}
