import styled from "styled-components";
import Flex from "../general/Flex.jsx";
import Typography from "../moleculs/Typography.jsx";
import ButtonCancel from "../moleculs/ButtonCancel";
import DeleteButton from "../moleculs/DeleteButton";
import { Modal } from "antd";

const ModalBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const StyledOverlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(77, 77, 77, 0.39);
  z-index: 99;
`;

const DeleteModal = ({
  icon,
  title,
  description,
  status,
  setStatus,
  setDelete = () => {},
}) => {
  return status ? (
    <>
      <Modal
        open={status}
        onCancel={() => setStatus(false)}
        footer={null}
        width={"330px"}
      >
        <ModalBody>
          <Flex>{icon}</Flex>
          <Typography
            text={title}
            variant="p"
            color={"#101828"}
            size="18px"
            weight="500"
            padding={"20px 0 0 0"}
          />
          <Typography
            text={description}
            variant="p"
            color={"#5B6D7F"}
            size="14px"
            weight="400"
            padding={"8px 0 0 0"}
          />
          <Flex style={{ margin: "24px 0 0 0", width: "100%" }}>
            <DeleteButton
              click={() => setStatus(false)}
              style={{ width: "100%" }}
              text={"Cancel"}
              type="button"
            />
            <ButtonCancel
              margin={"0 0 0 12px"}
              text={"Delete"}
              click={setDelete}
              style={{ width: "100%" }}
              type="button"
            />
          </Flex>
        </ModalBody>
      </Modal>
    </>
  ) : null;
};
export default DeleteModal;
