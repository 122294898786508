import styled from "styled-components";
import { Form, notification } from "antd";
import Flex from "../../../components/general/Flex.jsx";
import ButtonPrimary from "../../../components/moleculs/ButtonPrimary";
import ButtonCancel from "../../../components/moleculs/ButtonCancel";
import OrderDetailsCustomer from "./DetailsCustomer.jsx";
import Header from "../Edit/Header.jsx";
import Comment from "../Edit/Comment.jsx";
import Information from "../Edit/Information";
import Times from "../Edit/Times";
import Costs from "../Edit/Costs";
import useGetWarehouses from "../../../hooks/warehouses/useWarehouses.hook.jsx";
import dayjs from "dayjs";
import { EditOrderService } from "../../../services/orders.js";

const StyledEdit = styled.div`
  .two-select-2 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .edit-item {
    margin-right: 16px;
  }

  label[for="invoice"] {
    margin-top: 0px;
    display: block;
    border: 1px dashed #ddd;
    padding: 6px 16px;
    border-radius: 12px;
    cursor: pointer;
  }

  .info-block-item {
    border-left: none !important;
    padding-right: 16px !important;
  }

  .status-times-items-wrapper {
    .status-times-item {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  .status-times-item {
    width: 100%;

    .ant-picker {
      width: 100%;
    }
  }

  .order-edit-actions {
    position: absolute;
    top: 30px;
    right: 30px;
  }
`;

export default function OrderDetailsEdit({
  setEditStatus,
  order,
  refetch,
  refetchOrders,
}) {
  const [api, contextHolder] = notification.useNotification();
  const { data } = useGetWarehouses();

  const getStatusDate = (key) => {
    let result = order?.histories.find((item) => item.key === key);
    return result?.date || null;
  };

  const getChargeCosts = (key) => {
    let result = order?.additional_costs.find((item) => item.type === key);
    return result?.cost || null;
  };
  const handleSave = ({
    expected_date,
    at_warehouse_date,
    on_way_date,
    in_georgia_date,
    received_date,
    ...values
  }) => {
    const sendData = {
      expected_date: expected_date
        ? dayjs(expected_date).format("YYYY-MM-DD")
        : null,
      at_warehouse_date: at_warehouse_date
        ? dayjs(at_warehouse_date).format("YYYY-MM-DD")
        : null,
      on_way_date: on_way_date ? dayjs(on_way_date).format("YYYY-MM-DD") : null,
      in_georgia_date: in_georgia_date
        ? dayjs(in_georgia_date).format("YYYY-MM-DD")
        : null,
      received_date: received_date
        ? dayjs(received_date).format("YYYY-MM-DD")
        : null,
      ready_for_pickup: order?.ready_for_pickup,
      ...values,
    };
    Object.keys(sendData).forEach((key) => {
      if (!sendData[key]) {
        delete sendData[key];
      }
    });

    EditOrderService(order?.id, sendData)
      .then((res) => {
        if (res.data.success) {
          api.success({
            message: "Success !",
            description: res.data.message,
            placement: "topRight",
          });
          refetch().then(() => {
            setEditStatus(false);
            refetchOrders();
          });
        }
      })
      .catch((err) => {
        Object.entries(err.response.data.data).map((error) => {
          api.error({
            message: "Error !",
            description: error[1][0],
            placement: "topRight",
          });
        });
      });
  };

  const formInitialValues = {
    tracking_code: order?.tracking_code,
    warehouse_id: order?.warehouse?.id,
    status: order?.status,
    dispatch_type: order?.dispatch?.type,
    ready_for_pickup: 0,
    declaration_price: order?.declaration_price || 0,
    declaration_currency: order?.declaration_currency || "",
    is_dangerous: order?.is_dangerous,
    weight: order?.weight,
    v_weight: order?.v_weight,
    cost: order?.cost,
    parcel_id: order?.parcel?.id,
    box_id: order?.box?.id,
    height: order?.height,
    width: order?.width,
    length: order?.length,
    customer_comment: order?.customer_comment || "",
    expected_date: order?.expected?.created_at
      ? dayjs(order?.expected?.created_at, "YYYY-MM-DD")
      : null,
    at_warehouse_date: getStatusDate("at_warehouse_date")
      ? dayjs(getStatusDate("at_warehouse_date"), "YYYY-MM-DD")
      : null,
    on_way_date: getStatusDate("on_way_date")
      ? dayjs(getStatusDate("on_way_date"), "YYYY-MM-DD")
      : null,
    in_georgia_date: getStatusDate("in_georgia_date")
      ? dayjs(getStatusDate("in_georgia_date"), "YYYY-MM-DD")
      : null,
    received_date: getStatusDate("received_date")
      ? dayjs(getStatusDate("received_date"), "YYYY-MM-DD")
      : null,
    additional_cost: getChargeCosts("additional_cost") || 0,
    charge_cost: getChargeCosts("charge_cost") || 0,
    ups_cost: getChargeCosts("ups_cost") || 0,
  };
  const [form] = Form.useForm();

  return (
    <StyledEdit onClick={(e) => e.stopPropagation()}>
      {contextHolder}
      <Form
        form={form}
        name={"order-edit"}
        layout={"vertical"}
        onFinish={handleSave}
        onFinishFailed={handleSave}
        initialValues={formInitialValues}
      >
        <Header
          form={form}
          setEditStatus={setEditStatus}
          warehouses={data}
          order={order}
          handleSave={handleSave}
        />
        <Comment comment={order?.customer_comment} />
        <Information order={order} />
        <Times order={order} />
        <Costs />
        <OrderDetailsCustomer order={order} />
        <Flex className={"order-edit-actions"}>
          <ButtonCancel
            text="Cancel"
            margin="0 16px 0 0"
            click={() => setEditStatus(false)}
          />
          <Form.Item>
            <ButtonPrimary
              text="Save"
              // click={() => handleSave()}
              htmlType={"submit"}
            />
          </Form.Item>
        </Flex>
      </Form>
    </StyledEdit>
  );
}
