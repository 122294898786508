import { Form, Input, Select } from "antd";
import Flex from "../../../components/general/Flex.jsx";
import Typography from "../../../components/moleculs/Typography.jsx";
import Tag from "../../../components/moleculs/Tag.jsx";

export default function Costs() {
  return (
    <div className="info-block" style={{ width: "100%" }}>
      <Flex>
        <div className="p-r-16">
          <Form.Item name={"additional_cost"} label={"Add. cost"}>
            <Input value="0.4" suffix="$" style={{ width: 160 }} />
          </Form.Item>
        </div>
        <div className="p-r-16">
          <Form.Item name={"charge_cost"} label={"Add. charge cost"}>
            <Input value="0.4" suffix="$" style={{ width: 160 }} />
          </Form.Item>
        </div>
        <div className="p-r-16">
          <Form.Item name={"ups_cost"} label={"UPS cost"}>
            <Input value="0.4" suffix="$" style={{ width: 160 }} />
          </Form.Item>
        </div>
        {/* <div className="p-r-16"> */}
        {/*   <Typography */}
        {/*     text="Pay type" */}
        {/*     variant="p" */}
        {/*     color="#3B5166" */}
        {/*     size="14px" */}
        {/*     weight="500" */}
        {/*     padding="0 0 5px 0" */}
        {/*   /> */}
        {/*   <Select style={{ width: 160 }} defaultValue="Invoice"> */}
        {/*     <Select.Option> */}
        {/*       <Tag text="Invoice" background=" #EDFBFE" color="#47BFE1" /> */}
        {/*     </Select.Option> */}
        {/*   </Select> */}
        {/* </div> */}
        {/* <div className="p-r-16"> */}
        {/*   <Typography */}
        {/*     text="Customs service" */}
        {/*     variant="p" */}
        {/*     color="#3B5166" */}
        {/*     size="14px" */}
        {/*     weight="500" */}
        {/*     padding="0 0 5px 0" */}
        {/*   /> */}
        {/*   <Select style={{ width: 160 }} defaultValue="Registered"> */}
        {/*     <Select.Option> */}
        {/*       <Tag text="Registered" background="#FFF5EB" color="#E58C35" /> */}
        {/*     </Select.Option> */}
        {/*   </Select> */}
        {/* </div> */}
      </Flex>
    </div>
  );
}
