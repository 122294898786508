import styled from "styled-components";
import { useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import { Loading } from "../../components/general/Loading.jsx";
import NotSearchResult from "../../components/SvgComponents/NotSearchResult.jsx";
import Flex from "../../components/general/Flex.jsx";
import { Pagination } from "antd";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import plus from "../../assets/images/plus-icon.svg";
import { AddTestUsersModal } from "./AddTestUsersModal.jsx";
import { TableTestUsers } from "./TableTestUsers.jsx";
import { testUsersAutocomplete } from "../../hooks/TestUsers/useTestUsersAutocomplete.hook.js";
import TestUsersTopActions from "./TopActions.jsx";

const StyledTestUsers = styled.div`
  min-height: 100vh;
  padding-top: 24px;
  padding-bottom: 24px;

  .total {
    color: #5dba2f;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
  }

  .customers-top {
    @media (max-width: 10010px) {
      flex-wrap: wrap !important;
    }
  }

  .ant-table-thead > tr > th {
    background: #e7e9ec;
  }

  .ant-table-wrapper {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    max-height: 70vh;
    width: 100%;
    background: #ffffff;
    margin-top: 16px;
    margin-bottom: 24px;
    overflow: auto;
  }

  .inactive-status {
    background: #ffeced;
    color: #fc4447;
  }

  .active-status {
    background: #eff8ea;
    color: #5dba2f;
  }

  .ant-table-row {
    cursor: pointer;
  }
`;

export default function TestUsers() {
  const [filters, setFilters] = useState({ is_test: 1 });
  const [addTest, setAddTest] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const {
    usersList = [],
    refetch,
    meta,
    isLoading,
  } = testUsersAutocomplete(filters);

  return (
    <StyledTestUsers className="test-users">
      <AddTestUsersModal
        setFilters={setFilters}
        filters={filters}
        refetch={refetch}
        addTest={addTest}
        setAddTest={setAddTest}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
      />
      <div className="page-title">
        <Typography
          text="Test Users"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <TestUsersTopActions
          setFilters={setFilters}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />
        <Flex alignItems={"center"} style={{ padding: "16px 0" }}>
          <ButtonSecondary
            icon={<img alt="" src={plus} />}
            style={{ height: 35, width: "160px" }}
            text="Add Test user"
            background="#5B6D7F"
            color="white"
            click={() => setAddTest(true)}
          />
        </Flex>
      </div>
      {isLoading ? (
        <Loading />
      ) : usersList?.length > 0 ? (
        <TableTestUsers
          usersList={usersList}
          reFetch={refetch}
          page={filters.page}
        />
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ height: "80vh" }}
        >
          <NotSearchResult />
          <Typography
            variant="h2"
            text="No users found"
            size="20px"
            weight="500"
            lineHeight={"30px"}
            color="#3B5166"
            padding="16px 0 0 0"
            style={{ maxWidth: 400 }}
            align="center"
          />
        </Flex>
      )}

      <div className={"new-pagination"}>
        <Pagination
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page) => setFilters({ ...filters, page })}
          defaultPageSize={meta?.per_page || 15}
          defaultCurrent={meta?.current_page || 1}
        />
      </div>
    </StyledTestUsers>
  );
}
