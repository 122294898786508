import { useQuery } from "react-query";
import { GetCategoriesService } from "../../services/categories.js";

export default function useCategories(type = "order") {
  const { data: categories } = useQuery(
    ["categories", type],
    () => GetCategoriesService(type),
    {
      staleTime: Infinity,
      select: ({ data }) => {
        return data?.data?.map((category, i) => ({
          key: i,
          value: category?.id || "",
          label: category?.name || "",
          ...category,
        }));
      },
    }
  );
  return { categories };
}
