import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import React, { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core.js";
import * as am4charts from "@amcharts/amcharts4/charts.js";
import useDashboardData from "../../hooks/Dashboard/useDashboardData.hook.jsx";
import am4themes_animated from "@amcharts/amcharts4/themes/animated.js";
import Flex from "../../components/general/Flex.jsx";
import LeftBottomArrowIcon from "../../components/SvgComponents/LeftBottomArrow.jsx";
import TopRightArrowIcon from "../../components/SvgComponents/TopRightArrow.jsx";

const StyledByGender = styled.div`
  padding: 24px;
  background: white;
  border-radius: 12px;
  position: relative;
  .filter {
    position: absolute;
    top: 24px;
    right: 24px;
    color: #b3bbc4;
    @media (max-width: 768px) {
      top: 10px;
      right: 10px;
    }
  }
  #usersByGender {
    @media (max-width: 768px) {
      height: auto !important;
    }
  }
  .legend {
    .male {
      background: #3fcc75;
    }
    .female {
      background: #fb4ed5;
    }
    .not-specified {
      background: #4ed2f7;
    }
  }
`;

export default function UsersByGender({
  dashboardData,
  isLoading,
  filterType,
}) {
  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    const chart = am4core.create("usersByGender", am4charts.PieChart);

    if (dashboardData) {
      // Add dataaa
      chart.data = [
        {
          category: "Male",
          litres: dashboardData?.data?.registration_by_gender_info?.males_total,
          color: "#3FCC75",
        },
        {
          category: "Female",
          litres:
            dashboardData?.data?.registration_by_gender_info?.females_total,
          color: "#FB4ED5",
        },
        {
          category: "Not specified",
          litres:
            dashboardData?.data?.registration_by_gender_info?.unspecified_total,
          color: "#4ED2F7",
        },
      ];

      // Add and configure Series
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "category";
      pieSeries.dataFields.hidden = "hidden";
      pieSeries.slices.template.propertyFields.fill = "color";

      // Let's cut a hole in our Pie chart the size of 40% the radius
      chart.innerRadius = am4core.percent(40);

      // Disable ticks and labels
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      // Disable tooltips
      pieSeries.slices.template.tooltipText = "{category}: {litres}";
    }
  }, [dashboardData]);

  return (
    <StyledByGender>
      <div className="filter">
        <Typography
          xsSize={"12px"}
          text={`This ${filterType}`}
          variant={"p"}
          size={"14px"}
          weight={"400"}
          color={"#B3BBC4"}
        />
      </div>
      <Typography
        variant={"h5"}
        text={"Gender"}
        color={"#262626"}
        size={"24px"}
        weight={"500"}
        padding={"0 0 15px 0"}
      />

      <Flex alignItems={"center"}>
        <div id="usersByGender" style={{ width: "60%", height: "280px" }}></div>
        <div className={"legends"}>
          <div className="legend">
            <Flex alignItems={"center"}>
              <div className="legend-icon male"></div>
              <p>
                Male
                <span>
                  {
                    dashboardData?.data?.registration_by_gender_info
                      ?.males_total
                  }
                </span>
                <span className={"percent"}>
                  (
                  {
                    dashboardData?.data?.registration_by_gender_info
                      ?.males_percentage_in_completed
                  }
                  %)
                </span>
              </p>
            </Flex>
          </div>
          <div className="legend">
            <Flex alignItems={"center"}>
              <div className="legend-icon female"></div>
              <p>
                Female
                <span>
                  {
                    dashboardData?.data?.registration_by_gender_info
                      ?.females_total
                  }
                </span>
                <span className={"percent"}>
                  (
                  {
                    dashboardData?.data?.registration_by_gender_info
                      ?.females_percentage_in_completed
                  }
                  %)
                </span>
              </p>
            </Flex>
          </div>
          <div className="legend">
            <Flex alignItems={"center"}>
              <div className="legend-icon not-specified"></div>
              <p>
                Not specified
                <span>
                  {
                    dashboardData?.data?.registration_by_gender_info
                      ?.unspecified_total
                  }
                </span>
                <span className={"percent"}>
                  (
                  {
                    dashboardData?.data?.registration_by_gender_info
                      ?.unspecified_percentage_in_completed
                  }
                  %)
                </span>
              </p>
            </Flex>
          </div>
        </div>
      </Flex>
    </StyledByGender>
  );
}
