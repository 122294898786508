import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import { useActionsHistory } from "../../hooks/useActionsHistory.hook.js";
import { useState } from "react";
import ActionSearch from "./ActionSearch";
import { Loading } from "../../components/general/Loading.jsx";
import { TableActions } from "./TableActions.jsx";
import NotSearchResult from "../../components/SvgComponents/NotSearchResult.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import { Pagination } from "antd";

const StyledActions = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;

  .total {
    color: #5dba2f;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
  }

  .customers-top {
    @media (max-width: 10010px) {
      flex-wrap: wrap !important;
    }
  }

  .ant-table-thead > tr > th {
    background: #e7e9ec;
  }

  .table-wrapper {
    border-radius: 8px;
    width: 100%;
    background: #ffffff;
    margin-top: 16px;
    margin-bottom: 24px;
    overflow: hidden;

    .ant-table-cell-scrollbar {
      background: #e7e9ec;
    }

    .ant-table-cell {
      vertical-align: top;
    }
  }

  .role-td {
    background: ${({ backgroundTd }) => backgroundTd};
    border-radius: 16px;
    width: min-content;
    padding: 2px 10px 2px 10px;
    white-space: nowrap;
  }

  ._auto-complete {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
    outline: none;
  }
`;

export default function ActionsHistory() {
  const [filter, setFilter] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const { actionList, meta, isLoading } = useActionsHistory(filter);

  return (
    <StyledActions>
      <div className="page-title">
        <Typography
          text="Actions history"
          color="#262626"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>

      <ActionSearch setFilter={setFilter} />

      {isLoading ? (
        <Loading />
      ) : actionList?.length > 0 ? (
        <TableActions actionList={actionList} />
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ height: "80vh" }}
        >
          <NotSearchResult />
          <Typography
            variant="h2"
            text="No members found"
            size="20px"
            weight="500"
            lineHeight={"30px"}
            color="#3B5166"
            padding="16px 0 0 0"
            style={{ maxWidth: 400 }}
            align="center"
          />
          <Typography
            variant="h2"
            text={
              searchValue
                ? `Your search “${searchValue}” did not match any information. Please try again.`
                : "Once there will be members they will appear here"
            }
            size="20px"
            weight="400"
            style={{ maxWidth: 500 }}
            lineHeight={"30px"}
            color="#5B6D7F"
            align="center"
          />
          {searchValue ? (
            <ButtonSecondary
              text="Reset"
              click={() => {
                setSearchValue("");
                setFilter({});
              }}
            />
          ) : (
            ""
          )}
        </Flex>
      )}
      <div className={"new-pagination"}>
        <Pagination
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page) => setFilter({ ...filter, page })}
          defaultPageSize={meta?.per_page || 10}
          defaultCurrent={meta?.current_page || 1}
        />
      </div>
    </StyledActions>
  );
}
