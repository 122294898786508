import styled from "styled-components";
import Flex from "../general/Flex";
import CloseIcon from "../SvgComponents/CloseIcon";

const StyledModal = styled.div`
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  z-index: 999;
  width: ${({ width }) => (width ? width + "px" : "100px")};

  header {
    margin-bottom: 20px;

    .close {
      cursor: pointer;
    }
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.46);
  z-index: 99;
`;

export default function Modal({ children, icon, width, status, setStatus }) {
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setStatus(false);
    }
  });
  return status ? (
    <div>
      <StyledOverlay
        className="overlay"
        onClick={() => setStatus(false)}
      ></StyledOverlay>
      <StyledModal width={width}>
        <header>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            {icon}
            <div className="close" onClick={() => setStatus(false)}>
              <CloseIcon />
            </div>
          </Flex>
        </header>
        {children}
      </StyledModal>
    </div>
  ) : null;
}
