import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import dayjs from "dayjs";
import { Table } from "antd";
import { useState } from "react";
import { WarningIcon } from "../../components/SvgComponents/WarningIcon.jsx";
import DeleteModal from "../../components/modals/DeleteModal.jsx";

import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import prime from "../../assets/images/prime.svg";
import { order_icon } from "../../assets/images/prime/index.js";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import { deleteTestUserHook } from "../../hooks/TestUsers/deleteTestUser.hook.js";
import { clearTestUserData } from "../../hooks/TestUsers/clearTestUserData.js";

export const TableTestUsers = ({ usersList, reFetch = () => {}, page = 1 }) => {
  const permissions = JSON.parse(localStorage.getItem("permissions")) || {};
  const actionAvailable = (str) => {
    const test_users = permissions["test-user"];
    if (!test_users) return false;
    return test_users.some((item) => item.name === str);
  };

  const [isDelete, setIsDelete] = useState(false);
  const [testUser, setTestUser] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { mutate: deleteUsers } = deleteTestUserHook(
    (data) => {
      openNotification("Delete Test User", "Test User successfully deleted");
      reFetch();
      setIsDelete(false);
      setTestUser(null);
      setSelectedUsers([]);
    },
    (e) => {
      console.log(e);
      openNotificationError("Delete Test User", "something went wrong");
    }
  );

  const { mutate: clearData } = clearTestUserData(
    (data) => {
      openNotification("Delete Test User", "Test User successfully deleted");
      reFetch();
      setIsDelete(false);
    },
    (e) => {
      console.log(e);
      openNotificationError("Delete Test User", "something went wrong");
    }
  );
  const deleteTestUser = (ids) => {
    deleteUsers({ ids: ids });
  };
  const clearTestUserRelations = (ids) => {
    clearData(ids);
  };
  const columns = [
    {
      title: "Full name",
      dataIndex: "full_name",
      key: "name",
    },
    {
      title: "Total orders",
      dataIndex: "total_orders",
      key: "total_orders",
      sorter: (a, b) => a.count - b.count,
    },
    {
      title: "Orders",
      dataIndex: "orders",
      key: "orders",
    },

    {
      title: "Created date",
      dataIndex: "created_date",
      key: "created_date",
      sorter: (a, b) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
    },
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const dataSource = usersList?.map((el, i) => ({
    key: i,
    count: el?.orders_count,
    created: el?.created_et,
    id: el?.id,
    full_name: (
      <Flex>
        <Typography
          text={i + (page - 1) * 15 + 1}
          variant={"p"}
          size={"14px"}
        />
        <Typography
          text={el?.full_name + " " + el?.recipient?.user_code || ""}
          variant={"p"}
          size={"14px"}
          margin={"0 16px 0 16px"}
        />
        <img alt={"prime"} src={prime} />
      </Flex>
    ),
    orders: (
      <Flex>
        {el?.orders_count_by_country?.length
          ? el?.orders_count_by_country?.map((item, index) => (
              <Flex
                key={index}
                alignItems={"center"}
                style={{ margin: "0 0 0 8px" }}
              >
                <img
                  alt={`country${index}`}
                  width={24}
                  height={24}
                  src={`https://devbackadmin.onex.ge/storage/images/warehouses/${item?.round_flag}`}
                />
                <Typography
                  margin={"0 0 0 8px"}
                  weight={400}
                  variant={"p"}
                  color={"#5B6D7F"}
                  text={item?.total}
                  size={"14px"}
                />
              </Flex>
            ))
          : "-"}
      </Flex>
    ),
    total_orders: (
      <Flex alignItems={"center"}>
        <img alt={"order"} src={order_icon} />
        <Typography
          text={el?.orders_count || "0"}
          className={"role-td"}
          align={"start"}
          variant={"p"}
          size={"12px"}
          margin={"0 0 0 8px"}
          color={"#3B5166"}
        />
      </Flex>
    ),
    created_date: (
      <Flex>
        <Typography
          text={
            el?.created_et ? dayjs(el?.created_et).format("DD.MM.YYYY") : ""
          }
          variant={"p"}
          size={"14px"}
        />
      </Flex>
    ),
    actions: (
      <Flex gap="12px" alignItems="center">
        {actionAvailable("test-user-relations-delete") ? (
          <ButtonSecondary
            text="Clear data"
            onClick={(e) => clearTestUserRelations([el?.id])}
          />
        ) : (
          <></>
        )}
        {actionAvailable("test-user-delete") ? (
          <ButtonPrimary
            text="Delete user"
            onClick={(e) => {
              setIsDelete(true);
              setTestUser([el?.id]);
              e.stopPropagation();
            }}
          />
        ) : (
          <></>
        )}
      </Flex>
    ),
  }));

  // if (sort) {
  //   dataSource.sort((a, b) => {
  //     if (a.count > b.count) {
  //       return 1;
  //     }
  //     if (a.count < b.count) {
  //       return -1;
  //     }
  //   });
  // }

  return (
    <Flex justifyContent="space-between" direction={"column"}>
      <DeleteModal
        icon={
          <WarningIcon
            style={{ background: "#FFF5EB", borderRadius: "28px" }}
            size={"42px"}
          />
        }
        setStatus={setIsDelete}
        status={isDelete}
        title={"Delete Test user"}
        description={"Are you sure you want to delete test user"}
        setDelete={() => deleteTestUser(testUser ? testUser : selectedUsers)}
      />
      {selectedUsers?.length ? (
        <Flex
          gap="12px"
          alignItems="center"
          justifyContent="flex-end"
          style={{ marginBottom: "16px", width: "100%" }}
        >
          {actionAvailable("test-user-relations-delete") ? (
            <ButtonSecondary
              text="Clear data"
              onClick={(e) => clearTestUserRelations({ ids: selectedUsers })}
            />
          ) : (
            <></>
          )}
          {actionAvailable("test-user-delete") ? (
            <ButtonPrimary
              text="Delete user"
              onClick={(e) => {
                setIsDelete(true);
                e.stopPropagation();
              }}
            />
          ) : (
            <></>
          )}
        </Flex>
      ) : (
        <></>
      )}
      <Table
        dataSource={dataSource}
        columns={columns}
        style={{ width: "100%" }}
        height={"60vh"}
        pagination={false}
        rowSelection={{
          onChange: (i, selectedRowsArray) => {
            setSelectedUsers(selectedRowsArray.map((item) => item.id));
          },
        }}
      />
    </Flex>
  );
};
