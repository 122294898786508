import { useQuery } from "react-query";
import { balanceApi } from "../../services/balance.js";

export const useBalancesPaymentTypeHook = () => {
  const {
    isError,
    isLoading,
    data: paymentType,
    refetch,
  } = useQuery(
    ["balances-payment-type"],
    () => balanceApi.fetchBalancePaymentType(),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) =>
        data?.data?.data?.map((type, i) => ({
          key: i,
          value: type?.id || "",
          label: type?.name,
        })),
    }
  );
  return {
    isLoading,
    paymentType,
    refetch,
  };
};
