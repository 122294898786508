import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import ScanTopActions from "./TopActions";
import ScanTable from "./Table";

const StyledScan = styled.div`
  padding-top: 24px;
`;

export default function Scan() {
  return (
    <StyledScan>
      <div className="page-title">
        <Typography
          text="Scan"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <ScanTopActions />
      <ScanTable />
    </StyledScan>
  );
}
