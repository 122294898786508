import { Col, Row } from "antd";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import Flex from "../../components/general/Flex";
import LockerIcon from "../../components/SvgComponents/LockerIcon";
import GetAccountDeails from "../../hooks/customers/useGetAccountDetails.hook.jsx";
import HomeDelivery from "../../components/SvgComponents/HomeDelivery";

const StyledPickupPoint = styled.div`
  margin-top: 20px;
`;

export default function CustomerSinglePickupPoint() {
  const { id } = useParams();

  const { accountDeails } = GetAccountDeails(id);

  return (
    <StyledPickupPoint>
      <Row>
        <Col lg={8}>
          <div className="item">
            <Typography
              text="Main Pickup Point"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
              variant="p"
            />
            <Typography
              text={accountDeails?.user_pickup_point?.pickup_point?.name}
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
              variant="p"
            />
          </div>
        </Col>
        <Col lg={8}>
          <div className="item">
            <Typography
              text="Delivery type"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
              variant="p"
            />
            <Flex>
              {accountDeails?.user_pickup_point?.delivery_type === "branch" ? (
                <LockerIcon />
              ) : (
                <HomeDelivery />
              )}
              <Typography
                text={accountDeails?.user_pickup_point?.delivery_type}
                color={primaryTextColor}
                size="14px"
                weight="400"
                variant="p"
                padding="0 0 0 8px"
              />
            </Flex>
          </div>
        </Col>
        <Col lg={8}>
          <div className="item">
            <Typography
              text="Address"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
              variant="p"
            />
            <Typography
              text={accountDeails?.user_pickup_point?.pickup_point?.address}
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
              variant="p"
            />
          </div>
        </Col>
      </Row>
    </StyledPickupPoint>
  );
}
