import { Drawer, Form, Switch } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useFetchLanguages } from "../../../hooks/blog/useFetchLanguages.hook.js";
import Typography from "../../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../../constants.js";
import Flex from "../../../components/general/Flex.jsx";
import ButtonCancel from "../../../components/moleculs/ButtonCancel.jsx";
import ButtonPrimary from "../../../components/moleculs/ButtonPrimary.jsx";
import {
  openNotification,
  openNotificationError,
} from "../../../helpers/notification.js";
import DeleteButton from "../../../components/moleculs/DeleteButton.jsx";
import AlertCircleIcon from "../../../components/SvgComponents/AlertCircleIcon.jsx";
import DeleteModal from "../../../components/modals/DeleteModal.jsx";
import { WarningIcon } from "../../../components/SvgComponents/WarningIcon.jsx";
import { shopsInitialValue } from "../initial-value-shops-form.js";
import { MainParts } from "./MainParts.jsx";
import { SelectParts } from "./SelectParts.jsx";
import { DetailsParts } from "./DetailsParts.jsx";
import { CheckboxFeatures } from "./CheckboxFeatures.jsx";
import { useCreateAndEditShops } from "../../../hooks/shops/useCreateAndEditShops.hook.js";
import { useIsActiveShop } from "../../../hooks/shops/useIsActiveShop.hook.js";
import { useDeleteShops } from "../../../hooks/shops/useDeleteShops.hook.js";
import { PermissionFinder } from "../../../hooks/permissionFinder.hook.js";

const StyledCreateShops = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .style_form {
    border-radius: 12px;
    background: #ffffff;
    padding: 16px;
  }

  & .checked-item {
    background: #ffffff;
    margin: 16px 16px 18px 0;
    padding: 8px 16px;
    border-radius: 12px;
    transition: width 0.2ms;

    & label {
      font-family: "Montserrat arm2";
      font-size: 14px;
    }

    & .blog_select {
      margin-bottom: 0 !important;
      width: 400px;
      transition: all 0.2s;
      overflow: hidden;
    }

    & .inactive {
      width: 0px;
    }
  }

  &
    :where(
      .css-dev-only-do-not-override-1ipetx6
    ).ant-select-multiple.ant-select-lg
    .ant-select-selection-overflow {
    flex-wrap: nowrap;
    overflow: hidden;
  }
`;

export default function CreateShopsDrawer({
  reFetchShops = () => {},
  setShop = () => {},
  shop,
  permissions,
}) {
  const { languages = [] } = useFetchLanguages();
  const [languageId, setLanguageId] = useState(1);
  const [imageUrl, setImageUrl] = useState(null);
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectBlog, setSelectBlog] = useState(false);
  const [showVlog, setShowVlog] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (typeof shop === "object") {
      Object.entries(shop)?.forEach(([key, val]) => {
        if (key === "warehouse") {
          form.setFieldValue("warehouse_id", val?.id);
        } else if (key === "categories") {
          const cat_ids = val?.map((cat) => cat?.id);
          form.setFieldValue("category_ids", cat_ids);
        } else if (key === "logo") {
          setImageUrl((pre) => ({ ...pre, web: val }));
          form.setFieldValue(key, val);
        } else if (key === "mobile_logo") {
          setImageUrl((pre) => ({ ...pre, mob: val }));
          form.setFieldValue(key, val);
        } else if (key === "blog") {
          form.setFieldValue("blog_id", val?.id || null);
          setSelectBlog(val ? true : false);
        } else if (key === "details") {
          const details = [];
          val?.forEach((det) => {
            const item = {
              language_id: det?.language_id || "",
              description: det?.description || "",
              vlog: det?.vlog || "",
            };

            details[det?.language_id - 1] = item;
          });
          form.setFieldValue("details", val);
        } else {
          form.setFieldValue(key, val);
        }
      });

      setStatus(shop.is_active);
    }
  }, [shop]);

  const { mutate } = useCreateAndEditShops((data) => {
    setShop(false);
    setImageUrl(null);
    openNotification(data?.data?.message, shop ? "Shop update" : "Shop create");
    reFetchShops();
    form.resetFields();
    setLanguageId(1);
    setSelectBlog(false);
    setShowVlog(false);
  });
  const createBlogs = (values) => {
    const formData = new FormData();
    formData.append("sorting", 0);
    Object.entries(values)?.forEach(([key, val]) => {
      if (val !== null && !Array.isArray(val)) {
        if (key === "logo" && typeof val === "string") {
          formData.append(key, null);
        } else if (key === "mobile_logo" && typeof val === "string") {
          formData.append(key, null);
        } else if (key === "blog_id" && !val) {
          delete values["blog_id"];
        } else {
          formData.append(key, val);
        }
      } else if (key === "details") {
        val?.forEach((detail, index) => {
          Object.entries(detail)?.forEach(([key, value]) => {
            if (value) {
              formData.append(`details[${index}][${key}]`, value);
            }
          });
        });
      } else if (key === "category_ids") {
        val?.forEach((id, i) => {
          formData.append(`category_ids[${i}]`, id);
        });
      }
    });
    if (typeof shop === "object") {
      formData.append("_method", "put");
    }
    mutate({ id: shop?.id, formData });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const closeEditModal = () => {
    form.resetFields();
    setImageUrl(null);
    setShop(false);
    setLanguageId(1);
  };

  const { mutate: mutateIsActive, isLoading } = useIsActiveShop(
    (data) => {
      openNotification(data?.data?.message);
      setStatus(data?.data?.data?.is_active);
      reFetchShops();
    },
    (e) => {
      openNotificationError("Activate", "something went wrong");
    }
  );

  const { mutate: deleteShop } = useDeleteShops(
    (data) => {
      openNotification(data?.data?.message, "Shop delete");
      setImageUrl(null);
      setOpen(false);
      form.resetFields();
      reFetchShops();
      setShop(false);
      setLanguageId(1);
    },
    (e) => {
      openNotificationError("Activate", "something went wrong");
    }
  );

  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => closeEditModal()}
      open={shop}
      width="1112px"
      header={false}
    >
      <DeleteModal
        icon={<WarningIcon />}
        setStatus={setOpen}
        status={open}
        title={"Delete"}
        description={"Are you sure you want to delete shop"}
        setDelete={() => deleteShop(shop?.id)}
      />
      <StyledCreateShops>
        <Form
          initialValues={shopsInitialValue}
          form={form}
          name="createBlog"
          layout="vertical"
          onFinish={createBlogs}
          onFinishFailed={onFinishFailed}
        >
          <div className="header">
            <Typography
              text={typeof shop === "object" ? "Edit shop" : "Create shop"}
              variant="p"
              color={primaryTextColor}
              size="22px"
              weight="500"
              padding={"0 0 16px 0"}
            />
            <Flex alignItems={"center"}>
              {typeof shop === "object" ? (
                <>
                  {PermissionFinder(permissions, "shop-delete") ? (
                    <DeleteButton
                      margin={"0 16px 0 0"}
                      text={"Delete Shop"}
                      icon={<AlertCircleIcon />}
                      click={() => {
                        setOpen(true);
                      }}
                      type="button"
                    />
                  ) : null}

                  <Typography
                    variant={"p"}
                    text={status ? "Active" : "Inactive"}
                    margin={"0 8px 0 0"}
                    size={"14px"}
                  />
                  <Switch
                    loading={isLoading}
                    checked={status}
                    style={{ margin: "0 16px 0 0" }}
                    onChange={(val) =>
                      mutateIsActive({ id: shop?.id, is_active: val })
                    }
                  />
                </>
              ) : null}

              <ButtonCancel
                text="Cancel"
                margin="0 16px 0 0"
                type="button"
                click={closeEditModal}
              />
              <ButtonPrimary
                text={shop ? "Save" : "Create"}
                htmlType="submit"
                // click={() => createBlog()}
              />
            </Flex>
          </div>
          <MainParts setImageUrl={setImageUrl} imageUrl={imageUrl} />
          <SelectParts />
          <CheckboxFeatures
            form={form}
            selectBlog={selectBlog}
            setSelectBlog={setSelectBlog}
          />
          <DetailsParts
            languages={languages}
            languageId={languageId}
            setLanguageId={setLanguageId}
            form={form}
            showVlog={showVlog}
            setShowVlog={setShowVlog}
          />
        </Form>
      </StyledCreateShops>
    </Drawer>
  );
}
