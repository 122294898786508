import { useMutation, useQueryClient } from "react-query";
import { slidesApi } from "../../services/slides-sort.js";

export const useDeleteSlider = (onSuccess = () => {}, onError = () => {}) => {
  const client = useQueryClient();
  return useMutation(slidesApi.deleteSlider, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["update-activate-toggle"]);
    },
  });
};
