import styled from "styled-components";
import { Select, Table } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import Flex from "../../components/general/Flex";
import DeliveryIcon from "../../components/SvgComponents/DeliveryIcon";
import UsFlag from "../../components/SvgComponents/UsFlag";
import ChinaIcon from "../../components/SvgComponents/ChinaIcon";
import RightBottomArrow from "../../components/SvgComponents/RightBottomArrow";

const StyledDeliveryTable = styled.div`
  margin-top: 24px;
  thead {
    display: none;
  }
  tbody {
    tr {
      td:nth-child(5) {
        background: #f9f9f9;
        position: relative;
        .arrow {
          position: absolute;
          right: 5px;
          bottom: 5px;
        }
      }
    }
  }
  .count {
    width: 29px;
    height: 24px;
    background: #e7e9ec;
    border-radius: 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3b5166;
  }
  .accepted {
    background: #e7e9ec;
    border-radius: 16px;
    padding: 0 5px;
    width: 86px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
  }
  .ant-table-title {
    background: #f5f5f5;
    border-radius: 12px 12px 0 0 !important;
    padding: 8px 15px;
  }
  .children-item {
    margin-right: 20px;
  }
  .children-wrapper-item {
    background: #f9f9f9;
    padding: 10px 0;
  }
`;
const StyledCaption = styled.div`
  .caption-wrapper {
    .caption-item {
      margin-right: 8px;
      padding-right: 8px;
      &:not(:last-child) {
        border-right: 1px solid #ddd;
      }
    }
  }
`;
export default function DeliveryTable() {
  const columns = [
    {
      title: "date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
    },
  ];
  const data = [
    {
      key: 1,
      date: (
        <>
          <Typography
            text="23.08.2022"
            variant="span"
            color={primaryTextColor}
            size="14px"
            weight="500"
            padding="0 0 12px 0"
          />
          <Typography
            text="3254"
            variant="span"
            color="#5B6D7F"
            size="14px"
            weight="400"
          />
        </>
      ),
      name: (
        <>
          <Flex>
            <Typography
              text="Harutyun Abrahamyan ARM750350"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 5px 12px 0"
            />
            <DeliveryIcon />
          </Flex>
          <Typography
            text="Lorem Ipsum is simply dummy..."
            variant="span"
            color="#5B6D7F"
            size="14px"
            weight="400"
          />
        </>
      ),
      address: (
        <>
          <Flex>
            <Typography
              text="Arabkir"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="500"
              padding="0 12px 0 0"
            />
            <Typography
              text="Yerevan"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 12px 0 0"
            />
          </Flex>
          <Typography
            text="3 Hakob Hakobyan St"
            variant="span"
            color={primaryTextColor}
            size="14px"
            weight="400"
            padding="12px 0 0 0"
          />
        </>
      ),
      country: (
        <>
          <Flex>
            <UsFlag />
            <div className="count" style={{ marginLeft: 4 }}>
              3
            </div>
          </Flex>
          <Flex style={{ marginTop: 12 }}>
            <ChinaIcon />
            <div className="count" style={{ marginLeft: 4 }}>
              2
            </div>
          </Flex>
          <div className="arrow">
            <RightBottomArrow />
          </div>
        </>
      ),
      phone: (
        <Typography
          text="099 123 123"
          variant="span"
          color={primaryTextColor}
          size="14px"
          weight="400"
          padding="12px 0 0 0"
        />
      ),
      status: (
        <Select defaultValue="accepted">
          <Select.Option value="accepted">
            <div className="accepted">Accepted</div>
          </Select.Option>
        </Select>
      ),
      description: (
        <>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="1"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="2"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>

              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="3"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>

          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="4"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <ChinaIcon />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="4"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <ChinaIcon />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
        </>
      ),
    },
    {
      key: 2,
      date: (
        <>
          <Typography
            text="23.08.2022"
            variant="span"
            color={primaryTextColor}
            size="14px"
            weight="500"
            padding="0 0 12px 0"
          />
          <Typography
            text="3254"
            variant="span"
            color="#5B6D7F"
            size="14px"
            weight="400"
          />
        </>
      ),
      name: (
        <>
          <Flex>
            <Typography
              text="Harutyun Abrahamyan ARM750350"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 5px 12px 0"
            />
            <DeliveryIcon />
          </Flex>
          <Typography
            text="Lorem Ipsum is simply dummy..."
            variant="span"
            color="#5B6D7F"
            size="14px"
            weight="400"
          />
        </>
      ),
      address: (
        <>
          <Flex>
            <Typography
              text="Arabkir"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="500"
              padding="0 12px 0 0"
            />
            <Typography
              text="Yerevan"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 12px 0 0"
            />
          </Flex>
          <Typography
            text="3 Hakob Hakobyan St"
            variant="span"
            color={primaryTextColor}
            size="14px"
            weight="400"
            padding="12px 0 0 0"
          />
        </>
      ),
      country: (
        <>
          <Flex>
            <UsFlag />
            <div className="count" style={{ marginLeft: 4 }}>
              3
            </div>
          </Flex>
          <Flex style={{ marginTop: 12 }}>
            <ChinaIcon />
            <div className="count" style={{ marginLeft: 4 }}>
              2
            </div>
          </Flex>
          <div className="arrow">
            <RightBottomArrow />
          </div>
        </>
      ),
      phone: (
        <Typography
          text="099 123 123"
          variant="span"
          color={primaryTextColor}
          size="14px"
          weight="400"
          padding="12px 0 0 0"
        />
      ),
      status: (
        <Select defaultValue="accepted">
          <Select.Option value="accepted">
            <div className="accepted">Accepted</div>
          </Select.Option>
        </Select>
      ),
      description: (
        <>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="1"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="2"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="3"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="4"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <ChinaIcon />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="4"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <ChinaIcon />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
        </>
      ),
    },
    {
      key: 3,
      date: (
        <>
          <Typography
            text="23.08.2022"
            variant="span"
            color={primaryTextColor}
            size="14px"
            weight="500"
            padding="0 0 12px 0"
          />
          <Typography
            text="3254"
            variant="span"
            color="#5B6D7F"
            size="14px"
            weight="400"
          />
        </>
      ),
      name: (
        <>
          <Flex>
            <Typography
              text="Harutyun Abrahamyan ARM750350"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 5px 12px 0"
            />
            <DeliveryIcon />
          </Flex>
          <Typography
            text="Lorem Ipsum is simply dummy..."
            variant="span"
            color="#5B6D7F"
            size="14px"
            weight="400"
          />
        </>
      ),
      address: (
        <>
          <Flex>
            <Typography
              text="Arabkir"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="500"
              padding="0 12px 0 0"
            />
            <Typography
              text="Yerevan"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 12px 0 0"
            />
          </Flex>
          <Typography
            text="3 Hakob Hakobyan St"
            variant="span"
            color={primaryTextColor}
            size="14px"
            weight="400"
            padding="12px 0 0 0"
          />
        </>
      ),
      country: (
        <>
          <Flex>
            <UsFlag />
            <div className="count" style={{ marginLeft: 4 }}>
              3
            </div>
          </Flex>
          <Flex style={{ marginTop: 12 }}>
            <ChinaIcon />
            <div className="count" style={{ marginLeft: 4 }}>
              2
            </div>
          </Flex>
          <div className="arrow">
            <RightBottomArrow />
          </div>
        </>
      ),
      phone: (
        <Typography
          text="099 123 123"
          variant="span"
          color={primaryTextColor}
          size="14px"
          weight="400"
          padding="12px 0 0 0"
        />
      ),
      status: (
        <Select defaultValue="accepted">
          <Select.Option value="accepted">
            <div className="accepted">Accepted</div>
          </Select.Option>
        </Select>
      ),
      description: (
        <>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="1"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="2"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="3"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="4"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <ChinaIcon />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="4"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <ChinaIcon />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
        </>
      ),
    },
    {
      key: 4,
      date: (
        <>
          <Typography
            text="23.08.2022"
            variant="span"
            color={primaryTextColor}
            size="14px"
            weight="500"
            padding="0 0 12px 0"
          />
          <Typography
            text="3254"
            variant="span"
            color="#5B6D7F"
            size="14px"
            weight="400"
          />
        </>
      ),
      name: (
        <>
          <Flex>
            <Typography
              text="Harutyun Abrahamyan ARM750350"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 5px 12px 0"
            />
            <DeliveryIcon />
          </Flex>
          <Typography
            text="Lorem Ipsum is simply dummy..."
            variant="span"
            color="#5B6D7F"
            size="14px"
            weight="400"
          />
        </>
      ),
      address: (
        <>
          <Flex>
            <Typography
              text="Arabkir"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="500"
              padding="0 12px 0 0"
            />
            <Typography
              text="Yerevan"
              variant="span"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 12px 0 0"
            />
          </Flex>
          <Typography
            text="3 Hakob Hakobyan St"
            variant="span"
            color={primaryTextColor}
            size="14px"
            weight="400"
            padding="12px 0 0 0"
          />
        </>
      ),
      country: (
        <>
          <Flex>
            <UsFlag />
            <div className="count" style={{ marginLeft: 4 }}>
              3
            </div>
          </Flex>
          <Flex style={{ marginTop: 12 }}>
            <ChinaIcon />
            <div className="count" style={{ marginLeft: 4 }}>
              2
            </div>
          </Flex>
          <div className="arrow">
            <RightBottomArrow />
          </div>
        </>
      ),
      phone: (
        <Typography
          text="099 123 123"
          variant="span"
          color={primaryTextColor}
          size="14px"
          weight="400"
          padding="12px 0 0 0"
        />
      ),
      status: (
        <Select defaultValue="accepted">
          <Select.Option value="accepted">
            <div className="accepted">Accepted</div>
          </Select.Option>
        </Select>
      ),
      description: (
        <>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="1"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="2"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="3"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <UsFlag />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="4"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <ChinaIcon />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
          <Flex
            className="children-wrapper-item"
            justifyContent="space-between"
            style={{ width: "50%" }}
          >
            <Flex alignItems="center">
              <div className="children-item">
                <Typography
                  text="4"
                  variant="span"
                  color="#3B5166"
                  size="14px"
                  weight="400"
                />
              </div>
              <div className="children-item">
                <ChinaIcon />
              </div>
              <div className="children-item">
                <Typography
                  text="1Z9W1V62YN13179867"
                  variant="span"
                  color="#262626"
                  size="14px"
                  weight="400"
                />
              </div>
            </Flex>
            <Typography
              text="0.2 kg"
              variant="span"
              color="#262626"
              size="14px"
              weight="400"
            />
          </Flex>
        </>
      ),
    },
  ];
  return (
    <StyledDeliveryTable>
      <Table
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.description}
            </p>
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        dataSource={data}
        title={() => (
          <caption>
            <StyledCaption>
              <Flex style={{ width: 850 }} className="caption-wrapper">
                <div className="caption-item">
                  <Typography
                    text="All orders"
                    variant="p"
                    color="#5B6D7F"
                    size="14px"
                    weight="500"
                  />
                </div>
                <div className="caption-item">
                  <Typography
                    text="Accepted 2"
                    variant="p"
                    color="#8E9BA7"
                    size="14px"
                    weight="500"
                  />
                </div>
                <div className="caption-item">
                  <Typography
                    text="Packages 20"
                    variant="p"
                    color="#8E9BA7"
                    size="14px"
                    weight="500"
                  />
                </div>
              </Flex>
            </StyledCaption>
          </caption>
        )}
      />
    </StyledDeliveryTable>
  );
}
