import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import { Checkbox, DatePicker, Form, Popconfirm, Select } from "antd";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import { useState } from "react";
import { useMutation } from "react-query";
import { consignmentApi } from "../../services/consignment.js";
import styled from "styled-components";
import dayjs from "dayjs";
import { openNotification } from "../../helpers/notification.js";

const NotifyWrapper = styled.div`
  margin: 17px 0;
  background: #ffffff;
  border: 1px solid #e7e9ec;
  border-radius: 12px;
  padding: 16px;

  .input-item {
    margin-right: 16px;
  }
`;
const { RangePicker } = DatePicker;

const NotifyUser = ({ consignment }) => {
  const [form] = Form.useForm();
  let { resetFields } = form;
  const [notifyData, setNotifyData] = useState(null);

  const { mutate, isLoading } = useMutation(consignmentApi.setBoxesEstimate, {
    onSuccess: (data) => {
      openNotification("Edit orders", "Editing done successfully");
      resetFields();
    },
    onError: (e) => {
      console.log(e, "errrr");
    },
  });

  const onFinish = (values) => {
    let newData = {
      box_ids: values?.box_ids,
      estimation_data: {
        estimated_date_from: dayjs(values?.estimation_data).format(
          "YYYY-MM-DD"
        ),
        estimated_date_to: dayjs(values?.estimation_data).format("YYYY-MM-DD"),
      },
      notification_instructions: {
        notify_by_app: values?.notify_by_app,
        notify_by_email: values?.notify_by_email,
      },
    };
    mutate(newData);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      onFinish={onFinish}
      form={form}
      onFinishFailed={onFinishFailed}
      initialValues={{ notify_by_app: false, notify_by_email: false }}
    >
      <NotifyWrapper>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex>
            <div className="input-item">
              <Typography
                text="Box ID"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Form.Item
                name={"box_ids"}
                rules={[{ required: true, message: "Please select box id" }]}
              >
                <Select style={{ width: 415 }} mode={"multiple"}>
                  {consignment?.boxes?.map((box, i) => (
                    <Select.Option key={i} value={box.id}>
                      {box.reference_id}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="input-item">
              <Typography
                text="New Date"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Form.Item
                name={"estimation_data"}
                rules={[
                  { required: true, message: "Please select estimation data" },
                ]}
              >
                <DatePicker format={"DD.MM.YYYY"} />
              </Form.Item>
            </div>
            <div className="input-item">
              <Typography
                text="Notify customer"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Flex>
                <Form.Item name={"notify_by_email"} valuePropName="checked">
                  <Checkbox>Mail</Checkbox>
                </Form.Item>
                <Form.Item name={"notify_by_app"} valuePropName="checked">
                  <Checkbox>Notifications</Checkbox>
                </Form.Item>
              </Flex>
            </div>
          </Flex>
          <div>
            <Popconfirm title={"Are you sure?"} onConfirm={() => form.submit()}>
              <ButtonPrimary text="Send" />
            </Popconfirm>
          </div>
        </Flex>
      </NotifyWrapper>
    </Form>
  );
};
export default NotifyUser;
