import React from "react";
import Typography from "../../components/moleculs/Typography";
import Flex from "../../components/general/Flex";
import DeleteButton from "../../components/moleculs/DeleteButton";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import styled from "styled-components";

const DeleteModal = ({ onDelete = () => {}, setOpenDeleteModal }) => {
  return (
    <div>
      <Typography
        text="Delete version"
        weight={500}
        size="18px"
        variant="h2"
        margin="20px 0 8px 0"
        color="#101828"
      />
      <Typography
        text="Are you sure you want to delete this version?"
        weight={400}
        variant="span"
        size="14px"
        margin="0 0 24px 0"
        color="#5B6D7F"
      />
      <StyledFlex>
        <DeleteButton text="Close" click={() => setOpenDeleteModal(false)} />
        <ButtonPrimary text="Delete" click={onDelete} />
      </StyledFlex>
    </div>
  );
};
const StyledFlex = styled(Flex)`
  gap: 16px;

  > button {
    flex: 1;
    height: 36px;

    &:last-child {
      background-color: #fc4447;
    }
  }
`;
export default DeleteModal;
