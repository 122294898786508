import React, { useEffect } from "react";
import styled from "styled-components";
import Typography from "../../components/moleculs/Typography";
import Flex from "../../components/general/Flex";
import ButtonCancel from "../../components/moleculs/ButtonCancel";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import DeleteButton from "../../components/moleculs/DeleteButton";
import DangerousIcon from "../../components/SvgComponents/DangerousIcon";
import { Form, Input, Select, Tabs } from "antd";
import { useFetchLanguages } from "../../hooks/slides/useFetchLanguages.hook.js";
import check from "../../assets/images/check-circle.svg";
import apple from "../../assets/images/apple.svg";
import android from "../../assets/images/android.svg";
import { appVersionsApi } from "../../services/app-versions.js";

const CreateEdit = ({
  data,
  setOpenDrawer,
  setOpenDeleteModal,
  refetch,
  form,
  closeModal = () => {},
}) => {
  const isEdit = Boolean(data?.id);

  const { languages } = useFetchLanguages();
  const handleSubmit = (values) => {
    const afterSave = () => {
      closeModal();
      setOpenDrawer(null);
      refetch();
    };
    const form = { ...values, dev_mode: false, force_update: false };
    const details = values.details.filter(
      (item) => item.title && item.description
    );
    for (let update_type of values.update_type) {
      if (update_type === "all_update_types") {
        form.force_update = true;
        form.dev_mode = true;
      } else {
        form[update_type] = true;
      }
    }
    form.details = details;
    delete form.update_type;
    if (isEdit) {
      appVersionsApi.editVersion(data.id, form).then(afterSave);
    } else {
      appVersionsApi.createVersion(form).then(afterSave);
    }
  };
  const update_type = [];
  if (data?.id) {
    if (data?.force_update) update_type.push("force_update");
    if (data?.dev_mode) update_type.push("dev_mode");
  }

  useEffect(() => {
    if (isEdit) {
      const initialValues = {
        update_type,
        details: data?.apiVersionsMl,
        version: data?.version || "",
        os: data?.os || "",
      };
      form.setFieldsValue({
        ...initialValues,
      });
    } else {
      form.setFieldsValue({
        update_type: [],
        details: [],
        version: "",
        os: "",
      });
    }
  }, [isEdit]);
  return (
    <Form onFinish={handleSubmit} form={form}>
      <StyledCreate>
        <Flex alignItems="center" justifyContent="space-between">
          <div className="page-title">
            <Typography
              text={isEdit ? "Edit Version" : "Create Version"}
              color="#262626"
              weight={500}
              size="24px"
              variant="span"
            />
          </div>
          <Flex alignItems="center" gap="16px">
            {isEdit && (
              <DeleteButton
                text="Delete"
                icon={<DangerousIcon />}
                click={() => {
                  setOpenDeleteModal(data.id);
                }}
                type="button"
              />
            )}
            <ButtonCancel
              text="Cancel"
              click={() => {
                form.resetFields();
                setOpenDrawer(null);
              }}
              type="reset"
            />
            <ButtonPrimary text="Save" htmlType="submit" />
          </Flex>
        </Flex>
        <Flex gap={16} className="filters-row">
          <Form.Item
            label="Version"
            name="version"
            rules={[{ required: true, message: "Please add version" }]}
          >
            <Input placeholder="Version" />
          </Form.Item>
          <Form.Item
            label="Select system"
            name="os"
            rules={[{ required: true, message: "Please select os" }]}
          >
            <Select
              style={{ height: "36px" }}
              placeholder={"Select system"}
              options={[
                {
                  value: "android",
                  label: (
                    <Flex alignItems="center" gap="5px">
                      <img alt={"android"} src={android} />
                      Android
                    </Flex>
                  ),
                },
                {
                  value: "ios",
                  label: (
                    <Flex alignItems="center" gap="5px">
                      <img alt={"apple"} src={apple} />
                      IOS
                    </Flex>
                  ),
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="All update types"
            name="update_type"
            rules={[{ required: true, message: "Please select update types" }]}
          >
            <Select
              style={{ height: "36px" }}
              mode="multiple"
              placeholder={"All update types"}
              options={[
                {
                  value: "force_update",
                  label: "Force Update",
                  name: "force_update",
                },
                { value: "dev_mode", label: "Dev Mode", name: "dev_mode" },
              ]}
            />
          </Form.Item>
        </Flex>
        <Tabs
          style={{ marginBottom: 25 }}
          items={languages?.map((language, index) => {
            return {
              key: language?.id,
              label: (
                <Flex alignItems={"center"}>
                  <div
                    className={
                      isEdit &&
                      data?.apiVersionsMl?.some(
                        (item) => language?.id === item.language_id
                      )
                        ? "with-circle"
                        : "with-circle-none"
                    }
                  >
                    <img src={language?.flag} alt="" />
                    <img src={check} alt="" className="circle" />
                  </div>
                  <Typography
                    variant={"p"}
                    text={language?.name}
                    color={"#3B5166"}
                    size={"14px"}
                    weight={"500"}
                    padding={"0 0 0 7px"}
                  />
                </Flex>
              ),
              children: (
                <div className="title-box">
                  <Form.Item
                    hidden
                    name={["details", index, "language_id"]}
                    initialValue={`${language.id}`}
                  />
                  <Form.Item label="Title" name={["details", index, "title"]}>
                    <Input placeholder="Version" />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name={["details", index, "description"]}
                  >
                    <Input placeholder="Description" />
                  </Form.Item>
                </div>
              ),
            };
          })}
        />
      </StyledCreate>
    </Form>
  );
};

const StyledCreate = styled.div`
  .ant-tabs-tab-active .typography {
    color: #5dba2f;
  }

  .with-circle {
    position: relative;

    > .circle {
      position: absolute;
      bottom: 0;
      right: -5px;
    }
  }

  .with-circle-none .circle {
    display: none;
  }

  .filters-row {
    padding: 16px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 16px;
    margin-top: 16px;

    > div {
      flex: 1;
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ant-form-item-label {
      > label::after {
        display: none;
      }
    }

    > div {
      flex: 1;
      width: 100%;
      text-align: left;
    }
  }

  .title-box {
    background-color: #fff;
    padding: 16px;
    border-radius: 12px;
  }
`;
export default CreateEdit;
