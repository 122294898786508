import React, { forwardRef, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp" ||
    file.type === "image/svg+xml";
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/WEBP file!");
  } else if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }

  const promise = new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        resolve(true);
      });
      image.addEventListener("error", () => {});
    });
    reader.readAsDataURL(file);
  });

  return isJpgOrPng && isLt2M && promise.then((res) => res);
};
const UploadImages = forwardRef(
  (
    {
      onChange = () => {},
      imageUrl,
      setImageUrl = () => {},
      initialImage,
      ...props
    },
    ref
  ) => {
    const [loading, setLoading] = useState(false);

    const handleChange = (info) => {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (url) => {
          setLoading(false);
          setImageUrl(url);
        });
        onChange(info.file.originFileObj);
      }
    };

    const uploadButton = (
      <div ref={ref} style={props.style}>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    );
    return (
      <>
        <Upload
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={({ onSuccess }) => onSuccess("ok")}
          beforeUpload={(file) => beforeUpload(file)}
          onChange={handleChange}
          value={""}
          {...props}
        >
          {imageUrl || initialImage ? (
            <img
              src={imageUrl || initialImage}
              alt="avatar"
              style={{
                width: "100%",
              }}
              ref={ref}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </>
    );
  }
);
export default UploadImages;
