import { Col, Row } from "antd";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { primaryTextColor } from "../../../constants.js";
import Typography from "../../moleculs/Typography.jsx";
import GetAccountDeails from "../../../hooks/customers/useGetAccountDetails.hook.jsx";
import Loader from "../../moleculs/Loader";
import dayjs from "dayjs";
import WebIcon from "../../SvgComponents/WebIcon.jsx";
import Flex from "../../general/Flex";
import AppleIcon from "../../SvgComponents/AppleIcon";
import AndroidIcon from "../../SvgComponents/AndroidIcon.jsx";

const StyledPersonalInfo = styled.div`
  margin-top: 35px;

  .info-item {
    margin-bottom: 24px;
  }
`;

export default function PersonalInfoTab() {
  const { id } = useParams();
  const { accountDeails } = GetAccountDeails(id);

  return (
    <StyledPersonalInfo>
      {accountDeails ? (
        <Row gutter={50}>
          <Col lg={6}>
            <div className="info-item">
              <Typography
                variant="span"
                text={
                  accountDeails?.recipient?.first_name ? "Name" : "Company Name"
                }
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Typography
                variant="span"
                text={
                  accountDeails?.recipient?.first_name ||
                  accountDeails?.recipient?.company_name
                }
                color={primaryTextColor}
                size="14px"
                weight="400"
                padding="0 0 4px 0"
              />
            </div>
            {!!accountDeails?.recipient?.last_name && (
              <div className="info-item">
                <Typography
                  variant="span"
                  text="Surname"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                  padding="0 0 4px 0"
                />
                <Typography
                  variant="span"
                  text={accountDeails?.recipient?.last_name}
                  color={primaryTextColor}
                  size="14px"
                  weight="400"
                  padding="0 0 4px 0"
                />
              </div>
            )}

            <div className="info-item">
              <Typography
                variant="span"
                text={
                  accountDeails?.recipient?.georgian_first_name
                    ? "Full name in Georgian"
                    : "Company name (Georgian)"
                }
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Typography
                variant="span"
                text={
                  accountDeails?.recipient?.georgian_first_name
                    ? `${
                        accountDeails?.recipient?.georgian_first_name
                          ? accountDeails?.recipient?.georgian_first_name +
                            " " +
                            accountDeails?.recipient?.georgian_last_name
                          : accountDeails?.recipient?.company_name || ""
                      } `
                    : accountDeails?.recipient?.georgian_company_name
                }
                color={primaryTextColor}
                size="14px"
                weight="400"
                padding="0 0 4px 0"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="info-item">
              <Typography
                variant="span"
                text="Email"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Typography
                variant="span"
                text={accountDeails?.email}
                color={primaryTextColor}
                size="14px"
                weight="400"
                padding="0 0 4px 0"
              />
            </div>
            <div className="info-item">
              <Typography
                variant="span"
                text="Phone"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Typography
                variant="span"
                text={accountDeails?.phone}
                color={primaryTextColor}
                size="14px"
                weight="400"
                padding="0 0 4px 0"
              />
            </div>
            <div className="info-item">
              <Typography
                variant="span"
                text="Address"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Typography
                variant="span"
                text={accountDeails?.recipient?.address}
                color={primaryTextColor}
                size="14px"
                weight="400"
                padding="0 0 4px 0"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="info-item">
              <Typography
                variant="span"
                text="Passport details"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Typography
                variant="span"
                text={`${accountDeails?.recipient?.document_type} ${accountDeails?.recipient?.document_number}`}
                color={primaryTextColor}
                size="14px"
                weight="400"
                padding="0 0 4px 0"
              />
            </div>
            <div className="info-item">
              <Typography
                variant="span"
                text="Gender"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Typography
                variant="span"
                text={accountDeails?.recipient?.gender || "_"}
                color={primaryTextColor}
                size="14px"
                weight="400"
                padding="0 0 4px 0"
              />
            </div>
            <div className="info-item">
              <Typography
                variant="span"
                text="Birthday"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Typography
                variant="span"
                text={accountDeails?.birthday_date || "_"}
                color={primaryTextColor}
                size="14px"
                weight="400"
                padding="0 0 4px 0"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="info-item">
              <Typography
                variant="span"
                text="Registration date"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 4px 0"
              />
              <Flex alignItems={"center"}>
                {accountDeails?.verified_type === "email" ? (
                  <WebIcon />
                ) : accountDeails?.verified_type === "ios" ? (
                  <AppleIcon />
                ) : accountDeails?.verified_type === "android" ? (
                  <AndroidIcon />
                ) : null}
                <Typography
                  variant="span"
                  text={dayjs(accountDeails?.created_et).format(
                    "YYYY-MM-DD HH:mm"
                  )}
                  color={primaryTextColor}
                  size="14px"
                  weight="400"
                  padding={"0 0 0 5px"}
                />
              </Flex>
            </div>
            {accountDeails?.recipient?.user_code_old ? (
              <div className="info-item">
                <Typography
                  variant="span"
                  text="Old GA code"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                  padding="0 0 4px 0"
                />
                <Typography
                  variant="span"
                  text={`GA${accountDeails?.recipient?.user_code_old || ""}`}
                  color={primaryTextColor}
                  size="14px"
                  weight="400"
                  padding="0 0 4px 0"
                />
              </div>
            ) : null}
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </StyledPersonalInfo>
  );
}
