import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core.js";
import * as am4charts from "@amcharts/amcharts4/charts.js";
import Loader from "../../components/moleculs/Loader";
import Flex from "../../components/general/Flex.jsx";
import LeftBottomArrowIcon from "../../components/SvgComponents/LeftBottomArrow.jsx";
import TopRightArrowIcon from "../../components/SvgComponents/TopRightArrow.jsx";

const StyledOrdersByCountry = styled.div`
  padding: 24px;
  background: white;
  border-radius: 12px;
  min-height: 580px;
  position: relative;
  @media(max-width: 768px) {
    margin-bottom: 40px;
  }
  .filter {
    position: absolute;
    top: 24px;
    right: 24px;
    color: #b3bbc4;
    @media (max-width: 768px) {
      top: 10px;
      right: 10px;
    }
  }
  @media (max-width: 768px) {
    padding: 10px;
  }
  .orders-counts {
    margin-top: 32px;
    &-item {
      margin: 15px 0;
    }
  }
  .country {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .orders-counts-item {
    @media (max-width: 768px) {
      justify-content: space-between !important;
      & > div {
        margin-bottom: 10px;
      }
    }
  }

  .order-left-side {
    @media (max-width: 768px) {
      flex-direction: row !important;
      align-items: center !important;
    }
  }

  .order-right-side {
    @media (max-width: 768px) {
      flex-direction: column !important;
    }
  }
  .items-and-avg {
    @media (max-width: 768px) {
      flex-direction: column !important;
    }
  }
`;

export default function OrdersByCountry({ dashboardData, filterType, currentWeekDay }) {
  useLayoutEffect(() => {
    if (dashboardData) {
      let chart = am4core.create("ordersByCountry", am4charts.XYChart);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      chart.paddingBottom = 30;

      let data = Object.entries(dashboardData?.data?.order_info).map(
        (key, value) => {
          return {
            country: key[0] === "United Kingdom" ? "UK" : key[0],
            steps: key[1].total ? key[1].total : 0,
            img: `https://backadmin.onex.ge/storage/images/warehouses/${key[1].round_flag}`,
          };
        }
      );

      chart.data = data;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "country";
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.labels.template.dy = 35;
      categoryAxis.renderer.tooltip.dy = 35;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.fillOpacity = 0;
      valueAxis.renderer.grid.template.strokeOpacity = 0.1;
      valueAxis.min = 0;
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.baseGrid.strokeOpacity = 0;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "steps";
      series.dataFields.categoryX = "country";
      series.tooltipText = "{valueY.value}";
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.dy = -6;
      series.columnsContainer.zIndex = 100;

      let columnTemplate = series.columns.template;
      columnTemplate.width = am4core.percent(40);
      columnTemplate.maxWidth = 66;
      columnTemplate.column.cornerRadius(12, 12, 12, 12);
      columnTemplate.strokeOpacity = 0;

      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        dataField: "valueY",
        min: am4core.color("#5DBA2F"),
        max: am4core.color("#8eca76"),
      });
      series.mainContainer.mask = undefined;

      let cursor = new am4charts.XYCursor();
      chart.cursor = cursor;
      cursor.lineX.disabled = true;
      cursor.lineY.disabled = true;
      cursor.behavior = "none";

      let bullet = columnTemplate.createChild(am4charts.CircleBullet);
      bullet.circle.radius = 30;
      bullet.valign = "bottom";
      bullet.align = "center";
      bullet.isMeasured = true;
      bullet.mouseEnabled = false;
      bullet.verticalCenter = "bottom";

      let hoverState = bullet.states.create("hover");
      let outlineCircle = bullet.createChild(am4core.Circle);
      outlineCircle.adapter.add("radius", function (radius, target) {
        let circleBullet = target.parent;
        return 0;
      });

      let image = bullet.createChild(am4core.Image);
      image.width = 30;
      image.height = 30;
      image.horizontalCenter = "middle";
      image.verticalCenter = "middle";

      image.adapter.add("href", function (href, target) {
        let dataItem = target.dataItem;
        if (dataItem) {
          return dataItem._dataContext.img;
        }
      });

      image.adapter.add("mask", function (mask, target) {
        let circleBullet = target.parent;
        return circleBullet.circle;
      });

      let previousBullet;
      chart.cursor.events.on("cursorpositionchanged", function (event) {
        let dataItem = series.tooltipDataItem;

        if (dataItem.column) {
          let bullet = dataItem.column.children.getIndex(1);

          if (previousBullet && previousBullet != bullet) {
            previousBullet.isHover = false;
          }

          // if (previousBullet != bullet) {
          //   let hs = bullet.states.getKey("hover");
          //   hs.properties.dy = -bullet.parent.pixelHeight + 30;
          //   bullet.isHover = true;
          //
          //   previousBullet = bullet;
          // }
        }
      });
    }
  }, [dashboardData]);
  return (
    <StyledOrdersByCountry>
      <div className="filter">
        <Typography
          xsSize={"12px"}
          text={`This ${filterType}`}
          variant={"p"}
          size={"14px"}
          weight={"400"}
          color={"#B3BBC4"}
        />
      </div>
      <Typography
        variant={"h5"}
        text={"Orders by country"}
        color={"#262626"}
        size={"24px"}
        weight={"500"}
        padding={"0 0 15px 0"}
      />
      {dashboardData ? (
        <div
          id="ordersByCountry"
          style={{ width: "100%", height: "325px" }}
        ></div>
      ) : (
        <Loader />
      )}
      <div className="orders-counts">
        {dashboardData
          ? Object.entries(dashboardData?.data?.order_info).map((expected) => {
              return (
                <Flex
                  className={"orders-counts-item"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Flex alignItems={"center"} className={"order-left-side"}>
                    <img
                      src={`https://backadmin.onex.ge/storage/images/warehouses/${expected[1].round_flag}`}
                    />
                    <div className="country">
                      <Typography
                        xsSize={"12px"}
                        variant={"span"}
                        text={expected[0]}
                        size={"14px"}
                        weight={"400"}
                        color={"#262626"}
                        padding={"0 12px 0 5px"}
                      />
                    </div>
                    <Flex className={"items-and-avg"}>
                      <Typography
                        xsSize={"14px"}
                        variant={"span"}
                        text={`${expected[1].total} items `}
                        size={"14px"}
                        weight={"500"}
                        color={"#262626"}
                        padding={"0 12px 0 5px"}
                      />
                      <Flex>
                        <Typography
                          xsSize={"12px"}
                          variant={"span"}
                          text={` ${expected[1].total_weight} kg`}
                          size={"14px"}
                          weight={"400"}
                          color={"#262626"}
                          padding={"0 12px 0 5px"}
                        />
                        <Typography
                          xsSize={"12px"}
                          variant={"span"}
                          text={`Avg ${expected[1].avg_weight} kg`}
                          size={"12px"}
                          weight={"400"}
                          color={"#5B6D7F"}
                          padding={"0 12px 0 5px"}
                        />
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex className={"order-right-side"}>
                    <Flex alignItems={"center"}>
                      {Math.sign(expected[1].percentage_expression) === -1 ? (
                        <LeftBottomArrowIcon />
                      ) : (
                        <TopRightArrowIcon />
                      )}
                      <Typography
                        xsSize={"12px"}
                        variant={"h5"}
                        text={`${expected[1].percentage_expression}%`}
                        color={`${
                          Math.sign(expected[1].percentage_expression) === -1
                            ? "#FC4447"
                            : "#5DBA2F"
                        }`}
                        size={"14px"}
                        weight={"400"}
                        padding={"0 16px 0 4px"}
                      />
                    </Flex>
                    <Typography
                      xsSize={"12px"}
                      variant={"span"}
                      text={`${
                        Math.sign(expected[1].sum_expression) === -1
                          ? expected[1].sum_expression
                          : "+" + expected[1].sum_expression
                      } since  last ${filterType === "day" ? currentWeekDay : filterType} `}
                      size={"14px"}
                      weight={"400"}
                      color={"#262626"}
                      padding={"0 12px 0 5px"}
                    />
                  </Flex>
                </Flex>
              );
            })
          : null}
      </div>
    </StyledOrdersByCountry>
  );
}
