import styled from "styled-components";
import { Col, Row } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import ScanTopActions from "./TopActions";
import SingleScanContent from "./SingleContent";
import ScanSingleCalculation from "./SingleCalculations";

const StyledScanSingle = styled.div`
  padding-top: 24px;
`;

export default function ScanSingle() {
  return (
    <StyledScanSingle>
      <div className="page-title">
        <Typography
          text="Scan Packages"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <ScanTopActions />
      <Row gutter={24}>
        <Col lg={17}>
          <SingleScanContent />
        </Col>
        <Col lg={7}>
          <ScanSingleCalculation />
        </Col>
      </Row>
    </StyledScanSingle>
  );
}
