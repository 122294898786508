import NotSlidesResult from "../../components/SvgComponents/NotSlidesResult";
import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonGray from "../../components/moleculs/ButtonGray";

const NoBlog = ({ setBlog = () => {} }) => {
  return (
    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
      <NotSlidesResult />
      <Typography
        text="No blogs yet"
        color="#3B5166"
        weight={500}
        size="20px"
        variant="h2"
        margin="16px 0 4px 0"
      />
      <Typography
        text="Once there will be blogs they will appear here"
        color="#3B5166"
        weight={400}
        size="16px"
        variant="h4"
        margin="0 0 24px 0"
      />
      <ButtonGray
        text={"Create blog"}
        background={"#5B6D7F"}
        color={"#FFFFFF"}
        click={() => setBlog(true)}
      />
    </Flex>
  );
};

export default NoBlog;
