import styled from "styled-components";
import Flex from "../../components/general/Flex";
import { DatePicker, Segmented } from "antd";
import dayjs from "dayjs";
import { dashboardFilterDays } from "../../constants.js";

const { RangePicker } = DatePicker;
const StyledTopActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    justify-content: start !important;
  }

  .filters {
    @media (max-width: 768px) {
      flex-direction: column !important;
      width: 100%;
      .filter-by-date {
        width: 100%;

        .ant-segmented-group {
          justify-content: center !important;
        }
      }

      .picker-filter {
        margin-left: 0 !important;
        margin-top: 16px;
        width: 100%;

        .ant-picker {
          width: 100%;
        }
      }
    }
  }
`;

export default function DashboardTopActions({
  filterType,
  setFilterType,
  refetch,
  setCustomRange,
  dashboardData,
  currentStartDate,
  currentEndDate,
}) {
  return (
    <StyledTopActions>
      <Flex justifyContent={"space-between"} className={"filters"}>
        <Segmented
          className={"filter-by-date"}
          options={["Today", "This week", "This month", "This year"]}
          onChange={(val) => {
            setFilterType(dashboardFilterDays[val]);
            setCustomRange(null);
          }}
        />
        {dashboardData ? (
          <div className={"ml-16 picker-filter"}>
            <RangePicker
              defaultValue={[
                dayjs(
                  dashboardData?.data?.current_start_date.substr(0, 10),
                  "YYYY-MM-DD"
                ),
                dayjs(
                  dashboardData?.data?.current_end_date.substr(0, 10),
                  "YYYY-MM-DD"
                ),
              ]}
              onChange={(_, values) => setCustomRange(values)}
            />
          </div>
        ) : null}
      </Flex>
    </StyledTopActions>
  );
}
