export default function InArmeniaIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0833 6.06479L9.99997 9.99997M9.99997 9.99997L2.91664 6.06479M9.99997 9.99997L10 17.9167M11.6667 17.4074L10.6475 17.9736C10.4112 18.1049 10.293 18.1705 10.1679 18.1963C10.0571 18.2191 9.94288 18.2191 9.83213 18.1963C9.70698 18.1705 9.58881 18.1049 9.35248 17.9736L3.18581 14.5477C2.93621 14.409 2.8114 14.3397 2.72053 14.2411C2.64013 14.1538 2.57929 14.0504 2.54207 13.9378C2.5 13.8104 2.5 13.6677 2.5 13.3821V6.61787C2.5 6.33234 2.5 6.18957 2.54207 6.06224C2.57929 5.94959 2.64013 5.84619 2.72053 5.75895C2.8114 5.66033 2.93621 5.591 3.18581 5.45233L9.35248 2.0264C9.58881 1.89511 9.70698 1.82945 9.83213 1.80372C9.94288 1.78094 10.0571 1.78094 10.1679 1.80372C10.293 1.82945 10.4112 1.8951 10.6475 2.0264L16.8142 5.45233C17.0638 5.591 17.1886 5.66033 17.2795 5.75895C17.3599 5.84619 17.4207 5.94959 17.4579 6.06224C17.5 6.18957 17.5 6.33234 17.5 6.61787L17.5 10.4167M6.25 3.75L13.75 7.91667"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4123 12.764L15.3571 16.8377L13.5239 14.986"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
