import Flex from "../../components/general/Flex.jsx";
import not_found_icon from "../../assets/images/bonus-icon.svg";
import not_found_filter from "../../assets/images/filter-search.svg";
import Typography from "../../components/moleculs/Typography.jsx";

export const NotFound = ({ isFiltered }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
      style={{ height: "84vh" }}
    >
      <img src={isFiltered ? not_found_icon : not_found_filter} />
      <Typography
        variant="h2"
        text={isFiltered ? "No deals yet" : "No Result found"}
        size="20px"
        weight="500"
        color="#3B5166"
        padding="16px 0 0 0"
        align="center"
      />
      <Typography
        variant="h3"
        text={
          isFiltered
            ? "Once there will be deals they will appear here"
            : `No Result found. Please try again.`
        }
        size="16px"
        color="#5B6D7F"
        padding="4px 0 0 0"
        align="center"
        maxWidth={"400px"}
      />
    </Flex>
  );
};
