import { useEffect } from "react";

import { DownloadExcelService } from "../services/downloadExcel.js";

export default function DownloadExcel() {
  useEffect(() => {
    let id = window.location.search;
    const searchKeys = id.split("=");
    DownloadExcelService(searchKeys[1]).then((res) => {
      console.log(res);
    });
  }, []);

  return <>Download</>;
}
