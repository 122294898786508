import styled from "styled-components";
import { Pagination, Table } from "antd";
import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import EstimateIcon from "../../components/SvgComponents/EstimateIcon.jsx";
import WayIcon from "../../components/SvgComponents/WayIcon";
import EyeIcon from "../../components/SvgComponents/EyeIcon.jsx";
import Loader from "../../components/moleculs/Loader";
import dayjs from "dayjs";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledConsignmentTable = styled.div`
  margin-top: 24px;

  .ant-table-container {
    max-height: 70vh;
    overflow: auto;
  }

  .new-pagination {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 30px 0;
  }
}

table {
  background: white;
  margin-top: 24px;
  padding: 16px;
  border-radius: 12px;
}

.show-details {
  background: transparent;
  border: none;
  outline: none;
}

table {
  thead {
    display: none;
  }

  tbody {
    tr {
      td:nth-child(1) {
        padding: 16px 0 !important;
      }

      td:last-child {
        padding: 16px 0 !important;
      }

      td:not(:nth-child(1)) {
        padding: 10px;
      }
    }
  }

`;

export default function ConsignmentTable({
  setModalOpen,
  setId,
  params,
  setFilterData,
  consignmentList,
  meta,
  isLoading,
  permissions,
}) {
  const columns = [
    {
      key: "parcel",
      dataIndex: "parcel",
      title: "",
    },
    {
      key: "country",
      dataIndex: "country",
      title: "",
    },
    {
      key: "bill",
      dataIndex: "bill",
      title: "",
    },
    {
      key: "forwarder",
      dataIndex: "forwarder",
      title: "",
    },
    {
      key: "weight",
      dataIndex: "weight",
      title: "",
    },

    {
      key: "status",
      dataIndex: "status",
      title: "",
    },
    {
      key: "eye",
      dataIndex: "eye",
      title: "",
    },
  ];
  const newData = consignmentList?.map((consignment, i) => {
    let weight = 0;
    let v_weight = 0;
    consignment?.boxes?.forEach((box) => {
      weight += Number(box.total_weight);
      v_weight += Number(box.total_v_weight);
    });
    return {
      key: i,
      id: consignment?.id || i,
      parcel: (
        <>
          <Typography
            variant="span"
            text={consignment?.name || ""}
            color="#262626"
            size="14px"
            weight={500}
            padding="8px 0 0 0"
          />
          <Typography
            variant="span"
            text={consignment?.id || ""}
            color="#5B6D7F"
            size="14px"
            weight={400}
            padding="8px 0 0 0"
          />
        </>
      ),
      country: (
        <div>
          <Flex alignItems={"center"}>
            {/*<UsFlag />*/}
            <img
              src={consignment?.warehouse?.round_flag}
              height={24}
              width={24}
            />
            <div
              style={{ paddingLeft: 8 }}
              id="dispatch"
              data-tip="React-tooltip"
            >
              {/*<FlyIcon />*/}
              <img src={consignment?.dispatch?.icon} height={28} width={28} />
            </div>
          </Flex>
        </div>
      ),
      bill: (
        <Typography
          text={
            consignment?.air_waybill
              ? consignment?.air_waybill
              : "No Air Way Bill"
          }
          variant="p"
          size="14px"
          weight="400"
          color={consignment?.air_waybill ? "#5DBA2F" : "#262626"}
        />
      ),
      forwarder: (
        <div>
          <Typography
            variant="p"
            text={consignment?.freight_forwarder || ""}
            color="#262626"
            size="14px"
            weight="500"
          />
          <div className="product-description">
            <Typography
              variant="p"
              text={consignment?.comment || ""}
              color="#5B6D7F"
              size="12px"
              weight={400}
            />
          </div>
        </div>
      ),
      weight: (
        <>
          <Typography
            text={weight ? weight.toFixed(2) + " kg" : "0 kg"}
            variant="p"
            color="#262626"
            weight="400"
            size="14px"
          />
          <Typography
            text={v_weight ? v_weight.toFixed(2) + " kg" : ""}
            variant="p"
            color="#262626"
            weight="400"
            size="14px"
          />
        </>
      ),

      status: (
        <div>
          <Flex alignItems="center">
            <WayIcon />
            <Flex>
              <Typography
                variant="p"
                text="On the way"
                color="#5B6D7F"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
              <Typography
                variant="p"
                text={
                  consignment?.on_way_date
                    ? dayjs(consignment?.on_way_date).format("DD.MM.YYYY")
                    : ""
                }
                color="#262626"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
            </Flex>
          </Flex>
          <Flex alignItems="center" style={{ paddingTop: 10 }}>
            <EstimateIcon />
            <Flex>
              <Typography
                variant="p"
                text="Estimated"
                color="#5B6D7F"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
              <Typography
                variant="p"
                text={
                  consignment?.estimated_date_to
                    ? dayjs(consignment?.estimated_date_to).format("DD.MM.YYYY")
                    : ""
                }
                color="#262626"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
            </Flex>
          </Flex>
        </div>
      ),
      eye: PermissionFinder(permissions, "parcel-view") ? (
        <button
          className="show-details"
          onClick={() => {
            setId(consignment?.id);
            setModalOpen(true);
          }}
        >
          <EyeIcon />
        </button>
      ) : null,
    };
  });

  if (isLoading) return <Loader />;
  return (
    <StyledConsignmentTable>
      <Table
        loading={false}
        columns={columns}
        dataSource={newData}
        pagination={false}
      />
      <div className={"new-pagination"}>
        <Pagination
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page) => {
            console.log(page);
            setFilterData({ ...params, page });
          }}
          defaultPageSize={meta?.per_page || 15}
          defaultCurrent={meta?.current_page || 1}
        />
      </div>
    </StyledConsignmentTable>
  );
}
