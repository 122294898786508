import styled from "styled-components";
import Search from "antd/es/input/Search.js";
import { Tabs } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import OrdersTopActions from "./TopActions.jsx";
import Flex from "../../components/general/Flex";
import CheckTable from "./CheckTable";

const StyledOrdersCheck = styled.div`
  //margin: 24px 0 0 24px;
  padding-top: 24px;
  .search-tracking {
    margin-top: 16px;
    margin-right: 20px;
    input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    button {
      background: #5b6d7f;
      border-color: #5b6d7f;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }
  .tabs {
    margin-top: 10px;
  }
`;

export default function OrdersCheck() {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: `All`,
    },
    {
      key: "2",
      label: `Checked`,
      children: `Content of Tab Pane 2`,
    },
    {
      key: "3",
      label: `Unchecked`,
      children: `Content of Tab Pane 3`,
    },
  ];

  return (
    <StyledOrdersCheck className="orders">
      <div className="page-title">
        <Typography
          text="Orders Check"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <OrdersTopActions />
      <Flex>
        <Search
          className="search-tracking"
          enterButton="Check"
          placeholder="Tracking code"
          style={{ width: 330 }}
        />
        <Tabs
          className="tabs"
          items={items}
          onChange={onChange}
          defaultActiveKey="1"
          style={{ width: "50%" }}
        />
      </Flex>
      <CheckTable />
    </StyledOrdersCheck>
  );
}
