import useGetDashboardChartsData from "../../hooks/Dashboard/useGetDashboardCharts.hook.jsx";
import { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import styled from "styled-components";
import { Segmented, Select } from "antd";
import Flex from "../../components/general/Flex";

const StyledCharts = styled.div`
  padding: 24px;
  background: white;
  border-radius: 12px;
  min-height: 580px;
  position: relative;
  margin-bottom: 32px;
  @media (max-width: 390px) {
    padding: 10px;
    .filters {
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;

      .ant-select {
        margin-left: 0 !important;
        margin-top: 10px;
        margin-bottom: 16px;
      }
    }
  }
`;

export default function DashboardCharts({ customRange }) {
  const [filters, setFilters] = useState({
    time_statement: "weekly",
    property: "quantity",
  });

  useEffect(() => {
    if (customRange) {
      let newFilters = {};
      newFilters.time_statement = filters.time_statement;
      newFilters.property = filters.property;
      newFilters.start_date = customRange[0];
      newFilters.end_date = customRange[1];
      setFilters(newFilters);
    }
  }, [customRange]);

  const { dashboardCharts, refetch } = useGetDashboardChartsData(filters);
  const [countryTotals, setCountryTotals] = useState([
    "China",
    "Germany",
    "United Kingdom",
    "USA",
    "Greece",
  ]);

  useEffect(() => {
    if (dashboardCharts) {
      let data = Object.entries(dashboardCharts)?.map((dashboardItem) => {
        let newData = {
          date: new Date(dashboardItem[0]),
        };
        dashboardItem[1].map((item) => {
          let key = item?.country.toString();
          newData[key] = item?.total;
        });
        return newData;
      });

      am4core.useTheme(am4themes_animated);
      // Create chart instance
      let chart = am4core.create("chartsDashboard", am4charts.XYChart);

      // Add data
      chart.data = data;

      let categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
      categoryAxis.renderer.grid.template.location = 0;
      //categoryAxis.renderer.minGridDistance = 30;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create series
      function createSeries(field, name) {
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = "date";
        series.name = name;
        series.tooltipText = "{name}: [b]{valueY}[/]";
        series.strokeWidth = 2;
        series.stroke = am4core.color(
          field === "USA"
            ? "#3FCC75"
            : field === "Germany"
            ? "#FC9A3A"
            : field === "United Kingdom"
            ? "#4ED2F7"
            : field === "China"
            ? "#7B75E1"
            : ""
        );

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.fill = am4core.color(
          field === "USA"
            ? "#3FCC75"
            : field === "Germany"
            ? "#FC9A3A"
            : field === "United Kingdom"
            ? "#4ED2F7"
            : field === "China"
            ? "#7B75E1"
            : ""
        );
      }

      const countriesShortNames = {
        China: "China",
        Germany: "Germany",
        "United Kingdom": "UK",
        USA: "USA",
        Greece: "Greece",
      };

      countryTotals?.map((country) => {
        createSeries(country, countriesShortNames[country]);
      });
      // createSeries("value", "Series #1");
      // createSeries("value2", "Series #2");
      // createSeries("value3", "Series #3");

      chart.legend = new am4charts.Legend();
      chart.cursor = new am4charts.XYCursor();
    }
  }, [dashboardCharts]);

  return (
    <StyledCharts className={"chartsDashboard"}>
      <Flex justifyContent={"flex-end"} className={"filters"}>
        <Segmented
          className={"filter-by-date"}
          options={[
            // "Daily",
            "Weekly",
            "Monthly",
          ]}
          onChange={(val) => {
            let newFilters = {};
            newFilters.time_statement = val.toLowerCase();
            newFilters.property = filters.property;
            newFilters.start_date = filters?.start_date;
            newFilters.end_date = filters?.end_date;
            setFilters(newFilters);
            // console.log(val.toLowerCase());
            // setFilterType(dashboardFilterDays[val]);
            // setCustomRange(null);
          }}
        />
        <Select
          style={{ width: 200, height: 40, marginLeft: 16 }}
          defaultValue={"quantity"}
          onChange={(val) => {
            let newFilters = {};
            newFilters.time_statement = filters.time_statement;
            newFilters.property = val;
            newFilters.start_date = filters?.start_date;
            newFilters.end_date = filters?.end_date;
            setFilters(newFilters);
          }}
        >
          <Select.Option value={"quantity"}>Quantity</Select.Option>
          <Select.Option value={"weight"}>Weight</Select.Option>
        </Select>
      </Flex>
      <div
        id="chartsDashboard"
        style={{ width: "100%", height: "500px" }}
      ></div>
    </StyledCharts>
  );
}
