import { useMutation, useQueryClient } from "react-query";
import { blogApi } from "../../services/blog.js";

export const useCreateAndEditBlog = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const client = useQueryClient();
  return useMutation(blogApi.createAndEditBlog, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["created-and-updated-blogs"]);
    },
  });
};
