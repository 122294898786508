import styled from "styled-components";
import { Alert, Input } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import ButtonGray from "../../components/moleculs/ButtonGray";

const StyledInArmenia = styled.div`
  padding-top: 24px;
  min-height: 100vh;
  .create-expected {
    margin-top: 16px;
    background: #5b6d7f;
    border-radius: 8px;
    padding: 8px 16px;
    color: white;
    border: none;
    outline: none;
  }
  .flag {
    margin-right: 8px;
  }
  .in-armenia-content {
    margin-top: 16px;
    padding: 16px;
    background: white;
    border-radius: 12px;
    input {
      width: 311px !important;
      margin-right: 16px;
    }
    button {
      color: white;
      background: #b3bbc4;
    }
  }
`;

export default function InArmenia() {
  return (
    <StyledInArmenia>
      <div className="overlay" />
      <div className="page-title">
        <Typography
          text="In Armenia"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <Alert
        type="warning"
        showIcon
        message="If you press the button in Armenia, the selected package will be ready for pickup."
      />
      <div className="in-armenia-content">
        <Typography
          variant="label"
          text="Parcel name"
          color="#3B5166"
          size="14px"
          weight="400"
          padding="0 0 4px 0"
        />
        <Flex>
          <Input placeholder="Parcel name" />
          <ButtonGray text="In Armenia" />
        </Flex>
      </div>
    </StyledInArmenia>
  );
}
