import axios from "../libs/axios";

// const API_URL = "https://devback.onexglobal.ge";
// https://devback.onexglobal.ge/api/admin/

export const login = async (user) => {
  const { data } = await axios.post("/login", user);
  axios.defaults.headers.common = {
    Authorization: `Bearer ${data.data.token}`,
  };
  return data;
};

export const logout = async (user) => {
  const { data } = await axios.post("/logout");
  return data;
};

export const GetUsers = async (query) => {
  const data = axios.get(`/users`, {
    params: query,
  });
  return data;
};

export const getPrimeUsersList = async (query) => {
  if (query) {
    const data = axios.get(`/users/export`, {
      params: query,
    });
    return data;
  }
  return data;
};

export const updatePrimeUser = async ({ id, status }) => {
  const result = await axios.patch(`/users/${id}/prime-status/update`, {
    status: status,
  });
  return result.data;
};

export const createUser = async (user) => {
  const r = await axios.post(`/users`, user);
  return r.data;
};

export const updateUser = async ({ id, user }) => {
  const r = await axios.patch(`/users/${id}`, user);
  return r.data;
};

export const deleteUserById = async (id) => {
  const result = await axios.delete(`/users/${id}`);

  return result.data;
};
