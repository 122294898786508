import { Table } from "antd";
import Flex from "../../components/general/Flex.jsx";
import EditIcon from "../../components/SvgComponents/EditIcon.jsx";
import dayjs from "dayjs";
import Typography from "../../components/moleculs/Typography.jsx";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";
import PrimeIcon from "../../components/SvgComponents/primeIcon.jsx";

export const TableMembers = ({ usersList, setAddMembers, permissions }) => {
  const columns = [
    {
      title: "Full name",
      dataIndex: "full_name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Created date",
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: " ",
      dataIndex: "edit",
      key: "edit",
    },
  ];

  const dataSource = usersList?.map((el, i) => ({
    key: i,
    full_name: (
      <Flex>
        <Typography
          text={el?.user?.full_name + " " + el?.user_code || ""}
          variant={"p"}
          size={"14px"}
        />
        {el?.user?.is_prime ? (
          <div style={{ paddingLeft: 4 }}>
            <PrimeIcon />
          </div>
        ) : null}
      </Flex>
    ),
    email: (
      <Typography text={el?.user?.email || ""} variant={"p"} size={"14px"} />
    ),
    role: (
      <Typography
        text={el?.user?.role?.[0]?.name || ""}
        className={"role-td"}
        align={"center"}
        variant={"p"}
        size={"12px"}
        color={"#3B5166"}
      />
    ),
    created_date: (
      <Flex>
        <Typography
          text={
            el?.user?.created_et
              ? dayjs(el?.user?.created_et).format("DD.MM.YYYY") + ","
              : ""
          }
          variant={"p"}
          size={"14px"}
        />
        <Typography
          text={
            el?.user?.created_et
              ? dayjs(el?.user?.created_et).format("HH:MM")
              : ""
          }
          margin={"0 0 0 4px"}
          variant={"p"}
          size={"14px"}
          color={"#8E9BA7"}
        />
      </Flex>
    ),
    status: (
      <Typography
        text={el?.user?.is_active_admin ? "Active" : "Inactive"}
        className={`role-td ${
          el?.user?.is_active_admin ? "active-status" : "inactive-status"
        }`}
        align={"center"}
        variant={"p"}
        size={"14px"}
      />
    ),
    edit: PermissionFinder(permissions, "role-edit") ? (
      <EditIcon onClick={() => setAddMembers(el?.user)} />
    ) : null,
  }));
  return (
    <Flex
      justifyContent="space-between"
      className={"table-wrapper"}
      direction={"column"}
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        style={{ width: "100%" }}
        pagination={false}
      />
    </Flex>
  );
};
