import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import { useGetAppVersions } from "../../hooks/AppVersions/useGetAppVersions.hook.jsx";
import { useState } from "react";
import ActionSearch from "./ActionSearch";
import { Drawer, Modal, Pagination, Table } from "antd";
import Flex from "../../components/general/Flex.jsx";
import EditIcon from "../../components/SvgComponents/EditIcon.jsx";
import DeleteIcon from "../../components/SvgComponents/DeleteIcon.jsx";
import apple from "../../assets/images/apple.svg";
import android from "../../assets/images/android.svg";
import ButtonGray from "../../components/moleculs/ButtonGray.jsx";
import CreateEdit from "./CreateEdit.jsx";
import { WarningIcon } from "../../components/SvgComponents/WarningIcon.jsx";
import DeleteModal from "./DeleteModal.jsx";
import { appVersionsApi } from "../../services/app-versions.js";
import { useForm } from "antd/es/form/Form.js";

const StyledActions = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;

  .ant-table-thead > tr > td,
  .ant-table-thead > tr > th {
    background: #e7e9ec;
  }

  .actions {
    > svg {
      cursor: pointer;

      &:last-child > path {
        stroke: #fc4447;
      }
    }
  }

  .android {
    text-transform: capitalize;
  }

  .ios {
    text-transform: uppercase;
  }

  .new-pagination {
    margin-top: 16px;
  }

  .create-version {
    background-color: #5b6d7f;
    color: #fff;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .filters-row {
    background-color: #fff;
    padding: 16px;
    border-radius: 12px;
  }
`;

export default function ActionsHistory() {
  const [filter, setFilters] = useState({});
  const [openDrawer, setOpenDrawer] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(null);
  const { data, meta, refetch, isLoading } = useGetAppVersions(filter);
  const [form] = useForm();

  const closeModal = () => {
    setOpenDrawer(false);
    form.resetFields();
  };

  return (
    <>
      <StyledActions>
        <div className="page-title">
          <Typography
            text="App Versions"
            color="#262626"
            weight={500}
            size="24px"
            variant="h2"
            margin="0 0 24px 0"
          />
        </div>
        <ActionSearch setFilter={setFilters} />
        <ButtonGray
          text="Create version"
          className="create-version"
          click={() => setOpenDrawer(true)}
        />
        <Table
          columns={[
            {
              key: "version",
              dataIndex: "version",
              title: "Version",
            },
            {
              key: "operating_system",
              dataIndex: "operating_system",
              title: "Operating system",
            },
            {
              key: "update_types",
              dataIndex: "update_types",
              title: "Update types",
            },
            {
              key: "actions",
              dataIndex: "actions",
              title: "",
              width: "auto",
            },
          ]}
          dataSource={data.map((item) => ({
            version: item.version,
            operating_system: (
              <Flex alignItems="center" gap="5px" className={item.os}>
                <img
                  alt={"os"}
                  src={
                    item.os === "android"
                      ? android
                      : item.os === "ios"
                      ? apple
                      : ""
                  }
                />
                {item.os}
              </Flex>
            ),
            update_types:
              item.force_update && item.dev_mode
                ? "Force Update" + ", " + "Dev Mode"
                : item.dev_mode
                ? "Dev Mode"
                : "Force Update",
            actions: (
              <Flex
                alignItems="center"
                justifyContent={"flex-end"}
                gap="16px"
                className="actions"
              >
                <EditIcon onClick={() => setOpenDrawer(item)} />
                <DeleteIcon onClick={() => setOpenDeleteModal(item.id)} />
              </Flex>
            ),
          }))}
          pagination={false}
          loading={isLoading}
        />
        <div className={"new-pagination"} style={{ padding: 0 }}>
          <Pagination
            total={meta?.total || 1}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={(page) => setFilters({ ...filters, page })}
            defaultPageSize={meta?.per_page || 15}
            defaultCurrent={meta?.current_page || 1}
          />
        </div>
      </StyledActions>
      <Drawer open={!!openDrawer} width="694px" onClose={() => closeModal()}>
        <CreateEdit
          form={form}
          data={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setOpenDeleteModal={setOpenDeleteModal}
          refetch={refetch}
          closeModal={closeModal}
        />
      </Drawer>
      <Modal
        footer={null}
        title={
          <Warning>
            <WarningIcon size={28} />
          </Warning>
        }
        open={!!openDeleteModal}
        onCancel={() => setOpenDeleteModal(null)}
      >
        <DeleteModal
          setOpenDeleteModal={setOpenDeleteModal}
          onDelete={() =>
            appVersionsApi.deleteVersion(openDeleteModal).then(() => {
              setOpenDeleteModal(null);
              refetch(filter);
              setOpenDrawer(false);
            })
          }
        />
      </Modal>
    </>
  );
}

const Warning = styled.div`
  display: flex;
  width: 42px;
  height: 42px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  background: #fff5eb;
  border-radius: 1500px;
`;
