import styled from "styled-components";
import { Input, Select, Space } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import Flex from "../../components/general/Flex";

const StyledSearch = styled.div`
  .search-btn {
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  .customer-search-input {
    @media (max-width: 768px) {
      width: 150px !important;
    }
  }

  .customer-search-box {
    @media (max-width: 768px) {
      flex-wrap: wrap !important;
      max-width: 287px;
    }

    &-first-line {
      @media (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
  }
`;

export default function MembersSearch({
  searchValue,
  setSearchKey,
  setSearchValue,
  handleSearch,
}) {
  return (
    <StyledSearch>
      <Typography
        text="Search by"
        variant="span"
        color="#3B5166"
        size="14px"
        weight="500"
        padding="0 0 5px 0"
      />
      <Flex alignItems="center" className={"customer-search-box"}>
        <div className={"customer-search-box-first-line"}>
          <Space.Compact block>
            <Select
              defaultValue="user_info"
              style={{ width: 180, height: 36 }}
              onChange={(value) => setSearchKey(value)}
            >
              <Select.Option value="user_info">Full name or GA</Select.Option>
              <Select.Option value="phone">Phone</Select.Option>
              <Select.Option value="user_id">ID</Select.Option>
              <Select.Option value="address">Address</Select.Option>
              <Select.Option value="email">Email</Select.Option>
              <Select.Option value="passport_details">
                Passport details
              </Select.Option>
            </Select>
            <Input
              className={"customer-search-input"}
              style={{ width: 290 }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSearch();
                }
              }}
            />
          </Space.Compact>
        </div>
        <div className="search-btn">
          <ButtonPrimary
            style={{ height: 35 }}
            icon={<SearchIcon />}
            text="Search"
            click={handleSearch}
          />
        </div>
      </Flex>
    </StyledSearch>
  );
}
