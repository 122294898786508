export const statusTexts = {
  scan: "Scanned",
  expected: "Expected",
  at_warehouse: "At Warehouse",
  on_the_way: "On the Way",
  in_georgia: "In Georgia",
  received: "Received",
  refund: "Refunded",
};

//test
