import { useMutation, useQueryClient } from "react-query";
import { testUserApi } from "../../services/test-users";

export const clearTestUserData = (onSuccess = () => {}, onError = () => {}) => {
  const client = useQueryClient();
  return useMutation(testUserApi.clearTestUserData, {
    onSuccess,
    onError,
    onSettled: async () => {
      await client.invalidateQueries(["delete-expected"]);
    },
  });
};
