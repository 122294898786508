import styled from "styled-components";
import { Spin } from "antd";
import Flex from "../general/Flex";

const StyledButtonPrimary = styled.button`
  background: rgba(93, 186, 47, 1);
  border: ${({ border }) => border || "1px solid rgba(231, 233, 236, 1)"};
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat arm2";
  padding: ${({ padding }) => padding || "6px 36px"};
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  width: ${({ width }) => (width ? width : "auto")};
  height: ${({ height }) => height || "auto"};
  margin: ${({ margin }) => margin};

  &[disabled] {
    opacity: 0.4;
  }
`;

export default function ButtonPrimary({
  text,
  icon,
  click = () => {},
  htmlType = "button",
  loading = false,
  ...props
}) {
  return (
    <StyledButtonPrimary
      onClick={() => click()}
      {...props}
      type={htmlType}
      disabled={loading}
    >
      {icon ? (
        <Flex alignItems="center">
          {icon}
          <span style={{ paddingLeft: 10 }}>{text}</span>
        </Flex>
      ) : loading ? (
        <Spin />
      ) : (
        text
      )}
    </StyledButtonPrimary>
  );
}
