import styled from "styled-components";
import { Input, Select } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";

const StyledScanTopActions = styled.div`
  margin-top: 24px;
  padding: 16px;
  background: #ffffff;
  border-radius: 12px;
  .ant-input-group-compact {
    margin-right: 16px;
    .ant-select {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
  }
`;
export default function ScanTopActions() {
  return (
    <StyledScanTopActions>
      <Typography
        text="Search by"
        variant="p"
        size="14px"
        weight="500"
        color="#3B5166"
        padding="0 0 4px 0"
      />
      <Flex>
        <div>
          <Input.Group compact>
            <Select defaultValue="Tracking Code" style={{ width: 150 }}>
              <Select.Option>Full name or ARM</Select.Option>
              <Select.Option>Phone</Select.Option>
              <Select.Option>ID</Select.Option>
              <Select.Option>Address</Select.Option>
              <Select.Option>Email</Select.Option>
              <Select.Option>Passport details</Select.Option>
            </Select>
            <Input style={{ width: 290 }} value="42" />
          </Input.Group>
        </div>
        <ButtonPrimary icon={<SearchIcon />} text="Search" />
      </Flex>
    </StyledScanTopActions>
  );
}
