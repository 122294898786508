import styled from "styled-components";
import { Pagination, Table } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor, redTextColor } from "../../constants.js";
import Flex from "../../components/general/Flex.jsx";
import dayjs from "dayjs";
import Loader from "../../components/moleculs/Loader.jsx";
import { balanceApi } from "../../services/balance.js";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon.jsx";
import PrimeIcon from "../../components/SvgComponents/primeIcon";

const StyledBalanceTable = styled.div`
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 12px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 12px;
  }

  .table-footer {
    width: calc(100% - 2px);
    background: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 1px solid #e7e9ec;
    border-left: 1px solid #e7e9ec;
    border-right: 1px solid #e7e9ec;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;

    &_footer {
      border-top: 1px solid #f5f5f5;
      width: 100%;
      display: flex;
      padding: 16px;
      justify-content: end;
    }
  }

  .ant-table {
    border-top: 1px solid #e7e9ec;
    border-left: 1px solid #e7e9ec;
    border-right: 1px solid #e7e9ec;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &
    :where(.css-dev-only-do-not-over ride-1ipetx6).ant-table-wrapper
    .ant-table-tbody
    > tr
    > th {
    background: #e7e9ec;
  }

  & .ant-table-thead > tr > th {
    color: #5b6d7f;
    font-size: 12px;
    font-family: "Montserrat arm2";
    font-weight: 400;
  }

  .ant-table-tbody {
    height: 60vh;
  }

  .ant-table-wrapper {
    max-height: 60vh;
  }

  .footer-item {
    margin-right: 16px;
  }

  .download-excel {
    margin-top: 16px;
  }
`;

export default function BonusTable({
  params,
  setFilterData,
  bonusList,
  meta,
  isLoading,
  totalSum = 0,
}) {
  const onShowSizeChange = (current, pageSize) => {
    setFilterData({ ...params, page: current, per_page: pageSize });
  };

  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: " ",
      width: "50px",
    },
    {
      key: "user_code",
      dataIndex: "user_code",
      title: "Full name",
    },
    {
      key: "bonus_type",
      dataIndex: "bonus_type",
      title: "Bonus type",
    },
    {
      key: "bonus_amount",
      dataIndex: "bonus_amount",
      title: "Bonus amount",
    },
    {
      key: "given_date",
      dataIndex: "given_date",
      title: "Given date",
    },
    {
      key: "expire_date",
      dataIndex: "expire_date",
      title: "Expire date",
    },
  ];

  const dataSource = bonusList?.map((list, i) => ({
    key: i,
    id: <Typography variant="span" text={i + 1} size="14px" weight="400" />,
    user_code: (
      <Flex>
        <Typography
          variant="span"
          text={
            (list?.user?.full_name || "") +
            " " +
            (list?.user?.recipient?.user_code || "")
          }
          size="14px"
          weight="400"
          color={primaryTextColor}
        />
        {list?.user?.is_prime ? (
          <div style={{ paddingLeft: 4 }}>
            <PrimeIcon />
          </div>
        ) : null}
      </Flex>
    ),
    bonus_type: (
      <Typography
        variant="span"
        transform={"capitalize"}
        text={list?.bonus_type?.current_bonus_type?.name || ""}
        size="14px"
        weight="400"
        color={primaryTextColor}
      />
    ),
    bonus_amount: (
      <Typography
        variant="span"
        text={
          list?.sum && list?.type === "in"
            ? list?.sum + " ₾"
            : -list?.sum + " ₾"
        }
        size="14px"
        weight="500"
        color={list?.type === "in" ? primaryTextColor : redTextColor}
      />
    ),

    given_date: (
      <Flex>
        <Typography
          variant="span"
          text={
            list?.given_date
              ? dayjs(list?.given_date).format("DD.MM.YYYY ,")
              : ""
          }
          size="14px"
          weight="400"
          color={primaryTextColor}
        />
        <Typography
          variant="span"
          text={list?.given_date ? dayjs(list?.given_date).format("HH:MM") : ""}
          size="14px"
          weight="400"
          color="#8E9BA7"
        />
      </Flex>
    ),
    expire_date: (
      <Flex>
        <Typography
          variant="span"
          text={
            list?.expire_date
              ? dayjs(list?.expire_date).format("DD.MM.YYYY ,")
              : ""
          }
          size="14px"
          weight="400"
          color={primaryTextColor}
        />
        <Typography
          variant="span"
          text={
            list?.expire_date ? dayjs(list?.expire_date).format("HH:MM") : ""
          }
          size="14px"
          weight="400"
          color="#8E9BA7"
        />
      </Flex>
    ),
  }));

  if (isLoading) return <Loader />;
  return (
    <StyledBalanceTable>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ y: "60vh" }}
      />

      <div className="table-footer">
        <div className="table-footer_footer">
          <div className="footer-item">
            <Flex>
              <Typography
                variant="span"
                text="Total bonus amount"
                color="#5B6D7F"
                size="14px"
                weight={500}
              />
              <Typography
                variant="span"
                text={`${meta?.options?.total_sum || totalSum || ""} ₾`}
                color="#8E9BA7"
                size="14px"
                weight={400}
                padding="0 0 0 8px"
              />
            </Flex>
          </div>
        </div>
      </div>
      <Flex
        style={{ padding: "30px 0" }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <ButtonSecondary
          style={{ marginLeft: 10 }}
          text={params?.user_id ? "Excel" : "Excel All"}
          icon={<DownloadIcon />}
          click={() => balanceApi.exportExelList(params?.user_id || null)}
        />
        {/*list?.user_id*/}
        <Pagination
          showSizeChanger
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={onShowSizeChange}
          defaultPageSize={meta?.per_page || 15}
          defaultCurrent={meta?.current_page || 1}
        />
      </Flex>
    </StyledBalanceTable>
  );
}
