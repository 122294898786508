import { useQuery } from "react-query";
import { GetPickupPoints } from "../services/pickupPoints.js";

export const useGetPickupPoints = () => {
  const { isLoading, refetch, data } = useQuery(
    ["get-pickup-points"],
    () => GetPickupPoints(),
    {
      staleTime: Infinity,
      select: ({ data }) => data?.data?.data,
    }
  );
  return { isLoading, pickup_points: data, meta: data?.meta, refetch };
};
