import { useQuery } from "react-query";
import { consignmentApi } from "../../services/consignment.js";

export const useConsignmentList = (params) => {
  const {
    isError,
    isLoading,
    data,
    refetch: reFetchList,
  } = useQuery(
    ["consignment_list", params],
    () => consignmentApi.fetchConsignmentList(params),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        let consignmentList = data?.data?.data;
        let meta = data?.data?.meta;
        return { consignmentList, meta };
      },
    }
  );
  return {
    isLoading,
    consignmentList: data?.consignmentList,
    meta: data?.meta,
    reFetchList,
  };
};
