import { AutoComplete, Drawer, Form } from "antd";
import styled from "styled-components";
import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import { useState } from "react";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import {
  mail_icon,
  order_icon,
  phone_icon,
  recipients_icon,
  user_icon,
  year_icon,
} from "../../assets/images/prime/index.js";
import { analyzeDay } from "../../helpers/AnalyzeDayOfYear.js";
import { testUsersAutocomplete } from "../../hooks/TestUsers/useTestUsersAutocomplete.hook.js";
import { makeUserTest } from "../../hooks/TestUsers/makeUserTest.js";

const WrapperModal = styled.div`
  .auto_comp {
    width: 100%;
  }

  background: #f9fafb;
`;

export const AddTestUsersModal = ({
  refetch = () => {},
  addTest,
  setAddTest = () => {},
  userInfo,
  setUserInfo = () => {},
}) => {
  const [form] = Form.useForm();
  const [user, setUser] = useState(null);
  const { usersList = [] } = testUsersAutocomplete({ is_test: 0 });
  const { mutate } = makeUserTest(
    (data) => {
      openNotification("Make Test", "Test successfully created");
      setAddTest(false);
      form.resetFields();
      setUserInfo(null);
      refetch();
    },
    (e) => {
      openNotificationError("Make Test", "something went wrong");
    }
  );
  const onFinish = () => {
    if (user?.id) mutate(user?.id);
  };

  const closeModal = () => {
    setAddTest(false);
    setUserInfo(null);
    form.resetFields();
    setUser({});
  };
  const registeredInterval = analyzeDay(user?.registered_days);

  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => {
        closeModal();
        setUser(null);
      }}
      open={addTest}
      width={600}
      header={false}
    >
      <WrapperModal>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Typography
              variant={"h2"}
              weight={"500"}
              text={"Add Test User"}
              size={"22px"}
            />
            <Flex alignItems={"center"}>
              <ButtonSecondary
                text="Cancel"
                background="#FFECED"
                color="#FC4447"
                border={"none"}
                htmlType="reset"
                style={{ margin: "0 16px" }}
                click={() => closeModal()}
              />
              <ButtonPrimary text="Save" border={"none"} htmlType="submit" />
            </Flex>
          </Flex>
          <Flex
            style={{
              padding: "16px",
              background: "#ffffff",
              margin: "24px 0",
              borderRadius: "12px",
            }}
          >
            <Form.Item name="user_info" label={"Full name or User code"}>
              <AutoComplete
                style={{ width: "520px" }}
                placeholder="Full name or User code"
                value={userInfo}
                onSearch={(val) => {
                  setUserInfo({ user_info: val, is_test: 0 });
                }}
                onSelect={(_, user) => {
                  setUser(usersList.find((el) => el.id === user.id));
                }}
                options={usersList || []}
              />
            </Form.Item>
          </Flex>
        </Form>
        {user ? (
          <Flex
            style={{
              padding: "16px",
              background: "#ffffff",
              margin: "24px 0",
              borderRadius: "12px",
            }}
            direction={"column"}
          >
            <Flex justifyContent={"center"} alignItems={"center"}>
              <img alt={"user"} src={user_icon} />
              <Typography
                margin={"0 0 0 8px"}
                weight={500}
                variant={"p"}
                text={user?.full_name + " " + user?.recipient?.user_code}
              />
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              style={{ margin: "18px 0 0 0" }}
            >
              <img alt={"user"} src={year_icon} />
              <Typography
                margin={"0 16px 0 8px"}
                weight={400}
                variant={"p"}
                color={"#5B6D7F"}
                text={registeredInterval}
                size={"14px"}
              />
              <img alt={"order"} src={order_icon} />
              <Typography
                margin={"0 0 0 8px"}
                weight={400}
                variant={"p"}
                color={"#5B6D7F"}
                text={user?.orders_count || 0}
                size={"14px"}
              />
              {user?.orders_count_by_country?.map((el, index) => (
                <Flex
                  key={index}
                  alignItems={"center"}
                  style={{ margin: "0 0 0 16px" }}
                >
                  <img
                    alt={`country${index}`}
                    width={24}
                    height={24}
                    src={`https://devbackadmin.onex.ge/storage/images/warehouses/${el?.round_flag}`}
                  />
                  <Typography
                    margin={"0 0 0 8px"}
                    weight={400}
                    variant={"p"}
                    color={"#5B6D7F"}
                    text={el?.total}
                    size={"14px"}
                  />
                </Flex>
              ))}
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              style={{ margin: "18px 0 0 0" }}
            >
              <img alt={"recipients"} src={recipients_icon} />
              <Typography
                margin={"0 0 0 8px"}
                weight={400}
                variant={"p"}
                text={
                  (user?.recipients?.length === 1
                    ? user?.recipients?.length + " " + "recipient"
                    : user?.recipients?.length + " " + "recipients") || 0
                }
                size={"14px"}
              />
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              style={{ margin: "18px 0 0 0" }}
            >
              <img alt={"phone"} src={phone_icon} />
              <Typography
                margin={"0 0 0 8px"}
                weight={400}
                variant={"p"}
                text={user?.phone || ""}
                size={"14px"}
              />
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              style={{ margin: "18px 0 0 0" }}
            >
              <img alt={"email"} src={mail_icon} />
              <Typography
                margin={"0 0 0 8px"}
                weight={400}
                variant={"p"}
                text={user?.email || ""}
                size={"14px"}
              />
            </Flex>
          </Flex>
        ) : null}
      </WrapperModal>
    </Drawer>
  );
};
