export default function MissingIcon({ size = "18", margin, ...props }) {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      style={{ margin }}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0833 5.06478L8.99997 8.99996M8.99997 8.99996L1.91664 5.06478M8.99997 8.99996L9 16.9167M16.5 9.41666L16.5 5.61786C16.5 5.33232 16.5 5.18956 16.4579 5.06222C16.4207 4.94958 16.3599 4.84618 16.2795 4.75893C16.1886 4.66032 16.0638 4.59099 15.8142 4.45232L9.64753 1.02639C9.41119 0.895093 9.29302 0.829443 9.16788 0.803706C9.05712 0.780926 8.94288 0.780926 8.83213 0.803706C8.70698 0.829443 8.58881 0.895095 8.35248 1.02639L2.18581 4.45232C1.93621 4.59099 1.8114 4.66032 1.72053 4.75894C1.64013 4.84618 1.57929 4.94958 1.54207 5.06223C1.5 5.18956 1.5 5.33233 1.5 5.61786V12.3821C1.5 12.6677 1.5 12.8104 1.54207 12.9378C1.57929 13.0504 1.64013 13.1538 1.72053 13.241C1.8114 13.3397 1.93621 13.409 2.18581 13.5477L8.35248 16.9736C8.58881 17.1049 8.70698 17.1705 8.83213 17.1963C8.94288 17.2191 9.05712 17.2191 9.16788 17.1963C9.29302 17.1705 9.41119 17.1049 9.64753 16.9736L9.83333 16.8704M5.25 2.74999L12.75 6.91665M12.75 12.3333L16.9167 16.5M16.9167 12.3333L12.75 16.5"
        stroke="#47BFE1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
