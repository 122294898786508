import { useQuery } from "react-query";
import { GetParcels } from "../../services/parcels.js";

export default function useGetParcel(filters) {
  const { data, isLoading, refetch } = useQuery(
    ["get-parcel-list", filters],
    () => GetParcels(filters),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        const parcelList = data?.data?.data?.map((parcel, i) => ({
          key: i,
          value: parcel?.id || "",
          label: parcel?.name || "",
          ...parcel,
        }));
        const meta = data?.data?.meta;
        return { parcelList, meta };
      },
      // enabled: !!filters,
    }
  );
  return {
    parcelList: data?.parcelList,
    meta: data?.meta,
    isLoading,
    refetch,
  };
}
