import styled from "styled-components";
import { Col, Input, Row, Select } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import CheckIcon from "../../components/SvgComponents/CheckIcon";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";

const StyledScanSingleCalculation = styled.div`
  border: 1px solid #e7e9ec;
  border-radius: 12px;
  margin-top: 24px;
  padding: 16px;
  .custom-group-input {
    input {
      height: 53px;
      border-radius: 0 !important;
    }
  }
  .footer {
    padding-top: 25px;
    button {
      width: 100%;
      padding: 14px;
    }
  }
  .item {
    border-bottom: 1px solid #e7e9ec;
    padding-bottom: 10px;
    padding-top: 10px;
    input {
      height: 53px;
    }
    .ant-input-affix-wrapper {
      background: transparent;
    }
    .ant-select-selector {
      height: 50px;
    }
    .sizes-item {
      input {
        width: 88px;
      }
      //width: calc(100% / 4 - 41px);
      //margin-right: 16px;
    }
  }
  .size {
    padding: 6px 16px;
    border-radius: 16px;
    &.primary {
      background: #5dba2f;
    }
    &.secondary {
      background: #e7e9ec;
      svg {
        path {
          stroke: #0a2540;
        }
      }
    }
    svg {
      path {
        stroke: white;
      }
    }
  }
`;

const StyledMinus = styled.div`
  span {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
  background: #c3d2e2;
  padding: 14px 30px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const StyledPlus = styled.div`
  background: #5dba2f;
  border-radius: 0px 8px 8px 0px;
  padding: 14px 30px;
  span {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
`;

function Minus() {
  return (
    <StyledMinus>
      <span>-</span>
      <span>0.1</span>
    </StyledMinus>
  );
}

function Plus() {
  return (
    <StyledPlus>
      <span>+</span>
      <span>0.1</span>
    </StyledPlus>
  );
}

export default function ScanSingleCalculation() {
  return (
    <StyledScanSingleCalculation>
      <div className="item">
        <Typography
          variant="p"
          text="Change Weight"
          size="14px"
          weight="400"
          color="#5B6D7F"
          padding="0 0 8px 0"
        />
        <Typography
          variant="p"
          text="Change Weight"
          size="16px"
          weight="500"
          color="#3B5166"
          padding="0 0 4px 0"
        />
        <div className="custom-group-input">
          <Flex>
            <Minus />
            <Input suffix="Kg" />
            <Plus />
          </Flex>
        </div>
      </div>
      <div className="item">
        <Typography
          variant="p"
          text="Locker sizes"
          size="14px"
          weight="400"
          color="#5B6D7F"
          padding="0 0 8px 0"
        />
        <Typography
          variant="p"
          text="Sizes"
          size="16px"
          weight="500"
          color="#3B5166"
          padding="0 0 4px 0"
        />
        <Flex justifyContent="space-between" style={{ paddingBottom: 24 }}>
          <div className="size primary">
            <Flex alignItems="center">
              <CheckIcon />
              <Typography
                variant="span"
                text="Not Locker Size"
                padding="0 0 0 7px"
                color="white"
                size="16px"
                weight="400"
              />
            </Flex>
          </div>
          <div className="size secondary">
            <Flex alignItems="center">
              <CheckIcon />
              <Typography
                variant="span"
                text="Not Standart Size"
                padding="0 0 0 7px"
                color="#0A2540"
                size="16px"
                weight="400"
              />
            </Flex>
          </div>
        </Flex>
        <Flex justifyContent="space-between">
          <div>
            <Typography
              variant="p"
              text="Locker height 1-18"
              size="16px"
              weight="500"
              color="#3B5166"
              padding="0 0 4px 0"
            />
            <Input value="2" suffix="500mm" />
          </div>
          <div>
            <Typography
              variant="p"
              text="Locker depth 1-4"
              size="16px"
              weight="500"
              color="#3B5166"
              padding="0 0 4px 0"
            />
            <Input value="1" suffix="140mm" />
          </div>
        </Flex>
      </div>
      <div className="item">
        <Typography
          variant="p"
          text="Change V Weight"
          size="14px"
          weight="400"
          color="#5B6D7F"
          padding="0 0 8px 0"
        />
        <Row gutter={16} justifyContent="space-between">
          <Col lg={6} className="sizes-item">
            <Typography
              variant="p"
              text="Height"
              size="16px"
              weight="500"
              color="#3B5166"
              padding="0 0 4px 0"
            />
            <Input value="5.007" />
          </Col>
          <Col lg={6} className="sizes-item">
            <Typography
              variant="p"
              text="Height"
              size="16px"
              weight="500"
              color="#3B5166"
              padding="0 0 4px 0"
            />
            <Input value="5.007" />
          </Col>
          <Col lg={6} className="sizes-item">
            <Typography
              variant="p"
              text="Height"
              size="16px"
              weight="500"
              color="#3B5166"
              padding="0 0 4px 0"
            />
            <Input value="5.007" />
          </Col>
          <Col lg={6} className="sizes-item">
            <Typography
              variant="p"
              text=""
              size="16px"
              weight="500"
              color="#3B5166"
              padding="21px 0 4px 0"
            />
            <Select defaultValue="mm">
              <Select.Option>mm</Select.Option>
            </Select>
          </Col>
          <Col lg={6} className="sizes-item">
            <Typography
              variant="p"
              text="V Weight"
              size="16px"
              weight="500"
              color="#3B5166"
              padding="16px 0 4px 0"
            />
            <Input value="0.4" suffix="kg" />
          </Col>
        </Row>
      </div>
      <div className="footer">
        <ButtonPrimary text="Scanned" />
      </div>
    </StyledScanSingleCalculation>
  );
}
