export default function WhIcon({ ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      {...props}
    >
      <path
        d="M2.48535 7.43994L10.8188 12.4568L20.0854 7.46991"
        stroke="#3B5166"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9502 21.6096V12.5396"
        stroke="#3B5166"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9251 10.2066V9.17C20.9251 7.79 19.9351 6.11002 18.7251 5.44002L13.3851 2.48C12.2451 1.84 10.3851 1.84 9.24507 2.48L3.90508 5.44002C2.69508 6.11002 1.70508 7.79 1.70508 9.17V14.83C1.70508 16.21 2.69508 17.89 3.90508 18.56L9.24507 21.52C10.3851 22.16 11.7807 22.16 12.9207 21.52L13.3851 21.2001"
        stroke="#3B5166"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9112 20.2754H16.4071C15.4121 20.2754 14.6055 19.4688 14.6055 18.4737V15.0601C14.6055 14.4192 15.0416 13.8606 15.6633 13.7051L16.026 13.6145C16.2634 13.5551 16.454 13.3787 16.5313 13.1466C16.7261 12.5622 17.273 12.168 17.889 12.168H19.4293C20.0453 12.168 20.5922 12.5622 20.787 13.1466C20.8644 13.3787 21.055 13.5551 21.2923 13.6145L21.655 13.7051C22.2767 13.8606 22.7129 14.4192 22.7129 15.0601V18.4737C22.7129 19.4688 21.9063 20.2754 20.9112 20.2754Z"
        stroke="#3B5166"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0101 16.6728C20.0101 17.419 19.4051 18.024 18.6589 18.024C17.9126 18.024 17.3076 17.419 17.3076 16.6728C17.3076 15.9265 17.9126 15.3215 18.6589 15.3215C19.4051 15.3215 20.0101 15.9265 20.0101 16.6728Z"
        stroke="#3B5166"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
