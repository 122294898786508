import styled from "styled-components";
import { Input, message, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import IconButton from "../../moleculs/IconButton";
import EditIcon from "../../SvgComponents/EditIcon";
import Flex from "../../general/Flex";
import CancelIcon from "../../SvgComponents/CancelIcon";
import ButtonPrimary from "../../moleculs/ButtonPrimary";
import CheckIcon from "../../SvgComponents/CheckIcon";
import ButtonSecondary from "../../moleculs/ButtonSecondary";
import Typography from "../../moleculs/Typography.jsx";
import useGetRecipients from "../../../hooks/customers/useGetRecipients.hook.jsx";
import Loader from "../../moleculs/Loader";
import useRegions from "../../../hooks/useRegions.hook.jsx";
import { UpdateRecipientService } from "../../../services/recipients.js";
import { PermissionFinder } from "../../../hooks/permissionFinder.hook.js";
import { openNotificationError } from "../../../helpers/notification.js";

const StyledRecipientsTab = styled.div`
  margin-top: 20px;

  .ant-table-thead > tr > th {
    background: #f5f5f5;
  }

  .ant-table-container {
    border: 1px solid #e7e9ec;
    border-radius: 8px !important;
  }

  .ant-table-body {
    min-height: 70vh;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      border-radius: 10px;
    }

    /* Track */

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    /* Handle */

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;

      background: #d1dfec;
    }

    /* Handle on hover */

    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }

  .address-dropdown {
    position: absolute;
    background: white;
    z-index: 999;
    padding: 16px;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%),
      0px 4px 6px -2px rgb(16 24 40 / 3%);
    border-radius: 12px;

    &-item {
      padding-bottom: 16px;
    }
  }
`;

export default function RecipientsTab() {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const { id } = useParams();
  const { recipients, refetch, isLoading } = useGetRecipients({ user_id: id });

  const [editable, setEditable] = useState(false);
  const [editableId, setEditableId] = useState();
  const [editAddress, setEditAddress] = useState(false);

  const { regions } = useRegions();
  const [communities, setCommunities] = useState();
  const [region, setRegion] = useState();
  const [community, setCommunity] = useState();
  const [subAddress, setSubAddress] = useState();
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [userCode, setUserCode] = useState();
  const [passportType, setPassportType] = useState();
  const [passportNumber, setPassportNumber] = useState();
  const [phone, setPhone] = useState();
  const [editableRecipient, setEditableRecipient] = useState();
  const [editableObject, setEditableObject] = useState();
  useEffect(() => {
    if (editableId) {
      const editableObj = recipients?.find(
        (recipient) => recipient.id === editableId
      );
      setEditableObject(editableObj);
      const findCommunities = regions.data.data.data.find(
        (region) => region.id === editableObj.region_id
      );
      setCommunities(findCommunities.communities || "");
      setSubAddress(editableObj.address || "");
      setEditableRecipient(editableObj || "");
      setName(editableObj.first_name || editableObj.company_name || "");
      setSurname(
        editableObj.last_name || editableObj.georgian_company_name || ""
      );
      setUserCode(editableObj.user_code || "");
      setPassportType(editableObj.document_type || "");
      setPassportNumber(editableObj.document_number || "");
      setPhone(editableObj.phone || "");
      setSubAddress(editableObj.address || "");
      setRegion(editableObj.region_id || "");
      setCommunity(editableObj.community_id || "");
    }
  }, [editableId, editableObject]);

  const handleChangeRegion = (value) => {
    setRegion(value);
    const findCommunities = regions.data.data.data.find(
      (region) => region.id === value
    );
    setCommunities(findCommunities.communities);
  };

  const handleChangeCommunity = (value) => {
    setCommunity(value);
  };

  const handleApplyAddress = () => {
    setEditAddress(false);
    message.success("Address changed");
  };
  const handleUpdateRecipient = () => {
    const isPersonData = editableObject?.is_person
      ? { first_name: name, last_name: surname }
      : { company_name: name, georgian_company_name: surname };

    const data = {
      ...isPersonData,
      user_code: userCode,
      document_type: passportType,
      document_number: passportNumber,
      region_id: region,
      community_id: community,
      address: subAddress,
      phone,
      is_person: editableRecipient.is_person,
    };
    UpdateRecipientService(editableId, data)
      .then((res) => {
        message.success(res.data.message);
        setEditable(false);
        setEditAddress(false);
        refetch();
      })
      .catch((e) => {
        openNotificationError(
          "Update recipient",
          e.response?.data?.data?.user_code?.[0]
        );
      });
  };

  const columns = [
    {
      key: "name",
      dataIndex: "name",
      title: recipients?.[0]?.is_person ? "Name" : "Company name",
      width: "10%",
      // render(_, record) => ()
    },
    {
      key: "surname",
      dataIndex: "surname",
      title: recipients?.[0]?.is_person ? "Surname" : "Georgian company name",
    },
    {
      key: "armcode",
      dataIndex: "armcode",
      title: "GA code",
      width: "10%",
    },
    {
      key: "passportDetails",
      dataIndex: "passportDetails",
      title: "Passport details",
      width: "20%",
    },
    {
      key: "address",
      dataIndex: "address",
      title: "Address",
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "Phone",
    },
    {
      key: "edit",
      dataIndex: "edit",
      title: " ",
      width: 100,
      render: (_, record) =>
        editable && editableId === record.id ? (
          <Flex>
            <div style={{ marginRight: 8 }} onClick={() => setEditable(false)}>
              <CancelIcon />
            </div>
            <IconButton
              click={() => handleUpdateRecipient()}
              icon={<CheckIcon />}
              background="#EFF8EA"
              loading={isLoading}
            />
          </Flex>
        ) : PermissionFinder(permissions["recipient"], "recipient-edit") ? (
          <IconButton
            icon={<EditIcon />}
            click={() => {
              setEditable(true);
              setEditableId(record.id);
            }}
          />
        ) : null,
    },
  ];
  let dataSource = null;
  dataSource = recipients?.map((recipient, index) => ({
    id: recipient.id,
    key: index,
    name:
      editable && editableId === recipient.id ? (
        <Input value={name} onChange={(e) => setName(e.target.value)} />
      ) : (
        recipient?.first_name || recipient?.company_name
      ),
    surname:
      editable && editableId === recipient.id ? (
        <Input value={surname} onChange={(e) => setSurname(e.target.value)} />
      ) : (
        recipient?.last_name || recipient?.georgian_company_name
      ),
    armcode:
      editable && recipient?.is_parent && editableId === recipient?.id ? (
        <Input value={userCode} onChange={(e) => setUserCode(e.target.value)} />
      ) : (
        recipient?.user_code
      ),
    passportDetails:
      editable && editableId === recipient.id ? (
        <Input.Group compact>
          <Flex>
            <Select
              style={{ width: 220, height: 36 }}
              onChange={(value) => setPassportType(value)}
              defaultValue={passportType || "passport"}
            >
              <Select.Option value="passport">Passport</Select.Option>
              <Select.Option value="id">ID</Select.Option>
              <Select.Option value="foreign_citizen">
                Foreigh Citizen
              </Select.Option>
            </Select>
            <Input
              value={passportNumber}
              onChange={(e) => setPassportNumber(e.target.value)}
            />
          </Flex>
        </Input.Group>
      ) : (
        `${recipient?.document_type} ${recipient?.document_number}`
      ),
    address:
      editable && editableId === recipient.id ? (
        <div>
          <Input
            value={recipient.address}
            onClick={() => {
              setEditAddress(true);
            }}
          />
          {editAddress && (
            <div className="address-dropdown">
              <div className="address-dropdown-item">
                <Typography
                  variant="span"
                  text="Region"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                  padding="0 0 4px 0"
                />
                <Select
                  style={{ display: "flex" }}
                  defaultValue={recipient.region_id || ""}
                  onChange={(value) => handleChangeRegion(value)}
                >
                  {regions?.data.data.data.map((region, index) => (
                    <Select.Option value={region.id} key={index}>
                      {region.region}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="address-dropdown-item">
                <Typography
                  variant="span"
                  text="Community"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                  padding="0 0 4px 0"
                />
                <Select
                  style={{ display: "flex" }}
                  onChange={(value) => handleChangeCommunity(value)}
                  defaultValue={recipient.community_id || ""}
                >
                  {communities?.map((community, index) => (
                    <Select.Option value={community.id} key={index}>
                      {community.community}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <Typography
                  variant="span"
                  text="Address"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                  padding="0 0 4px 0"
                />
                <Input
                  placeholder="Address"
                  style={{ display: "flex" }}
                  value={subAddress}
                  onChange={(e) => setSubAddress(e.target.value)}
                />
              </div>
              <div
                className="address-dropdown-item"
                style={{ marginTop: "16px" }}
              >
                <Flex justifyContent="space-between" gap={"0 8px"}>
                  <ButtonSecondary
                    text="Reset"
                    click={() => setEditAddress(false)}
                  />
                  <ButtonPrimary
                    text="Apply"
                    click={() => handleApplyAddress()}
                  />
                </Flex>
              </div>
            </div>
          )}
        </div>
      ) : (
        recipient?.address
      ),
    phone:
      editable && editableId === recipient.id ? (
        <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
      ) : (
        recipient?.phone
      ),
  }));

  return (
    <StyledRecipientsTab>
      {recipients ? (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: 1200, y: 350 }}
        />
      ) : (
        <Loader />
      )}
    </StyledRecipientsTab>
  );
}
