import Typography from "../../components/moleculs/Typography.jsx";
import styled from "styled-components";
import NoBlog from "./NoBlog.jsx";
import Flex from "../../components/general/Flex.jsx";
import ButtonGray from "../../components/moleculs/ButtonGray.jsx";
import TableBlog from "./TableBlog.jsx";
import { useEffect, useState } from "react";
import CreateBlogDrawer from "./CreateBlog";
import Loader from "../../components/moleculs/Loader";
import { useFetchBlog } from "../../hooks/blog/useFetchBlogs.hook.js";
import { useFetchLanguages } from "../../hooks/slides/useFetchLanguages.hook.js";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledBlog = styled.div`
  padding-top: 24px;

  .table-wrapper {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    width: 100%;
    background: #ffffff;
    margin-bottom: 24px;
  }

  .role-td {
    background: #edf5fb;
    border-radius: 16px;
    width: min-content;
    padding: 2px 10px 2px 10px;
    white-space: nowrap;
  }

  .inactive-status {
    background: #ffeced;
    color: #fc4447;
  }

  .active-status {
    background: #eff8ea;
    color: #5dba2f;
  }

  .ant-table-cell {
    font-size: 12px;
    color: #5b6d7f !important;
  }

  .ant-table-wrapper {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .ant-table {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    max-height: 70vh;
    overflow: auto;
    width: 100%;
    background: #ffffff;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export default function Blog({ permissions }) {
  const { blogs = [], meta, refetch, isLoading } = useFetchBlog("blog");
  const [blog, setBlog] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [isActiveData, setIsActiveData] = useState([]);
  const { languages = [] } = useFetchLanguages();
  useEffect(() => {
    if (blogs.length) {
      setDataSource(blogs);
    }
    blogs?.map((el) => {
      if (el?.is_active) {
        setIsActiveData((prevState) => [...prevState, el]);
      }
    });
  }, [blogs]);

  return (
    <StyledBlog>
      <div className="page-title">
        <Typography
          text="Blog"
          color="#262626"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <Flex
        alignItems={dataSource?.length > 0 ? "flex-start" : "center"}
        justifyContent={dataSource?.length > 0 ? "flex-start" : "center"}
        direction={"column"}
        style={{
          minHeight: "calc(100vh - 70px)",
          width: "100%",
        }}
      >
        {dataSource?.length > 0 ? (
          PermissionFinder(permissions, "blog-create") ? (
            <ButtonGray
              text={"Create blog"}
              background={"#5B6D7F"}
              color={"#FFFFFF"}
              onClick={() => setBlog(true)}
            />
          ) : null
        ) : null}
        {isLoading ? (
          <Loader />
        ) : !dataSource?.length > 0 ? (
          <NoBlog setBlog={setBlog} />
        ) : (
          <TableBlog
            isActiveData={isActiveData}
            data={blogs}
            dataSource={dataSource}
            setDataSource={setDataSource}
            setBlog={setBlog}
            meta={meta}
            languages={languages}
            permissions={permissions}
          />
        )}
      </Flex>
      <CreateBlogDrawer
        reFetchBlogs={refetch}
        setBlog={setBlog}
        blog={blog}
        languages={languages}
        permissions={permissions}
      />
    </StyledBlog>
  );
}
