import { useUser } from "../hooks/useUser.hooks";
import LoginForm from "../components/sections/Forms/loginForm/LoginForm";
import { notification } from "antd";

export default function SignIn() {
  const { logIn } = useUser();
  const [api, contextHolder] = notification.useNotification();

  const onFinish = (values) => {
    logIn(values).catch((e) => {
      api.error({
        message: "Error !",
        description: "Email or Password is incorrect!",
        placement: "topRight",
      });
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {contextHolder}
      <LoginForm onFail={onFinish} onError={onFinishFailed} />
    </>
  );
}
