import styled from "styled-components";
import { AutoComplete, DatePicker, Form, Select } from "antd";
import Flex from "../../components/general/Flex";
import InputIcon from "../../components/SvgComponents/InputIcon";
import OutputIcon from "../../components/SvgComponents/OutputIcon";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import { useState } from "react";
import dayjs from "dayjs";
import { useBalancesTransferTypeHook } from "../../hooks/Balances/useBalancesTransferType.hook.jsx";
import { useBalancesPaymentTypeHook } from "../../hooks/Balances/useBalancesPaymentType.hook.jsx";

const { RangePicker } = DatePicker;

const StyledBalanceTopActions = styled.div`
  margin-top: 24px;
  padding: 16px;
  background: #ffffff;
  border-radius: 12px;

  .item {
    margin-right: 16px;
  }

  .ipt {
    min-width: 250px;
  }
`;

export default function BalanceTopActions({
  setFilterData,
  setUserInfo = () => {},
  usersList = [],
}) {
  const [form] = Form.useForm();
  const [selectId, setSelectId] = useState(null);
  const { transferType = [] } = useBalancesTransferTypeHook();
  const { paymentType = [] } = useBalancesPaymentTypeHook();
  const onFinish = (values) => {
    let newValues = {};
    Object.entries(values).forEach(([key, val]) => {
      if (val) {
        if (key === "date") {
          newValues["start_date"] = dayjs(val[0]).format("YYYY-MM-DD");
          newValues["end_date"] = dayjs(val[1]).format("YYYY-MM-DD");
        } else {
          newValues[key] = val;
        }
        if (selectId) {
          newValues["user_id"] = selectId;
        }
      }
    });
    setFilterData(newValues);
  };

  const onReset = () => {
    form.resetFields();
    setSelectId(null);
    setFilterData({});
  };
  return (
    <StyledBalanceTopActions>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        {" "}
        <Flex justifyContent="space-between">
          <Flex>
            <div className="item ipt">
              <Form.Item name={"user_id"}>
                <AutoComplete
                  placeholder="Name of user or GA"
                  onSearch={(val, el) => setUserInfo({ user_info: val })}
                  onSelect={(_, el) => setSelectId(el.user_id)}
                  options={usersList}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </div>
            <div className="item ipt">
              <Form.Item name="date">
                <RangePicker format={"DD.MM.YYYY"} />
              </Form.Item>
            </div>
            <div className="item ">
              <Form.Item name="type" style={{ width: 120 }}>
                <Select defaultValue="" style={{ height: 36 }}>
                  <Select.Option value="">
                    <Flex style={{ paddingTop: 3 }}>
                      <InputIcon size={24} />
                      <OutputIcon size={24} />
                    </Flex>
                  </Select.Option>
                  <Select.Option value="in">
                    <Flex style={{ paddingTop: 3 }}>
                      <InputIcon size={24} />
                    </Flex>
                  </Select.Option>
                  <Select.Option value="out">
                    <Flex style={{ paddingTop: 3 }}>
                      <OutputIcon size={24} />
                    </Flex>
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="item ipt">
              <Form.Item name="payment_type_id">
                <Select
                  placeholder={"Payment type"}
                  options={paymentType}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </div>
            <div className="item ipt">
              <Form.Item name="transfer_type_id">
                <Select
                  placeholder={"Transfer type"}
                  options={transferType}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </div>
          </Flex>
          <Flex>
            <div className="item">
              <ButtonSecondary
                text="Reset"
                htmlType={"button"}
                click={onReset}
                height={"35px"}
              />
            </div>
            <div className="item">
              <ButtonPrimary
                text="Search"
                htmlType={"submit"}
                icon={<SearchIcon />}
                height={"35px"}
              />
            </div>
          </Flex>
        </Flex>
      </Form>
    </StyledBalanceTopActions>
  );
}
