import { Checkbox, DatePicker, Drawer, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import Typography from "../../components/moleculs/Typography";
import { primaryTextColor } from "../../constants";
import Flex from "../../components/general/Flex";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import DeleteButton from "../../components/moleculs/DeleteButton";
import AlertCircleIcon from "../../components/SvgComponents/AlertCircleIcon";
import styled from "styled-components";
import useGetWarehouses from "../../hooks/warehouses/useWarehouses.hook";
import dayjs from "dayjs";
import { tariffApi } from "../../services/tariffs";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import DeleteModal from "../../components/modals/DeleteModal.jsx";
import { useCreateUpdateTariffs } from "../../hooks/tariffs/useCreateUpdateTariffs.hook.jsx";
import { useDeleteTariff } from "../../hooks/tariffs/useDeleteTariff.hook.jsx";

const DrawerModal = ({ open, item, onClose = () => {}, refetch }) => {
  const [form] = Form.useForm();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { mutate } = useCreateUpdateTariffs(
    item?.id,
    () => {
      onClose();
      form.resetFields();
      setCheck({
        v_cost: false,
        min_cost: false,
        is_v_weight: false,
      });
      refetch();
    },
    (e) => {
      Object.entries(e?.response?.data?.data).forEach(([key, val]) => {
        openNotificationError(val[0], "Error");
      });
    }
  );

  const { mutate: deleteTariff } = useDeleteTariff((data) => {
    openNotification(data?.data?.message, "Delete tariff");
    onClose();
    refetch();
    setDeleteOpen(false);
    form.resetFields();
    setCheck({
      v_cost: false,
      min_cost: false,
      is_v_weight: false,
    });
  });

  const { RangePicker } = DatePicker;
  const [check, setCheck] = useState({
    v_cost: false,
    min_cost: false,
    is_v_weight: false,
  });
  const [dispatches, setDispatches] = useState([]);

  const { data: warehouses = [] } = useGetWarehouses();
  const handleSubmit = (values) => {
    const sendData = {
      ...values,
      start_at: values?.dates?.[0]
        ? dayjs(values.dates[0]).format("YYYY-MM-DD")
        : null,
      end_at: values?.dates?.[1]
        ? dayjs(values.dates[1]).format("YYYY-MM-DD")
        : null,
      is_v_weight: Number(check.is_v_weight),
    };
    delete sendData.dates;
    mutate({
      data: sendData,
      id: item?.id,
    });
  };
  useEffect(() => {
    if (item) {
      tariffApi.getTariffById(item?.id).then((res) => {
        const tariff = res.data.data;
        if (tariff) {
          setDispatches([tariff.dispatch]);
          setCheck({
            v_cost: tariff.v_weight_cost ? true : false,
            min_cost: tariff.min_cost ? true : false,
            is_v_weight: Boolean(tariff.is_v_weight),
          });
          form.setFields([
            {
              name: "warehouse_id",
              value: tariff?.warehouse?.id,
            },
            {
              name: "dispatch_id",
              value: tariff?.dispatch?.id,
            },
            {
              name: "rounding",
              value: tariff?.rounding,
            },
            {
              name: "cost",
              value: tariff?.cost,
            },
            {
              name: "v_weight_cost",
              value: tariff?.v_weight_cost,
            },
            {
              name: "min_cost",
              value: tariff?.min_cost,
            },
            {
              name: "dates",
              value: [dayjs(item?.start_at), dayjs(item?.end_at)],
            },
          ]);
        }
      });
    } else {
      form.resetFields();
    }
  }, [item]);

  return (
    <Drawer
      title=""
      placement="right"
      open={open}
      width="694px"
      header={false}
      onClose={() => {
        onClose();
        setCheck({
          v_cost: false,
          min_cost: false,
          is_v_weight: false,
        });
      }}
    >
      <DeleteModal
        status={deleteOpen}
        setStatus={setDeleteOpen}
        title="Delete tariff"
        description="Are you sure you want to delete tariff"
        setDelete={() => deleteTariff({ id: item?.id })}
      />
      <Form onFinish={handleSubmit} form={form}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: "24px" }}
        >
          <Typography
            text={item ? "Edit tariff" : "Add tariff"}
            variant="p"
            color={primaryTextColor}
            size="22px"
            weight="500"
          />
          <Flex alignItems={"center"}>
            {item ? (
              <DeleteButton
                text="Delete"
                icon={<AlertCircleIcon />}
                click={() => setDeleteOpen(true)}
                type="button"
              />
            ) : (
              <></>
            )}
            <ButtonSecondary
              text="Cancel"
              background="#FFECED"
              color="#FC4447"
              border={"none"}
              htmlType="reset"
              style={{ margin: "0 16px" }}
              click={onClose}
            />
            <ButtonPrimary text="Save" border={"none"} htmlType="submit" />
          </Flex>
        </Flex>
        <StyledForm>
          <div className="card">
            <Flex className="fl">
              <div className="info-item">
                {" "}
                <Typography
                  variant="span"
                  text="Warehouse"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                  padding="0 0 4px 0"
                />
                <Form.Item
                  name={"warehouse_id"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Select
                    placeholder={"Warehouse"}
                    style={{ width: 160 }}
                    onSelect={(val, el) => {
                      setDispatches(el?.dispatches);
                      form.setFieldValue("dispatch_id", "");
                    }}
                    options={warehouses}
                  />
                </Form.Item>
              </div>{" "}
              <div className="info-item">
                <Typography
                  variant="span"
                  text="Dispatch"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                  padding="0 0 4px 0"
                />
                <Form.Item
                  name={"dispatch_id"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Select style={{ width: 100 }} placeholder={"Dispatch"}>
                    {dispatches?.length
                      ? dispatches.map((dispatch, index) => (
                          <Select.Option value={dispatch.id} key={index}>
                            <div style={{ paddingTop: 2 }}>
                              <Flex alignItems={"center"}>
                                <img
                                  src={dispatch?.icon}
                                  alt="dispatch"
                                  width={24}
                                />
                                <span style={{ paddingLeft: 5 }}>
                                  {dispatch.name}
                                </span>
                              </Flex>
                            </div>
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
              </div>
              <div className="info-item">
                <Typography
                  variant="span"
                  text="Routing"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                  padding="0 0 4px 0"
                />
                <Form.Item
                  name="rounding"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input type="number" />
                </Form.Item>
              </div>
            </Flex>{" "}
            <Flex className="fl">
              <div className="info-item">
                <Typography
                  variant="span"
                  text="Cost"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                  padding="0 0 4px 0"
                />
                <Form.Item
                  name="cost"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input placeholder="Cost" type="number" />
                </Form.Item>
              </div>
              <div className="info-item">
                <Typography
                  variant="span"
                  text="Date"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                  padding="0 0 4px 0"
                />
                <Form.Item name="dates">
                  <RangePicker format={"DD.MM.YYYY"} />
                </Form.Item>
              </div>
            </Flex>
          </div>
          <div className="card">
            <Checkbox
              checked={check.v_cost}
              onClick={() => setCheck((p) => ({ ...p, v_cost: !p.v_cost }))}
            >
              <Typography variant={"p"} text="V weight cost" size={"14px"} />
            </Checkbox>

            {check.v_cost && (
              <Form.Item
                name="v_weight_cost"
                style={{ marginTop: 10 }}
                rules={[{ required: true, message: "Required" }]}
              >
                <Input type="number" />
              </Form.Item>
            )}
          </div>
          <div className="card">
            <Checkbox
              checked={check.min_cost}
              onClick={() => setCheck((p) => ({ ...p, min_cost: !p.min_cost }))}
            >
              <Typography variant={"p"} text="Min cost" size={"14px"} />
            </Checkbox>

            {check.min_cost && (
              <Form.Item
                name="min_cost"
                style={{ marginTop: 10 }}
                rules={[{ required: true, message: "Required" }]}
              >
                <Input type="number" />
              </Form.Item>
            )}
          </div>
          <div className="card">
            <div className="info-item">
              <Checkbox
                onClick={() =>
                  setCheck((p) => ({ ...p, is_v_weight: !p.is_v_weight }))
                }
                checked={check.is_v_weight}
              >
                <Typography variant={"p"} text="V weight" size={"14px"} />
              </Checkbox>
            </div>
          </div>
        </StyledForm>
      </Form>
    </Drawer>
  );
};

const StyledForm = styled.div`
  .card {
    margin-bottom: 16px;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 12px;

    .fl > .info-item {
      flex: 1;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    .m-0 {
      margin-bottom: 0 !important;
    }

    .ant-select {
      width: 100% !important;


    }

    .whouse-select-item {
      img {
        margin: 0 5px 0 0 !important;
      }
    }
`;
export default DrawerModal;
