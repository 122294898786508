export default function EditIcon(props) {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.39662 14.0964C1.43491 13.7518 1.45405 13.5795 1.50618 13.4185C1.55243 13.2756 1.61778 13.1396 1.70045 13.0143C1.79363 12.873 1.91621 12.7504 2.16136 12.5052L13.1666 1.5C14.0871 0.579524 15.5795 0.579525 16.4999 1.5C17.4204 2.42047 17.4204 3.91286 16.4999 4.83333L5.49469 15.8386C5.24954 16.0837 5.12696 16.2063 4.98566 16.2995C4.86029 16.3821 4.72433 16.4475 4.58146 16.4937C4.42042 16.5459 4.24813 16.565 3.90356 16.6033L1.08325 16.9167L1.39662 14.0964Z"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
