import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

export const getOrders = createAsyncThunk("orders", async () => {
  const { data } = await axios.get(`admin/orders`)
  return data
})

export const createOrder = createAsyncThunk("createOrders", async (data) => {
  const { res } = await axios.post(`admin/orders`, data)
  return res
})
