import { useMutation, useQueryClient } from "react-query";
import { bannersApi } from "../../services/banners.js";

export const useCreateAndEditBanner = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const client = useQueryClient();
  return useMutation(bannersApi.createAndUpdateBanner, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["created-and-updated-banner"]);
    },
  });
};
