import styled from "styled-components";
import { useState } from "react";
import NoBanners from "./NoBanners.jsx";
import Loader from "../../components/moleculs/Loader.jsx";
import TableBanners from "./TableBanners.jsx";
import ButtonGray from "../../components/moleculs/ButtonGray.jsx";
import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import { useFetchBanners } from "../../hooks/banners/useFetchBanners.hook.js";
import CreateBannerDrawer from "./CreateBanner.jsx";
import { useFetchLanguages } from "../../hooks/slides/useFetchLanguages.hook.js";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledBanners = styled.div`
  padding-top: 24px;

  .table-wrapper {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    width: 100%;
    background: #ffffff;
    margin-bottom: 24px;
  }

  .role-td {
    background: #edf5fb;
    border-radius: 16px;
    width: min-content;
    padding: 2px 10px 2px 10px;
    white-space: nowrap;
  }

  .inactive-status {
    background: #ffeced;
    color: #fc4447;
  }

  .active-status {
    background: #eff8ea;
    color: #5dba2f;
  }

  .ant-table-cell {
    font-size: 12px;
    color: #5b6d7f !important;
  }

  .ant-table-wrapper {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .ant-table {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    max-height: 70vh;
    overflow: auto;
    width: 100%;
    background: #ffffff;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export default function Banners({ permissions }) {
  const [filters, setFilters] = useState({});
  const { banners = [], refetch, isLoading, meta } = useFetchBanners(filters);
  const [banner, setBanner] = useState(null);
  const { languages } = useFetchLanguages();

  return (
    <StyledBanners>
      <div className="page-title">
        <Typography
          text="Header"
          color="#262626"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <Flex
        alignItems={banners?.length > 0 ? "flex-start" : "center"}
        justifyContent={banners?.length > 0 ? "flex-start" : "center"}
        direction={"column"}
        style={{
          minHeight: "calc(100vh - 70px)",
          width: "100%",
        }}
      >
        {banners?.length > 0 ? (
          PermissionFinder(permissions, "header-message-create") ? (
            <ButtonGray
              text={"Create header"}
              background={"#5B6D7F"}
              color={"#FFFFFF"}
              onClick={() => setBanner(true)}
            />
          ) : null
        ) : null}
        {banners?.length > 0 ? (
          <TableBanners
            languages={languages}
            data={banners}
            setBanner={setBanner}
            meta={meta}
            filters={filters}
            setFilters={setFilters}
            permissions={permissions}
          />
        ) : isLoading ? (
          <Loader />
        ) : (
          <NoBanners />
        )}
      </Flex>
      <CreateBannerDrawer
        languages={languages}
        setBanner={setBanner}
        banner={banner}
        refetch={refetch}
      />
    </StyledBanners>
  );
}
