import { useQuery } from "react-query";
import { GetLostOrdersService } from "../../services/orders";

export default function useGetLostOrders(filters) {
  const { data, isLoading, refetch } = useQuery(
    ["get-lost-orders", filters],
    () => GetLostOrdersService(filters),
    {
      staleTime: Infinity,
      select: ({ data }) => data?.data,
    }
  );
  return {
    orders: data?.data || [],
    meta: data?.meta || {},
    isLoading,
    refetch,
  };
}
