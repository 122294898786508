import styled from "styled-components";
import { useEffect, useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import OrdersTopActions from "./TopActions";
import OrdersTable from "./Table";
import OrdersDetailsModal from "./DetailsModal";
import useGetOrders from "../../hooks/orders/useGetOrders.hook.jsx";

const StyledOrders = styled.div`
  padding-top: 24px;
  min-height: 100vh;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: rgb(92 92 92 / 56%);
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: none;

    &.active {
      display: block;
    }
  }
`;
export default function Orders({ permissions }) {
  const [id, setId] = useState();
  const [detailsStatus, setDetailsStatus] = useState(false);
  const [filters, setFilters] = useState({});
  const { orders, isLoading, refetch } = useGetOrders(filters);
  useEffect(() => {
    let search = window.location.search;
    if (search) {
      const searchKeys = search.split("=");

      // search come from receive orders
      if (searchKeys[0] === "?user_info") {
        setFilters({ user_info: searchKeys[1] });
      } else {
        // search come from customer single page
        const status = searchKeys[1].split("&")[0];
        const userId = searchKeys[2];
        setFilters({ user_info: searchKeys[2], order_status: status });
      }
    }
  }, []);
  return (
    <StyledOrders className="orders">
      <div className="page-title">
        <Typography
          text="Orders"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <OrdersTopActions setFilters={setFilters} />

      <OrdersTable
        setDetailsStatus={setDetailsStatus}
        id={id}
        setId={setId}
        orders={orders}
        meta={orders?.meta}
        setFilters={setFilters}
        filters={filters}
        isLoading={isLoading}
        permissions={permissions}
      />

      <OrdersDetailsModal
        status={detailsStatus}
        setStatus={setDetailsStatus}
        id={id}
        refetchOrders={refetch}
        permissions={permissions}
      />
    </StyledOrders>
  );
}
