import styled from "styled-components";
import { Spin } from "antd";
import Flex from "../general/Flex";

const StyledSecondaryButton = styled.button`
  background: white;
  border: 1px solid rgba(231, 233, 236, 1);
  color: rgba(59, 81, 102, 1);
  font-size: 14px;
  font-weight: 400;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  background: ${({ background }) => background || "transparent"};
`;

export default function IconButton({
  icon,
  click = () => {},
  loading = false,
  text = "",
  ...props
}) {
  return (
    <StyledSecondaryButton {...props} onClick={() => click()}>
      <Flex alignItems="center" justifyContent="center">
        {loading ? <Spin /> : icon}
      </Flex>
    </StyledSecondaryButton>
  );
}
