import { useQuery } from "react-query";
import { bonusApi } from "../../services/bonus.js";

export const useGetBonusTypeLis = () => {
  const { isError, isLoading, data, refetch } = useQuery(
    ["bonus-type-list"],
    () => bonusApi.fetchBonusTypeList(),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        const list = data?.data?.data?.map((type) => ({
          key: type?.id || "",
          value: type?.id || "",
          label: type?.name || "",
          ...type,
        }));
        return { list, meta: data?.meta };
      },
    }
  );
  return {
    isLoading,
    bonusTypeList: data?.list,
    refetch,
  };
};
