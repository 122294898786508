import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import { useEffect, useLayoutEffect, useState } from "react";
import { create as am4coreCreate } from "@amcharts/amcharts4/core";

import * as am4core from "@amcharts/amcharts4/core.js";
import * as am4charts from "@amcharts/amcharts4/charts.js";
import useDashboardData from "../../hooks/Dashboard/useDashboardData.hook.jsx";
import Loader from "../../components/moleculs/Loader";
import am4themes_animated from "@amcharts/amcharts4/themes/animated.js";
import OnexTooltip from "../../helpers/tooltip";

const StyledTotalVerifiedPercent = styled.div`
  @property --progress-value {
    syntax: "<integer>";
    inherits: false;
    initial-value: 0;
  }

  @keyframes html-progress {
    to {
      --progress-value: 92;
    }
  }

  @keyframes css-progress {
    to {
      --progress-value: 87;
    }
  }

  @keyframes js-progress {
    to {
      --progress-value: 73;
    }
  }
  .progress-bar::before {
    counter-reset: percentage var(--progress-value);
  }

  .progress-bar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 250ms;
    background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
      conic-gradient(
        ${({ percent }) => (percent >= 85 ? "#0a2540" : "#FC4447")}
          ${({ percent }) => percent || "21"}%,
        #e7e9ec 0
      );
    .percentage {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #0a2540;
      font-size: 20px;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 17px;
      }
    }
  }
`;
export default function TotalVerifiedPercent({ dashboardData, isLoading }) {
  const [percentage, setPercentage] = useState();

  useEffect(() => {
    const percentage =
      dashboardData?.data?.all_info?.completed_percentage_in_all;
    setPercentage(Math.round(percentage));
  }, [dashboardData]);

  return (
    <StyledTotalVerifiedPercent percent={percentage}>
      <div className="progress-bar">
        <div className={"percentage"}>
          <OnexTooltip content={"Verified rate"} direction={"bottom"}>
            <div>{percentage}%</div>
          </OnexTooltip>
        </div>

        <progress
          id={"myprogress"}
          value={25}
          max="100"
          style={{ visibility: "hidden", width: "0", height: "0" }}
        ></progress>
      </div>
    </StyledTotalVerifiedPercent>
  );
}
