import axios from "../libs/axios.js";

export const appVersionsApi = {
  fetchAppVersions: async (filters) =>
    await axios("/api-versions", { params: filters }),
  createVersion: async (values) => await axios.post(`/api-versions`, values),
  editVersion: async (id, values) =>
    await axios.put(`/api-versions/${id}`, values),
  deleteVersion: async (id) => await axios.delete(`/api-versions/${id}`),
};
