import styled from "styled-components";
import { Col, Form, Input, Row, Select } from "antd";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";

const StyledTopActions = styled.div`
  margin-bottom: 10px;

  .row {
    height: ${({ more }) => (more ? "100%" : " 52px")};
    overflow: hidden;
  }
`;

export default function OrdersFailedTopActions({ setFilters }) {
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    let newValues = {};
    Object.entries(values).forEach(([key, val]) => {
      if (val) {
        newValues[key] = val;
      }
    });
    setFilters(newValues);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleResetForm = () => {
    setFilters({});
    form.resetFields();
  };

  return (
    <StyledTopActions>
      <Form
        form={form}
        name="filters"
        labelCol={8}
        wrapperCol={4}
        layout="vertical"
        onFinish={handleFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16} className="row">
          <Col lg={4}>
            <Form.Item
              name="tracking_code"
              rules={[
                {
                  min: 7,
                  message: "Tracking code must be minimum 7 characters.",
                },
              ]}
            >
              <Input placeholder={"Tracking min 7"} />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item name="order_status">
              <Select
                style={{ height: 36 }}
                placeholder={"Order Status"}
                options={[
                  { value: "at_warehouse", label: "At Warehouse" },
                  { value: "on_the_way", label: "On the way" },
                  { value: "scan", label: "Scanned" },
                  { value: "in_georgia", label: "In georgia" },
                  { value: "received", label: "Received" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col lg={1.5}>
            <ButtonSecondary text="Reset" click={() => handleResetForm()} />
          </Col>
          <Col lg={4}>
            <ButtonPrimary
              text="Search"
              htmlType="submit"
              icon={<SearchIcon />}
            />
          </Col>
        </Row>
      </Form>
    </StyledTopActions>
  );
}
