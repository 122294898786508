import React, { useState } from "react";
import Typography from "../../components/moleculs/Typography";
import styled from "styled-components";
import TariffTable from "./Table";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import DrawerModal from "./DrawerModal";
import { useGetTariffList } from "../../hooks/tariffs/useGetList";

const Tariffs = () => {
  const { data, refetch } = useGetTariffList();
  const [modal, setModal] = useState({
    open: false,
    edit: undefined,
  });

  const permissions = JSON.parse(localStorage.getItem("permissions")) || {};
  return (
    <>
      <StyledTariffs>
        <div className="page-title">
          <Typography
            text="Tariffs"
            color="rgba(38, 38, 38, 1)"
            weight={500}
            size="24px"
            variant="h2"
            margin="0 0 24px 0"
          />
        </div>
        <ButtonPrimary
          text="+ Add tariff"
          click={() => {
            setModal({
              open: true,
              edit: null,
            });
          }}
        />
        <TariffTable
          permissions={permissions}
          onEdit={(item) => {
            setModal({
              open: true,
              edit: item,
            });
          }}
          data={data}
        />
      </StyledTariffs>
      <DrawerModal
        open={modal.open}
        refetch={refetch}
        onClose={() => setModal({ open: false, edit: null })}
        item={modal.edit}
      />
    </>
  );
};

const StyledTariffs = styled.div`
  padding-top: 24px;
  height: 100vh;

  .ant-table-wrapper {
    max-height: 80vh;
    overflow: auto;
  }

  > button {
    background-color: #5b6d7f;
    margin-bottom: 16px;
    margin-left: auto;
    display: block;
  }

  .ant-table-placeholder {
    z-index: 0 !important;
  }
`;
export default Tariffs;
