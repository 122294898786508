import Flex from "../../../components/general/Flex.jsx";
import { Checkbox, Form, Select } from "antd";
import { useFetchBlog } from "../../../hooks/blog/useFetchBlogs.hook.js";

export const CheckboxFeatures = ({
  form,
  selectBlog,
  setSelectBlog = () => {},
}) => {
  const { blogs = [] } = useFetchBlog();

  const checkedData = [
    { name: "is_special", label: "Special" },
    {
      name: "is_buyforme",
      label: "Buy for me",
    },
    { name: "is_dropify", label: "Dropify" },
    { name: "is_one_click", label: "One click" },
    { name: "for_home_page", label: "Show on homepage" },
  ];
  return (
    <Flex direction={"column"}>
      <Flex flexWrap={"wrap"}>
        {checkedData?.map((item, i) => (
          <Form.Item
            name={item?.name}
            key={i}
            className={"checked-item"}
            valuePropName="checked"
          >
            <Checkbox
              disabled={
                item?.name === "is_dropify" || item?.name === "is_one_click"
              }
              onChange={(e) =>
                form.setFieldValue(item?.name, e.target.checked ? 1 : 0)
              }
            >
              {item?.label}
            </Checkbox>
          </Form.Item>
        ))}
      </Flex>
      <div
        className={"checked-item"}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Checkbox
          onChange={(e) => setSelectBlog(e.target.checked)}
          checked={selectBlog}
        >
          Blog
        </Checkbox>

        <Form.Item
          name={"blog_id"}
          // valuePropName="checked"
          className={`blog_select ${!selectBlog && "inactive"}`}
        >
          <Select placeholder={"Select blog"} options={blogs || []} />
        </Form.Item>
      </div>
    </Flex>
  );
};
