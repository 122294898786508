import React from "react";

const SwitchHorizontal = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 14.1667H16.6667M16.6667 14.1667L13.3333 10.8333M16.6667 14.1667L13.3333 17.5M16.6667 5.83333H3.33333M3.33333 5.83333L6.66666 2.5M3.33333 5.83333L6.66666 9.16667"
        stroke="#3B5166"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SwitchHorizontal;
