import styled from "styled-components";
import { message, Pagination, Table } from "antd";
import EyeIcon from "../../components/SvgComponents/EyeIcon";
import Flex from "../../components/general/Flex";
import Typography from "../../components/moleculs/Typography.jsx";
import FileIcon from "../../components/SvgComponents/FileIcon";
import ExpectedIcon from "../../components/SvgComponents/ExpectedIcon";
import Tag from "../../components/moleculs/Tag.jsx";
import Loader from "../../components/moleculs/Loader";
import OnexTooltip from "../../helpers/tooltip.jsx";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";
import DeleteIcon from "../../components/SvgComponents/DeleteIcon.jsx";
import PrimeIcon from "../../components/SvgComponents/primeIcon.jsx";

const StyledExpectedTable = styled.div`
  table {
    background: white;
    padding: 16px;
    border-radius: 12px;

    td {
      vertical-align: top !important;
    }

    .dispatch-icon {
      margin-left: 8px;
    }
  }

  .show-details {
    background: transparent;
    border: none;
    outline: none;
  }

  table {
    thead {
      display: none;
    }

    tbody {
      tr {
        td:nth-child(1) {
          padding: 16px 0 !important;
        }

        td:last-child {
          padding: 16px 0 !important;
        }
      }
    }

    .product-name {
      padding-right: 5px;
      border-right: 1px solid #e7e9ec;
      margin-top: 10px;
    }

    .product-description {
      margin-top: 10px;
      display: -webkit-box;
      overflow: hidden;
      max-height: 44px;
      text-overflow: ellipsis;
      transition: all 0.2s;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .download-excel {
    margin-top: 24px;
  }

  .ant-table-container {
    max-height: 60vh;
    overflow: auto;
  }

  .not-orders-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 65vh;
    justify-content: center;

    .search-icon {
      background: #e7e9ec;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 31px;
    }
  }
`;

export default function ExpectedTable({
  setStatus,
  setId,
  data,
  isLoading,
  filters,
  setFilters,
  permissions,
}) {
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      key: "country",
      dataIndex: "country",
      title: "",
      width: "5%",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "",
      width: "24%",
    },
    {
      key: "tracking",
      dataIndex: "tracking",
      title: "",
      width: "10%",
    },
    {
      key: "invoice",
      dataIndex: "invoice",
      title: "",
      width: "7%",
    },
    {
      key: "shop",
      dataIndex: "shop",
      title: "",
    },
    {
      key: "status",
      dataIndex: "status",
      title: "",
    },
    {
      key: "activeStatus",
      dataIndex: "activeStatus",
      title: "",
    },
    {
      key: "eye",
      dataIndex: "eye",
      title: "",
      render: (_, record) =>
        PermissionFinder(permissions, "expected-view") ? (
          <>
            <button
              className="show-details"
              onClick={() => {
                setStatus("detail-drawer");
                setId(record.id);
              }}
            >
              <EyeIcon />
            </button>
            <button
              className="show-details"
              onClick={() => {
                setId(record.id);
                setStatus("delete");
              }}
            >
              <DeleteIcon />
            </button>
          </>
        ) : null,
      width: 100,
    },
  ];

  let dataSource = null;

  if (data?.data?.data) {
    dataSource = data?.data?.data?.map((item, i) => ({
      key: i,
      id: item.id,
      country: (
        <Flex>
          <img src={item?.warehouse?.round_flag} />
          <img className="dispatch-icon" src={item?.dispatch?.icon} />
        </Flex>
      ),
      name: (
        <Flex>
          <Typography
            text={`${
              item?.recipient?.company_name
                ? item?.recipient?.company_name
                : item?.recipient?.first_name + " " + item?.recipient?.last_name
            } ${item?.recipient?.user_code}`}
            variant="p"
            size="14px"
            weight="500"
            color=" #262626"
          />
          {item?.user?.is_prime ? (
            <div style={{ paddingLeft: 4 }}>
              <PrimeIcon />
            </div>
          ) : null}
        </Flex>
      ),
      tracking: (
        <div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(item.tracking_code);
              messageApi.success("Copied");
            }}
          >
            <OnexTooltip content={"Click to copy"} direction={"bottom"}>
              <Typography
                variant="p"
                text={item.tracking_code}
                color="#262626"
                size="14px"
                weight="400"
              />
            </OnexTooltip>
          </div>
          <Flex>
            <div className="product-description">
              <Typography
                variant="p"
                text={item.customer_comment}
                color="#5B6D7F"
                size="14px"
                weight={400}
              />
            </div>
          </Flex>
        </div>
      ),

      invoice: (
        <Flex direction="column">
          {item.invoice && (
            <a href={item.invoice.file} target={"_blank"}>
              <Flex alignItems="center" style={{ paddingBottom: "10px" }}>
                <FileIcon />
                <Typography
                  text="Invoice"
                  variant="p"
                  color="#262626"
                  weight="400"
                  padding="0 0 0 8px"
                  size="14px"
                />
              </Flex>
            </a>
          )}
          <Typography
            text={`${item?.declaration_price || ""} ${
              item?.declaration_currency || ""
            }`}
            variant="p"
            color="#5B6D7F"
            weight="400"
            size="14px"
          />
        </Flex>
      ),
      shop: (
        <Typography
          text={item.shop_name}
          variant="p"
          color="#5DBA2F"
          weight="400"
          size="14px"
        />
      ),
      status: (
        <div>
          <Flex alignItems="center">
            <ExpectedIcon />
            <Flex>
              <Typography
                variant="p"
                text="Expected"
                color="#5B6D7F"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
              <OnexTooltip content={item.created_at} direction={"top"}>
                <Typography
                  variant="p"
                  text={item.created_at.substr(0, 10)}
                  color="#262626"
                  size="14px"
                  weight="400"
                  margin="0 0 0 5px"
                />
              </OnexTooltip>
            </Flex>
          </Flex>
          {item.order_smart_services && (
            <Flex alignItems="center" style={{ paddingTop: 10 }}>
              <img
                src={item?.order_smart_services.smart_service.image}
                width="25"
              />
              <Flex alignItems="center">
                <Typography
                  variant="p"
                  text={
                    item?.order_smart_services.smart_service
                      ?.current_smart_service?.name
                  }
                  color="#5B6D7F"
                  size="14px"
                  weight="400"
                  margin="0 0 0 5px"
                />
                <Tag
                  text={item?.order_smart_services?.status}
                  background="#EDFBFE"
                  color="#47BFE1"
                  margin="0 0 0 8px"
                />
              </Flex>
            </Flex>
          )}
        </div>
      ),
      activeStatus: item.is_active ? (
        <Tag
          text="Active"
          background="#ECFAF1"
          color="#32A35E"
          margin="0 0 0 8px"
        />
      ) : (
        <Tag
          text="Deleted"
          background="#FFF5EB"
          color="#E58C35"
          margin="0 0 0 8px"
        />
      ),
    }));
  }

  return (
    <StyledExpectedTable>
      {contextHolder}
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Table columns={columns} dataSource={dataSource} pagination={false} />
          <div className={"new-pagination"}>
            <Pagination
              total={data?.data?.meta?.total || 1}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              onChange={(page) => setFilters({ ...filters, page })}
              defaultPageSize={data?.data?.meta?.per_page || 15}
              defaultCurrent={data?.data?.meta?.current_page || 1}
            />
          </div>
        </div>
      )}
    </StyledExpectedTable>
  );
}
