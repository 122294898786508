import { useMutation, useQueryClient } from "react-query";
import { balanceApi } from "../../services/balance.js";

export const useAddBankTransfer = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const client = useQueryClient();
  return useMutation(balanceApi.addBankTransfer, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["send-to-hub"]);
    },
  });
};
