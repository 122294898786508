import styled from "styled-components";
import { Table } from "antd";
import { useParams } from "react-router-dom";
import Typography from "../../components/moleculs/Typography.jsx";
import InputIcon from "../../components/SvgComponents/InputIcon.jsx";
import { primaryTextColor } from "../../constants.js";
import Flex from "../../components/general/Flex";
import OutputIcon from "../../components/SvgComponents/OutputIcon";
import useGetTransactions from "../../hooks/customers/useGetTransactions.hook.jsx";
import Loader from "../../components/moleculs/Loader";
import dayjs from "dayjs";

const StyledTransactions = styled.div`
  margin-top: 20px;
  .ant-table-thead > tr > th {
    background: #f5f5f5;
  }
  .ant-table-container {
    border: 1px solid #e7e9ec;
    border-radius: 8px !important;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      border-radius: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;

      background: #d1dfec;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
`;

export default function CustomerSingleTransactions() {
  const { id } = useParams();
  const { transactions } = useGetTransactions(id);
  const columns = [
    {
      key: "orderId",
      dataIndex: "orderId",
      title: "Order ID",
      width: "8%",
      // render(_, record) => ()
    },
    {
      key: "inout",
      dataIndex: "inout",
      title: "In/Out",
      width: "15%",
    },
    {
      key: "type",
      dataIndex: "type",
      title: "Type",
      width: "15%",
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "Amount",
      width: "28%",
    },
    {
      key: "date",
      dataIndex: "date",
      title: "Date",
    },
  ];

  let dataSource = null;

  dataSource = transactions?.map((transaction, index) => ({
    id: transaction.id,
    key: index,
    orderId: (
      <Typography
        variant="span"
        text={transaction.order_id}
        size="14px"
        weight="400"
        color="#5DBA2F"
      />
    ),
    inout:
      transaction.type === "in" ? (
        <Flex alignItems={"center"}>
          <InputIcon />
          <Typography
            variant="span"
            text={transaction?.comment}
            size="14px"
            weight="400"
            color={primaryTextColor}
            padding={"0 0 0 10px"}
          />
        </Flex>
      ) : (
        <Flex alignItems={"center"}>
          <OutputIcon />
          <Typography
            variant="span"
            text={transaction?.comment}
            size="14px"
            weight="400"
            color={primaryTextColor}
            padding={"0 0 0 10px"}
          />
        </Flex>
      ),
    type: (
      <Typography
        variant="span"
        text={transaction.balance_payment_type?.name || "Visa Card"}
        size="14px"
        weight="400"
        color={primaryTextColor}
      />
    ),
    //pr
    amount: (
      <Typography
        variant="span"
        text={`${transaction.sum} ₾`}
        size="14px"
        weight="500"
        color={primaryTextColor}
      />
    ),
    date: (
      <Flex>
        <Typography
          variant="span"
          text={
            dayjs(transaction.created_at).format("YYYY-MM-DD") || "11.12.2022"
          }
          size="14px"
          weight="400"
          color={primaryTextColor}
        />
        <Typography
          variant="span"
          text={`${dayjs(transaction.created_at).format("HH:mm:ss")}`}
          size="14px"
          weight="400"
          color="#8E9BA7"
          padding={"0 0 0 5px"}
        />
      </Flex>
    ),
  }));

  return (
    <StyledTransactions>
      {transactions ? (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: 1200, y: 350 }}
        />
      ) : (
        <Loader />
      )}
    </StyledTransactions>
  );
}
