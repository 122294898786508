import styled from "styled-components";
import { AutoComplete, Col, DatePicker, Form, Input, Row } from "antd";
import { useState } from "react";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import SearchIcon from "../../components/SvgComponents/SearchIcon.jsx";
import { testUsersAutocomplete } from "../../hooks/TestUsers/useTestUsersAutocomplete.hook.js";

const { RangePicker } = DatePicker;
const StyledTestUsersTopActions = styled.div`
  border-radius: 12px;
  padding: 16px 16px 16px 0;
  width: 100%;

  .actions-btns {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 36px;

    button {
      margin: 0 0 0 8px;
      height: 36px;
      align-items: center;
      display: flex;
    }
  }
`;

export default function TestUsersTopActions({
  setFilters,
  userInfo,
  setUserInfo = () => {},
}) {
  const { usersList = [] } = testUsersAutocomplete(
    userInfo && userInfo?.user_info?.trim()?.length === 0 ? null : userInfo
  );
  const [date, setDate] = useState();
  const [form] = Form.useForm();
  const handleFinish = (values) => {
    let newValues = { is_test: 1 };
    if (date) {
      values["test_start_date"] = date[0];
      values["test_end_date"] = date[1];
    }

    Object.entries(values).forEach(([key, val]) => {
      if (val) {
        newValues[key] = val;
      }
    });
    setFilters(newValues);
    form.resetFields();
    setUserInfo(null);
  };

  const handleChangeDate = (values) => {
    setDate(values);
  };

  return (
    <StyledTestUsersTopActions>
      <div>
        <Form
          style={{ width: "100%" }}
          form={form}
          name="filters"
          layout="vertical"
          initialValues={[{ orderStatus: "status1" }]}
          onFinish={handleFinish}
        >
          <Row gutter={16} className="row">
            <Col lg={4}>
              <Form.Item name="user_info">
                <AutoComplete
                  placeholder="Full name or User code"
                  onSearch={(val) => {
                    setUserInfo({ user_info: val, is_test: 1 });
                  }}
                  options={usersList}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item
                name="email"
                rules={[{ type: "email", message: "Must be valid email" }]}
              >
                <Input placeholder="Email address" />
              </Form.Item>
            </Col>

            <Col lg={4}>
              <Form.Item>
                <RangePicker onChange={(_, value) => handleChangeDate(value)} />
              </Form.Item>
            </Col>
            <div className="actions-btns">
              <ButtonPrimary
                text="Search"
                htmlType="submit"
                icon={<SearchIcon />}
              />
            </div>
          </Row>
        </Form>
      </div>
    </StyledTestUsersTopActions>
  );
}
