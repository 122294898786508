export const OnexAppRoutes = {
  singIn: () => "/sign-in/",
  fallback: () => "*",
  home: () => "/",
  signUp: () => "/sign-up",
  customers: () => "/customers",
  orders: () => "/orders",
  customerSingle: () => "/customer/:id",
  balance: () => "/balance",
  bonus: () => "/bonus",
  onlinePayment: () => "/online-payment",
  scan: () => "/scan",
  scanSingle: () => "/scan/:id",
  consignment: () => "/consignment",
  consignmentLocation: () => "/consignment/location",
  expected: () => "/expected",
  ordersCheck: () => "/orders-check",
  ordersFailed: () => "/orders-failed",
  delivery: () => "/delivery",
  inArmenia: () => "/in-armenia",
  downloadExcel: () => "/download-excel",
  receiveOrders: () => "/receive-orders",
  dashboard: () => "/dashboard",
  membersAndRoles: () => "/members",
  slides: () => "/slides",
  blog: () => "/blog",
  banners: () => "/banners",
  shops: () => "/shops",
  reports: () => "/reports",
  notifications: () => "/notifications",
  stories: () => "/stories",
  roles: () => "/roles",
  actionsHistory: () => "/actions-history",
  prime_users: () => "/prime-users",
  test_users: () => "/test-users",
  tariffs: () => "/tariffs",
  customized_pricing: () => "/customized-pricing",
  app_versions: () => "/app-versions",
  lost_orders: () => "/lost-orders",
};
