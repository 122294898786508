import styled from "styled-components";
import { useParams } from "react-router-dom";
import Flex from "../../components/general/Flex";
import UserIcon from "../../components/SvgComponents/UserIcon";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import PrimeIcon from "../../components/SvgComponents/primeIcon";
import GetAccountDeails from "../../hooks/customers/useGetAccountDetails.hook.jsx";
import Loader from "../../components/moleculs/Loader";
import RecipientIcon from "../../components/SvgComponents/RecipientIcon.jsx";
import GeorgianIcon from "../../components/SvgComponents/Georgian.jsx";
import CompanyIconInCustomer from "../../components/SvgComponents/CompanyIconInCustomer.jsx";
import { OutArrowIcon } from "../../components/SvgComponents/OutArrowIcon.jsx";
import { useMemo } from "react";
import { useMutationAuthCustomers } from "../../hooks/customers/useMutationAuthCustomers.hook.js";
import { openNotificationError } from "../../helpers/notification.js";

const StyledUserInfo = styled.div`
  margin: 16px 0;
  padding: 20px 16px;
  background: white;
  border-radius: 12px;
  .balance-item {
    border-left: 1px solid #e7e9ec;
    padding-left: 10px;
  }
  .balances {
    .balance-item {
      &:nth-child(1) {
        margin-right: 50px;
      }
    }
  }
`;

export default function UserInfo({ clientLogin }) {
  const location = window.location.origin;

  const pathname = useMemo(() => {
    if (location.includes("dev")) return "https://dev.onex.ge";
    if (location.includes("staging")) return "https://staging.onex.ge";
    if (location.includes("localhost")) return "http://localhost:3000";
    return "https://onex.ge";
  }, [location]);
  const { id } = useParams();
  const { accountDeails } = GetAccountDeails(id);

  const { mutate } = useMutationAuthCustomers(
    (data) => {
      const newData = JSON.stringify(data?.data?.data);
      // document.cookie = `auth_key=${newData}; domain=.onex.ge; path=/;`;
      window.open(`${pathname}/en/user/log-in?auth=${newData}`);

      // Cookie.set('key', 'value', { domain: '.domain.com' })
    },
    (e) => {
      openNotificationError(
        "User Log In",
        e?.response?.data?.message || "something went wrong"
      );
    }
  );
  const handleRedirect = (id) => {
    mutate({ user_id: id });
  };
  return (
    <StyledUserInfo>
      {accountDeails ? (
        <Flex alignItems="center" justifyContent="space-between">
          <Flex>
            <div className="p-r-16">
              {accountDeails?.recipient.is_person === 1 &&
              accountDeails?.recipient.is_parent === 0 ? (
                <RecipientIcon />
              ) : accountDeails?.recipient?.is_person !== 1 ? (
                <CompanyIconInCustomer />
              ) : accountDeails.recipient.is_person === 1 &&
                accountDeails.recipient.is_parent === 1 &&
                accountDeails?.recipient.is_resident === "1" ? (
                <GeorgianIcon />
              ) : (
                <UserIcon />
              )}
            </div>
            <div>
              <Typography
                variant="p"
                text={`ID ${id}`}
                color="#B3BBC4"
                size="14px"
                weight="400"
              />
              <Flex>
                <Typography
                  variant="p"
                  text={
                    accountDeails?.is_person !== 1
                      ? accountDeails?.full_name +
                        " " +
                        accountDeails?.recipient?.user_code
                      : accountDeails?.recipient.first_name +
                        " " +
                        accountDeails?.recipient.last_name +
                        " " +
                        accountDeails?.recipient.user_code
                  }
                  color={primaryTextColor}
                  size="20px"
                  weight="500"
                  padding="0 5px 10px 0"
                />
                {accountDeails?.is_prime ? <PrimeIcon /> : null}
                {clientLogin ? (
                  <OutArrowIcon
                    onClick={() => handleRedirect(accountDeails?.id)}
                    margin={"0 0 0 8px"}
                    cursor={"pointer"}
                    className={"_hover"}
                  />
                ) : (
                  ""
                )}
              </Flex>
              {/* <Flex> */}
              {/*   <Tag */}
              {/*     color="#3B5166" */}
              {/*     background="#F5F5F5" */}
              {/*     text="Lost" */}
              {/*     margin="0 16px 0 0" */}
              {/*     weight="500" */}
              {/*   /> */}
              {/*   <Tag */}
              {/*     color="#FC9A3A" */}
              {/*     background="#FFF5EB" */}
              {/*     text="CEO" */}
              {/*     margin="0 16px 0 0" */}
              {/*     weight="500" */}
              {/*   /> */}
              {/*   <Tag */}
              {/*     color="#E53E41" */}
              {/*     background="#FFECED" */}
              {/*     text="Problem maker" */}
              {/*     margin="0 16px 0 0" */}
              {/*     weight="500" */}
              {/*   /> */}
              {/* </Flex> */}
            </div>
          </Flex>
          <Flex className="balances">
            <div className="balance-item">
              <Typography
                variant="p"
                text="Balance"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 5px 0"
              />
              <Typography
                variant="p"
                text={`${accountDeails?.balance} ₾`}
                color={primaryTextColor}
                size="20px"
                weight="500"
                padding="0 0 4px 0"
              />
            </div>
            <div className="balance-item">
              <Typography
                variant="p"
                text="Bonus"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 5px 0"
              />
              <Typography
                variant="p"
                text={`${accountDeails?.bonus} ₾`}
                color={primaryTextColor}
                size="20px"
                weight="500"
                padding="0 0 4px 0"
              />
            </div>
          </Flex>
        </Flex>
      ) : (
        <Loader />
      )}
    </StyledUserInfo>
  );
}
