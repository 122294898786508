import styled from "styled-components";
import { Tabs } from "antd";
import { useState } from "react";
import Flex from "../../components/general/Flex";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import EditIcon from "../../components/SvgComponents/EditIcon";
import PersonalInfoTab from "../../components/sections/SingleCustomer/PersonalInfo.jsx";
import EditPersonalInfo from "../../components/sections/SingleCustomer/EditPersonalInfo.jsx";
import RecipientsTab from "../../components/sections/SingleCustomer/Recipients";
import CustomerSinglePickupPoint from "./PickupPoint.jsx";
import CustomerSingleTransactions from "./Transactions.jsx";
import CustomerSingleSettings from "./Settings.jsx";
import CustomerNotificationSettings from "./NotificationSettings";
import useGetRecipients from "../../hooks/customers/useGetRecipients.hook.jsx";
import { useParams } from "react-router-dom";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledTabs = styled.div`
  padding: 17px 20px;
  margin-bottom: 16px;
  background: white;
  border-radius: 12px;
  position: relative;

  .actions {
    position: absolute;
    top: -100px;
    right: 20px;
  }

  .recipients-count {
    background: #eff8ea;
    padding: 2px 10px;
    border-radius: 19px;
    color: #5dba2f;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default function CustomerSingleTabs({ permissions }) {
  const [editPersonalInfoStatus, setEditPersonalInfoStatus] = useState(false);
  const { id } = useParams();
  const query = { user_id: id };
  const { recipients = [], refetch } = useGetRecipients(query);

  const items = [
    {
      key: "1",
      label: `Personal Info`,
      children: editPersonalInfoStatus ? (
        <EditPersonalInfo
          setEditPersonalInfoStatus={setEditPersonalInfoStatus}
        />
      ) : (
        <>
          <div className="actions">
            {PermissionFinder(permissions, "recipient-edit") ? (
              <ButtonSecondary
                text="Edit"
                icon={<EditIcon />}
                click={() => setEditPersonalInfoStatus(true)}
              />
            ) : null}
          </div>
          <PersonalInfoTab />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <Flex>
          <span>Recipients</span>
          <span className="recipients-count">{recipients?.length || 0}</span>
        </Flex>
      ),
      children: <RecipientsTab />,
    },
    {
      key: "3",
      label: `Pickup point`,
      children: <CustomerSinglePickupPoint />,
    },
    {
      key: "4",
      label: `Transactions`,
      children: <CustomerSingleTransactions />,
    },
    {
      key: "5",
      label: `Settings`,
      children: PermissionFinder(permissions, "recipient-edit") ? (
        <CustomerSingleSettings />
      ) : null,
    },
    {
      key: "6",
      label: `Notification settings`,
      children: <CustomerNotificationSettings />,
    },
  ];

  return (
    <StyledTabs>
      <div>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </StyledTabs>
  );
}
