import { useQuery } from "react-query";
import { ShowSingleOrder } from "../../services/orders.js";

export default function useShowOrder(id) {
  const { data: order, refetch } = useQuery(
    ["single-order", id],
    () => ShowSingleOrder(id),
    {
      enabled: !!id,
      staleTime: Infinity,
      select: ({ data }) => {
        let order = data?.data;
        return { order };
      },
    }
  );
  return { order, refetch };
}
