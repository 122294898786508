export const sliderInitialValue = {
  details: [
    {
      language_id: 1,
      title: "",
      description: "",
      image: null,
      url: "",
      button_name: "",
    },
    {
      language_id: "",
      title: "",
      description: "",
      image: null,
      url: "",
      button_name: "",
    },
    {
      language_id: "",
      title: "",
      description: "",
      image: null,
      url: "",
      button_name: "",
    },
  ],
};
