import { Drawer, Form, Input, Switch } from "antd";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import Flex from "../../components/general/Flex.jsx";
import ButtonCancel from "../../components/moleculs/ButtonCancel.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import DeleteButton from "../../components/moleculs/DeleteButton.jsx";
import AlertCircleIcon from "../../components/SvgComponents/AlertCircleIcon";
import DeleteModal from "../../components/modals/DeleteModal.jsx";
import { WarningIcon } from "../../components/SvgComponents/WarningIcon";
import { bannerInitialValue } from "./initial-value-banner-form.js";
import TabList from "../../components/tab/TabList.jsx";
import { CopyLink } from "../../components/SvgComponents/CopyLink.jsx";
import { useCreateAndEditBanner } from "../../hooks/banners/useCreateAndEditBanner.hook.js";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import { useDeleteBanner } from "../../hooks/banners/useDeleteBanner.hook.js";
import { useIsActiveBanner } from "../../hooks/banners/useIsActiveBanner.hook.js";
import { useCheckEmptyArrItem } from "../../helpers/useCheckEmptyArrItem.js";

const StyledCreateBanner = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .style_form {
    border-radius: 12px;
    background: #ffffff;
    padding: 16px;
  }

  .clipped {
    width: 100%;
    border-bottom: 1px solid #e7e9ec;
  }
`;

export default function CreateBannerDrawer({
  setBanner = () => {},
  refetch = () => {},
  banner,
  languages,
  isLoading,
}) {
  const [languageId, setLanguageId] = useState(banner?.language_id || 1);
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [selectedWord, setSelectedWord] = useState(""); // To store selected word
  const [addLink, setAddLink] = useState(false);
  const [manualLink, setManualLink] = useState("");
  const inputRef = useRef(null);
  const bannerLink = Form.useWatch("details", form);
  const isTrueAllItems = useCheckEmptyArrItem(bannerLink);

  const handelClosed = () => {
    form.resetFields();
    setLanguageId(1);
    setBanner(false);
    setOpen(false);
    setAddLink(false);
    setManualLink("");
  };
  const { mutate } = useCreateAndEditBanner(
    () => {
      handelClosed();
      openNotification("Create header", "The header was created successfully!");
      refetch();
    },
    (e) => {
      Object.entries(e?.response?.data?.data).forEach(([key, val]) => {
        openNotificationError(val[0], "Something went wrong");
      });
    }
  );
  const { mutate: deleteBanner } = useDeleteBanner(
    (data) => {
      handelClosed();
      openNotification(data?.data?.message, "Success");
      refetch();
    },
    () => openNotificationError("Create header", "Something went wrong")
  );
  const { mutate: mutateIsActive, isLoading: isActivateLoading } =
    useIsActiveBanner(
      (data) => {
        openNotification(data?.data?.message);
        setStatus(data?.data?.data?.is_active);
        refetch();
      },
      (e) => {
        openNotificationError(
          "Action failed.",
          e?.response?.data?.message || "something went wrong"
        );
      }
    );
  useEffect(() => {
    console.log(banner);
    if (banner) {
      banner?.details?.forEach((br, i) => {
        Object.entries(br).forEach(([key, val]) => {
          form.setFieldValue(["details", +br?.language_id - 1, key], val);
        });
      });
      setStatus(banner?.is_active);
      if (banner?.language_id) {
        setLanguageId(banner.language_id);
        form.setFieldValue(
          ["details", banner.language_id - 1, "language_id"],
          banner?.language_id
        );
      }
    }
  }, [banner]);

  const createBanner = (values) => {
    const formData = new FormData();
    if (values) {
      values?.details?.map((detail, index) => {
        if (isTrueAllItems[index]) {
          Object.entries(detail).forEach(([key, value]) => {
            if (value) {
              formData.append(`details[${index}][${key}]`, value);
            }
          });
        }
      });
    }
    if (typeof banner === "object") {
      formData.append("_method", "put");
    }
    mutate({ id: banner?.id, formData });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSelect = (e) => {
    // Capture the selected word
    const selection = window.getSelection();
    setSelectedWord(selection.toString());
  };

  const handleAddLink = () => {
    if (selectedWord && manualLink) {
      // Create the anchor tag with the selected word and link
      const linkTag = `<a href="${manualLink}" target="_blank">${selectedWord}</a>`;
      // Replace the selected word with the anchor tag
      const updatedText = inputRef.current.input.value.replace(
        selectedWord,
        linkTag
      );

      // Update the input text
      form.setFieldValue(["details", languageId - 1, "text"], updatedText);
      setSelectedWord(null); // Reset selected word
      setAddLink(false);
      setManualLink("");
    }
  };

  return (
    <Drawer
      title=""
      placement="right"
      onClose={handelClosed}
      open={banner}
      width="1112px"
      header={false}
    >
      <DeleteModal
        icon={<WarningIcon />}
        setStatus={setOpen}
        status={open}
        title={"Delete header"}
        description={"Are you sure you want to delete header"}
        setDelete={() => deleteBanner(banner?.id)}
      />
      <StyledCreateBanner>
        <Form
          initialValues={bannerInitialValue}
          form={form}
          name="createSlide"
          layout="vertical"
          onFinish={createBanner}
          onFinishFailed={onFinishFailed}
        >
          <div className="header">
            <Typography
              text={
                typeof banner === "object" ? "Edit header" : "Create header"
              }
              variant="p"
              color={primaryTextColor}
              size="22px"
              weight="500"
              padding={"0 0 16px 0"}
            />
            <Flex alignItems={"center"}>
              {typeof banner === "object" ? (
                <>
                  <DeleteButton
                    margin={"0 16px 0 0"}
                    text={"Delete Banner"}
                    icon={<AlertCircleIcon />}
                    click={() => {
                      setOpen(true);
                    }}
                    type="button"
                  />

                  <Typography
                    variant={"p"}
                    text={status ? "Active" : "Inactive"}
                    margin={"0 8px 0 0"}
                    size={"14px"}
                  />
                  <Switch
                    loading={isActivateLoading}
                    checked={status}
                    style={{ margin: "0 16px 0 0" }}
                    onChange={(val) => {
                      mutateIsActive({ id: banner?.id, is_active: val });
                    }}
                  />
                </>
              ) : null}

              <ButtonCancel
                text="Cancel"
                margin="0 16px 0 0"
                type="button"
                click={handelClosed}
              />
              <ButtonPrimary
                text={typeof banner === "object" ? "Save" : "Create"}
                htmlType="submit"
              />
            </Flex>
          </div>
          {banner ? (
            <TabList
              data={languages}
              isTrueAllItems={isTrueAllItems}
              onChange={(key) => {
                setLanguageId(key);
                form.setFieldValue(["details", key - 1, "language_id"], key);
              }}
              value={languageId}
            />
          ) : (
            ""
          )}

          <Form.List name={"details"}>
            {(fields, { add, remove }) => {
              return (
                <div className={"style_form"}>
                  <div className={"clipped"}>
                    <CopyLink
                      style={{ margin: "0 0 12px 0" }}
                      onClick={() => setAddLink(true)}
                    />
                  </div>
                  <Form.Item
                    style={{ padding: "12px 0 0 0" }}
                    name={[languageId - 1, "text"]}
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Missing Description.",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        minHeight: "52px",
                      }}
                      placeholder={"Description"}
                      ref={inputRef}
                      onSelect={handleSelect}
                    />
                  </Form.Item>
                  {addLink ? null : (
                    <div
                      style={{ color: "#3B5166", fontSize: "16px" }}
                      dangerouslySetInnerHTML={{
                        __html: bannerLink?.[languageId - 1]?.text,
                      }}
                    />
                  )}

                  {selectedWord && addLink && (
                    <div>
                      <Input
                        placeholder="Enter a link"
                        value={manualLink}
                        onChange={(e) => setManualLink(e.target.value)}
                      />

                      <div>
                        <Typography
                          variant={"p"}
                          color={"#3B5166"}
                          size="14px"
                          weight="400"
                          padding={"12px 0"}
                          text={`Selected word: ${selectedWord}`}
                        />

                        <ButtonPrimary
                          type="button"
                          margin="0 16px 0 0"
                          onClick={handleAddLink}
                          text={"Add"}
                        />
                        <ButtonCancel
                          text="Cancel"
                          type="button"
                          click={() => {
                            setAddLink(false);
                            setManualLink("");
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          </Form.List>
        </Form>
      </StyledCreateBanner>
    </Drawer>
  );
}
