import styled from "styled-components";
import Flex from "../../components/general/Flex";
import Typography from "../../components/moleculs/Typography.jsx";
import EditIcon from "../../components/SvgComponents/EditIcon";
import EyeIcon from "../../components/SvgComponents/EyeIcon";
import DownArrowIcon from "../../components/SvgComponents/DownArrowIcon";
import ChildStory from "./ChildStory";
import { useEffect, useMemo, useState } from "react";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TableDots } from "../../components/SvgComponents/TableDots";
import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { handleDragEnd } from "../../hooks/stories/dragDropStory.js";
import { useSortStories } from "../../hooks/stories/useSortStories.hook.js";
import EyeHidden from "../../components/SvgComponents/EyeHidden.jsx";
import { storyApi } from "../../services/stories.js";

const StyledStoryItem = styled.div`
  background: white;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #e7e9ec;
  margin-bottom: 16px;
  .arrow {
    transition: 200ms;
    cursor: pointer;
    transform: ${({ open }) => (open ? "rotate(180deg)" : "0")};
    svg {
      width: 20px;
      height: 12px;
    }
  }
  .childrens {
    transition: all 500ms ease-in;
    height: ${({ open }) => (open ? "auto" : "0")};
    overflow: ${({ open }) => (open ? "auto" : "hidden")};
    margin: 0 35px;
  }
  .children {
    margin: 20px 0;
    .id {
      color: #5b6d7f !important;
      font-weight: 400 !important;
    }
  }
  img {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    object-fit: cover;
  }
  .description {
    margin-left: 40px;
  }
  .actions {
    svg {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
`;

export default function StoryItem({
  id,
  item,
  index,
  onEdit,
  refetch,
  handleDelete,
  language_index,
}) {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState(item.child_stories);
  const { mutate } = useSortStories();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled: open });

  const style = {
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
          pointerEvents: "none",
          boxShadow: "0px 4px 14px 0px rgba(0, 31, 77, 0.25)",
        }
      : {}),
  };
  const handleSort = (new_array) => {
    const new_order = new_array.map((item) => ({
      id: item.id,
      priority: item.priority,
    }));
    mutate(new_order);
  };
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const dragDrop = (event) => {
    handleDragEnd(event, items, (new_array, active_index, over_index) => {
      handleSort(new_array);
      setItems(arrayMove(new_array, active_index, over_index));
    });
  };
  useEffect(() => {
    setItems(item.child_stories);
  }, [item.child_stories]);
  const handleHideStory = (id, is_hide) => {
    storyApi.hideStory(id, is_hide).then(refetch);
  };
  const active = useMemo(() => {
    if (!language_index) return item;
    return item.storiesMl[language_index];
  }, [item, language_index]);
  return (
    <StyledStoryItem
      open={open}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <div>
            <TableDots />
          </div>
          <Typography
            className={"id"}
            variant={"span"}
            text={item?.priority || index}
            color={"#262626"}
            size={"14px"}
            weight={"500"}
            padding={"0 40px 0 40px"}
          />
          <img src={item.image} alt="" />
          <div className={"description"}>
            <Typography
              variant={"span"}
              text={active?.description}
              color={"#262626"}
              size={"16px"}
              weight={"500"}
            />
            <Typography
              variant={"span"}
              text={`${item?.child_stories?.length} Stories`}
              color={"#5B6D7F"}
              size={"14px"}
              weight={"400"}
            />
          </div>
        </Flex>
        <Flex alignItems={"center"} className={"actions"}>
          <EditIcon onClick={onEdit} />
          {item.is_hide ? (
            <EyeHidden onClick={() => handleHideStory(item.id, false)} />
          ) : (
            <EyeIcon onClick={() => handleHideStory(item.id, true)} />
          )}
          <div
            onClick={() => {
              setOpen(!open);
            }}
            className={"arrow"}
          >
            {item?.child_stories?.length ? <DownArrowIcon /> : <></>}
          </div>
        </Flex>
      </Flex>
      {items ? (
        <div className="childrens">
          <DndContext
            onDragEnd={dragDrop}
            modifiers={[restrictToVerticalAxis]}
            sensors={sensors}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items?.map((item, index) => (
                <ChildStory
                  hideStory={handleHideStory}
                  refetch={refetch}
                  handleDelete={handleDelete}
                  language_index={language_index}
                  key={item.id}
                  id={item.id}
                  item={item}
                  index={index}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      ) : (
        <></>
      )}
    </StyledStoryItem>
  );
}
