import { Checkbox, Table } from "antd";
import styled from "styled-components";
import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import InArmeniaIcon from "../../components/SvgComponents/InArmeniaIcon";
import ReadyIcon from "../../components/SvgComponents/ReadyIcon";
import LockerIcon from "../../components/SvgComponents/LockerIcon";
import dayjs from "dayjs";
import OnexTooltip from "../../helpers/tooltip";

const StyledCaption = styled.div`
  .caption-item {
    display: flex;
  }
  .bar-code-btn {
    padding: 8px 10px;
    border: none;
    background: #e2f7ea;
    border-radius: 16px;
    width: 150px;
    svg {
      margin-right: 5px;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }
`;

const StyledTable = styled.div`
  thead {
    display: none;
  }
  .ant-table-title {
    background: #f5f5f5;
    padding: 8px !important;
    border-radius: 12px 12px 0 0;
  }
`;
export default function ReceiveTableContent({
  orders,
  tabKey,
  totalWeight,
  totalPrice,
  selectedRowKeys,
  setSelectedRowKeys,
}) {
  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "",
    },
    {
      key: "country",
      dataIndex: "country",
      title: "",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "",
    },
    {
      key: "tracking",
      dataIndex: "tracking",
      title: "",
    },
    {
      key: "weight",
      dataIndex: "weight",
      title: "",
    },
    {
      key: "price",
      dataIndex: "price",
      title: "",
    },
    {
      key: "status",
      dataIndex: "status",
      title: "",
    },
    {
      key: "pickup_point",
      dataIndex: "pickup_point",
      title: "",
    },
  ];
  const data = orders?.map((item, i) => ({
    key: item?.id,
    id: item?.id || "",
    country: (
      <div>
        <Flex alignItems={"center"}>
          <img src={item?.warehouse?.round_flag} height={24} width={24} />
          <div
            style={{ paddingLeft: 8 }}
            id="dispatch"
            data-tip="React-tooltip"
          >
            <img src={item?.dispatch?.icon} height={28} width={28} />
            {/*<FlyIcon />*/}
          </div>
        </Flex>
        <Typography
          variant="span"
          text={item?.parcel?.name || ""}
          color="#262626"
          size="14px"
          weight={500}
          padding="8px 0 0 0"
        />
      </div>
    ),
    name: (
      <Flex>
        <Typography
          text={
            item?.recipient?.first_name +
            " " +
            item?.recipient?.last_name +
            " " +
            item?.recipient?.user_code
          }
          variant="p"
          size="14px"
          weight="500"
          color=" #262626"
        />
      </Flex>
    ),
    tracking: (
      <div>
        <Typography
          variant="p"
          text={item?.tracking_code || ""}
          color="#262626"
          size="14px"
          weight="400"
        />
        <Flex>
          <div className="product-description">
            <Typography
              variant="p"
              text={item?.customer_comment || ""}
              color="#5B6D7F"
              size="12px"
              weight={400}
              padding="10px 0 0 0 "
            />
          </div>
        </Flex>
      </div>
    ),
    weight: (
      <>
        <div>
          <Typography
            text={`${item?.weight || "0"} kg`}
            variant="p"
            color="#262626"
            weight="400"
            size="14px"
          />
        </div>
        <div>
          <Typography
            text={`${item?.v_weight || "0"} kg`}
            variant="p"
            color="#262626"
            weight="400"
            size="14px"
            padding="10px 0 0 0 "
          />
        </div>
      </>
    ),
    price: (
      <>
        <Typography
          variant="p"
          text={`${item?.cost || "0 "} ₾`}
          color="#262626"
          weight="400"
          size="14px"
          padding="0 7px 10px 0"
        />
        {item?.additional_cost ? (
          <OnexTooltip content={`Add. cost`}>
            <Typography
              text={item?.additional_cost + " ₾"}
              color={"#8E9BA7"}
              variant={"p"}
              size={"14px"}
            />
          </OnexTooltip>
        ) : null}

        <Typography
          variant="p"
          text={`${item?.declaration_price || ""} ${
            item?.declaration_currency || ""
          }`}
          color="#5B6D7F"
          weight="400"
          size="14px"
          padding="10px 0 0 0"
        />
      </>
    ),
    status: (
      <div>
        <Flex alignItems="center">
          <InArmeniaIcon />
          <Flex>
            <Typography
              variant="p"
              text="In Georgia"
              color="#5B6D7F"
              size="14px"
              weight="400"
              margin="0 0 0 5px"
            />
            <Typography
              variant="p"
              text={
                item?.created_at
                  ? dayjs(item?.created_at).format("DD.MM.YYYY")
                  : ""
              }
              color="#262626"
              size="14px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </Flex>
        <Flex alignItems="center" style={{ paddingTop: 10 }}>
          {item?.ready_for_pickup ? (
            <>
              {" "}
              <ReadyIcon />
              <Typography
                variant="p"
                text="Ready"
                color="#5DBA2F"
                size="12px"
                weight="400"
                margin="0 0 0 5px"
              />
            </>
          ) : (
            ""
          )}
        </Flex>
      </div>
    ),
    pickup_point: (
      <>
        <Typography
          variant="p"
          text={item?.pickup_point?.name || ""}
          size="14px"
          weight="400"
        />
        <Flex style={{ marginTop: 10 }}>
          <LockerIcon />
          <Typography
            variant="p"
            text={item?.pickup_point?.address || ""}
            color="#262626"
            size="12px"
            weight="400"
            margin="0 0 0 5px"
          />
        </Flex>
      </>
    ),
  }));

  const rowSelection = {
    selectedRowKeys,
    onChange: (value) => {
      setSelectedRowKeys(value);
    },
  };
  const handleSelectAll = (val) => {
    if (val) {
      let selectedIds = [];
      orders?.forEach((el) => {
        selectedIds.push(el?.id);
      });
      setSelectedRowKeys(selectedIds);
    } else {
      setSelectedRowKeys([]);
    }
  };
  return (
    <StyledTable>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowSelection={rowSelection}
        scroll={{ x: 1300 }}
        title={() => (
          <StyledCaption>
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              style={{ padding: "4px 0" }}
            >
              <Flex style={{ width: 1250 }}>
                <Checkbox onChange={(e) => handleSelectAll(e.target.checked)} />
                <Typography
                  variant="p"
                  text={tabKey}
                  size="14px"
                  weight="500"
                  color="#5B6D7F"
                  padding="0 16px 0 25px"
                />
                <div className="caption-item">
                  <Typography
                    variant="p"
                    text="Packages"
                    size="14px"
                    weight="400"
                    color="#8E9BA7"
                    padding="0 8px 0 0"
                  />
                  <Typography
                    variant="p"
                    text={orders?.length || "0"}
                    size="14px"
                    weight="400"
                    color="#5B6D7F"
                    padding="0 8px 0 0"
                  />
                </div>
                <div className="caption-item">
                  <Typography
                    variant="p"
                    text="Total weight"
                    size="14px"
                    weight="400"
                    color="#8E9BA7"
                    padding="0 8px 0 0"
                  />
                  <Typography
                    variant="p"
                    text={`${totalWeight} kg`}
                    size="14px"
                    weight="400"
                    color="#5B6D7F"
                    padding="0 8px 0 0"
                  />
                </div>
                <div className="caption-item">
                  <Typography
                    variant="p"
                    text="Total price"
                    size="14px"
                    weight="400"
                    color="#8E9BA7"
                    padding="0 8px 0 0"
                  />
                  <Typography
                    variant="p"
                    text={`${totalPrice} ₾`}
                    size="14px"
                    weight="400"
                    color="#5B6D7F"
                    padding="0 8px 0 0"
                  />
                </div>
              </Flex>
              {/*<ButtonPrimary*/}
              {/*  style={{ margin: "5px 0" }}*/}
              {/*  text={"Receive selected orders"}*/}
              {/*  click={() => setReceiveModalStatus(true)}*/}
              {/*/>*/}
              {/*<button className={"bar-code-btn"} disabled={true}>*/}
              {/*  <Flex alignItems={"center"}>*/}
              {/*    <BarCodeIcon />*/}
              {/*    <Typography*/}
              {/*      variant={"span"}*/}
              {/*      text={"View barcode"}*/}
              {/*      color={"#32A35E"}*/}
              {/*      size={"14px"}*/}
              {/*      weight={"500"}*/}
              {/*    />*/}
              {/*  </Flex>*/}
              {/*</button>*/}
            </Flex>
          </StyledCaption>
        )}
      />
    </StyledTable>
  );
}
