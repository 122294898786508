import {
  Drawer,
  Card,
  Checkbox,
  Divider,
  Row,
  Col,
  Form,
  Popconfirm,
} from "antd";
import Typography from "../../components/moleculs/Typography";
import Flex from "../../components/general/Flex";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import { useEffect } from "react";
import { useState } from "react";
import { useRolesPermissions } from "../../hooks/members-and-roles/useRolesPermissions.hook.jsx";
import { useFetchPermissionsByRoles } from "../../hooks/members-and-roles/useGetPermissionsByRole.hook.jsx";
import { useUpdateToggle } from "../../hooks/members-and-roles/useUpdateToggle.hook.js";
import { membersAndRoles } from "../../services/members-and-roles.js";
import { useUpdateRole } from "../../hooks/members-and-roles/useUpdateRole.hook.jsx";
import { openNotification } from "../../helpers/notification.js";
import Loader from "../../components/moleculs/Loader";
import DeleteButton from "../../components/moleculs/DeleteButton";
import CloseIcon from "../../components/SvgComponents/CloseIcon";
import DeleteIcon from "../../components/SvgComponents/DeleteIcon";
import { useDeleteRole } from "../../hooks/members-and-roles/useDeleteRole.hook.jsx";

export default function RoleEditModal({
  status,
  setStatus,
  item,
  refetchRoles,
}) {
  // const [permissions, setPermissions] = useState(null);
  const [indeterminate, setIndeterminate] = useState({});
  const [filters, setFilters] = useState(null);
  const [checkedList, setCheckedList] = useState([]);

  const { permissions, refetch } = useFetchPermissionsByRoles(filters);
  const { mutate: deleteRole } = useDeleteRole(
    () => {
      openNotification("Role", "Deleted successfully");
      setStatus(false);
      refetchRoles();
    },
    () => {
      console.log("error");
    }
  );
  const { mutate } = useUpdateRole(
    () => {
      openNotification("Role", "Successfully updated");
      setStatus(false);
      refetchRoles();
    },
    () => {
      openNotification("Role", "Something went wrong!");
    }
  );
  useEffect(() => {
    if (item) {
      setFilters({ grouped_list: 1, role_id: item?.id });
    }
  }, [item]);

  useEffect(() => {
    if (permissions) {
      Object.entries(permissions)?.map((item) => {
        item[1]?.map((permission) => {
          if (permission.has_selected_role) {
            setCheckedList((checkedList) => [...checkedList, permission.id]);
          }
        });
      });
    }
  }, [permissions]);

  const handleDelete = () => {
    deleteRole({ id: item?.id });
  };

  return (
    <Drawer
      title="Edit Role"
      placement="right"
      onClose={() => {
        setStatus(false);
        setCheckedList([]);
      }}
      open={status}
      width="1112px"
      header={false}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Typography
          variant={"h2"}
          text={`Edit role ${item?.name}`}
          weight={"500"}
          size={"22px"}
          margin="0 0 36px 0"
        />
        <Flex>
          <div style={{ marginRight: 15 }}>
            <Popconfirm title={"Are you sure?"} onConfirm={handleDelete}>
              <ButtonSecondary icon={<DeleteIcon />} text={"Delete"} />
            </Popconfirm>
          </div>
          <ButtonSecondary
            text="Cancel"
            background="#FFECED"
            color="#FC4447"
            border={"none"}
            htmlType="reset"
            click={() => setStatus(false)}
          />
          <ButtonPrimary
            text="Save"
            border={"none"}
            style={{ marginLeft: 16, marginBottom: 36 }}
            htmlType="submit"
            click={() =>
              mutate({
                id: item?.id,
                name: item?.name,
                permissions: checkedList,
              })
            }
          />
        </Flex>
      </Flex>
      <Form>
        {checkedList && permissions ? (
          Object.entries(permissions)?.map((group, i) => {
            return (
              <Card style={{ marginBottom: 24 }} key={i}>
                <Typography
                  variant={"p"}
                  text={group[0].replaceAll("-", " ")}
                  size={"20px"}
                  weight={"500"}
                  margin={"0 0 18px 0"}
                />
                {checkedList?.length ? (
                  <Checkbox.Group
                    defaultValue={checkedList}
                    onChange={(values) => console.log(values)}
                  >
                    <Row gutter={[36, 26]}>
                      {group[1]?.map((permission, i) => {
                        return (
                          <Col lg={6} key={i}>
                            <Checkbox
                              value={permission.id}
                              onChange={(a) => {
                                if (checkedList.includes(a.target.value)) {
                                  const updatedArray = checkedList.filter(
                                    (existingId) =>
                                      existingId !== a.target.value
                                  );
                                  setCheckedList(updatedArray);
                                } else {
                                  const updatedArray = [
                                    ...checkedList,
                                    a.target.value,
                                  ];
                                  setCheckedList(updatedArray);
                                }
                              }}
                            >
                              {permission.name}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                ) : (
                  <Loader />
                )}
              </Card>
            );
          })
        ) : (
          <Loader />
        )}
      </Form>
    </Drawer>
  );
}
