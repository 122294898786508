import styled from "styled-components";

const StyledButtonPrimary = styled.button`
  background: #ffeced;
  color: #fc4447;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat arm2";
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  margin: ${({ margin }) => margin};
`;

export default function ButtonCancel({ text, margin, click, ...props }) {
  return (
    <StyledButtonPrimary onClick={() => click()} margin={margin} {...props}>
      <div>{text}</div>
    </StyledButtonPrimary>
  );
}
