import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import ReportTopActions from "./TopActions";
import ReportsData from "./Data";
import { useGetReportsLis } from "../../hooks/reports/useGetReportsList.hook.jsx";
import { useState } from "react";

const StyledBalance = styled.div`
  padding-top: 24px;
  min-height: 99vh;
`;

export default function Reports() {
  const [filters, setFilters] = useState({ type: "general" });
  const { reportsList } = useGetReportsLis(filters);
  return (
    <StyledBalance>
      <div className="page-title">
        <Typography
          text="Reports"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <ReportTopActions setFilters={setFilters} filters={filters} />
      <ReportsData
        reportsList={reportsList}
        params={{
          end_date: filters?.end_date || null,
          start_date: filters?.start_date || null,
        }}
      />
    </StyledBalance>
  );
}
