import { Pagination, Table, Tooltip } from "antd";
import Flex from "../../components/general/Flex.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon.jsx";
import { useOnlinePaymentList } from "../../hooks/OnlinePaymen/useFetchOnlinePaymen.hook.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import dayjs from "dayjs";
import { RefundedIcon } from "../../components/SvgComponents/RefundedIcon.jsx";
import { onlinePaymentApi } from "../../services/online-payment.js";

export const PaymentTable = ({
  setRefund = () => {},
  filterData,
  setFilterData = () => {},
}) => {
  const {
    paymentList = [],
    meta,
    isLoading,
  } = useOnlinePaymentList(filterData);
  const dataSource = paymentList?.map((pay, i) => ({
    key: i,
    order_id: (
      <Typography
        text={pay?.order_id || ""}
        variant={"p"}
        size={"14px"}
        color={"#5dba2f"}
      />
    ),
    transaction_id: (
      <Typography
        text={pay?.transaction_id || ""}
        variant={"p"}
        size={"14px"}
      />
    ),
    full_name: (
      <Typography
        text={
          (pay?.user?.recipient?.first_name || "") +
          " " +
          (pay?.user?.recipient?.last_name || "") +
          " " +
          (pay?.user?.recipient?.user_code || "")
        }
        variant={"p"}
        size={"14px"}
      />
    ),
    card_no: (
      <Typography
        text={pay?.payer_identifier ? pay?.payer_identifier?.slice(-4) : ""}
        variant={"p"}
        size={"14px"}
      />
    ),
    date: (
      <Flex>
        <Typography
          variant="span"
          text={
            pay?.create_date
              ? dayjs(pay?.create_date).format("DD.MM.YYYY,")
              : ""
          }
          size="14px"
        />
        <Typography
          variant="span"
          text={pay?.create_date ? dayjs(pay?.create_date).format("HH:ss") : ""}
          size="14px"
          color="#8E9BA7"
          margin={"0 0 0 5px"}
        />
      </Flex>
    ),
    amount: (
      <Typography
        text={pay?.request_amount || ""}
        variant={"p"}
        size={"14px"}
      />
    ),
    status: (
      <Typography
        text={"Payment " + pay?.status || ""}
        variant={"p"}
        size={"14px"}
      />
    ),
    description: (
      <Tooltip placement="bottom" title={"Approved: Payment post authorized"}>
        <Typography
          text={pay?.reject_reason || ""}
          variant={"p"}
          size={"14px"}
        />
      </Tooltip>
    ),
    approved: (
      <Typography
        text={pay?.transfer_amount || ""}
        variant={"p"}
        size={"14px"}
      />
    ),
    deposit: (
      <Typography
        text={+pay?.request_amount - +pay?.refund_amount || ""}
        variant={"p"}
        size={"14px"}
      />
    ),
    refunded: (
      <Typography
        text={(pay?.refund_amount || "") + " ₾"}
        variant={"p"}
        size={"14px"}
      />
    ),
    action: (
      <Tooltip title={"Refund to customer card"} placement="bottomRight">
        <RefundedIcon cursor={"pointer"} onClick={() => setRefund(pay)} />
      </Tooltip>
    ),
  }));

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: 200,
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
    },
    {
      title: "Full name",
      dataIndex: "full_name",
      key: "full_name",
      width: 250,
    },
    {
      title: "Card no.",
      dataIndex: "card_no",
      key: "card_no",
      width: 80,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Response state",
      dataIndex: "status",
      key: "status",
      width: 180,
    },
    {
      title: "Response description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
    },
    {
      title: "Deposit",
      dataIndex: "deposit",
      key: "deposit",
    },
    {
      title: "Refunded",
      dataIndex: "refunded",
      key: "refunded",
      width: 90,
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
      width: 50,
    },
  ];
  return (
    <>
      <div className={"table_wrapper"}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ y: 550 }}
          loading={isLoading}
        />
      </div>
      <Flex
        style={{ padding: "30px 0", width: "100%" }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <ButtonSecondary
          style={{ marginLeft: 10 }}
          text={"Excel"}
          icon={<DownloadIcon />}
          click={() => onlinePaymentApi.exportExelList(filterData)}
        />
        {/*list?.user_id*/}
        <Pagination
          showSizeChanger
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(current, pageSize) => {
            setFilterData({ ...filterData, page: current, per_page: pageSize });
          }}
          defaultPageSize={meta?.per_page || 15}
          defaultCurrent={meta?.current_page || 1}
        />
      </Flex>
    </>
  );
};
