import InfoIcon from "../../components/SvgComponents/InfoIcon.jsx";
import CloseIcon from "../../components/SvgComponents/CloseIcon.jsx";
import { Modal, Tag } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import styled from "styled-components";

const TedWrapper = styled.div`
  width: 100%;

  .tag {
    border: none;
    border-radius: 16px;
    padding: 2px 10px;
    background: #f5f5f5;
    color: #262626;
    font-size: 14px;
    font-family: "Montserrat arm2";
    margin-bottom: 10px;
  }
`;
export const ShowReasonModal = ({ open, setOpen = () => {} }) => {
  return (
    <Modal
      open={open}
      title={<InfoIcon />}
      footer={false}
      onCancel={() => setOpen(false)}
      closeIcon={<CloseIcon style={{ marginTop: 5 }} />}
      bodyStyle={{
        width: 450,
      }}
    >
      <Typography
        text={"Reasons"}
        variant={"h4"}
        size={"18px"}
        weight={"500"}
        color={"#262626"}
        margin={"0 0 16px 0"}
      />
      <TedWrapper>
        {!!open &&
          open?.map((not, i) => (
            <Tag className={"tag"} key={i}>
              {not}
            </Tag>
          ))}
      </TedWrapper>
    </Modal>
  );
};
