export default function RefoundIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.94336 11.5683C7.94336 12.3258 8.55746 12.9399 9.315 12.9399H10.5887C11.4003 12.9399 12.0583 12.2819 12.0583 11.4703C12.0583 10.6587 11.4003 10.0007 10.5887 10.0007H9.41297C8.60133 10.0007 7.94336 9.34272 7.94336 8.53107C7.94336 7.71942 8.60133 7.06146 9.41297 7.06146H10.6866C11.4442 7.06146 12.0583 7.67556 12.0583 8.4331M10.0008 6.17969V7.06146M10.0008 12.9399V13.8217"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4087 16.0629C16.2791 14.7002 17.4946 12.4929 17.4946 10.0016C17.4946 5.8626 14.1393 2.50725 10.0002 2.50725H9.5594M10.0002 17.496C5.86121 17.496 2.50586 14.1407 2.50586 10.0016C2.50586 7.51038 3.72142 5.30304 5.59178 3.94035M9.5594 18.8344L10.8819 17.4078L9.5594 16.0629M10.4635 3.94035L9.11855 2.59542L10.4635 1.16202"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
