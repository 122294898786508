import { useQuery } from "react-query";
import { tariffApi } from "../../services/tariffs";

export const useGetTariffList = () => {
  const { data, refetch } = useQuery("get-tariffs", tariffApi.getList, {
    staleTime: Infinity,
    select: ({ data }) => data?.data,
  });
  return { data: data || [], refetch };
};
