import styled from "styled-components";
import { useEffect, useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import ExpectedTopActions from "./TopActions";
import ExpectedTable from "./Table";
import ExpectedDetaildDrawer from "./DetaildDrawer";
import AddSmartService from "./AddSmartService";
import CreateExpectedDrawer from "./CreateExpected";
import useExpecteds from "../../hooks/expected/useExpecteds.hook.jsx";
import Loader from "../../components/moleculs/Loader";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";
import { useDeleteExpected } from "../../hooks/expected/useDeleteExpected.hook.jsx";
import DeleteModal from "../../components/modals/DeleteModal.jsx";
import InfoIcon from "../../components/SvgComponents/InfoIcon.jsx";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";

const StyledExpected = styled.div`
  padding-top: 24px;

  .create-expected {
    margin-top: 16px;
    margin-bottom: 24px;
    background: #5b6d7f;
    border-radius: 8px;
    padding: 8px 16px;
    color: white;
    border: none;
    outline: none;
  }

  .flag {
    margin-right: 8px;
  }
`;

export default function Expected({ permissions }) {
  const [status, setStatus] = useState("");
  const [deactivateModalStatus, setDeactivateModalStatus] = useState(false);
  const [smartServiceStatus, setSmartServiceStatus] = useState(false);
  const [createStatus, setCreateStatus] = useState(false);
  const [id, setId] = useState();
  const [singleExpected, setSingleExpected] = useState();
  const [filters, setFilters] = useState({ status: 1 });
  const { isLoading, data, refetch } = useExpecteds(filters);
  const [fullNameFromUrl, setFullNameFromUrl] = useState(null);
  const { mutate: deleteExpected } = useDeleteExpected(
    () => {
      setStatus("");
      refetch();
      openNotification("Deleted", "expected order deleted successfully");
    },
    (e) => {
      openNotificationError("Delete Order", e?.response?.data?.message);
      setStatus("");
    }
  );

  useEffect(() => {
    let search = window.location.search;
    if (search) {
      const searchKeys = search.split("=");
      setFullNameFromUrl(searchKeys[1]);
      setFilters({ user_info: decodeURI(searchKeys[1]) });
    }
  }, []);

  return (
    <StyledExpected>
      <div className="overlay" />
      <div className="page-title">
        <Typography
          text="Expected"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <ExpectedTopActions
        filters={filters}
        setFilters={setFilters}
        fullNameFromUrl={fullNameFromUrl}
      />
      {PermissionFinder(permissions, "expected-create") ? (
        <button
          type="button"
          className="create-expected"
          onClick={() => setCreateStatus(true)}
        >
          Create expected
        </button>
      ) : null}

      {data ? (
        <ExpectedTable
          setStatus={setStatus}
          setId={setId}
          setSingleExpected={setSingleExpected}
          data={data}
          isLoading={isLoading}
          filters={filters}
          setFilters={setFilters}
          permissions={permissions}
        />
      ) : (
        <Loader />
      )}
      <ExpectedDetaildDrawer
        status={status === "detail-drawer"}
        setStatus={setStatus}
        deactivateModalStatus={deactivateModalStatus}
        setDeactivateModalStatus={setDeactivateModalStatus}
        smartServiceStatus={smartServiceStatus}
        setSmartServiceStatus={setSmartServiceStatus}
        id={id}
        singleExpected={singleExpected}
        refetch={refetch}
      />
      <AddSmartService
        smartServiceStatus={smartServiceStatus}
        setSmartServiceStatus={setSmartServiceStatus}
        id={id}
      />
      <CreateExpectedDrawer
        createStatus={createStatus}
        setCreateStatus={setCreateStatus}
        refetch={refetch}
      />
      <DeleteModal
        status={status === "delete"}
        setStatus={setStatus}
        setDelete={() => deleteExpected(id)}
        description={"Are you sure that you want to delete the order"}
        title={"Delete Expected"}
        icon={<InfoIcon />}
      />
    </StyledExpected>
  );
}
