import { AutoComplete, Col, DatePicker, Form, Row } from "antd";
import { useState } from "react";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";
import dayjs from "dayjs";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import SearchIcon from "../../components/SvgComponents/SearchIcon.jsx";
import Flex from "../../components/general/Flex.jsx";

const { RangePicker } = DatePicker;

export default function OnlinePaymentTopActions({ setFilterData }) {
  const [form] = Form.useForm();
  const [userInfo, setUserInfo] = useState(null);
  const [selectId, setSelectId] = useState(null);
  const { usersList = [] } = usersAutocomplete(
    userInfo && userInfo?.user_info?.trim()?.length === 0 ? null : userInfo
  );
  const onFinish = (values) => {
    let newValues = {};
    Object.entries(values).forEach(([key, val]) => {
      if (val) {
        if (key === "date") {
          newValues["start_date"] = dayjs(val[0]).format("YYYY-MM-DD");
          newValues["end_date"] = dayjs(val[1]).format("YYYY-MM-DD");
        } else {
          newValues[key] = val;
        }
        if (selectId) {
          newValues["user_id"] = selectId;
        }
      }
    });
    setFilterData(newValues);
  };

  const onReset = () => {
    form.resetFields();
    setSelectId(null);
    setFilterData({});
  };
  return (
    <Form
      labelCol={8}
      wrapperCol={4}
      layout="vertical"
      onFinish={onFinish}
      form={form}
      className={"payment_search_action"}
    >
      <Row gutter={16} className="row">
        <Col lg={4}>
          <Form.Item name={"user_id"}>
            <AutoComplete
              placeholder="Name of user or GA"
              onSearch={(val, el) => setUserInfo({ user_info: val })}
              onSelect={(_, el) => setSelectId(el.user_id)}
              options={usersList}
              style={{ height: 36 }}
            />
          </Form.Item>
        </Col>
        <Col lg={16}>
          <Form.Item name="date">
            <RangePicker format={"DD.MM.YYYY"} />
          </Form.Item>
        </Col>

        <Col lg={4}>
          <Flex
            alignItems={"center"}
            justifyContent={"flex-end"}
            style={{ width: "100%" }}
          >
            <ButtonSecondary text="Reset" htmlType={"button"} click={onReset} />
            <ButtonPrimary
              text="Search"
              htmlType={"submit"}
              icon={<SearchIcon />}
              margin={"0 0 0 16px"}
            />
          </Flex>
        </Col>
      </Row>
    </Form>
  );
}
