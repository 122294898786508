import { useMutation, useQueryClient } from "react-query";
import { membersAndRoles } from "../../services/members-and-roles.js";

export const useAddNewMember = (onSuccess = () => {}, onError = () => {}) => {
  const client = useQueryClient();
  return useMutation(membersAndRoles.createNewMember, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["create-new-member"]);
    },
  });
};
