import { useQuery } from "react-query";
import { RefundAmount } from "../../services/orders.js";

export const useRefundAmount = (order) => {
  const { data: amount } = useQuery(
    ["refund_amount", order],
    () => RefundAmount(order?.id),
    {
      staleTime: Infinity,
      select: ({ data }) => data?.data?.amount,
      enabled: order?.status === "received",
    }
  );
  return { amount };
};
