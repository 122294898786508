export default function MoneyIcon() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00002 7.16667V10.5M15 5.5V8.83333M14.1667 1.33333C16.2073 1.33333 17.311 1.64563 17.8601 1.88787C17.9333 1.92013 17.9698 1.93626 18.0754 2.03698C18.1386 2.09735 18.2541 2.2745 18.2838 2.35674C18.3334 2.49395 18.3334 2.56895 18.3334 2.71895V11.676C18.3334 12.4333 18.3334 12.8119 18.2198 13.0065C18.1043 13.2045 17.9929 13.2966 17.7766 13.3727C17.5641 13.4475 17.135 13.365 16.2768 13.2001C15.6762 13.0847 14.9638 13 14.1667 13C11.6667 13 9.16669 14.6667 5.83335 14.6667C3.79276 14.6667 2.68908 14.3544 2.13992 14.1121C2.06679 14.0799 2.03022 14.0637 1.92469 13.963C1.86143 13.9027 1.74595 13.7255 1.71625 13.6433C1.66669 13.5061 1.66669 13.431 1.66669 13.281L1.66669 4.32405C1.66669 3.56674 1.66669 3.18809 1.78025 2.99345C1.89577 2.79547 2.00718 2.70343 2.2234 2.62734C2.43597 2.55254 2.86505 2.63499 3.72321 2.79987C4.32387 2.91528 5.03625 3 5.83335 3C8.33335 3 10.8334 1.33333 14.1667 1.33333ZM12.0834 8C12.0834 9.15059 11.1506 10.0833 10 10.0833C8.84943 10.0833 7.91669 9.15059 7.91669 8C7.91669 6.84941 8.84943 5.91667 10 5.91667C11.1506 5.91667 12.0834 6.84941 12.0834 8Z"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
