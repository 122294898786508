import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import UserInfo from "./UserInfo";
import CustomerSingleStatuses from "./Statuses";
import CustomerSingleTabs from "./Tabs";
import CustomerAlert from "./Alert";
import GetAccountDeails from "../../hooks/customers/useGetAccountDetails.hook.jsx";
import Loader from "../../components/moleculs/Loader";
import CustomersSingleSearch from "./Search";

const StyledSingleCustomer = styled.div`
  padding-top: 24px;
`;

export default function CustomerSingle({ permissions, clientLogin }) {
  const { id } = useParams();
  const { accountDeails } = GetAccountDeails(id);

  return (
    <StyledSingleCustomer>
      <div className="page-title">
        <Typography
          text={`Customers ${id || ""}`}
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <CustomersSingleSearch />
      <UserInfo clientLogin={clientLogin} />
      {accountDeails ? (
        !accountDeails?.recipient?.document_number && (
          <CustomerAlert type="passport" />
        )
      ) : (
        <Loader />
      )}
      {accountDeails?.current_blocked_account ? (
        <Alert
          type="error"
          showIcon
          message="This user is blocked"
          style={{ marginBottom: 16 }}
        />
      ) : null}
      <CustomerSingleStatuses />
      <CustomerSingleTabs permissions={permissions} />
    </StyledSingleCustomer>
  );
}
