import StoryItem from "./Item";
import styled from "styled-components";
import { useFetchLanguages } from "../../hooks/slides/useFetchLanguages.hook.js";
import { Tabs } from "antd";
import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import Loader from "../../components/moleculs/Loader";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useSortStories } from "../../hooks/stories/useSortStories.hook";
import { handleDragEnd } from "../../hooks/stories/dragDropStory";

const StyledStoriesTable = styled.div`
  margin-top: 25px;

  .ant-tabs-content-holder {
    max-height: 80vh;
    overflow: auto;
  }
`;

export default function StoryTable({
  onEdit,
  stories = [],
  refetch,
  handleDelete,
}) {
  const { languages } = useFetchLanguages();

  const [items, setItems] = useState([]);

  const { mutate } = useSortStories();
  const handleSort = (new_array) => {
    const new_order = new_array.map((item, index) => ({
      id: item.id,
      priority: index + 1,
    }));
    mutate(new_order);
  };
  useEffect(() => {
    if (stories) setItems(stories);
  }, [stories]);
  const dragDrop = (event) => {
    handleDragEnd(event, items, (new_array, active_index, over_index) => {
      handleSort(new_array);
      setItems(arrayMove(new_array, active_index, over_index));
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  return (
    <StyledStoriesTable>
      {languages ? (
        <Tabs
          style={{ marginBottom: 25 }}
          items={languages?.map((language, language_index) => ({
            key: language?.id,
            label: (
              <Flex alignItems={"center"}>
                <img src={language?.flag} alt="" />
                <Typography
                  variant={"p"}
                  text={language?.name}
                  color={"#3B5166"}
                  size={"14px"}
                  weight={"500"}
                  padding={"0 0 0 7px"}
                />
              </Flex>
            ),
            children: (
              <DndContext
                onDragEnd={dragDrop}
                modifiers={[restrictToVerticalAxis]}
                sensors={sensors}
              >
                <SortableContext
                  items={items}
                  strategy={verticalListSortingStrategy}
                >
                  {items?.map((item, index) => {
                    return (
                      <StoryItem
                        key={item.id}
                        id={item.id}
                        index={index}
                        handleDelete={handleDelete}
                        items={items}
                        sensors={sensors}
                        language_index={language_index}
                        item={item}
                        onEdit={() => {
                          onEdit(item.id);
                        }}
                        refetch={refetch}
                      />
                    );
                  })}
                </SortableContext>
              </DndContext>
            ),
          }))}
        />
      ) : (
        <Loader />
      )}
    </StyledStoriesTable>
  );
}
