import { useQuery } from "react-query";
import { blogApi } from "../../services/blog.js";

export const useFetchBlog = () => {
  const { isError, isLoading, refetch, data } = useQuery(
    "get-blogs",
    () => blogApi.fetchBlog(),
    {
      staleTime: Infinity,
      select: ({ data }) => {
        const blogs = data?.data?.data?.map((blog, i) => ({
          key: blog.id,
          value: blog.id || "",
          label: blog?.title || "",
          ...blog,
        }));
        return {
          data: data?.data,
          blogs,
        };
      },
    }
  );
  return { isLoading, blogs: data?.blogs, meta: data?.meta, refetch };
};
