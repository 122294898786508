import { Card, Checkbox, Col, Divider, Drawer, Form, Input, Row } from "antd";
import styled from "styled-components";
import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import { useState } from "react";
import { useRolesPermissions } from "../../hooks/members-and-roles/useRolesPermissions.hook.jsx";
import { useAddNewRole } from "../../hooks/members-and-roles/useAddNewRole.hook.js";
import { openNotification } from "../../helpers/notification.js";

const WrapperModal = styled.div`
  .form-card {
    background: #ffffff;
    border-radius: 12px;
    display: grid;
    grid-template-columns: auto;
    padding: 10px 16px;
    margin: 24px 0;
  }
  .ant-form-item-label {
    padding: 0 0 4px 0;
  }
  .ant-form-item {
    margin-bottom: 10px !important;
  }
  label {
    font-size: 14px;
    color: #3b5166 !important;
    :before {
      display: none !important;
    }
  }
  .selected-card {
    box-shadow: none;
    .ant-card-body {
      padding: 16px;
    }
  }
`;

export default function CreateRoleModal({ createRole, setCreateRole }) {
  const [indeterminate, setIndeterminate] = useState({});
  const [checkedList, setCheckedList] = useState([]);
  const { rolesPermissions = [] } = useRolesPermissions();
  const [form] = Form.useForm();
  const { mutate } = useAddNewRole(
    (data) => {
      openNotification("Create role", data?.data?.message);
      form.resetFields();
      setCheckedList([]);
      setIndeterminate({});
      setCreateRole(false);
    },
    (e) => {
      console.log(e);
    }
  );

  const onFinish = (values) => {
    let permissions = [];
    Object.entries(checkedList)?.forEach(([key, val]) => {
      val.forEach((item) => {
        permissions.push(item);
      });
    });

    mutate({ permissions, ...values });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => setCreateRole(false)}
      open={createRole}
      width="1112px"
      header={false}
    >
      <WrapperModal>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Typography
              variant={"h2"}
              text={"Create role"}
              weight={"500"}
              size={"22px"}
            />
            <span>
              <ButtonSecondary
                text="Cancel"
                background="#FFECED"
                color="#FC4447"
                border={"none"}
                htmlType="reset"
                click={() => setCreateRole(false)}
              />
              <ButtonPrimary
                text="Save"
                border={"none"}
                style={{ marginLeft: 16 }}
                htmlType="submit"
              />
            </span>
          </Flex>
          <div className={"form-card"}>
            <Form.Item
              name="name"
              rules={[{ required: true }]}
              label={"Role name"}
            >
              <Input placeholder={"Role name"} />
            </Form.Item>
          </div>
        </Form>
        {rolesPermissions?.map((item, i) => (
          <Card
            bordered={false}
            className={"selected-card"}
            key={i}
            style={{ marginBottom: 24 }}
          >
            <Flex alignItems={"center"}>
              <Checkbox
                checked={indeterminate[item?.title]}
                indeterminate={!indeterminate[item?.title]}
                onChange={() => {
                  setCheckedList({
                    ...checkedList,
                    [item?.title]: indeterminate[item?.title]
                      ? []
                      : item?.list?.map((el) => el.id),
                  });
                  setIndeterminate({
                    ...indeterminate,
                    [item?.title]: indeterminate[item?.title]
                      ? false
                      : item?.title,
                  });
                }}
              />
              <Typography
                variant={"p"}
                text={item?.title}
                size={"14px"}
                weight={"500"}
                color={"#3B5166"}
                margin={"0 0 0 8px"}
              />
            </Flex>
            <Divider style={{ margin: "8px 0 -8px 0" }} />
            <Checkbox.Group
              style={{
                width: "100%",
              }}
              value={checkedList[item?.title] || []}
              onChange={(val) =>
                setCheckedList({
                  ...checkedList,
                  [item?.title]: val,
                })
              }
            >
              <Row style={{ width: "100%" }}>
                {item?.list?.map((el, i) => (
                  <Col span={8} style={{ marginTop: 16 }} key={i}>
                    <Checkbox value={el?.id}>
                      <Typography
                        variant={"p"}
                        text={el?.name.replaceAll("-", " ")}
                        size={"14px"}
                      />
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Card>
        ))}
      </WrapperModal>
    </Drawer>
  );
}
