import { Pagination, Table } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import UserIcon from "../../components/SvgComponents/UserIcon";
import Flex from "../../components/general/Flex";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import PrimeIcon from "../../components/SvgComponents/primeIcon";
import CompanyIconInCustomer from "../../components/SvgComponents/CompanyIconInCustomer";
import Loader from "../../components/moleculs/Loader";
import OnexTooltip from "../../helpers/tooltip";
import dayjs from "dayjs";
import RecipientIcon from "../../components/SvgComponents/RecipientIcon";
import GeorgianIcon from "../../components/SvgComponents/Georgian";

const StyledTable = styled.div`
  margin-top: 24px;

  .ant-table-wrapper {
    max-height: 70vh;
    overflow: auto;
  }

  table {
    thead {
      display: none;
    }

    tr:hover {
      background: #f5f5f5 !important;
      cursor: pointer;
    }
  }

  .user-name-column {
    margin-left: 10px;
  }
`;

export default function CustomersTable({ recipients, meta, setQuery, query }) {
  const navigate = useNavigate();

  const handleClickCustomer = (id) => {
    navigate(`/customer/${id}`);
  };

  const columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "",
    },
    {
      key: "id",
      dataIndex: "id",
      title: "",
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "",
    },
    {
      key: "address",
      dataIndex: "address",
      title: "",
    },
    {
      key: "email",
      dataIndex: "email",
      title: "",
    },
    {
      key: "passportDetails",
      dataIndex: "passportDetails",
      title: "",
    },
    {
      key: "registeredDate",
      dataIndex: "registeredDate",
      title: "",
    },
  ];
  let dataSource = null;
  dataSource = recipients?.map((recipient, index) => ({
    key: index,
    name: (
      <Flex alignItems={"center"}>
        <div>
          {recipient.is_person === 1 && recipient.is_parent === 0 ? (
            <RecipientIcon />
          ) : recipient.is_person === 1 &&
            recipient.is_parent === 1 &&
            recipient?.is_resident === "1" ? (
            <GeorgianIcon />
          ) : !recipient?.is_person ? (
            <CompanyIconInCustomer />
          ) : (
            <UserIcon />
          )}
        </div>
        <Flex direction="column" className={"user-name-column"}>
          <Flex>
            <Typography
              text={
                recipient?.company_name && recipient?.company_name !== "null"
                  ? recipient.company_name
                  : recipient.first_name +
                    " " +
                    recipient.last_name +
                    " " +
                    recipient.user_code
              }
              color={primaryTextColor}
              size="14px"
              weight="500"
              variant="span"
            />
            {recipient.user.is_prime ? (
              <OnexTooltip content={"Prime"} direction={"bottom"}>
                <div style={{ paddingLeft: 5 }}>
                  <PrimeIcon />
                </div>
              </OnexTooltip>
            ) : null}
          </Flex>
          {recipient?.user?.recipient ? (
            <Typography
              text={`${recipient?.user?.recipient.first_name} ${recipient?.user?.recipient.last_name} ${recipient?.user?.recipient.user_code}`}
              color={primaryTextColor}
              size="14px"
              weight="400"
              variant="span"
            />
          ) : null}
        </Flex>
      </Flex>
    ),
    id: (
      <Flex direction="column">
        <Typography
          text="ID"
          color="#3B5166"
          size="14px"
          weight="500"
          variant="span"
          padding="0 0 12px 0"
        />
        <Typography
          text={recipient.user_id}
          color={primaryTextColor}
          size="14px"
          weight="400"
          variant="span"
        />
      </Flex>
    ),
    user_id: recipient.user_id,
    phone: (
      <Flex direction="column">
        <Typography
          text="Phone"
          color="#3B5166"
          size="14px"
          weight="500"
          variant="span"
          padding="0 0 12px 0"
        />
        <Typography
          text={recipient.phone}
          color={primaryTextColor}
          size="14px"
          weight="400"
          variant="span"
        />
      </Flex>
    ),
    address: (
      <Flex direction="column">
        <Typography
          text="Address"
          color="#3B5166"
          size="14px"
          weight="500"
          variant="span"
          padding="0 0 12px 0"
        />
        <Typography
          text={recipient.address}
          color={primaryTextColor}
          size="14px"
          weight="400"
          variant="span"
        />
      </Flex>
    ),
    email: (
      <Flex direction="column">
        <Typography
          text="Email"
          color="#3B5166"
          size="14px"
          weight="500"
          variant="span"
          padding="0 0 12px 0"
        />
        <Typography
          text={recipient.user.email}
          color={primaryTextColor}
          size="14px"
          weight="400"
          variant="span"
        />
      </Flex>
    ),
    passportDetails: (
      <Flex direction="column">
        <Typography
          text="Passport details"
          color="#3B5166"
          size="14px"
          weight="500"
          variant="span"
          padding="0 0 12px 0"
        />
        <Typography
          text={
            recipient.document_number
              ? recipient.document_number
              : "Not completed"
          }
          color={primaryTextColor}
          size="14px"
          weight="400"
          variant="span"
        />
      </Flex>
    ),
    registeredDate: (
      <>
        <Typography
          text="Registered At"
          color="#3B5166"
          size="14px"
          weight="500"
          variant="span"
          padding="0 0 12px 0"
        />
        <Typography
          text={dayjs(recipient.user.created_et).format("YYYY-MM-DD HH:mm")}
          color="#3B5166"
          size="14px"
          weight="400"
          variant="span"
          padding="0 0 12px 0"
        />
      </>
    ),
  }));

  return (
    <StyledTable>
      {recipients ? (
        <div>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                handleClickCustomer(record.user_id);
              },
            })}
          />
          <div className={"new-pagination"}>
            <Pagination
              total={meta?.total || 1}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              onChange={(page) => setQuery({ ...query, page })}
              defaultPageSize={meta?.per_page || 15}
              defaultCurrent={meta?.current_page || 1}
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </StyledTable>
  );
}
