import { useMutation, useQueryClient } from "react-query";
import { refillBalanceService } from "../../services/receive-orders.js";

export const useRefillBalance = (onSuccess = () => {}, onError = () => {}) => {
  const client = useQueryClient();
  return useMutation(refillBalanceService, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["refill-balance"]);
    },
  });
};
