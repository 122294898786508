import { useQuery } from "react-query";
import { GetExpecteds } from "../../services/expected.js";

export default function useExpecteds(filters) {
  const { data, refetch } = useQuery(
    ["expected", filters],
    () => GetExpecteds(filters),
    {
      staleTime: Infinity,
    }
  );
  return { data, refetch };
}
