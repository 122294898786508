import { useQuery } from "react-query";
import { consignmentApi } from "../../services/consignment.js";

export const useConsignmentById = (id) => {
  const {
    isError,
    isLoading,
    data: consignment,
    refetch: reFetchConsignment,
  } = useQuery(
    ["consignment_id", id],
    () => consignmentApi.fetchConsignmentById(id),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => data.data,
      enabled: !!id,
    }
  );
  return {
    isLoading,
    consignment,
    reFetchConsignment,
  };
};
