import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import FilterIcon from "../../components/SvgComponents/FilterIcon";

export default function NotificationsFilters({
  filterModalStatus,
  setFilterModalStatus,
}) {
  return (
    <div>
      <ButtonSecondary
        click={() => setFilterModalStatus(true)}
        icon={<FilterIcon />}
        text={"Filters"}
      />
    </div>
  );
}
