export const bannerInitialValue = {
  details: [
    {
      language_id: 1,
      text: "",
    },
    {
      language_id: "",
      text: "",
    },
    {
      language_id: "",
      text: "",
    },
  ],
};
