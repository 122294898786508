import axios from "../libs/axios.js";

export const GetRecipients = async (query) => {
  const data = axios.get(`/recipients`, {
    params: query,
  });
  return data;
};

export const UpdateRecipientService = async (id, data) => {
  const res = axios.put(`/recipients/${id}`, data);
  return res;
};
