import { useQuery } from "react-query";
import { GetWareHouses } from "../../services/warehouses.js";
import Flex from "../../components/general/Flex.jsx";

export default function useGetWarehouses() {
  return useQuery("get-warehouses", () => GetWareHouses(), {
    staleTime: Infinity,
    select: ({ data }) =>
      data.data.data?.map((whouse, i) => ({
        key: i,
        value: whouse.id,
        label: (
          <Flex className="whouse-select-item" alignItems="center">
            <img
              style={{ width: 20, marginRight: 5 }}
              src={whouse.round_flag}
              alt=""
            />
            <span>{whouse.country}</span>
          </Flex>
        ),
        ...whouse,
      })),
  });
}
