import styled from "styled-components";
import Flex from "../../../components/general/Flex";
import Typography from "../../../components/moleculs/Typography.jsx";
import BackArrowIcon from "../../../components/SvgComponents/BackArrowIcon";

const StyledFooter = styled.div`
  margin-top: 55px;
  .icon {
    //position: absolute;
    //left: 30px;
    //bottom: 30px;
  }
  .id {
    //position: absolute;
    //right: 30px;
    //bottom: 30px;
  }
  .time {
    padding-right: 16px;
    border-right: 1px solid #ddd;
  }
  .id-number {
    padding-left: 16px;
  }
`;

export default function OrderDetailsFooter({ id, setStatus }) {
  return (
    <StyledFooter>
      <Flex justifyContent="space-between">
        <div className="icon" onClick={() => setStatus(false)}>
          <BackArrowIcon />
        </div>
        <div className="id">
          <Flex>
            <div className="time">
              <Flex>
                <Typography
                  text="Updated by Name at "
                  variant="p"
                  color="#B3BBC4"
                  size="14px"
                  weight="400"
                />
                <Typography
                  text="2016-11-20, 12:04"
                  variant="p"
                  color="#8E9BA7"
                  size="14px"
                  weight="400"
                  padding="0 0 0 8px"
                />
              </Flex>
            </div>
            <div className="id-number">
              <Typography
                text={`Order ID ${id}`}
                variant="p"
                color="#B3BBC4"
                size="14px"
                weight="400"
              />
            </div>
          </Flex>
        </div>
      </Flex>
    </StyledFooter>
  );
}
