import { useQuery } from "react-query";
import { GetUsers } from "../../services/user.js";

export const testUsersAutocomplete = (query) => {
  const { isLoading, refetch, data } = useQuery(
    ["users-list-autocomplete", query],
    () => GetUsers(query),
    {
      staleTime: Infinity,
      select: ({ data }) => {
        const userList = data?.data?.data?.map((recipient, i) => {
          return {
            key: i,
            id: recipient?.id,
            label: `${recipient?.recipient?.first_name || ""} ${
              recipient?.recipient?.last_name || ""
            } ${recipient?.recipient?.company_name || ""} ${
              recipient?.recipient?.user_code
            }`,
            value: `${recipient?.recipient?.first_name || ""} ${
              recipient?.recipient?.last_name || ""
            } ${recipient?.recipient?.company_name || ""}`,
            ...recipient,
          };
        });
        const meta = data?.data?.meta;
        return { userList, meta };
      },
      enabled: !!query,
    }
  );
  return { isLoading, usersList: data?.userList, meta: data?.meta, refetch };
};
