import styled from "styled-components";

export const StyleOnlinePayment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 24px 0 0 0;

  & .payment_search_action {
    width: 100%;
    margin: 24px 0;
    padding: 16px 16px 0 16px;
    background: #ffffff;
    border-radius: 12px;
  }

  .table_wrapper {
    padding: 16px;
    height: 100%;
    border-radius: 12px;
    background: #ffffff;

    .ant-table-wrapper {
      width: 100%;
      border: 1px solid #e7e9ec;
      border-radius: 8px;
      height: 90%;
      overflow: hidden;

      .ant-table-thead > tr > th {
        font-size: 12px;
        font-weight: 400;
        color: #5b6d7f;
        background: #f5f5f5;
      }

      .ant-table-thead > tr > td {
        background: #f5f5f5;
      }

      .ant-table-cell:before {
        display: none;
      }

      .ellipsis_td {
        display: block;
        overflow: hidden;
        max-width: 300px;
        text-overflow: ellipsis;
        transition: all 0.2s;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: nowrap;
      }
    }
  }
`;
