import { useQuery } from "react-query";
import { GetAccountDetails } from "../../services/customers.js";

export default function GetAccountDeails(id) {
  const { data: accountDeails, refetch } = useQuery(
    ["get-account-details", id],
    () => GetAccountDetails(id),
    { staleTime: Infinity }
  );

  return { accountDeails, refetch };
}
