import { Form, Select } from "antd";
import Flex from "../../../components/general/Flex.jsx";
import useCategories from "../../../hooks/categories/useCategories.hook.jsx";
import useGetWarehouses from "../../../hooks/warehouses/useWarehouses.hook.jsx";

export const SelectParts = () => {
  const { categories = [] } = useCategories("shop");
  const { data: warehouses = [] } = useGetWarehouses();
  return (
    <Flex style={{ marginTop: 16 }} className={"style_form"}>
      <Form.Item
        style={{ width: "33%" }}
        name={"warehouse_id"}
        label={"Country"}
        rules={[
          {
            required: true,
            message: "Missing Country.",
          },
        ]}
      >
        <Select
          placeholder={"Select country"}
          size="large"
          options={warehouses || []}
        />
      </Form.Item>

      <Form.Item
        style={{ margin: "0 20px", width: "33%" }}
        name={"category_ids"}
        label={"Category"}
        rules={[
          {
            required: true,
            message: "Missing Category.",
          },
        ]}
      >
        <Select
          placeholder={"Select category"}
          size="large"
          options={categories || []}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item
        style={{ width: "33%" }}
        name={"price"}
        label={"Price"}
        rules={[
          {
            required: true,
            message: "Missing Price.",
          },
        ]}
      >
        <Select
          placeholder={"Select price"}
          size="large"
          options={[
            { value: 1, label: "$" },
            { value: 2, label: "$$" },
            { value: 3, label: "$$$" },
          ]}
        />
      </Form.Item>
    </Flex>
  );
};
