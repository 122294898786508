import { useQuery } from "react-query";
import { balanceApi } from "../../services/balance.js";

export const useBalancesList = (params) => {
  const { isError, isLoading, data, refetch } = useQuery(
    ["balances", params],
    () => balanceApi.fetchBalanceList(params),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        let sumIn = 0;
        let sumOut = 0;
        data?.data?.data?.forEach((el) => {
          if (el.type == "in" && el?.sum) {
            sumIn += Number(el.sum);
          }
          if (el.type == "out" && el?.sum) {
            sumOut += Number(el.sum);
          }
        });
        let balancesList = data?.data?.data;
        let meta = data?.data?.meta;
        return { balancesList, meta, sumIn, sumOut };
      },
    }
  );
  return {
    isLoading,
    balancesList: data?.balancesList,
    meta: data?.meta,
    sumIn: Math.round(data?.sumIn * 100) / 100,
    sumOut: Math.round(data?.sumOut * 100) / 100,
    refetch,
  };
};
