import { useQuery } from "react-query";
import { reportsApi } from "../../services/reports.js";

export const useGetReportsLis = (params) => {
  const { isError, isLoading, data, refetch } = useQuery(
    ["reports-list", params],
    () => reportsApi.fetchReportsList(params),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        return data.data;
      },
    }
  );
  return {
    isLoading,
    reportsList: data,
    meta: data?.meta,
    refetch,
  };
};
