import styled from "styled-components";
import { Link } from "react-router-dom";
import Flex from "../../components/general/Flex";
import iherbImg from "../../assets/images/iherb.png";
import UsFlag from "../../components/SvgComponents/UsFlag";
import FlyIcon from "../../components/SvgComponents/FlyIcon";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import PrimeIcon from "../../components/SvgComponents/primeIcon";

const StyledScanTable = styled.div`
  margin-top: 24px;
  width: 800px;
  height: 740px;
  overflow: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 16px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background: #d1dfec;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  .row-item {
    margin-bottom: 16px;
    border-bottom: 1px solid #e7e9ec;
    padding: 15px 0;
  }
`;

export default function ScanTable() {
  return (
    <StyledScanTable>
      <div className="row-item">
        <Link to="/scan/12">
          <Flex justifyContent="space-between">
            <div className="shop">
              <img src={iherbImg} alt="" />
            </div>
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              className="dispatch"
            >
              <Flex alignItems="center">
                <div style={{ marginRight: 5 }}>
                  <UsFlag />
                </div>
                <FlyIcon />
              </Flex>
              <Typography
                variant="p"
                text="UK 336"
                size="18px"
                weight="500"
                color={primaryTextColor}
                padding="4px 0 0 0"
              />
            </Flex>
            <div className="tracking">
              <Typography
                variant="p"
                text="420198049361289681015807602222229638"
                size="18px"
                weight="500"
                color={primaryTextColor}
                padding="0 0 12px 0"
              />
              <Flex alignItems="center">
                <Typography
                  variant="p"
                  text="Harutyun Abrahamyan ARM750350"
                  size="18px"
                  weight="400"
                  color={primaryTextColor}
                  padding="0 8px 0 0"
                />
                <PrimeIcon />
              </Flex>
            </div>
            <div className="weight">
              <Typography
                variant="p"
                text="0.4 kg"
                size="18px"
                weight="500"
                color="#5B6D7F"
                padding="12px 0 0 0"
              />
            </div>
          </Flex>
        </Link>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
      <div className="row-item">
        <Flex justifyContent="space-between">
          <div className="shop">
            <img src={iherbImg} alt="" />
          </div>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className="dispatch"
          >
            <Flex alignItems="center">
              <div style={{ marginRight: 5 }}>
                <UsFlag />
              </div>
              <FlyIcon />
            </Flex>
            <Typography
              variant="p"
              text="UK 336"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="4px 0 0 0"
            />
          </Flex>
          <div className="tracking">
            <Typography
              variant="p"
              text="420198049361289681015807602222229638"
              size="18px"
              weight="500"
              color={primaryTextColor}
              padding="0 0 12px 0"
            />
            <Flex alignItems="center">
              <Typography
                variant="p"
                text="Harutyun Abrahamyan ARM750350"
                size="18px"
                weight="400"
                color={primaryTextColor}
                padding="0 8px 0 0"
              />
              <PrimeIcon />
            </Flex>
          </div>
          <div className="weight">
            <Typography
              variant="p"
              text="0.4 kg"
              size="18px"
              weight="500"
              color="#5B6D7F"
              padding="12px 0 0 0"
            />
          </div>
        </Flex>
      </div>
    </StyledScanTable>
  );
}
