import ScannedIcon from "../../components/SvgComponents/ScannedIcon";
import ExpectedIcon from "../../components/SvgComponents/ExpectedIcon";
import WerHouseIcon from "../../components/SvgComponents/werhouse";
import OnTheWayIcon from "../../components/SvgComponents/OnTheWayIcon";
import InArmeniaIcon from "../../components/SvgComponents/InArmeniaIcon";
import ReceivedIcon from "../../components/SvgComponents/ReceivedIcon";
import { RefundIcon } from "../../components/SvgComponents/RefundIcon.jsx";

export default function OrderStatusIcon({ status }) {
  if (status === "scan") {
    return <ScannedIcon />;
  }
  if (status === "expected") {
    return <ExpectedIcon />;
  }
  if (status === "at_warehouse") {
    return <WerHouseIcon />;
  }
  if (status === "on_the_way") {
    return <OnTheWayIcon />;
  }
  if (status === "in_georgia") {
    return <InArmeniaIcon />;
  }
  if (status === "received") {
    return <ReceivedIcon />;
  }
  if (status === "refund") {
    return <RefundIcon />;
  }
}
