import { useMutation, useQueryClient } from "react-query";
import { useAuthCustomers } from "../../services/customers.js";

export const useMutationAuthCustomers = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const client = useQueryClient();
  return useMutation(useAuthCustomers, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["use-auth-customers"]);
    },
  });
};
