import { useQuery } from "react-query";
import { GetDashboardData } from "../../services/dashboard.js";

export default function useDashboardData(filters) {
  const {
    data: dashboardData,
    isLoading,
    refetch,
  } = useQuery(["dashboardData", filters], () => GetDashboardData(filters), {
    staleTime: Infinity,
    enabled: !!filters,
  });
  return { dashboardData, isLoading, refetch };
}
