import styled from "styled-components";
import { AutoComplete, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import Flex from "../../components/general/Flex";
import ShowLessIcon from "../../components/SvgComponents/ShowLessIcon";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import useGetWarehouses from "../../hooks/warehouses/useWarehouses.hook.jsx";
import Tag from "../../components/moleculs/Tag.jsx";
import useSmartServices from "../../hooks/smartServices/useSmartServices.hook.jsx";
import useCategories from "../../hooks/categories/useCategories.hook.jsx";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";

const { RangePicker } = DatePicker;
const StyledOrdersTopActions = styled.div`
  background: white;
  border-radius: 12px;
  padding: 16px;

  .fly-sea {
    span.ant-select-selection-placeholder {
      padding-top: 5px;
    }
  }

  .show-less {
    background: transparent;
    border: none;
    outline: none;
  }

  .actions-btns {
    display: flex;
    align-items: center;
    justify-content: end;

    button {
      margin: 0 0 0 8px;
    }
  }

  .row {
    height: ${({ more }) => (more ? "100%" : " 52px")};
    overflow: hidden;
  }

  .show-more-icon {
    svg {
      transform: ${({ more }) => (more ? "rotate(0)" : "rotate(180deg)")};
    }
  }
`;

export default function ExpectedTopActions({ setFilters, fullNameFromUrl }) {
  const [more, setMore] = useState(false);
  const [dates, setDates] = useState();
  const { data: warehouses, isLoading } = useGetWarehouses();
  const { smartServices = [] } = useSmartServices();
  const { categories } = useCategories();
  const [userInfo, setUserInfo] = useState(null);
  const { usersList = [] } = usersAutocomplete(
    userInfo && userInfo?.user_info?.trim()?.length === 0 ? null : userInfo
  );
  const [dispatch, setDispatch] = useState(null);
  const [form] = Form.useForm();
  const handleChangeDate = (values) => {
    setDates(values);
  };

  const FinishFilters = (values) => {
    const newParams = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        if (key === "without_invoice") {
          if (value === 0) {
            newParams[key] = true;
          } else {
            newParams[key] = false;
          }
        }
        if (key === "services") {
          if (value === "all") {
            newParams.services = [];
            smartServices?.map((smartService, index) => {
              newParams.services.push(smartService.id);
            });
          } else {
            newParams.services = [];
            newParams.services.push(value);
          }
        } else {
          newParams[key] = value;
        }
      }
    });
    if (dates && dates[0] !== "") {
      newParams.start_date = dates[0];
      newParams.end_date = dates[1];
    } else {
      delete newParams.start_date;
      delete newParams.end_date;
    }
    newParams.status = 1;
    setFilters(newParams);
  };

  const handleResetForm = () => {
    form.resetFields();
    setFilters({ status: 1 });
  };

  return (
    <StyledOrdersTopActions more={more}>
      <div>
        <Form
          form={form}
          labelCol={8}
          wrapperCol={4}
          layout="vertical"
          // initialValues={[
          //   {
          //     orderStatus: "status1",
          //     user_info: fullNameFromUrl ? fullNameFromUrl : "",
          //   },
          // ]}
          onFinish={FinishFilters}
        >
          <Row gutter={16} className="row">
            <Col lg={4}>
              <Form.Item name="user_info">
                <AutoComplete
                  placeholder="Full name or GA"
                  onSearch={(val) => setUserInfo({ user_info: val })}
                  options={usersList}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="tracking_code" rules={[{ min: 7 }]}>
                <Input placeholder="Tracking code" />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item>
                <RangePicker
                  onChange={(date, value) => handleChangeDate(value)}
                />
              </Form.Item>
            </Col>

            <Col lg={3}>
              <Form.Item name="warehouse_id">
                <Select
                  placeholder="All Warehouses"
                  style={{ height: 36 }}
                  options={warehouses}
                  onSelect={(_, el) => {
                    setDispatch(el?.dispatches);
                    form.setFieldValue("dispatch_type", null);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={2} className="fly-sea">
              <Form.Item name="dispatch_type">
                <Select style={{ height: 36 }}>
                  {dispatch?.map((item, index) => (
                    <Select.Option value={item.type} key={index}>
                      <div style={{ paddingTop: 2 }}>
                        <Flex alignItems={"center"}>
                          <img src={item?.icon} alt="dispatch" width={24} />
                          <span style={{ paddingLeft: 5 }}>{item.name}</span>
                        </Flex>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="is_person">
                <Select placeholder="User Type" style={{ height: 36 }}>
                  <Select.Option value="1">Person</Select.Option>
                  <Select.Option value="0">Company</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="without_invoice">
                <Select placeholder="Invoice" style={{ height: 36 }}>
                  <Select.Option value="0">Has Invoice</Select.Option>
                  <Select.Option value="1">Missing Invoice</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col lg={3}>
              <Form.Item name="category_id">
                <Select placeholder="Select category" style={{ height: 36 }}>
                  {categories?.map((category, index) => (
                    <Select.Option value={category.id} key={index}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="services">
                <Select
                  style={{ height: 36 }}
                  placeholder="Service type"
                  options={[{ value: "all", label: "All" }, ...smartServices]}
                />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="status">
                <Select placeholder="Order condition" style={{ height: 36 }}>
                  <Select.Option value="1">
                    <Tag
                      background="#ECFAF1"
                      color="#32A35E"
                      text="Active"
                      margin="2px 0 0 0"
                    />
                  </Select.Option>
                  <Select.Option value="0">
                    <Tag
                      background="#FFF5EB"
                      color="#E58C35"
                      text="Deactivated"
                      margin="2px 0 0 0"
                    />
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className="actions-btns">
            <button
              className="show-less"
              type="button"
              onClick={() => setMore(!more)}
            >
              <Flex alignItems="center">
                <div className="show-more-icon">
                  <ShowLessIcon />
                </div>
                <Typography
                  variant="span"
                  text={more ? "Show less" : "Show more"}
                  color="rgba(91, 109, 127, 1)"
                  padding="0 0 0 15px"
                />
              </Flex>
            </button>
            <ButtonSecondary
              text="Reset"
              htmlType="button"
              click={() => handleResetForm()}
            />
            <ButtonPrimary
              text="Search"
              icon={<SearchIcon />}
              htmlType={"submit"}
            />
          </div>
        </Form>
      </div>
    </StyledOrdersTopActions>
  );
}
