import styled from "styled-components";
import { useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import ConsignmentTopActions from "./TopActions";
import ConsignmentTable from "./Table";
import ConsignmentModal from "./DetailsModal";
import { useConsignmentById } from "../../hooks/consignment/useConsignmentById.hook.jsx";
import { useConsignmentList } from "../../hooks/consignment/useConsignment.hook.jsx";

const StyledConsignment = styled.div`
  padding-top: 24px;
`;

export default function Consignment({ permissions }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [id, setId] = useState(null);
  const {
    consignment,
    isLoading: loadingById,
    reFetchConsignment,
  } = useConsignmentById(id);
  const { consignmentList, meta, isLoading, reFetchList } =
    useConsignmentList(filterData);
  return (
    <StyledConsignment>
      <div className="page-title">
        <Typography
          text="Consignment"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <ConsignmentTopActions setFilterData={setFilterData} />
      <ConsignmentTable
        consignmentList={consignmentList}
        meta={meta}
        isLoading={isLoading}
        setModalOpen={setModalOpen}
        setId={setId}
        params={filterData}
        setFilterData={setFilterData}
        permissions={permissions}
      />
      <ConsignmentModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        consignments={consignment}
        loading={loadingById}
        reFetchConsignment={reFetchConsignment}
        reFetchList={reFetchList}
        permissions={permissions}
      />
    </StyledConsignment>
  );
}
