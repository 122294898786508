import axios from "../libs/axios.js";

export const onlinePaymentApi = {
  async fetchOnlinePaymentList(params) {
    return await axios.get("/bog-transactions/get-list", {
      params: { ...params },
    });
  },
  async refundPayment({ id, ...data }) {
    return await axios.post(`/bog-transactions/${id}/refund`, data);
  },
  async exportExelList(params) {
    return await axios
      .get(`/bog-transactions/export`, {
        headers: {
          "Content-Type": "application/vnd.ms-excel",
          Accept: "application/vnd.ms-excel",
        },
        responseType: "blob",
        method: "GET",
        params: { ...params },
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "online-payment.xls");
        document.body.appendChild(link);
        link.click();
      });
  },
};
