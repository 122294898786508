import styled from "styled-components";
import { Drawer } from "antd";
import { useState } from "react";
import OrdersDetailsHeader from "./Components/DetailsHeader";
import OrdersDetailsComment from "./Components/Comment";
import OrderDetailStatuses from "./Components/DetailsStatuses";
import OrderDetailsCosts from "./Components/DetailsCosts";
import OrderDetailsInfo from "./Components/DetailsInfo";
import OrderDetailsCustomer from "./Components/DetailsCustomer";
import OrderDetailsFooter from "./Components/DetailFooter";
import OrderDetailsEdit from "./Components/Edit";
import useShowOrder from "../../hooks/orders/useShowOrder.hook";
import Loader from "../../components/moleculs/Loader";
import OrderDetailsSmartService from "./Components/DetailsSmartService.jsx";

const DetailsModal = styled.div`
  //width: 80%;
  //height: 100vh;
  //position: fixed;
  //top: 0;
  //right: -100%;
  padding: 24px;
  z-index: 9999;
  transition: right 400ms;

  &.active {
    right: 0;
  }

  .flag {
    margin-right: 10px;
  }

  .dispatch {
    margin-right: 15px;
  }

  .description {
    display: flex;
    margin-left: 95px;
  }

  .info-block {
    background: white;
    padding: 16px;
    border-radius: 12px;
    width: calc(50% - 40px);
    margin-bottom: 16px;

    &-item {
      padding-right: 50px;

      &:not(:first-child) {
        border-left: 1px solid #e7e9ec;
        padding-left: 10px;
      }
    }
  }

  .order-details-statuses {
    background: white;
    padding: 20px;
    border-radius: 12px;

    &__status {
      margin-right: 50px;
      padding-right: 10px;
      position: relative;

      &:not(:last-child) {
        &:after {
          position: absolute;
          content: ".............";
          top: 60%;
          transform: translateY(-50%);
          right: 0;
          width: 2px;
          color: #e7e9ec;
          height: 100%;
        }
      }
    }
  }
`;

export default function OrdersDetailsModal({
  status,
  setStatus,
  id,
  refetchOrders,
  permissions,
}) {
  const [editStatus, setEditStatus] = useState(false);
  const { order, refetch } = useShowOrder(id);
  return (
    //   {/* <OrderDetailsEdit />
    <Drawer
      title=""
      placement="right"
      onClose={() => setStatus(false)}
      open={status}
      width="80%"
      header={false}
    >
      {order ? (
        <DetailsModal>
          {!editStatus ? (
            <>
              <OrdersDetailsHeader
                refetch={refetch}
                refetchOrders={refetchOrders}
                setEditStatus={setEditStatus}
                order={order.order}
                category={order.order.category}
                permissions={permissions}
              />
              {order.order.customer_comment ? (
                <OrdersDetailsComment
                  comment={order.order.customer_comment}
                  category={order.order.category}
                />
              ) : null}

              <OrderDetailsInfo order={order.order} />
              {order?.order?.histories.length ? (
                <OrderDetailStatuses histories={order.order.histories} />
              ) : null}
              <OrderDetailsCosts order={order.order} />
              <OrderDetailsCustomer
                order={order.order}
                refetch={refetch}
                refetchOrders={refetchOrders}
                permissions={permissions}
              />
              {!!order?.order?.service && (
                <OrderDetailsSmartService service={order?.order?.service} />
              )}
              {/* <OrderComments /> */}
              <OrderDetailsFooter id={order.order.id} setStatus={setStatus} />
            </>
          ) : (
            <>
              <OrderDetailsEdit
                setEditStatus={setEditStatus}
                order={order.order}
                refetch={refetch}
                refetchOrders={refetchOrders}
              />
              <OrderDetailsFooter order={order} />
            </>
          )}
        </DetailsModal>
      ) : (
        <Loader />
      )}
    </Drawer>
  );
}
