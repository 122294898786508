export default function HomeDelivery() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6666 3.83333H13.6143C13.8182 3.83333 13.9201 3.83333 14.016 3.85635C14.101 3.87677 14.1823 3.91044 14.2569 3.95613C14.341 4.00766 14.413 4.07973 14.5572 4.22385L17.9428 7.60947C18.0869 7.7536 18.159 7.82566 18.2105 7.90976C18.2562 7.98432 18.2899 8.0656 18.3103 8.15063C18.3333 8.24654 18.3333 8.34845 18.3333 8.55228V10.9167C18.3333 11.3049 18.3333 11.4991 18.2699 11.6522C18.1853 11.8564 18.0231 12.0186 17.8189 12.1032C17.6657 12.1667 17.4716 12.1667 17.0833 12.1667M12.9166 12.1667H11.6666M11.6666 12.1667V3.99999C11.6666 3.06657 11.6666 2.59986 11.485 2.24334C11.3252 1.92974 11.0702 1.67477 10.7566 1.51498C10.4001 1.33333 9.93338 1.33333 8.99996 1.33333H4.33329C3.39987 1.33333 2.93316 1.33333 2.57664 1.51498C2.26304 1.67477 2.00807 1.92974 1.84828 2.24334C1.66663 2.59986 1.66663 3.06657 1.66663 3.99999V10.5C1.66663 11.4205 2.41282 12.1667 3.33329 12.1667M11.6666 12.1667H8.33329M8.33329 12.1667C8.33329 13.5474 7.214 14.6667 5.83329 14.6667C4.45258 14.6667 3.33329 13.5474 3.33329 12.1667M8.33329 12.1667C8.33329 10.7859 7.214 9.66666 5.83329 9.66666C4.45258 9.66666 3.33329 10.7859 3.33329 12.1667M17.0833 12.5833C17.0833 13.7339 16.1506 14.6667 15 14.6667C13.8494 14.6667 12.9166 13.7339 12.9166 12.5833C12.9166 11.4327 13.8494 10.5 15 10.5C16.1506 10.5 17.0833 11.4327 17.0833 12.5833Z"
        stroke="#8E9BA7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
