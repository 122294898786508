import axios from "../libs/axios";

export const GetExpecteds = async (filters) => {
  const { data } = await axios.get(
    "/order-expected",
    filters && {
      params: filters,
    }
  );
  return data;
};

export const GetSingleExpected = async (id) => {
  const { data } = await axios.get(`/order-expected/${id}`);
  return data.data;
};

export const AddSmartServiceService = async (id, serviceId) => {
  const data = await axios.post(`/order-expected/${id}/add-smart-service`, {
    service_id: serviceId,
  });
  return data;
};

export const CreateExpected = async (data) => {
  const res = await axios.post("/order-expected", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const DeleteExpected = async (id) => {
  return await axios.delete(`/order-expected/${id}`);
};
export const DeleteSmartService = async (id) => {
  const res = await axios.post(`/order-expected/${id}/delete-smart-service`);
  return res;
};
