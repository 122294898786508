import { AutoComplete, Drawer, Form, Input, notification, Select } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import Flex from "../../components/general/Flex";
import ButtonCancel from "../../components/moleculs/ButtonCancel";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import FileIcon from "../../components/SvgComponents/FileIcon.jsx";
import useGetWarehouses from "../../hooks/warehouses/useWarehouses.hook.jsx";
import useCategories from "../../hooks/categories/useCategories.hook.jsx";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";
import BackArrowIcon from "../../components/SvgComponents/BackArrowIcon.jsx";
import useGetCurrencies from "../../hooks/useGetCurrencies.hooks.jsx";
import { useCreateExpected } from "../../hooks/expected/useCreateExpected.hook.js";

const StyledCreateExpected = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .input-file-invoice {
    padding: 1px 11px;

    .ant-form-item {
      margin-bottom: 0 !important;
      cursor: pointer;
    }
  }

  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: "";
  }

  .close-drawer {
    position: absolute;
    bottom: 20px;
  }

  .ant-select-single {
    height: 36px;
  }

  .input-with-select {
    display: grid;
    grid-template-columns: 80% auto;
    place-items: center;
    max-width: 150px;

    > div:last-child {
      align-self: flex-end;
    }
  }

  .whouse-select-item {
    img {
      margin-top: 0 !important;
    }
  }
`;

const orderTypes = [
  {
    key: 0,
    title: "Online shop",
    value: "online_shopping",
  },
  {
    key: 1,
    title: "Personal items",
    value: "personal_items",
  },
];

export default function CreateExpectedDrawer({
  createStatus,
  setCreateStatus,
  refetch,
}) {
  const [form] = Form.useForm();

  const { data: warehouses = [] } = useGetWarehouses();
  const { categories } = useCategories();
  const [userInfo, setUserInfo] = useState(null);
  const { usersList = [] } = usersAutocomplete(userInfo);
  const [userId, setUserId] = useState();
  const [selectedValue, setSelectedValue] = useState(null);
  const [invoice, setInvoice] = useState();
  const { currencies } = useGetCurrencies();
  const [api, contextHolder] = notification.useNotification();
  const [dispatches, setDispatches] = useState([]);
  const [orderType, setOrderType] = useState(null);
  const handleCancel = (ref) => {
    setCreateStatus(false);
    form.resetFields();
    setInvoice();
    if (ref) refetch();
  };
  const { mutate } = useCreateExpected(
    () => {
      handleCancel(true);
      setOrderType(null);
    },
    ({ response }) => {
      if (response?.data?.data) {
        Object.entries(response?.data?.data).map((err) => {
          api.error({
            message: `Error!`,
            description: err[1][0],
            placement: "topLeft",
          });
        });
      } else {
        api.error({
          message: `Error!`,
          description: "Something wen wrong",
          placement: "topLeft",
        });
      }
    }
  );

  const finishForm = (values) => {
    if (values) {
      const formData = {
        user_id: userId?.user?.id,
        recipient_id: userId?.id,
        warehouse_id: values?.warehouse_id,
        dispatch_id: values?.dispatch_id,
        tracking_code: values?.tracking_code,
        price: values?.price,
        category_id: values?.category_id,
        invoice: invoice,
        currency: values?.currency,
        shop_name: values?.shop_name,
        order_type: values?.order_type,
      };
      mutate(formData);
    }
  };

  const handleUploadInvoice = (e) => {
    const file = e.target.files[0];
    setInvoice(file);
  };
  useEffect(() => {
    if (form.getFieldValue("dispatch_id") && form.getFieldValue("warehouse_id"))
      form.validateFields(["dispatch_id"], false);
  }, [form.getFieldValue("dispatch_id"), form.getFieldValue("warehouse_id")]);

  useEffect(() => {
    const selectedUser = usersList?.find(
      (user) => user.id === selectedValue.key
    );
    setUserId(selectedUser);
  }, [selectedValue]);

  return (
    <Drawer
      title=""
      placement="right"
      onClose={handleCancel}
      open={createStatus}
      width="840px"
      header={false}
    >
      {contextHolder}
      <StyledCreateExpected>
        <Form
          name="createExpected"
          layout="vertical"
          onFinish={finishForm}
          form={form}
        >
          <div className="header">
            <Typography
              text="Create expected order"
              variant="p"
              color={primaryTextColor}
              size="22px"
              weight="500"
              padding={"0 0 16px 0"}
            />
            <Flex alignItems={"center"}>
              <Form.Item>
                <ButtonCancel
                  text="Cancel"
                  margin="0 16px 0 0"
                  click={handleCancel}
                  type="button"
                />
              </Form.Item>
              <Form.Item>
                <ButtonPrimary
                  text="Create"
                  click={() => finishForm()}
                  // click={() => setCreateStatus(false)}
                  htmlType="submit"
                />
              </Form.Item>
            </Flex>
          </div>
          <Form.Item
            name="user_id"
            rules={[{ required: true, message: "Required" }]}
            label="Full name or GA"
          >
            <AutoComplete
              placeholder="Full name or GA"
              onSearch={(val) => setUserInfo({ user_info: val })}
              onSelect={(val, option) => setSelectedValue(option)}
              options={usersList.map((user) => ({
                key: user.id,
                value:
                  user.first_name + " " + user.last_name + " " + user.user_code,
              }))}
            />
          </Form.Item>
          <Flex className="mt-16">
            <div className="mr-16">
              <Form.Item
                name={"warehouse_id"}
                label={"Warehouse"}
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  placeholder={"Warehouse"}
                  // onChange={handleChangeWhouse}
                  onSelect={(_, val) => {
                    form.setFieldValue("dispatch_id", val?.dispatches[0]?.id);
                    setDispatches(val?.dispatches);
                  }}
                  style={{ width: 160 }}
                  options={warehouses}
                />
              </Form.Item>
            </div>
            <div className="mr-16">
              <Form.Item
                name={"dispatch_id"}
                label={"Dispatch"}
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  style={{ width: 100 }}
                  placeholder={"Dispatch"}
                  disabled={!dispatches.length}
                >
                  {dispatches?.length
                    ? dispatches.map((dispatch, index) => (
                        <Select.Option value={dispatch.id} key={index}>
                          <div style={{ paddingTop: 2 }}>
                            <Flex alignItems={"center"}>
                              <img
                                width={24}
                                alt={"dispatch"}
                                src={dispatch.icon}
                              />
                              <span
                                style={{
                                  paddingLeft: 5,
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {dispatch.name}
                              </span>
                            </Flex>
                          </div>
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="tracking_code"
                label="Tracking"
                rules={[
                  { required: true, message: "Required" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("tracking_code").length < 9) {
                        return Promise.reject(
                          "The field must contain at least 9 characters"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder={"Tracking number"}
                />
              </Form.Item>
            </div>
            <div className={"ml-16"}>
              <div className="input-with-select">
                <Form.Item
                  name={"price"}
                  label={"Declared price"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input type="number" placeholder={"Price"} />
                </Form.Item>
                <Form.Item
                  name={"currency"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Select
                    defaultValue="Cur"
                    style={{ display: "flex", width: 70 }}
                  >
                    {currencies &&
                      Object.values(currencies).map((item) => {
                        return (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Flex>
          <Flex>
            <div>
              <Form.Item
                name={"order_type"}
                label={"Product type"}
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  placeholder="Product type"
                  style={{ width: 227 }}
                  onChange={(val) => {
                    setOrderType(val);
                    form.setFieldValue("shop_name", "");
                  }}
                >
                  {orderTypes?.map((type, index) => (
                    <Select.Option value={type?.value} key={index}>
                      {type.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="ml-16">
              <Form.Item name={"category_id"} label={"Category"}>
                <Select placeholder="Category" style={{ width: 227 }}>
                  {categories?.map((category, index) => (
                    <Select.Option value={category.id} key={index}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {orderType ? (
              <div className="ml-16">
                <Form.Item
                  name={"shop_name"}
                  label={
                    orderType === "online_shopping"
                      ? "Shop name"
                      : "Sender name"
                  }
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                    orderType === "online_shopping"
                      ? {
                          pattern:
                            /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
                          message: "Enter a valid URL",
                        }
                      : "",
                  ]}
                >
                  <Input
                    placeholder={
                      orderType === "online_shopping"
                        ? "Shop name"
                        : "Sender name"
                    }
                  />
                </Form.Item>
              </div>
            ) : null}
          </Flex>
          <div className="mt-16">
            <Form.Item name={"note"} label={"Product name"}>
              <Input style={{ width: "100%" }} placeholder={"Product name"} />
            </Form.Item>
          </div>
          <div>
            <Typography
              text="Invoice"
              variant="p"
              size="14px"
              weight="500"
              color="#3B5166"
              padding="0 0 4px 0"
            />
            <div className="input-file-invoice">
              <Form.Item name={"invoice"}>
                <input
                  type="file"
                  id={"invoice"}
                  style={{ display: "none" }}
                  onChange={(e) => handleUploadInvoice(e)}
                />
                <label htmlFor="invoice">
                  <Flex alignItems="center">
                    <FileIcon />
                    <Typography
                      text={invoice?.name ? invoice.name : "Attach Invoice"}
                      variant="p"
                      size="14px"
                      weight="400"
                      color="#8E9BA7"
                      padding={"0 0 0 7px"}
                    />
                  </Flex>
                </label>
              </Form.Item>
            </div>
          </div>
        </Form>
        <div className="close-drawer" onClick={() => setCreateStatus(false)}>
          <BackArrowIcon />
        </div>
      </StyledCreateExpected>
    </Drawer>
  );
}
