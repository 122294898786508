import Flex from "../../components/general/Flex";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon";
import Typography from "../../components/moleculs/Typography.jsx";
import styled from "styled-components";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import { useState } from "react";
import * as XLSX from "xlsx";
import { Modal, Table } from "antd";

import InfoIcon from "../../components/SvgComponents/InfoIcon.jsx";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledPreOrderTopActions = styled.div`
  margin: 16px 0;

  .import-excel {
    background: transparent;
    border: 1px dashed #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 34px;
    margin: 0 16px;
  }

  .added_info {
    background: #fff5eb;
    padding: 16px;
    margin-top: 16px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 600;
    color: #e58c35;
  }
`;

export default function ExelFileActions({
  reFetch = () => {},
  isEmpty,
  setStatus = () => {},
  permissions,
}) {
  const [createOrder, setCreateOrder] = useState(false);
  const [previewStatus, setPreviewStatus] = useState(false);
  const [previewDataSource, setPreviewDataSource] = useState([]);
  const [sendData, setSendData] = useState(null);
  const [showAlert, setShowAlert] = useState(0);
  let timeout;
  let second = 60;

  function handleTimedCount() {
    second--;
    setShowAlert(second);
    timeout = setTimeout(handleTimedCount, 1000);
    if (second <= 0) {
      clearTimeout(timeout);
      second = 60;
      reFetch();
    }
  }

  // const { mutate } = useImportPreOrder(
  //   () => {
  //     handleTimedCount();
  //     setSendData(null);
  //     setPreviewStatus(false);
  //     setPreviewDataSource([]);
  //   },
  //   (e) => console.log(e)
  // );
  const downloadExampleExel = () => {
    console.log("downloaded");
  };
  const handleUploadExcel = (e) => {
    setPreviewStatus(true);
    const reader = new FileReader();
    const formData = new FormData();
    formData.append("manifest", e.target.files[0]);
    setSendData(formData);
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      let filteredData = parsedData.reduce((acc, item, index) => {
        if (item?.receiver_first_name) {
          acc.push({
            nameSurname: item?.receiver_full_name,
            phone: item?.receiver_phone,
            email: item?.receiver_email,
            town: item?.receiver_town,
            address: item?.receiver_address,
            comment: item?.receiver_comment,
          });
        }
        return acc;
      }, []);
      setPreviewDataSource(filteredData);
    };
  };

  const previewColumns = [
    {
      key: "nameSurname",
      dataIndex: "nameSurname",
      title: "Name surname patronymic",
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "Phone",
    },
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
    },
    {
      key: "town",
      dataIndex: "town",
      title: "Town",
    },
    {
      key: "address",
      dataIndex: "address",
      title: "Address",
    },
    {
      key: "comment",
      dataIndex: "comment",
      title: "Comment",
    },
  ];

  const handleImportExcel = async () => {
    // mutate(sendData);
  };

  return (
    <StyledPreOrderTopActions>
      <Flex alignItems={"center"} justifyContent={"flex-end"}>
        <Flex
          alignItems={"center"}
          style={{ cursor: "pointer" }}
          onClick={downloadExampleExel}
        >
          <DownloadIcon color={"#4F96D9"} />
          <Typography
            text={"Download example"}
            variant={"span"}
            color={"#4F96D9"}
            size={"14px"}
            weight={"400"}
            padding={"0 0 0 5px"}
          />
        </Flex>

        <label htmlFor="excel" className={"import-excel"}>
          <Flex alignItems={"center"}>
            <DownloadIcon />
            <Typography
              text={"Import Excel"}
              variant={"span"}
              color={"#5B6D7F"}
              size={"14px"}
              weight={"400"}
              padding={"0 0 0 5px"}
            />
          </Flex>
          <input
            type="file"
            id={"excel"}
            value={""}
            style={{ display: "none" }}
            onChange={(e) => handleUploadExcel(e)}
          />
        </label>

        {PermissionFinder(permissions, "bonus-create") ? (
          <ButtonSecondary
            color={"white"}
            background={"#5B6D7F"}
            text={"Create bonus"}
            click={() => setStatus(true)}
          />
        ) : null}
      </Flex>
      {!!showAlert && (
        <Flex className={"added_info"} alignItems={"center"}>
          <InfoIcon style={{ marginRight: 6 }} />
          Your uploads will be added around {showAlert || "0"} seconds
        </Flex>
      )}
      <Modal
        open={previewStatus}
        title={"Orders preview"}
        width={1000}
        onCancel={() => setPreviewStatus(false)}
        onOk={() => handleImportExcel()}
      >
        <Table
          columns={previewColumns}
          dataSource={previewDataSource}
          pagination={false}
        />
      </Modal>
    </StyledPreOrderTopActions>
  );
}
