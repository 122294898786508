import { useMutation, useQueryClient } from "react-query";
import { updatePrimeUser } from "../services/user.js";

export const updatePrimeUserStatusHook = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const client = useQueryClient();
  return useMutation(updatePrimeUser, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["delete-expected"]);
    },
  });
};
