import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import BonusTopActions from "./TopActions";
import BonusTable from "./Table";
import { useState } from "react";
import { NotFound } from "./Not-Found.jsx";
import { isEmpty } from "lodash";
import ExelFileActions from "./ExelFileActions.jsx";
import CreateBonusModal from "./CreateBonusModal.jsx";
import { useGetBonusLis } from "../../hooks/bonus/useGetBonusList.hook.jsx";
import Loader from "../../components/moleculs/Loader.jsx";

const StyledBalance = styled.div`
  padding-top: 24px;
`;

export default function Bonus({ permissions }) {
  const [filterData, setFilterData] = useState({});
  const [create, setCreate] = useState(false);
  const isFiltered = isEmpty(filterData);
  const {
    bonusList,
    meta,
    isLoading,
    sumIn = 0,
    sumOut = 0,
    refetch,
  } = useGetBonusLis(filterData);
  return (
    <StyledBalance>
      <div className="page-title">
        <Typography
          text="Bonus"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      {isFiltered && !bonusList?.length > 0 ? (
        <NotFound isFiltered={isFiltered} />
      ) : (
        <>
          <BonusTopActions setFilterData={setFilterData} />
          <ExelFileActions
            setStatus={setCreate}
            reFetch={refetch}
            permissions={permissions}
          />
          {isLoading ? (
            <Loader />
          ) : !isFiltered && !bonusList?.length > 0 ? (
            <NotFound isFiltered={isFiltered} />
          ) : (
            <BonusTable
              params={filterData}
              setFilterData={setFilterData}
              bonusList={bonusList}
              meta={meta}
              isLoading={isLoading}
              totalSum={sumIn - sumOut}
            />
          )}
        </>
      )}
      <CreateBonusModal
        status={create}
        setStatus={setCreate}
        reFetch={refetch}
      />
    </StyledBalance>
  );
}
