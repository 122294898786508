import Modal from "../../../components/modals/Modal.jsx";
import InfoIcon from "../../../components/SvgComponents/InfoIcon.jsx";
import Typography from "../../../components/moleculs/Typography.jsx";
import Flex from "../../../components/general/Flex.jsx";
import ButtonSecondary from "../../../components/moleculs/ButtonSecondary.jsx";
import { useRefund } from "../../../hooks/orders/useRefund.hook.jsx";
import { openNotification } from "../../../helpers/notification.js";
import { useRefundAmount } from "../../../hooks/orders/useRefundAmount.hook.js";

export const RefundModal = ({
  status,
  setStatus = () => {},
  order,
  refetch,
  refetchOrders,
}) => {
  const { mutate } = useRefund(
    () => {
      refetch();
      refetchOrders();
      setStatus(false);
      openNotification("Success", "Refund successfully");
    },
    () => {
      openNotification.error("Error", "Something went wrong");
    }
  );
  const { amount } = useRefundAmount(order);

  const handleRefund = () => {
    mutate({ id: order?.id });
  };

  return (
    <Modal
      icon={<InfoIcon />}
      width={"450"}
      status={status}
      setStatus={setStatus}
    >
      <Typography
        text={"Refund"}
        variant={"h4"}
        size={"18px"}
        weight={"500"}
        color={"#262626"}
        margin={"0 0 16px 0"}
      />
      <Typography
        text={
          order?.status === "received"
            ? `The system will refund ${amount} Gel to the customer's Onex balance`
            : "Are you sure you want to refund?"
        }
        variant={"h4"}
        size={"14px"}
        weight={"400"}
        color={"#5B6D7F"}
        margin={"0 0 16px 0"}
      />
      <Flex justifyContent={"space-between"} className={"mt-24"}>
        <ButtonSecondary
          padding={"10px 16px"}
          text={"Cancel"}
          jm
          width={"48%"}
          click={() => setStatus(false)}
        />
        <ButtonSecondary
          padding={"10px 16px"}
          text={"Refund"}
          width={"48%"}
          background={"#5dba2f"}
          color={"white"}
          click={() => handleRefund()}
          type="button"
        />
      </Flex>
    </Modal>
  );
};
