import styled from "styled-components";
import { DatePicker, Form, Input, Pagination, Table } from "antd";
import { useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import InputIcon from "../../components/SvgComponents/InputIcon.jsx";
import { primaryTextColor, redTextColor } from "../../constants.js";
import Flex from "../../components/general/Flex.jsx";
import IconButton from "../../components/moleculs/IconButton";
import EditIcon from "../../components/SvgComponents/EditIcon";
import CancelIcon from "../../components/SvgComponents/CancelIcon";
import AcceptIcon from "../../components/SvgComponents/AcceptIcon";
import OutputIcon from "../../components/SvgComponents/OutputIcon.jsx";
import dayjs from "dayjs";
import Loader from "../../components/moleculs/Loader.jsx";
import { useMutation } from "react-query";
import { openNotification } from "../../helpers/notification.js";
import { balanceApi } from "../../services/balance.js";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon.jsx";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledBalanceTable = styled.div`
  margin-top: 24px;

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 12px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 12px;
  }

  .table-footer {
    width: calc(100% - 2px);
    background: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 1px solid #e7e9ec;
    border-left: 1px solid #e7e9ec;
    border-right: 1px solid #e7e9ec;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;

    &_footer {
      border-top: 1px solid #f5f5f5;
      width: 100%;
      display: flex;
      padding: 16px;
      justify-content: end;
    }
  }

  .ant-table {
    border-top: 1px solid #e7e9ec;
    border-left: 1px solid #e7e9ec;
    border-right: 1px solid #e7e9ec;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &
    :where(.css-dev-only-do-not-override-1ipetx6).ant-table-wrapper
    .ant-table-tbody
    > tr
    > th {
    background: #e7e9ec;
  }

  .ant-table-tbody {
    height: 60vh;
  }

  .footer-item {
    margin-right: 16px;
  }

  .download-excel {
    margin-top: 16px;
  }
`;

export default function BalanceTable({
  params,
  balancesList,
  meta,
  isLoading,
  refetch,
  sumIn = 0,
  sumOut = 0,
  setFilterData,
  permissions,
}) {
  const [form] = Form.useForm();
  const [editable, setEditable] = useState(false);

  const { mutate } = useMutation(balanceApi.updateBalance, {
    onSuccess: (data) => {
      openNotification("Balance", "Balance information updated successfully");
      setEditable(-1);
      refetch();
    },
  });
  const onShowSizeChange = (current, pageSize) => {
    setFilterData({ ...params, page: current, per_page: pageSize });
  };
  const handleSave = async (id) => {
    const { created_at, ...row } = await form.validateFields();
    mutate({
      id: id,
      created_at: created_at
        ? dayjs(created_at).format("YYYY-MM-DD HH:ss")
        : "",
      ...row,
    });
  };
  const edit = (el) => {
    form.setFieldsValue({
      sum: el?.sum,
      created_at: el?.created_at ? dayjs(el?.created_at) : null,
      comment: el?.comment,
    });
    setEditable(el?.id);
  };
  const columns = [
    {
      key: "orderId",
      dataIndex: "orderId",
      title: "Order ID",
      width: "100px",
    },
    {
      key: "changedBy",
      dataIndex: "changedBy",
      title: "Customer",
      width: "20%",
    },
    {
      key: "inout",
      dataIndex: "inout",
      title: "In/Out",
      width: "80px",
    },
    {
      key: "type",
      dataIndex: "type",
      title: "Type",
      width: "12%",
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "Amount",
      width: "12%",
      rowScope: "row",
    },
    {
      key: "date",
      dataIndex: "date",
      title: "Date",
      rowScope: "row",
      width: "15%",
    },
    {
      key: "comment",
      dataIndex: "comment",
      title: "Comment",
      width: "22%",
      rowScope: "row",
    },
    {
      key: "edit",
      dataIndex: "edit",
      title: "",
      width: "120px",
      rowScope: "row",
    },
  ];

  const dataSource = balancesList?.map((list, i) => ({
    id: 1,
    key: i,
    orderId: (
      <Typography
        variant="span"
        text={list?.id}
        size="14px"
        weight="400"
        color="#5DBA2F"
      />
    ),
    changedBy: (
      <Typography
        variant="span"
        text={
          list?.user?.full_name + " " + list?.user?.recipient?.user_code || ""
        }
        size="14px"
        weight="400"
        color={primaryTextColor}
      />
    ),
    inout: list?.type === "in" ? <InputIcon /> : <OutputIcon />,
    type: (
      <Typography
        variant="span"
        transform={"capitalize"}
        text={
          list?.balance_payment_type?.name ||
          list?.balance_transfer_type?.name ||
          ""
        }
        size="14px"
        weight="400"
        color={primaryTextColor}
      />
    ),
    amount:
      editable === list?.id ? (
        <Form.Item
          name={"sum"}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <Input suffix="₾" />
        </Form.Item>
      ) : (
        <Typography
          variant="span"
          text={
            list?.sum && list?.type === "in"
              ? list?.sum + " ₾"
              : -list?.sum + " ₾"
          }
          size="14px"
          weight="500"
          color={list?.type === "in" ? primaryTextColor : redTextColor}
        />
      ),
    date:
      editable === list?.id ? (
        <Form.Item
          name={"created_at"}
          rules={[{ required: true, message: "Please select date" }]}
        >
          <DatePicker
            style={{ width: 200 }}
            format={"DD.MM.YYYY"}
            inputReadOnly={true}
          />
        </Form.Item>
      ) : (
        <Flex>
          <Typography
            variant="span"
            text={
              list?.created_at
                ? dayjs(list?.created_at).format("DD.MM.YYYY,")
                : ""
            }
            size="14px"
            weight="400"
            color={primaryTextColor}
          />
          <Typography
            variant="span"
            text={
              list?.created_at ? dayjs(list?.created_at).format("HH:ss") : ""
            }
            size="14px"
            weight="400"
            color="#8E9BA7"
            style={{ marginLeft: "5px" }}
          />
        </Flex>
      ),
    comment:
      editable === list?.id ? (
        <Form.Item
          name={"comment"}
          rules={[{ required: true, message: "Field is required" }]}
        >
          <Input />
        </Form.Item>
      ) : (
        <Typography
          variant="p"
          size="14px"
          weight="400"
          color={primaryTextColor}
          text={list?.comment || ""}
        />
      ),
    edit:
      editable === list?.id ? (
        <Flex>
          <div
            className="item"
            style={{ marginRight: 8 }}
            onClick={() => setEditable(-1)}
          >
            <CancelIcon />
          </div>
          <div className="item" onClick={() => handleSave(list?.id)}>
            <AcceptIcon />
          </div>
        </Flex>
      ) : (
        <Flex justifyContent={"flex-end"}>
          {PermissionFinder(permissions, "balance-edit") ? (
            <IconButton icon={<EditIcon />} click={() => edit(list)} />
          ) : null}
        </Flex>
      ),
  }));

  if (isLoading) return <Loader />;
  return (
    <StyledBalanceTable>
      <Form form={form} component={false}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ y: "60vh" }}
        />
      </Form>
      <div className="table-footer">
        <div className="table-footer_footer">
          <Flex>
            <div className="footer-item">
              <Flex>
                <Typography
                  variant="span"
                  text="In"
                  color="#5B6D7F"
                  size="14px"
                  weight={500}
                />
                <Typography
                  variant="span"
                  text={sumIn?.toFixed(2)}
                  color="#8E9BA7"
                  size="14px"
                  weight={400}
                  padding="0 0 0 8px"
                />
              </Flex>
            </div>
            <div className="footer-item">
              <Flex>
                <Typography
                  variant="span"
                  text="Out"
                  color="#5B6D7F"
                  size="14px"
                  weight={500}
                />
                <Typography
                  variant="span"
                  text={-sumOut?.toFixed(2)}
                  color="#8E9BA7"
                  size="14px"
                  weight={400}
                  padding="0 0 0 8px"
                />
              </Flex>
            </div>
            <div className="footer-item">
              <Flex>
                <Typography
                  variant="span"
                  text="Balance"
                  color="#5B6D7F"
                  size="14px"
                  weight={500}
                />
                <Typography
                  variant="span"
                  text={`${(sumIn - sumOut)?.toFixed(2)} ₾`}
                  color="#8E9BA7"
                  size="14px"
                  weight={400}
                  padding="0 0 0 8px"
                />
              </Flex>
            </div>
          </Flex>
        </div>
      </div>
      <Flex
        style={{ padding: "30px 0" }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <ButtonSecondary
          style={{ marginLeft: 10 }}
          text={params?.user_id ? "Excel" : "Excel All"}
          icon={<DownloadIcon />}
          click={() => balanceApi.exportExelList(params?.user_id || null)}
        />
        {/*list?.user_id*/}
        <Pagination
          showSizeChanger
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={onShowSizeChange}
          defaultPageSize={meta?.per_page || 15}
          defaultCurrent={meta?.current_page || 1}
        />
      </Flex>
    </StyledBalanceTable>
  );
}
