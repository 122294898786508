import { useQuery } from "react-query";
import { GetRecipients } from "../services/recipients.js";

export const usersAutocomplete = (query) => {
  const { isError, isLoading, refetch, data } = useQuery(
    ["users-list-autocomplete", query],
    () => GetRecipients(query),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        const userList = data?.data?.data?.map((recipient, i) => {
          return {
            key: i,
            id: recipient?.id,
            label: `${recipient.first_name || ""} ${
              recipient.last_name || ""
            } ${recipient.company_name || ""} ${recipient.user_code}`,
            value: `${recipient.first_name || ""} ${
              recipient.last_name || ""
            } ${recipient.company_name || ""}`,
            ...recipient,
          };
        });
        const meta = data?.data?.meta;
        return { userList, meta };
      },
      enabled: !!query,
    }
  );
  return { isLoading, usersList: data?.userList, meta: data?.meta, refetch };
};
