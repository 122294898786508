import { useQuery } from "react-query";
import { bannersApi } from "../../services/banners.js";

export const useFetchBanners = (params) => {
  const { isError, isLoading, refetch, data } = useQuery(
    ["get-banners", params],
    () => bannersApi.fetchBanners(params),
    {
      staleTime: Infinity,
      select: ({ data }) => {
        return data?.data;
      },
    }
  );
  return { isLoading, banners: data?.data, meta: data?.meta, refetch };
};
