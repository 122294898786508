import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Flex from "../../components/general/Flex";
import OnexTooltip from "../../helpers/tooltip";
import check from "../../assets/images/check.svg";
import Typography from "../../components/moleculs/Typography";
import { primaryTextColor } from "../../constants";
import dayjs from "dayjs";
import { PermissionFinder } from "../../hooks/permissionFinder.hook";
import IconButton from "../../components/moleculs/IconButton";
import EditIcon from "../../components/SvgComponents/EditIcon";
import styled from "styled-components";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import grid_dots from "../../assets/images/dots-grid.svg";
import { tariffApi } from "../../services/tariffs";
import { openNotification } from "../../helpers/notification.js";
import Loader from "../../components/moleculs/Loader";

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: "move",
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

const TariffTable = ({ permissions, onEdit = () => {}, data = [] }) => {
  const [dataSource, setDataSource] = useState([]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        const sorted = arrayMove(prev, activeIndex, overIndex);
        tariffApi.sortTariffs(sorted).then((res) => {
          openNotification(res?.data?.message, "Sort tariffs");
        });
        return sorted;
      });
    }
  };
  useEffect(() => {
    setDataSource(
      data?.data?.map((item) => ({
        ...item,
        id: item.id,
        key: item.id,
        country: (
          <Flex alignItems={"center"}>
            <div style={{ marginRight: 8 }}>
              <img src={grid_dots} alt="" />
            </div>
            <OnexTooltip content={"Warehouse"} direction={"bottom"}>
              <img src={item?.warehouse?.round_flag || ""} alt="" />
            </OnexTooltip>
            <div style={{ paddingLeft: 8 }} id="dispatch">
              <img src={item?.dispatch?.icon || ""} alt="" />
            </div>
          </Flex>
        ),
        v_weight: item.v_weight ? <img src={check} /> : "-",
        delivery_time: `${item?.dispatch?.estimated_day_from}-${item?.dispatch?.estimated_day_to} working days`,
        routing: (
          <Flex>
            <Typography
              variant="span"
              text={item.rounding}
              size="14px"
              weight="400"
              color={primaryTextColor}
            />
            <Typography
              variant="span"
              text="GR"
              size="14px"
              weight="400"
              color="#8E9BA7"
              style={{ marginLeft: "5px" }}
            />
          </Flex>
        ),
        started_at: (
          <Typography
            variant="span"
            text={
              item?.start_at ? dayjs(item?.start_at).format("DD.MM.YYYY") : ""
            }
            size="14px"
            weight="400"
            color={primaryTextColor}
          />
        ),
        ended_at: (
          <Typography
            variant="span"
            text={item?.end_at ? dayjs(item?.end_at).format("DD.MM.YYYY") : "-"}
            size="14px"
            weight="400"
            color={primaryTextColor}
          />
        ),
        actions: (
          <Flex justifyContent={"flex-end"}>
            {PermissionFinder(permissions["tariff"], "tariff-edit") ? (
              <IconButton icon={<EditIcon />} click={() => onEdit(item)} />
            ) : null}
          </Flex>
        ),
      }))
    );
  }, [data]);
  const columns = [
    { dataIndex: "country", title: "Country", dataKey: "country" },
    {
      dataIndex: "delivery_time",
      title: "Delivery time",
      dataKey: "delivery_time",
    },
    { dataIndex: "cost", title: "Cost", dataKey: "cost" },
    { dataIndex: "min_cost", title: "Min cost", dataKey: "min_cost" },
    {
      dataIndex: "v_weight_cost",
      title: "V weight cost",
      dataKey: "v_weight_cost",
    },
    { dataIndex: "v_weight", title: "V weight", dataKey: "v_weight" },
    { dataIndex: "routing", title: "Routing", dataKey: "routing" },
    { dataIndex: "started_at", title: "Started at", dataKey: "started_at" },
    { dataIndex: "ended_at", title: "Ended at", dataKey: "ended_at" },
    { dataIndex: "actions", title: " ", dataKey: "actions" },
  ];

  return (
    <TableWrapper>
      {data?.data?.length ? (
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={dataSource?.map((i) => i.id) || []}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              columns={columns || []}
              pagination={false}
              dataSource={dataSource || []}
            />
          </SortableContext>
        </DndContext>
      ) : (
        <Loader />
      )}
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  padding: 0 0 32px 0;

  .ant-table-thead > tr > th {
    background: #f5f5f5;
  }

  .new-pagination {
    margin-top: 24px;
  }
`;

export default TariffTable;
