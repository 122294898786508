import { useQuery } from "react-query";
import { GetDashboardChartsData } from "../../services/dashboard.js";

export default function useGetDashboardChartsData(filters) {
  const {
    data: dashboardCharts,
    isLoading,
    refetch,
  } = useQuery(
    ["dashboardCharts", filters],
    () => GetDashboardChartsData(filters),
    {
      staleTime: Infinity,
      // enabled: !!filters,
      select: ({ data }) => {
        return data?.data;
      },
    }
  );
  return { dashboardCharts, isLoading, refetch };
}
