import styled from "styled-components";
import { Col, Row } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import EyeIcon from "../../components/SvgComponents/EyeIcon";
import Flex from "../../components/general/Flex";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon";
import { useState } from "react";
import ReportModal from "./ReportModal.jsx";
import { reportsApi } from "../../services/reports.js";

const StyledReportsData = styled.div`
  .total-card {
    background: #ffffff;
    border: 1.5px solid #f5f5f5;
    border-radius: 12px;
    padding: 24px;
    height: 80px;
  }

  .data-row {
    margin-bottom: 16px;
  }
`;

export default function ReportsData({ reportsList = [], params }) {
  const [reportsModal, setReportsModal] = useState(false);
  const [load, setLoad] = useState("");
  const reportsInfo = {};
  reportsList?.forEach((el) => {
    reportsInfo[el?.key] = el?.value;
  });
  const handleExportExcel = (type) => {
    setLoad(type);
    reportsApi
      .exportReportsByTypeExcel({ type: type, ...params })
      .then((res) => setLoad(""))
      .catch(() => setLoad(""));
  };
  const handleExportReceiveExcel = (type) => {
    setLoad(type);
    reportsApi
      .exportReceiveReportsExcel({  ...params })
      .then((res) => setLoad(""))
      .catch(() => setLoad(""));
  };
  return (
    <StyledReportsData>
      <div className="data-row">
        <Typography
          variant={"p"}
          size={"16px"}
          weight={"600"}
          text={"Bonus and cashback"}
          color={"#5B6D7F"}
          padding={"0 0 12px 0"}
          xsPadding={"10px 0 10px 0"}
        />
        <Row gutter={16}>
          <Col lg={8}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.bonus_in || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />

                    <EyeIcon onClick={() => setReportsModal("bonus_in")} />
                  </Flex>
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Bonus In"}
                    color={"#5B6D7F"}
                    padding={"15px 0 18px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                </div>
                <ButtonSecondary
                  text={load === "bonus" ? "Load..." : "Download"}
                  width={"130px"}
                  disabled={load === "bonus"}
                  icon={<DownloadIcon />}
                  click={() => handleExportExcel("bonus")}
                />
              </Flex>
            </div>
          </Col>
          <Col lg={8}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.bonus_out || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />
                    {/*<EyeIcon onClick={() => setReportsModal("bonus_out")} />*/}
                  </Flex>

                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Bonus Out"}
                    color={"#5B6D7F"}
                    padding={"15px 0 18px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                </div>
              </Flex>
            </div>
          </Col>
          <Col lg={8}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.bonus_remain || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />
                  </Flex>
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Bonus remain"}
                    color={"#5B6D7F"}
                    padding={"15px 0 18px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                </div>
              </Flex>
            </div>
          </Col>
        </Row>
      </div>
      <div className="data-row">
        <Typography
          variant={"p"}
          size={"16px"}
          weight={"600"}
          text={"Order Received and Balance"}
          color={"#5B6D7F"}
          padding={"0 0 12px 0"}
          xsPadding={"10px 0 10px 0"}
        />
        <Row gutter={24}>
          <Col lg={6}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.balance_in || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />
                    <EyeIcon onClick={() => setReportsModal("balance_in")} />
                  </Flex>
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Balance in"}
                    color={"#5B6D7F"}
                    padding={"15px 0 18px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                </div>
                <ButtonSecondary
                  text={load === "balance" ? "Load..." : "Download"}
                  width={"130px"}
                  disabled={load === "balance"}
                  icon={<DownloadIcon />}
                  click={() => handleExportExcel("balance")}
                />
              </Flex>
            </div>
          </Col>
          <Col lg={6}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.balance_out || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />
                    <EyeIcon onClick={() => setReportsModal("balance_out")} />
                  </Flex>
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Balance out"}
                    color={"#5B6D7F"}
                    padding={"15px 0 18px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                </div>
                {/*<ButtonSecondary*/}
                {/*  text={"Download"}*/}
                {/*  icon={<DownloadIcon />}*/}
                {/*  click={() => handleExportExcel("balance_out")}*/}
                {/*/>*/}
              </Flex>
            </div>
          </Col>
          <Col lg={6}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.balance_at_the_moment || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />
                  </Flex>
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Balance at the moment"}
                    color={"#5B6D7F"}
                    padding={"15px 0 18px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                </div>
                {/*<ButtonSecondary*/}
                {/*  text={"Download"}*/}
                {/*  icon={<DownloadIcon />}*/}
                {/*  click={() => handleExportExcel("balance_at_the_moment")}*/}
                {/*/>*/}
              </Flex>
            </div>
          </Col>
          <Col lg={6}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.order_received_total_cost || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />
                    <EyeIcon onClick={() => setReportsModal("receive_order")} />
                  </Flex>
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Orders received"}
                    color={"#5B6D7F"}
                    padding={"15px 0 18px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                </div>
                <ButtonSecondary
                    disabled={load === "receive_order"}
                    text={load === "receive_order" ? "Load..." : "Download"}
                  icon={<DownloadIcon />}
                  click={() => handleExportReceiveExcel("receive_order")}
                />
              </Flex>
            </div>
          </Col>
        </Row>
      </div>
      <div className="data-row">
        <Typography
          variant={"p"}
          size={"16px"}
          weight={"600"}
          text={"Services"}
          color={"#5B6D7F"}
          padding={"0 0 12px 0"}
          xsPadding={"10px 0 10px 0"}
        />
        <Row gutter={24}>
          <Col lg={6}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.bfm_total || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />
                    <EyeIcon onClick={() => setReportsModal("bfm")} />
                  </Flex>
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"BFM commission"}
                    color={"#5B6D7F"}
                    padding={"15px 0 18px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                </div>
                <ButtonSecondary
                  text={load === "bfm" ? "Load..." : "Download"}
                  width={"130px"}
                  disabled={load === "bfm"}
                  icon={<DownloadIcon />}
                  click={() => handleExportExcel("bfm")}
                />
              </Flex>
            </div>
          </Col>
          <Col lg={6}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.declaration_service_fee || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />
                  </Flex>
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Declaration Service fee"}
                    color={"#5B6D7F"}
                    padding={"15px 0 3px 0"}
                    xsPadding={"10px 0 3px 0"}
                  />
                </div>
                <ButtonSecondary
                  text={
                    load === "declaration_service_fee" ? "Load..." : "Download"
                  }
                  width={"130px"}
                  disabled={load === "declaration_service_fee"}
                  icon={<DownloadIcon />}
                  click={() => handleExportExcel("declaration_service_fee")}
                />
              </Flex>
            </div>
          </Col>
          <Col lg={6}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.home_delivery_cost || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />
                    <EyeIcon onClick={() => setReportsModal("home_delivery")} />
                  </Flex>
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Home Delivery cost"}
                    color={"#5B6D7F"}
                    padding={"15px 0 3px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />
                  {/*<Typography*/}
                  {/*  variant={"p"}*/}
                  {/*  size={"14px"}*/}
                  {/*  weight={"400"}*/}
                  {/*  text={"Tbilisi"}*/}
                  {/*  color={"#4F96D9"}*/}
                  {/*/>*/}
                </div>
                <ButtonSecondary
                  text={load === "home_delivery" ? "Load..." : "Download"}
                  width={"130px"}
                  disabled={load === "home_delivery"}
                  icon={<DownloadIcon />}
                  click={() => handleExportExcel("home_delivery")}
                />
              </Flex>
            </div>
          </Col>
          <Col lg={6}>
            <div className="total-card">
              <Flex justifyContent={"space-between"}>
                <div>
                  <Flex alignItems={"center"}>
                    <Typography
                      variant={"h5"}
                      text={reportsInfo?.smart_service_cost || 0}
                      color={"#000"}
                      size={"22px"}
                      weight={"500"}
                      xsSize={"36px"}
                      margin={"0 8px 0 0"}
                    />
                    <EyeIcon onClick={() => setReportsModal("smart_service")} />
                  </Flex>
                  <Typography
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={"Smart Services"}
                    color={"#5B6D7F"}
                    padding={"15px 0 3px 0"}
                    xsPadding={"10px 0 10px 0"}
                  />

                  {/*<Typography*/}
                  {/*  variant={"p"}*/}
                  {/*  size={"14px"}*/}
                  {/*  weight={"400"}*/}
                  {/*  text={"25 request"}*/}
                  {/*  color={"#4F96D9"}*/}
                  {/*/>*/}
                </div>
                <ButtonSecondary
                  cursor={"no-drop"}
                  text={load === "smart_service" ? "Load..." : "Download"}
                  width={"130px"}
                  disabled={load === "smart_service"}
                  icon={<DownloadIcon />}
                  click={() => handleExportExcel("smart_service")}
                />
              </Flex>
            </div>
          </Col>
        </Row>
      </div>
      <ReportModal
        setOpen={setReportsModal}
        open={reportsModal}
        params={params}
      />
    </StyledReportsData>
  );
}
