import axios from "../libs/axios.js";

export const GetOrdersService = async (filters) => {
  return await axios.get(
    "/orders",
    filters && {
      params: filters,
    }
  );
};

export const ShowSingleOrder = async (id) => {
  const data = await axios.get(`/orders/${id}`);
  return data;
};

export const ChangeUserService = async (id, data) => {
  const res = await axios.post(`/orders/${id}/change-user`, data);
  return res;
};

export const EditOrderService = async (id, data) => {
  return await axios.put(`/orders/${id}`, data);
};
export const SendToHubOrder = async (data) => {
  return await axios.post(`/orders/send-to-hub`, data);
};

export const RefundOrder = async (data) => {
  return await axios.post(`/orders/${data?.id}/refund`);
};

export const GetOrdersFailedService = async (params) => {
  return await axios.get("/hub-failed-orders", { params: { ...params } });
};

export const GetLostOrdersService = async (params) => {
  return await axios.get("/lost-orders", { params });
};

export const RefundAmount = async (id) => {
  return await axios.get(`/orders/${id}/refund-amount`);
};
