import { useQuery } from "react-query";
import { getCurrenciesList } from "../services/currencies.js";

export default function useGetCurrencies() {
  const { data: currencies } = useQuery(
    "get-currencies",
    () => getCurrenciesList(),
    {
      staleTime: Infinity,
      select: ({ data }) => data.data,
    }
  );

  return { currencies };
}
