import { Form, Input, Tooltip } from "antd";
import InfoIcon from "../../../components/SvgComponents/InfoIcon.jsx";
import UploadImages from "../../../components/moleculs/UploadImages.jsx";
import Flex from "../../../components/general/Flex.jsx";

export const MainParts = ({ imageUrl, setImageUrl = () => {} }) => {
  return (
    <div className={"style_form"}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <Form.Item
          name={"logo"}
          rules={[
            {
              required: true,
              message: "Missing Images.",
            },
          ]}
          label={
            <div
              style={{
                display: "flex",
                padding: "16px 0",
                marginRight: 56,
              }}
            >
              Image web
              <Tooltip
                overlayInnerStyle={{ background: "#0A2540" }}
                placement="right"
                title={
                  <span>
                    Dimensions <br />
                    <span style={{ fontWeight: 700 }}>200 × 200px</span> SVG
                  </span>
                }
              >
                <InfoIcon color={"#5B6D7F"} style={{ margin: "0 0 0 4px" }} />
              </Tooltip>
            </div>
          }
        >
          <UploadImages
            imageUrl={imageUrl ? imageUrl["web"] : ""}
            setImageUrl={(img) => setImageUrl({ ...imageUrl, ["web"]: img })}
          />
        </Form.Item>
        <Form.Item
          name={"mobile_logo"}
          rules={[
            {
              required: true,
              message: "Missing Images.",
            },
          ]}
          label={
            <div style={{ display: "flex", padding: "16px 0" }}>
              Image mobile
              <Tooltip
                overlayInnerStyle={{ background: "#0A2540" }}
                placement="right"
                title={
                  <span>
                    Dimensions <br />
                    <span style={{ fontWeight: 700 }}>200 × 200px</span> PNG
                  </span>
                }
              >
                <InfoIcon color={"#5B6D7F"} style={{ margin: "0 0 0 4px" }} />
              </Tooltip>
            </div>
          }
        >
          <UploadImages
            imageUrl={imageUrl ? imageUrl["mob"] : ""}
            setImageUrl={(img) => setImageUrl({ ...imageUrl, ["mob"]: img })}
          />
        </Form.Item>
      </div>

      <Flex style={{ width: "100%" }}>
        <Form.Item
          style={{ width: "100%" }}
          name={"name"}
          label={"Shop name"}
          rules={[
            {
              required: true,
              message: "Missing Shop name.",
            },
          ]}
        >
          <Input placeholder={"Shop name"} size="large" />
        </Form.Item>

        <Form.Item
          style={{ margin: "0 20px", width: "100%" }}
          name={"url"}
          label={"URL"}
          rules={[
            {
              required: true,
              message: "Missing URL.",
            },
          ]}
        >
          <Input placeholder={"URL"} size="large" />
        </Form.Item>
        <Form.Item
          style={{ width: "100%" }}
          name={"affilate_url"}
          label={"Affiliate URL"}
        >
          <Input placeholder={"Affiliate URL"} size="large" />
        </Form.Item>
      </Flex>
    </div>
  );
};
