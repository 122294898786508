import styled from "styled-components";
import { Modal } from "antd";

const ModalBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
`;

const ImageModal = ({ status, setStatus }) => {
  return status ? (
    <>
      <Modal
        open={status}
        onCancel={() => setStatus(false)}
        footer={null}
        width={"50%"}
      >
        <ModalBody>
          <img
            alt={"wh_photo"}
            src={status}
            width={"100%"}
            height={"100%"}
            style={{ borderRadius: "12px" }}
          />
        </ModalBody>
      </Modal>
    </>
  ) : null;
};
export default ImageModal;
