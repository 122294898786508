import styled from "styled-components";
import { Spin } from "antd";
import Flex from "../general/Flex";

const StyledButtonPrimary = styled.button`
  background: #ecfaf1;
  color: #3fcc75;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat arm2";
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  margin: ${({ margin }) => margin};
`;

export default function ButtonUnlock({ text, margin, click, loading = false }) {
  return (
    <StyledButtonPrimary margin={margin}>
      <div onClick={() => click()}>{loading ? <Spin /> : text}</div>
    </StyledButtonPrimary>
  );
}
