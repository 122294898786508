import { useMutation, useQueryClient } from "react-query";
import { tariffApi } from "../../services/tariffs.js";

export const useCreateUpdateTariffs = (
  id,
  onSuccess = () => {},
  onError = () => {}
) => {
  const client = useQueryClient();
  return useMutation(id ? tariffApi.updateTariffs : tariffApi.createTariffs, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["create-edit-tariffs"]);
    },
  });
};
