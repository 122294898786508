import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import React, { useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import useDashboardData from "../../hooks/Dashboard/useDashboardData.hook.jsx";
import Loader from "../../components/moleculs/Loader";
import Flex from "../../components/general/Flex.jsx";
import LeftBottomArrowIcon from "../../components/SvgComponents/LeftBottomArrow.jsx";
import TopRightArrowIcon from "../../components/SvgComponents/TopRightArrow.jsx";

const StyledRegistrationIsPerson = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  position: relative;
  .filter {
    position: absolute;
    top: 24px;
    right: 24px;
    color: #b3bbc4;
    @media (max-width: 768px) {
      top: 10px;
      right: 10px;
    }
  }
  #chartdiv {
    @media (max-width: 768px) {
      height: auto !important;
    }
  }

  .legend {
    .person {
      background: #7b75e1;
    }
    .company {
      background: #3fcc75;
    }
    .not-completed {
      background: #4ed2f7;
    }
  }
`;

export default function RegistrationIsPerson({
  dashboardData,
  isLoading,
  filterType,
}) {
  useLayoutEffect(() => {
    const chart = am4core.create("chartdiv", am4charts.PieChart);
    chart.responsive.enabled = true;
    chart.responsive.useDefault = false;
    chart.responsive.rules.push({
      relevant: function (target) {
        if (target.pixelWidth <= 400) {
          return true;
        }

        return false;
      },
      state: function (target, stateId) {
        if (target instanceof am4charts.Chart) {
          const state = target.states.create(stateId);
          state.properties.paddingTop = 0;
          state.properties.paddingRight = 0;
          state.properties.paddingBottom = 0;
          state.properties.paddingLeft = 0;
          return state;
        } else if (
          target instanceof am4charts.AxisLabelCircular ||
          target instanceof am4charts.PieTick
        ) {
          const state = target.states.create(stateId);
          state.properties.disabled = true;
          return state;
        }
        return null;
      },
    });
    if (dashboardData) {
      // Add data
      chart.data = [
        {
          platform: "Person",
          litres: dashboardData?.data?.registration_by_user_type_info?.users_total,
          color: "#7b75e1",
        },
        {
          platform: "Company",
          litres: dashboardData?.data?.registration_by_user_type_info?.companies_total,
          color: "#3FCC75",
        },
      ];

      // Add and configure Series
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "platform";
      pieSeries.dataFields.hidden = "hidden";
      pieSeries.slices.template.propertyFields.fill = "color";
      pieSeries.slices.template.tooltipText = "Ruben";

      // Let's cut a hole in our Pie chart the size of 40% the radius
      chart.innerRadius = am4core.percent(40);

      // Disable ticks and labels
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      // Disable tooltips
      pieSeries.slices.template.tooltipText = "{category}: {litres}";
    }
  }, [dashboardData]);

  return (
    <StyledRegistrationIsPerson>
      <div className="filter">
        <Typography
          xsSize={"12px"}
          text={`This ${filterType}`}
          variant={"p"}
          size={"14px"}
          weight={"400"}
          color={"#B3BBC4"}
        />
      </div>
      <Typography
        variant={"h5"}
        text={"User type"}
        color={"#262626"}
        size={"24px"}
        weight={"500"}
        padding={"0 0 15px 0"}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Flex alignItems={"center"}>
            <div id="chartdiv" style={{ width: "60%", height: "240px" }}></div>
            <div className={"legends"}>
              <div className="legend">
                <Flex alignItems={"center"}>
                  <div className="legend-icon person"></div>
                  <p>
                    Person
                    <span>
                      {dashboardData?.data?.registration_by_user_type_info?.users_total}
                    </span>
                    <span className={"percent"}>
                      (
                      {
                        dashboardData?.data?.registration_by_user_type_info
                          ?.users_percentage_in_completed
                      }
                      %)
                    </span>
                  </p>
                </Flex>
              </div>
              <div className="legend">
                <Flex alignItems={"center"}>
                  <div className="legend-icon company"></div>
                  <p>
                    Company
                    <span>
                      {
                        dashboardData?.data?.registration_by_user_type_info
                          ?.companies_total
                      }
                    </span>
                    <span className={"percent"}>
                      (
                      {
                        dashboardData?.data?.registration_by_user_type_info
                          ?.companies_percentage_in_completed
                      }
                      %)
                    </span>
                  </p>
                </Flex>
              </div>
            </div>
          </Flex>
        </>
      )}
    </StyledRegistrationIsPerson>
  );
}
