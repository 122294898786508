import { useQuery } from "react-query";
import { balanceApi } from "../../services/balance.js";

export const useBalancesTransferTypeHook = () => {
  const {
    isError,
    isLoading,
    data: transferType,
    refetch,
  } = useQuery(
    ["balances-transfer-type"],
    () => balanceApi.fetchBalanceTransferType(),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) =>
        data?.data?.data?.map((type, i) => ({
          key: i,
          value: type?.id || "",
          label: type?.name,
        })),
    }
  );
  return {
    isLoading,
    transferType,
    refetch,
  };
};
