import { useQuery } from "react-query";
import { membersAndRoles } from "../../services/members-and-roles.js";

export const useFetchRoles = (query) => {
  const {
    isLoading,
    data: roles,
    refetch,
  } = useQuery(
    ["fetch-roles-list", query],
    () => membersAndRoles.getRoles(query),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => data?.data,
    }
  );
  return {
    isLoading,
    roles,
    refetch,
    meta: roles?.meta,
  };
};
