import { Col, Row, Select } from "antd";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import apple from "../../assets/images/apple.svg";
import android from "../../assets/images/android.svg";
import Flex from "../../components/general/Flex.jsx";
import { useState } from "react";

const initialFilter = {
  dev_mode: undefined,
  force_update: undefined,
  os: undefined,
};

export default function ActionSearch({ setFilter = () => {} }) {
  const [myFilter, setMyFilter] = useState(initialFilter);
  const [selectedOS, setSelectedOS] = useState(null);
  const [selectedUpdateType, setSelectedUpdateType] = useState("");

  const handleReset = () => {
    setFilter();
    setMyFilter(initialFilter);
    setSelectedOS(null);
    setSelectedUpdateType("");
  };

  return (
    <Row gutter={16} align={"bottom"} className="filters-row">
      <Col>
        <Select
          style={{ height: "35px" }}
          value={selectedOS}
          placeholder="All systems"
          onChange={(value) => {
            setSelectedOS(value);
            setMyFilter((p) => ({ ...p, os: value ? value : undefined }));
          }}
          options={[
            { value: null, label: "All systems" },
            {
              value: "android",
              label: (
                <Flex alignItems="center" gap="5px">
                  <img src={android} />
                  Android
                </Flex>
              ),
            },
            {
              value: "ios",
              label: (
                <Flex alignItems="center" gap="5px">
                  <img src={apple} />
                  IOS
                </Flex>
              ),
            },
          ]}
        />
      </Col>
      <Col lg={2}>
        <Select
          style={{ height: "35px" }}
          value={selectedUpdateType}
          placeholder={"All update types"}
          onChange={(value) => {
            setSelectedUpdateType(value);
            setMyFilter((p) => ({
              ...p,
              force_update: value === "force_update" ? 1 : undefined,
              dev_mode: value === "dev_mode" ? 1 : undefined,
            }));
          }}
          options={[
            { value: "", label: "All update types" },
            { value: "force_update", label: "Force Update" },
            { value: "dev_mode", label: "Dev Mode" },
          ]}
        />
      </Col>

      <Col>
        <ButtonSecondary
          style={{ margin: "0 0 0 8px" }}
          text="Reset"
          click={handleReset}
          height={"35px"}
          htmlType={"button"}
        />
      </Col>
      <Col>
        <ButtonPrimary
          height={"35px"}
          icon={<SearchIcon />}
          text="Search"
          click={() => setFilter(myFilter)}
        />
      </Col>
    </Row>
  );
}
