import { Modal, notification, Table } from "antd";
import { ModalInfoIcon } from "../../components/SvgComponents/ModalInfoIcon.jsx";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import { CSS } from "@dnd-kit/utilities";
import { TableDots } from "../../components/SvgComponents/TableDots.jsx";
import TrashIcon from "../../components/SvgComponents/Trash.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import { useFetchShops } from "../../hooks/shops/useFetchShops.hook.js";
import { useSortShops } from "../../hooks/shops/useSortShops.hook.js";
import { WarningIcon } from "../../components/SvgComponents/WarningIcon";

const columns = [
  {
    key: "sort",
    width: 50,
  },
  {
    title: "Shop",
    dataIndex: "shop",
    key: "shop",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },

  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: 50,
  },
];
const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
          pointerEvents: "none",
          boxShadow: "0px 4px 14px 0px rgba(0, 31, 77, 0.25)",
          borderRadius: "12px",
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React?.Children?.map(children, (child) => {
        if (child.key === "sort" && child) {
          return React.cloneElement(child, {
            children: (
              <div
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              >
                <TableDots />
              </div>
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
export const SortModal = ({ open, setOpen = () => {} }) => {
  const [isDrag, setIsDrag] = useState(false);
  const { shops = [] } = useFetchShops({ for_home_page: 1 });
  const [activeStatus, setActiveStatus] = useState(false);
  const [overStatus, setOverSatus] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const { mutate, isLoading } = useSortShops(
    (data) => {
      api.success({
        message: `Success`,
        description: data?.data?.message,
        placement: "topRight",
      });
      setIsDrag(false);
    },
    (e) => {
      console.log(e, "error");
    }
  );

  useEffect(() => {
    setDataSource(shops);
  }, [shops?.length]);

  const onDragEnd = ({ active, over }) => {
    setIsDrag(true);
    let localActiveStatus = false;
    let localOverStatus = false;
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => {
          setActiveStatus(i?.is_active);
          localActiveStatus = i?.is_active;
          return i?.is_active && i.id === active.id;
        });

        const overIndex = previous.findIndex((i) => {
          setOverSatus(i.is_active);
          localOverStatus = i?.is_active;
          return i?.is_active && i.id === over?.id;
        });
        if (localActiveStatus && localOverStatus) {
          return arrayMove(previous, activeIndex, overIndex);
        } else {
          return previous;
        }
      });
    }
  };

  useEffect(() => {
    const newData = [];
    if (isDrag && activeStatus && overStatus) {
      dataSource?.forEach((el, i) => {
        if (el.is_active) {
          newData.push({
            id: el.id,
            sorting: dataSource?.length - 1 - i + 1,
          });
        }
      });
      mutate({ shop_sort: newData });
    }
  }, [isDrag, activeStatus, overStatus]);

  const newData = dataSource?.map((shop, i) => ({
    key: shop?.id,
    shop: (
      <Flex alignItems={"center"}>
        <Typography
          margin={"0 16px 0 -12px"}
          text={i < 9 ? i + 1 : i + 1}
          variant={"p"}
          color={"#5B6D7F"}
          size={"14px"}
        />
        <img src={shop?.logo} alt={shop?.name} width={60} height={50} />

        <Typography
          margin={"0 0 0 12px"}
          text={shop?.name || ""}
          variant={"p"}
          size={"14px"}
        />
      </Flex>
    ),
    country: (
      <Flex alignItems={"center"}>
        <img
          src={`https://devbackadmin.onex.ge/storage/images/warehouses/${shop?.warehouse?.round_flag}`}
          alt={shop?.name}
          width={24}
          height={24}
        />

        <Typography
          margin={"0 0 0 12px"}
          text={shop?.warehouse?.country || ""}
          variant={"p"}
          size={"14px"}
        />
      </Flex>
    ),

    actions: <TrashIcon color={"#FC4447"} />,
  }));
  return (
    <Modal
      open={open}
      title={<ModalInfoIcon style={{ marginTop: "0" }} />}
      onCancel={() => setOpen(false)}
      footer={false}
    >
      <Typography
        text={"Sort shops for the homepage"}
        variant={"h4"}
        color={"#101828"}
        size={"18px"}
        weight={"500"}
        margin={"0 0 16px 0"}
      />
      <div
        style={{
          background: "#FFF5EB",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          borderRadius: "12px",
        }}
      >
        <WarningIcon
          style={{
            padding: "0 4px 0 16px",
          }}
          size={24}
        />
        <Typography
          text={"Remove one shop to add new"}
          variant={"p"}
          color={"#101828"}
          size={"14px"}
          weight={"400"}
          padding={"16px 0"}
        />
      </div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        {contextHolder}
        <SortableContext
          // rowKey array
          items={dataSource?.map((i) => i.id) || []}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={columns || []}
            dataSource={newData}
            pagination={false}
            showHeader={false}
            scroll={{ y: 500 }}
          />
        </SortableContext>
      </DndContext>
      <Flex justifyContent={"space-between"} style={{ marginTop: 24 }}>
        <ButtonSecondary
          text={"Back"}
          width={"48%"}
          height={"36px"}
          click={() => setOpen(false)}
        />
        <ButtonSecondary
          text={"Next"}
          background={"#B3BBC4"}
          color={"#FFFFFF"}
          width={"48%"}
          height={"36px"}
        />
      </Flex>
    </Modal>
  );
};
