export default function AppleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.75 10C18.75 14.83 14.8344 18.75 10 18.75C5.16562 18.75 1.25 14.83 1.25 10C1.25 5.16562 5.16562 1.25 10 1.25C14.8344 1.25 18.75 5.16562 18.75 10Z"
        fill="#283544"
      />
      <path
        d="M14.1013 7.78587C14.0536 7.81372 12.9169 8.40155 12.9169 9.70491C12.9705 11.1913 14.3513 11.7126 14.375 11.7126C14.3513 11.7404 14.1665 12.4227 13.6192 13.1378C13.1848 13.7539 12.7026 14.375 11.9705 14.375C11.2741 14.375 11.0241 13.9644 10.2205 13.9644C9.35752 13.9644 9.11334 14.375 8.45262 14.375C7.72048 14.375 7.20262 13.7206 6.74455 13.1103C6.14944 12.3116 5.64361 11.0582 5.62575 9.85467C5.61372 9.21692 5.74493 8.59002 6.07801 8.05754C6.54812 7.31416 7.3874 6.80952 8.30394 6.79288C9.0062 6.77082 9.6312 7.24217 10.0598 7.24217C10.4705 7.24217 11.2383 6.79288 12.1071 6.79288C12.4821 6.79325 13.4821 6.89851 14.1013 7.78587ZM10.0004 6.66555C9.87538 6.08315 10.2205 5.50074 10.5419 5.12923C10.9526 4.67995 11.6013 4.375 12.1607 4.375C12.1964 4.9574 11.9701 5.5286 11.5656 5.9446C11.2026 6.39388 10.5776 6.73211 10.0004 6.66555Z"
        fill="white"
      />
    </svg>
  );
}
