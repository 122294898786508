import axios from "../libs/axios.js";

export const testUserApi = {
  deleteTestUsers: async ({ ids }) => {
    const result = await axios.post("/test-users/delete", {
      user_ids: ids,
    });
    return result.data;
  },
  clearTestUserData: async (ids) => {
    const result = await axios.post("/test-users/relations/delete", {
      user_ids: ids,
    });
    return result.data;
  },
  makeAsTest: async (id) => {
    const result = await axios.post(`/test-users/${id}/add`);
    return result.data;
  },
};
