import { useQuery } from "react-query";
import { GetOrdersFailedService } from "../../services/orders.js";

export default function useGetFailedOrders(filters) {
  const { data, isLoading, refetch } = useQuery(
    ["get-failed-orders", filters],
    () => GetOrdersFailedService(filters),
    {
      staleTime: Infinity,
      onError: (error) => {},
      select: ({ data }) => {
        return data?.data;
      },
    }
  );
  return {
    failedOrders: data?.data,
    meta: data?.meta,
    isLoading,
    refetch,
  };
}
