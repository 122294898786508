import Flex from "../../components/general/Flex.jsx";
import EyeIcon from "../../components/SvgComponents/EyeIcon.jsx";
import CloseIcon from "../../components/SvgComponents/CloseIcon.jsx";
import { Empty, Modal } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import { useGetReportsTypeLis } from "../../hooks/reports/useGetReportsTypeList.hook.jsx";
import Loader from "../../components/moleculs/Loader.jsx";

const BonusIn = ({ data = [], type }) => {
  let total = 0;
  data?.forEach((el) => {
    if (el?.value) {
      total += Number(el?.value);
    }
  });
  return (
    <>
      {" "}
      <Flex justifyContent={"space-between"}>
        <Typography
          variant={"p"}
          size={"18px"}
          weight={"500"}
          text={
            type === "bonus_in"
              ? "Bonus In"
              : type === "balance_out"
              ? "Balance out"
              : "Balance in"
          }
          color={"#5B6D7F"}
          padding={"16px 0 16px 0"}
        />
        <Typography
          variant={"p"}
          size={"18px"}
          text={`Total: ${total.toFixed(2)} ₾`}
          color={"#5B6D7F"}
          padding={"16px 0 16px 0"}
        />
      </Flex>
      {data?.length > 0 ? (
        data?.map((el, i) => (
          <Flex key={i} direction={"column"}>
            <Typography
              variant={"p"}
              size={"22px"}
              weight={"500"}
              text={el?.value || "0.00"}
              color={"#000"}
            />
            <Typography
              variant={"p"}
              size={"14px"}
              weight={"400"}
              text={el?.name || ""}
              color={"#5B6D7F"}
              padding={"5px 0 10px 0"}
            />
          </Flex>
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};
const HomeDelivery = ({ data = [], type = "home_delivery" }) => {
  let total = 0;
  data?.forEach((el) => {
    if (el?.total_sum || el?.total_cost) {
      total += Number(el?.total_sum || el?.total_cost);
    }
  });

  return (
    <>
      {" "}
      <Flex justifyContent={"space-between"}>
        <Typography
          variant={"p"}
          size={"18px"}
          weight={"500"}
          text={
            type === "home_delivery"
              ? "Home Delivery cost"
              : "Smart Service cost"
          }
          color={"#5B6D7F"}
          padding={"16px 0 16px 0"}
        />
        <Typography
          variant={"p"}
          size={"18px"}
          text={`Total: ${total.toFixed(2)} ₾`}
          color={"#5B6D7F"}
          padding={"16px 0 16px 0"}
        />
      </Flex>
      {data.length > 0 ? (
        data?.map((el, i) => (
          <Flex direction={"column"} key={i}>
            <Typography
              variant={"p"}
              size={"22px"}
              weight={"500"}
              text={el?.total_sum || el?.total_cost || ""}
              color={"#000"}
              padding={"0 0 3px 0"}
            />
            <Typography
              variant={"p"}
              size={"14px"}
              weight={"400"}
              text={el?.region_name || el?.service_name || ""}
              color={"#5B6D7F"}
              padding={"0 0 10px 0"}
            />
          </Flex>
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};
const stylesBfm = { borderLeft: "1.5px solid #E7E9EC", padding: "0 0 0 10px" };
const Bfm = ({ data = [] }) => {
  let total = data?.find((b) => b?.name === "get from customer")?.value || 0;
  return (
    <>
      {" "}
      <Flex justifyContent={"space-between"}>
        <Typography
          variant={"p"}
          size={"18px"}
          weight={"500"}
          text={"BFM total Commission"}
          color={"#5B6D7F"}
          padding={"16px 0 16px 0"}
        />
        <Typography
          variant={"p"}
          size={"18px"}
          text={`Total: ${total} ₾`}
          color={"#5B6D7F"}
          padding={"16px 0 16px 0"}
        />
      </Flex>
      {data?.length > 0 ? (
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ marginTop: 16 }}
        >
          {data?.map((el, i) => (
            <div style={stylesBfm} key={i}>
              <Typography
                transform={"capitalize"}
                variant={"p"}
                size={"14px"}
                weight={"400"}
                text={el?.name || ""}
                color={"#5B6D7F"}
              />
              <Typography
                variant={"p"}
                size={"22px"}
                weight={"500"}
                text={el?.value || "0.00"}
                color={"#000"}
                padding={"5px 0 0 0"}
              />
            </div>
          ))}
        </Flex>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};
const OrderReceived = ({ data = [] }) => {
  let total = 0;
  data?.forEach((el) => {
    if (el?.value) {
      total += Number(el?.value);
    }
  });

  return (
    <>
      {" "}
      <Flex justifyContent={"space-between"}>
        <Typography
          variant={"p"}
          size={"18px"}
          weight={"500"}
          text={"Order Received"}
          color={"#5B6D7F"}
          padding={"16px 0 16px 0"}
        />
        <Typography
          variant={"p"}
          size={"18px"}
          text={`Total: ${total.toFixed(2)} ₾`}
          color={"#5B6D7F"}
          padding={"16px 0 16px 0"}
        />
      </Flex>
      {data?.length > 0 ? (
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ marginTop: 16, width: "100%" }}
          flexWrap={"wrap"}
        >
          {data?.map((el, i) => (
            <Flex
              key={i}
              style={{ width: "50%", marginTop: 24 }}
              direction={"column"}
            >
              <Flex alignItems={"center"}>
                <img src={el?.w_flag} alt={el?.name} width={30} height={30} />
                <Typography
                  transform={"capitalize"}
                  variant={"p"}
                  size={"14px"}
                  weight={"400"}
                  text={el?.name || ""}
                  color={"#5B6D7F"}
                  margin={"0 5px"}
                />
              </Flex>
              <div style={stylesBfm}>
                <Typography
                  variant={"p"}
                  size={"22px"}
                  weight={"500"}
                  text={el?.value || "0.00"}
                  color={"#000"}
                  padding={"5px 0 0 0"}
                />
                <Flex alignItems={"center"}>
                  <img
                    src={el?.d_icon}
                    alt={el?.dispatch}
                    width={24}
                    height={24}
                  />
                  <Typography
                    transform={"capitalize"}
                    variant={"p"}
                    size={"14px"}
                    weight={"400"}
                    text={el?.dispatch || ""}
                    color={"#5B6D7F"}
                  />
                </Flex>
              </div>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export default function ReportModal({ setOpen = () => {}, open, params }) {
  const { reportsTypeList: typeList = [], isLoading } = useGetReportsTypeLis(
    open,
    params
  );

  const newData = typeList?.reduce((acc, item) => {
    if (open === "bfm") {
      Object.entries(item).forEach(([key, val]) => {
        acc.push({ name: key?.replaceAll("_", " "), value: val });
      });
    }
    if (
      open === "bonus_in" ||
      open === "balance_in" ||
      open === "balance_out"
    ) {
      acc.push({
        name:
          item?.bonus_type || item?.payment_type || item?.transfer_type || "",
        value: item?.total || "",
      });
    }

    if (open === "bonus_out") {
      acc.push({
        name: item?.bonus_type?.current_bonus_type?.name || "",
        value: item?.total || "",
      });
    }
    if (open === "receive_order") {
      acc.push({
        name: item?.warehouse?.country || "",
        value: item?.total_cost || "",
        w_flag: item?.warehouse?.warehouse_round_flag || "",
        dispatch: item?.dispatch?.type || "",
        d_icon: item?.dispatch?.dispatch_icon || "",
      });
    }
    return acc;
  }, []);

  return (
    <Modal
      open={open}
      title={<EyeIcon fill={"#3FCC75"} />}
      footer={false}
      onCancel={() => setOpen(false)}
      closeIcon={<CloseIcon style={{ marginTop: 5 }} />}
      bodyStyle={{
        overflowY: "scroll",
        overflowX: "hidden",
        maxHeight: 500,
        minHeight: 150,
      }}
    >
      {isLoading ? (
        <Loader height={"200px"} />
      ) : open === "bfm" ? (
        <Bfm data={newData} />
      ) : open === "home_delivery" || open === "smart_service" ? (
        <HomeDelivery data={typeList} type={open} />
      ) : open === "bonus_in" ||
        open === "balance_in" ||
        open === "balance_out" ? (
        <BonusIn data={newData} type={open} />
      ) : open === "receive_order" ? (
        <OrderReceived data={newData} />
      ) : (
        ""
      )}
    </Modal>
  );
}
