export default function NotRolesResult(props) {
  return (
    <svg
      {...props}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="21" fill="#E7E9EC" />
      <g clipPath="url(#clip0_3035_15028)">
        <path
          d="M18.6666 21C21.8882 21 24.4999 18.3883 24.4999 15.1667C24.4999 11.945 21.8882 9.33333 18.6666 9.33333C15.4449 9.33333 12.8333 11.945 12.8333 15.1667C12.8333 18.3883 15.4449 21 18.6666 21Z"
          stroke="#3B5166"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.16663 32.6667C8.16663 28.1517 12.6583 24.5 18.1883 24.5C19.3083 24.5 20.3933 24.6517 21.4083 24.9317"
          stroke="#3B5166"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.139 28.6363C28.1562 28.6363 28.9808 27.8117 28.9808 26.7944C28.9808 25.7772 28.1562 24.9526 27.139 24.9526C26.1217 24.9526 25.2971 25.7772 25.2971 26.7944C25.2971 27.8117 26.1217 28.6363 27.139 28.6363Z"
          stroke="#3B5166"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 27.3353V26.2548C21 25.6163 21.5219 25.0883 22.1665 25.0883C23.2778 25.0883 23.7321 24.3024 23.1734 23.3385C22.8541 22.7859 23.0445 22.0676 23.6032 21.7484L24.6653 21.1405C25.1503 20.852 25.7766 21.0239 26.0651 21.5089L26.1327 21.6256C26.6852 22.5895 27.5939 22.5895 28.1526 21.6256L28.2201 21.5089C28.5087 21.0239 29.1349 20.852 29.6199 21.1405L30.6821 21.7484C31.2408 22.0676 31.4311 22.7859 31.1118 23.3385C30.5531 24.3024 31.0075 25.0883 32.1187 25.0883C32.7572 25.0883 33.2852 25.6101 33.2852 26.2548V27.3353C33.2852 27.9739 32.7634 28.5019 32.1187 28.5019C31.0075 28.5019 30.5531 29.2877 31.1118 30.2516C31.4311 30.8103 31.2408 31.5225 30.6821 31.8418L29.6199 32.4496C29.1349 32.7381 28.5087 32.5662 28.2201 32.0812L28.1526 31.9646C27.6 31.0006 26.6914 31.0006 26.1327 31.9646L26.0651 32.0812C25.7766 32.5662 25.1503 32.7381 24.6653 32.4496L23.6032 31.8418C23.0445 31.5225 22.8541 30.8042 23.1734 30.2516C23.7321 29.2877 23.2778 28.5019 22.1665 28.5019C21.5219 28.5019 21 27.9739 21 27.3353Z"
          stroke="#3B5166"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3035_15028">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
