import styled from "styled-components";
import Flex from "../general/Flex";

const StyledSecondaryButton = styled.button`
  background: ${({ background }) => background || "white"};
  border: ${({ border }) => border || "1px solid rgba(231, 233, 236, 1);"};
  color: ${({ color }) => color || "rgba(59, 81, 102, 1)"};
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat arm2";
  padding: ${({ padding }) => (padding ? padding : "6px 16px")};
  border-radius: 8px;
  cursor: pointer;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  outline: none;

  &[disabled] {
    opacity: 0.5;
    cursor: no-drop;
  }
`;

export default function ButtonSecondary({
  text,
  icon,
  click = () => {},
  background = "white",
  color = "rgba(59, 81, 102, 1)",
  htmlType = "button",
  ...props
}) {
  return (
    <StyledSecondaryButton
      background={background}
      color={color}
      type={htmlType}
      onClick={() => click()}
      {...props}
    >
      {icon ? (
        <Flex alignItems="center">
          {icon}
          <span style={{ paddingLeft: 8 }}>{text}</span>
        </Flex>
      ) : (
        text
      )}
    </StyledSecondaryButton>
  );
}
