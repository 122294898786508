import { useQuery } from "react-query";
import { storyApi } from "../../services/stories";

export const useGetStories = () => {
  const { data, refetch, isLoading } = useQuery(
    ["get-stories"],
    () => storyApi.getStories(),
    {
      staleTime: Infinity,
    }
  );
  return { data, refetch, isLoading };
};
