export default function ExpectedIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.28572 5.14287C4.28572 3.24932 5.76592 1.71429 7.59184 1.71429H16.4082C18.2341 1.71429 19.7143 3.24932 19.7143 5.14287V18.8572C19.7143 20.7507 18.2341 22.2857 16.4082 22.2857H7.59184C5.76592 22.2857 4.28572 20.7507 4.28572 18.8572V5.14287Z"
        stroke="#5B6D7F"
        strokeWidth="1.5"
      />
      <path
        d="M10.2656 4.28571H13.7344"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.933 10.7873L11.2667 15.6757L9.06697 13.4537"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
