import styled from "styled-components";
import { AutoComplete, notification } from "antd";
import { useState } from "react";
import Flex from "../../../components/general/Flex";
import Typography from "../../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../../constants.js";
import PrimeIcon from "../../../components/SvgComponents/primeIcon";
import IconButton from "../../../components/moleculs/IconButton";
import ChangeUserIcon from "../../../components/SvgComponents/ChangeUserIcon";
import CancelIcon from "../../../components/SvgComponents/CancelIcon.jsx";
import AcceptIcon from "../../../components/SvgComponents/AcceptIcon.jsx";
import { Link } from "react-router-dom";
import { usersAutocomplete } from "../../../hooks/useUsersAutocomplete.hook.js";
import { ChangeUserService } from "../../../services/orders.js";
import { PermissionFinder } from "../../../hooks/permissionFinder.hook.js";

const StyledDetailsCustomer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 16px;

  .name-surname {
    margin-left: 50px;
    padding-left: 10px;
    border-left: 1px solid #e7e9ec;
    margin-top: 20px;
  }

  .customer-info-item {
    margin-right: 55px;
  }
`;

const EditedUser = styled.div``;

export default function OrderDetailsCustomer({
  order,
  refetch,
  refetchOrders,
  permissions,
}) {
  const [editStatus, setEditStatus] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const { usersList = [] } = usersAutocomplete(userInfo);
  const [changeUserInfo, setChangeUserInfo] = useState();
  const [api, contextHolder] = notification.useNotification();
  const handleChangeUser = () => {
    ChangeUserService(order.id, {
      user_id: changeUserInfo?.user_id,
      recipient_id: changeUserInfo?.id,
    })
      .then((res) => {
        if (res.data.success) {
          setEditStatus(false);
          api.success({
            message: `Success!`,
            description: res.data.message,
            placement: "topLeft",
          });
        } else {
          api.error({
            message: `Error!`,
            description: res.data.message,
            placement: "topLeft",
          });
        }
      })
      .then(() => {
        refetch();
      })
      .catch(() => {
        api.error({
          message: `Error!`,
          description: "Something went wrong!",
          placement: "topLeft",
        });
      })
      .finally(() => refetchOrders());
    // setEditStatus(false)
  };

  return (
    <>
      <StyledDetailsCustomer>
        {contextHolder}
        <Flex justifyContent="space-between">
          <Flex alignItems="center">
            <div>
              <Typography
                variant="p"
                text="Customer"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 5px 0"
              />
              <Link to={`/customer/${order?.recipient?.user?.id}`}>
                <Flex>
                  <Typography
                    variant="p"
                    text={`${order?.recipient?.first_name} ${order?.recipient?.last_name} ${order?.recipient?.user_code}`}
                    color={primaryTextColor}
                    size="14px"
                    weight={500}
                    margin="0 5px 0 0"
                  />

                  {order?.recipient?.user?.is_prime ? <PrimeIcon /> : null}
                </Flex>
              </Link>
            </div>
            <div className="name-surname">
              {!editStatus ? (
                !order?.recipient?.is_parent === 1 ? (
                  <Typography
                    variant="p"
                    text={`${order.recipient.first_name} ${order.recipient.last_name} ${order?.recipient?.user_code}`}
                    color={primaryTextColor}
                    size="14px"
                    weight="400"
                  />
                ) : null
              ) : (
                <EditedUser>
                  <Typography
                    variant="p"
                    text="New User"
                    color="#3B5166"
                    size="14px"
                    weight="500"
                    padding="0 0 5px 0"
                  />
                  <AutoComplete
                    placeholder="Full name or GA"
                    onSearch={(val) => setUserInfo({ user_info: val })}
                    options={usersList}
                    onSelect={(_, info) => setChangeUserInfo(info)}
                    style={{ width: 250 }}
                  />
                </EditedUser>
              )}
            </div>
          </Flex>
          {editStatus ? (
            <Flex>
              <div style={{ marginRight: 8 }}>
                <div className="cancel" onClick={() => setEditStatus(false)}>
                  <CancelIcon />
                </div>
              </div>
              <div className="accept" onClick={() => handleChangeUser()}>
                <AcceptIcon />
              </div>
            </Flex>
          ) : PermissionFinder(permissions, "order-change-user") ? (
            <IconButton
              icon={<ChangeUserIcon />}
              click={() => setEditStatus(true)}
            />
          ) : null}
        </Flex>
      </StyledDetailsCustomer>
      <StyledDetailsCustomer>
        <Flex>
          <div className="customer-info-item">
            <Typography
              variant="p"
              text="Balance"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 5px 0"
            />
            <Typography
              variant="p"
              text={`${order?.recipient?.user?.balance} ₾`}
              color={primaryTextColor}
              size="14px"
              weight="400"
            />
          </div>
          <div className="customer-info-item">
            <Typography
              variant="p"
              text="Phone"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 5px 0"
            />
            <Typography
              variant="p"
              text={order?.recipient?.phone}
              color={primaryTextColor}
              size="14px"
              weight="400"
            />
          </div>
          <div className="customer-info-item">
            <Typography
              variant="p"
              text="Address"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 5px 0"
            />
            <Typography
              variant="p"
              text={order?.recipient?.address}
              color={primaryTextColor}
              size="14px"
              weight="400"
            />
          </div>
          <div className="customer-info-item">
            <Typography
              variant="p"
              text="Email"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 5px 0"
            />
            <Typography
              variant="p"
              text={order?.recipient?.user?.email}
              color={primaryTextColor}
              size="14px"
              weight="400"
            />
          </div>
        </Flex>
      </StyledDetailsCustomer>
    </>
  );
}
