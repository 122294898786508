import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import StoryTable from "./Table";
import CreateStory from "./Create";
import { useState } from "react";
import { useGetStories } from "../../hooks/stories/useGetStories.js";
import { storyApi } from "../../services/stories.js";

const StyledNotifications = styled.div`
  padding-top: 24px;
`;

export default function Stories() {
  const [openCreate, setOpenCreate] = useState(false);
  const [editId, setEditId] = useState();
  const { data = [], refetch } = useGetStories();
  const handleDelete = (id) => storyApi.deleteStory(id).then(refetch);
  return (
    <StyledNotifications>
      <div className="page-title">
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Typography
            text="Story"
            color="#262626"
            weight={500}
            size="24px"
            variant="h2"
          />
          <ButtonSecondary
            text={"Create Story"}
            color={"white"}
            background={"#5B6D7F"}
            click={() => setOpenCreate(true)}
          />
        </Flex>
      </div>
      <StoryTable
        stories={data}
        onEdit={(id) => {
          if (id) {
            setEditId(id);
            setOpenCreate(true);
          }
        }}
        handleDelete={handleDelete}
        refetch={refetch}
      />
      <CreateStory
        setOpen={setOpenCreate}
        onEditFinished={() => setOpenCreate(false)}
        setEditId={setEditId}
        editId={editId}
        open={openCreate}
        handleDelete={handleDelete}
        refetch={refetch}
      />
    </StyledNotifications>
  );
}
