import { Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import useGetParcel from "../../hooks/parcel/useGetParcel.hook.jsx";
import { openNotification } from "../../helpers/notification.js";
import InfoIcon from "../../components/SvgComponents/InfoIcon.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import { useSendToHub } from "../../hooks/orders/useSendToHub.hook.js";
import CloseIcon from "../../components/SvgComponents/CloseIcon.jsx";

export const SendToHubFailedOrderModal = ({
  sendToHub,
  setSendToHub = () => {},
}) => {
  const [form] = Form.useForm();
  const [boxes, setBoxes] = useState([]);
  const { parcelList = [] } = useGetParcel({
    per_page: 10000,
    warehouse_id: sendToHub?.warehouse?.id,
  });
  const [filterList, setFilterList] = useState([]);
  const { mutate } = useSendToHub(() => {
    setSendToHub(false);
    openNotification(
      "Sent to hub",
      "This order successfully has been sent to hub!"
    );
  });
  useEffect(() => {
    if (parcelList?.length > 0) {
      setFilterList(parcelList);
    }
  }, [parcelList]);
  const onFinish = (values) => {
    mutate({ orders: [sendToHub?.id], ...values });
  };
  const onFilter = (val) => {
    const filterData = parcelList?.filter((parcel) =>
      parcel?.label.toLowerCase().includes(val.toLowerCase())
    );
    setFilterList(filterData);
  };
  return (
    <Modal
      open={sendToHub}
      title={<InfoIcon />}
      footer={false}
      onCancel={() => setSendToHub(false)}
      closeIcon={<CloseIcon style={{ marginTop: 5 }} />}
      bodyStyle={{
        width: 450,
      }}
    >
      <Typography
        text={"Send to hub"}
        variant={"h4"}
        size={"18px"}
        weight={"500"}
        color={"#262626"}
        margin={"0 0 16px 0"}
      />
      <Form
        form={form}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          parcel_id: sendToHub?.parcel?.id || "",
          box_id: sendToHub?.box?.id || "",
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Flex justifyContent={"space-between"}>
          <Form.Item
            style={{ width: "48%" }}
            name="parcel_id"
            rules={[
              {
                required: true,
                message: "Please select a parcel!",
              },
            ]}
          >
            <Select
              placeholder={"Select parcel"}
              options={filterList}
              onSelect={(_, val) => {
                const boxes = val?.boxes?.map((box, i) => ({
                  key: i,
                  value: box?.id,
                  label: box?.id,
                }));
                setBoxes(boxes);
                form.setFieldValue("box_id", "");
              }}
              showSearch
              optionFilterProp="children"
              onSearch={onFilter}
              autoComplete="new-state"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            style={{ width: "48%" }}
            name="box_id"
            rules={[
              {
                required: true,
                message: "Please select a box!",
              },
            ]}
          >
            <Select placeholder={"Box ID"} options={boxes} />
          </Form.Item>
        </Flex>
        <Flex justifyContent={"space-between"} className={"mt-24"}>
          <ButtonSecondary
            padding={"10px 16px"}
            text={"Cancel"}
            jm
            width={"48%"}
            click={() => setSendToHub(false)}
          />
          <ButtonSecondary
            padding={"10px 16px"}
            text={"Send to Hub"}
            width={"48%"}
            background={"#5dba2f"}
            color={"white"}
            htmlType={"submit"}
          />
        </Flex>
      </Form>
    </Modal>
  );
};
