import styled from "styled-components";

const HomeStyles = styled.div`
  padding-top: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 97vh;
  h1 {
    text-align: center;
    color: #6c6c6c;
  }
`;

export default function Home() {
  return (
    <HomeStyles className="home">
      <h1>Welcome {localStorage.getItem("name")} To Onex Admin Panel !</h1>
    </HomeStyles>
  );
}
