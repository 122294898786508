import styled from "styled-components";
import { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core.js";
import * as am4charts from "@amcharts/amcharts4/charts.js";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import TopRightArrowIcon from "../../components/SvgComponents/TopRightArrow";
import LeftBottomArrowIcon from "../../components/SvgComponents/LeftBottomArrow";
import Loader from "../../components/moleculs/Loader";

const StyledDashboardRegistration = styled.div`
  margin-top: 40px;
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  .filter {
    position: absolute;
    top: 24px;
    right: 24px;
    color: #b3bbc4;
    @media (max-width: 768px) {
      top: 10px;
      right: 10px;
    }
  }
  @media (max-width: 768px) {
    margin-top: 0;
  }
  .titles-flex {
    @media (max-width: 768px) {
      flex-direction: column !important;
      .typography {
        padding: 0 !important;
      }
      .titles-sub-flex {
        flex-wrap: nowrap !important;
      }
    }
    .total-numbers {
      width: 25%;
      @media (max-width: 768px) {
        width: 45% !important;
      }
      @media (max-width: 545px) {
        width: 75% !important;
      }
    }
  }
`;

export default function DashboardRegistration({ dashboardData, filterType }) {
  useLayoutEffect(() => {
    if (dashboardData) {
      let chart = am4core.create("registration", am4charts.XYChart);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      let data = Object.entries(
        dashboardData?.data?.registration_by_time_info
      ).map((key, value) => {
        return {
          category: key[0],
          value1: key[1].verified_total,
          value2: key[1].unverified_total,
          color: "#5DBA2F",
          colorUnverified: "#CDEABF",
        };
      });

      chart.data = data;

      chart.legend = new am4charts.Legend();
      chart.colors.step = 0;
      chart.legend.markers.fill = "red";

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.renderer.minWidth = 0;
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.renderer.labels.template.fillOpacity = 1;
      valueAxis.renderer.baseGrid.strokeOpacity = 0;

      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.columns.template.width = am4core.percent(30);
      series1.columns.template.tooltipText = "{name}: {valueY.value}";
      series1.name = "Verified";
      series1.dataFields.categoryX = "category";
      series1.dataFields.valueY = "value1";
      series1.stacked = true;
      series1.columns.template.column.cornerRadius(0, 0, 0, 0);
      series1.columns.template.adapter.add("fill", function (fill, target) {
        return target.dataItem.dataContext["color"];
      });
      series1.columns.template.adapter.add("stroke", function (fill, target) {
        return target.dataItem.dataContext["color"];
      });
      let series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.columns.template.width = am4core.percent(30);
      series2.columns.template.tooltipText = "{name}: {valueY.value}";
      series2.name = "Unverified";
      series2.dataFields.categoryX = "category";
      series2.dataFields.valueY = "value2";
      series2.stacked = true;
      series2.columns.template.column.cornerRadius(8, 8, 0, 0);
      series2.columns.template.adapter.add("fill", function (fill, target) {
        return target.dataItem.dataContext["colorUnverified"];
      });
      series2.columns.template.adapter.add("stroke", function (fill, target) {
        return target.dataItem.dataContext["colorUnverified"];
      });

      chart.scrollbarX = new am4core.Scrollbar();

      chart.cursor = new am4charts.XYCursor();
    }
  }, [dashboardData]);

  return dashboardData ? (
    <StyledDashboardRegistration>
      <div className="filter">
        <Typography
          xsSize={"12px"}
          text={`This ${filterType}`}
          variant={"p"}
          size={"14px"}
          weight={"400"}
          color={"#B3BBC4"}
        />
      </div>
      <Typography
        variant={"h5"}
        text={"Registration"}
        color={"#262626"}
        size={"24px"}
        weight={"500"}
        padding={"0 0 15px 0"}
      />
      <Flex className={"titles-flex"}>
        <div>
          <Typography
            xsSize={"12px"}
            variant={"h5"}
            text={`${dashboardData?.data?.current_start_date.substr(
              0,
              10
            )} - ${dashboardData?.data?.current_end_date.substr(0, 10)}`}
            color={"#8E9BA7"}
            size={"12px"}
            weight={"400"}
            padding={"0 0 15px 0"}
          />
        </div>
        <Flex justifyContent={"space-between"} className={"total-numbers"}>
          <Typography
            xsSize={"12px"}
            variant={"h5"}
            text={"Total"}
            color={"#262626"}
            size={"14px"}
            weight={"400"}
            padding={"0 4px 0 16px"}
          />
          <Typography
            xsSize={"12px"}
            variant={"h5"}
            text={dashboardData?.data?.all_info?.all_total}
            color={"#262626"}
            size={"14px"}
            weight={"600"}
            padding={"0 0 0 4px"}
          />

          <Typography
            xsSize={"12px"}
            variant={"h5"}
            text={"Verified"}
            color={"#262626"}
            size={"14px"}
            weight={"400"}
            padding={"0 4px 0 16px"}
          />
          <Typography
            xsSize={"12px"}
            variant={"h5"}
            text={dashboardData?.data?.all_info?.completed_total}
            color={"#262626"}
            size={"14px"}
            weight={"600"}
            padding={"0 0 0 4px"}
          />

          <Typography
            xsSize={"12px"}
            variant={"h5"}
            text={"Unverified"}
            color={"#262626"}
            size={"14px"}
            weight={"400"}
            padding={"0 4px 0 16px"}
          />
          <Typography
            xsSize={"12px"}
            variant={"h5"}
            text={dashboardData?.data?.all_info?.incompleted_total + dashboardData?.data?.all_info?.unverified_total}
            color={"#262626"}
            size={"14px"}
            weight={"600"}
            padding={"0 0 0 4px"}
          />
        </Flex>
      </Flex>
      <div id="registration" style={{ width: "100%", height: "500px" }}></div>
    </StyledDashboardRegistration>
  ) : (
    <Loader />
  );
}
