import Typography from "../../components/moleculs/Typography.jsx";
import OnlinePaymentTopActions from "./TopActions";
import { useState } from "react";
import { StyleOnlinePayment } from "./style-online-payment.jsx";
import { PaymentTable } from "./PaymentTable.jsx";
import { RefundPaymentModal } from "./RefundPaymentModal.jsx";

export default function OnlinePayment({ permissions }) {
  const [filterData, setFilterData] = useState({});
  const [refund, setRefund] = useState(false);
  return (
    <StyleOnlinePayment>
      <div className="page-title">
        <Typography
          text="Online payment"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <OnlinePaymentTopActions setFilterData={setFilterData} />
      <PaymentTable
        setRefund={setRefund}
        filterData={filterData}
        setFilterData={setFilterData}
      />
      <RefundPaymentModal refund={refund} setRefund={setRefund} />
    </StyleOnlinePayment>
  );
}
