import { configureStore, combineReducers } from "@reduxjs/toolkit"
import counterReducer from "./slices/counter/counterSlice"
import ordersReducer from "../redux/slices/orders/ordersSlice"

const rootReducer = combineReducers({
  counter: counterReducer,
  ordersReducer: ordersReducer,
})

export default configureStore({
  reducer: rootReducer,
})
