export const BuyForMe = ({ size = "24", ...props }) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4925 9.3V6.6C15.4925 4.61178 13.9289 3 12 3C10.0711 3 8.5075 4.61178 8.5075 6.6V9.3M4.65876 10.5168L4.13488 16.2768C3.98593 17.9145 3.91145 18.7334 4.17509 19.3658C4.40668 19.9215 4.81253 20.3809 5.32759 20.6704C5.9139 21 6.71179 21 8.30759 21H15.6924C17.2882 21 18.0861 21 18.6724 20.6704C19.1875 20.3809 19.5933 19.9215 19.8249 19.3658C20.0885 18.7334 20.0141 17.9145 19.8651 16.2768L19.3412 10.5168C19.2155 9.13381 19.1526 8.44233 18.8508 7.91955C18.5851 7.45913 18.1925 7.0906 17.723 6.86086C17.1899 6.6 16.5161 6.6 15.1685 6.6L8.83147 6.6C7.48392 6.6 6.81015 6.6 6.27702 6.86086C5.8075 7.0906 5.41489 7.45913 5.14916 7.91955C4.84743 8.44233 4.78454 9.13381 4.65876 10.5168Z"
        stroke="#3B5166"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
