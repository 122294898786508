import { useQuery } from "react-query";
import { GetSmartServices } from "../../services/smartServices.js";
import Flex from "../../components/general/Flex.jsx";

export default function useSmartServices() {
  const { data: smartServices } = useQuery(
    "smart-services",
    () => GetSmartServices(),
    {
      staleTime: Infinity,
      select: ({ data }) => {
        return data?.data?.data?.map((service) => ({
          value: service?.id || "",
          label: (
            <Flex alignItems="center">
              <img style={{ width: 25, marginRight: 5 }} src={service.image} />
              <span>{service.current_smart_service.name}</span>
            </Flex>
          ),
          ...service,
        }));
      },
    }
  );
  return { smartServices };
}
