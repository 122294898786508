import { Form, Input } from "antd";
import { useState } from "react";
import { openNotification } from "../../helpers/notification.js";
import Modal from "../../components/modals/Modal.jsx";
import transferIcon from "../../assets/images/transfer-icon.svg";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import { useAddBankTransfer } from "../../hooks/Balances/useAddBankTransfer.hook.js";
import isPrime from "../../assets/images/prime.svg";

const { TextArea } = Input;

export const AddBankTransferModal = ({
  user,
  recipient,
  setOpen = () => {},
  open,
  refetch = () => {},
}) => {
  const [form] = Form.useForm();
  const [transfer, setTransfer] = useState("");
  const { mutate } = useAddBankTransfer(() => {
    refetch();
    setOpen(false);
    form.resetFields();
    openNotification(
      "Bank Transfer",
      "This Transfer successfully has been sent!"
    );
  });

  const onFinish = (values) => {
    mutate({ user_id: user?.id ? user?.id : recipient?.user_id, ...values });
  };
  return (
    <Modal
      icon={<img src={transferIcon} />}
      width={"450"}
      status={open}
      setStatus={setOpen}
    >
      <Typography
        text={"Add bank transfer"}
        variant={"h4"}
        size={"18px"}
        weight={"500"}
        color={"#262626"}
      />
      <Flex alignItems={"center"} style={{ margin: "8px 0 16px 0" }}>
        <Typography
          text={
            (user?.recipient?.first_name || recipient?.first_name) +
            " " +
            (user?.recipient?.last_name || recipient?.last_name) +
            " " +
            (user?.recipient?.user_code || recipient?.user_code)
          }
          variant={"h4"}
          size={"14px"}
          weight={"500"}
          color={"#262626"}
        />
        {user?.is_prime || recipient?.user?.is_prime ? (
          <img src={isPrime} style={{ margin: "0 auto 0 5px" }} />
        ) : (
          ""
        )}
      </Flex>

      <Form
        form={form}
        style={{
          maxWidth: 600,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Typography
          variant={"p"}
          text={"Bank transfer amount"}
          color={"#3B5166"}
          size={"14px"}
          weight={"500"}
        />
        <Form.Item
          name="sum"
          rules={[
            {
              required: true,
              message: "The amount can not be blank.",
            },
          ]}
        >
          <Input
            suffix="₾"
            type={"number"}
            placeholder={"Enter amount"}
            onChange={(e) => setTransfer(e.target.value)}
          />
        </Form.Item>
        <Typography
          variant={"p"}
          text={"Description"}
          color={"#3B5166"}
          size={"14px"}
          weight={"500"}
        />
        <Form.Item name="description">
          <TextArea placeholder={"Description"} showCount maxLength={244} />
        </Form.Item>
        <Flex justifyContent={"space-between"} className={"mt-24"}>
          <ButtonSecondary
            padding={"10px 16px"}
            text={"Cancel"}
            width={"48%"}
            click={() => setOpen(false)}
          />
          <ButtonSecondary
            padding={"10px 16px"}
            text={`Transfer ${transfer ? transfer + " ₾" : ""}`}
            width={"48%"}
            background={"#5dba2f"}
            color={"white"}
            htmlType={"submit"}
          />
        </Flex>
      </Form>
    </Modal>
  );
};
