import Flex from "./Flex";
import Loader from "../moleculs/Loader";

export const Loading = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{ width: "100%", height: "100vh" }}
    >
      <Loader />
      {/*<Spin size="large" spinning />*/}
    </Flex>
  );
};
