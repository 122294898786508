import { useMutation, useQueryClient } from "react-query";
import { CreateSlide } from "../../services/slides-sort.js";

export const useCreateAndEditSlid = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const client = useQueryClient();
  return useMutation(CreateSlide, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["created-and-updated-slider"]);
    },
  });
};
