import axios from "axios";

export const tariffApi = {
  getList: async () => await axios("/tariffs"),
  sortTariffs: async (details) => {
    const data = details.map((item, index) => ({
      id: item.id,
      sorting: index,
    }));
    return await axios.patch("/tariffs/sorting", { details: data });
  },
  updateTariffs: async ({ data, id }) =>
    await axios.put(`/tariffs/${id}`, data),
  createTariffs: async ({ data, id }) => await axios.post(`/tariffs`, data),
  getTariffById: async (id) => await axios(`/tariffs/${id}`),
  deleteTariff: async ({ id }) => await axios.delete(`/tariffs/${id}`),
};
