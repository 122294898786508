import { useQuery } from "react-query";
import { onlinePaymentApi } from "../../services/online-payment.js";

export const useOnlinePaymentList = (params) => {
  const { isError, isLoading, data, refetch } = useQuery(
    ["online-payment", params],
    () => onlinePaymentApi.fetchOnlinePaymentList(params),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        let payment = data?.data?.data;
        let meta = data?.data?.meta;
        return { payment, meta };
      },
    }
  );
  return {
    isLoading,
    paymentList: data?.payment,
    meta: data?.meta,
    refetch,
  };
};
