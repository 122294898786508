import { Drawer, Input, Select } from "antd";
import styled from "styled-components";
import { useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import Flex from "../../components/general/Flex";
import ButtonCancel from "../../components/moleculs/ButtonCancel";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import BackArrowIcon from "../../components/SvgComponents/BackArrowIcon.jsx";
import useSmartServices from "../../hooks/smartServices/useSmartServices.hook.jsx";
import useSingleExpected from "../../hooks/expected/useSingleExpected.hook.jsx";
import { AddSmartServiceService } from "../../services/expected.js";

const StyledAddSmartService = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .info-item {
    margin-bottom: 16px;
    border-left: 1px solid #e7e9ec;
    padding-left: 5px;
  }
`;

const StyledFooter = styled.div`
  margin-top: 55px;
  .icon {
    //position: absolute;
    //left: 30px;
    //bottom: 30px;
  }
  .id {
    //position: absolute;
    //right: 30px;
    //bottom: 30px;
  }
  .time {
    padding-right: 16px;
    border-right: 1px solid #ddd;
  }
  .id-number {
    padding-left: 16px;
  }
`;

export default function AddSmartService({
  smartServiceStatus,
  setSmartServiceStatus,
  id,
}) {
  const { smartServices = [] } = useSmartServices();
  const { data, isLoading } = useSingleExpected(id);
  const [price, setPrice] = useState();
  const [smartService, setSmartService] = useState();

  const handleChangeSmartService = (value) => {
    setSmartService(value);
  };

  const handleCreate = () => {
    AddSmartServiceService(id, smartService).then((res) => {
      console.log(res);
    });
    // setSmartServiceStatus(false)
  };

  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => setSmartServiceStatus(false)}
      open={smartServiceStatus}
      width="700px"
      header={false}
    >
      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems="normal"
        style={{ height: "95vh" }}
      >
        <StyledAddSmartService>
          <div className="header">
            <Typography
              text="Add smart service"
              variant="p"
              size="22px"
              weight="500"
              color={primaryTextColor}
            />
            <Flex>
              <ButtonCancel text="Cancel" />
              <ButtonPrimary
                text="Create"
                style={{ marginLeft: 16 }}
                click={() => handleCreate()}
              />
            </Flex>
          </div>
          <div className="info-item">
            <Typography
              text={`The receiver in ${data?.recipient?.address}`}
              variant="p"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Flex alignItems="center">
              <Typography
                text={`${data?.recipient?.first_name} ${data?.recipient?.last_name} ${data?.recipient?.user_code}`}
                variant="p"
                color={primaryTextColor}
                size="14px"
                weight="500"
                padding="0 4px 0 0"
              />
              {/* <PrimeIcon /> */}
            </Flex>
          </div>
          <div className="info-item">
            <Typography
              text="Tracking code"
              variant="p"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Typography
              text={data?.tracking_code}
              variant="p"
              color={primaryTextColor}
              size="14px"
              weight="500"
              padding="0 4px 0 0"
            />
          </div>
          <div>
            <Typography
              text="Add smart service"
              variant="p"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Flex justifyContent="space-between">
              <Select
                style={{ width: 420 }}
                onChange={handleChangeSmartService}
                options={smartServices}
              ></Select>
              <Input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                suffix="֏"
                style={{ width: 216 }}
              />
            </Flex>
          </div>
        </StyledAddSmartService>
        <StyledFooter>
          <Flex justifyContent="space-between">
            <div className="icon" onClick={() => setSmartServiceStatus(false)}>
              <BackArrowIcon />
            </div>
            <div className="id">
              <Flex>
                <div className="time">
                  <Flex>
                    <Typography
                      text="Updated by Name at "
                      variant="p"
                      color="#B3BBC4"
                      size="14px"
                      weight="400"
                    />
                    <Typography
                      text="2016-11-20, 12:04"
                      variant="p"
                      color="#8E9BA7"
                      size="14px"
                      weight="400"
                      padding="0 0 0 8px"
                    />
                  </Flex>
                </div>
                <div className="id-number">
                  <Typography
                    text="ID 1774569"
                    variant="p"
                    color="#B3BBC4"
                    size="14px"
                    weight="400"
                  />
                </div>
              </Flex>
            </div>
          </Flex>
        </StyledFooter>
      </Flex>
    </Drawer>
  );
}
