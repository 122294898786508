import { Table } from "antd";
import Flex from "../../components/general/Flex.jsx";
import EditIcon from "../../components/SvgComponents/EditIcon.jsx";
import dayjs from "dayjs";
import Typography from "../../components/moleculs/Typography.jsx";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";
import DublicateIcon from "../../components/SvgComponents/DublicateIcon.jsx";
import OnexTooltip from "../../helpers/tooltip.jsx";
import RoleEditModal from "./RoleEditModal.jsx";
import { useState } from "react";
import { useAddNewRole } from "../../hooks/members-and-roles/useAddNewRole.hook.js";
import { useFetchRoles } from "../../hooks/members-and-roles/useFetchRoles.hook.jsx";
import { openNotification } from "../../helpers/notification.js";

export const RolesTable = ({ rolesList, refetchRoles }) => {
  const [editStatus, setEditStatus] = useState(false);
  const [editableItem, setEditableItem] = useState(null);
  const { mutate } = useAddNewRole(
    (res) => {
      openNotification("Role Dublicated", res?.data?.message);
      refetchRoles();
    },
    (err) => {
      console.log(err);
      openNotification("Error");
    }
  );

  const handleDublicate = (el) => {
    const permissions = [];
    Object.entries(el.permissions).forEach((item) => {
      item[1].forEach((permission) => {
        permissions.push(permission.id);
      });
    });
    mutate({ name: `${el?.name} copy`, permissions });
  };

  const columns = [
    {
      title: "Role Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
    },
    {
      title: "Created date",
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: " ",
      dataIndex: "edit",
      key: "edit",
    },
  ];
  const dataSource = rolesList?.map((el, i) => ({
    key: i,
    name: <Typography text={el?.name} variant={"p"} size={"14px"} />,
    users: <Typography text={el?.users?.length} variant={"p"} size={"14px"} />,
    role: (
      <Typography
        text={el?.user?.role?.[0]?.name || ""}
        className={"role-td"}
        align={"center"}
        variant={"p"}
        size={"12px"}
        color={"#3B5166"}
      />
    ),
    created_date: (
      <Flex>
        <Typography
          text={
            el?.created_at
              ? dayjs(el?.created_at).format("DD.MM.YYYY") + ","
              : ""
          }
          variant={"p"}
          size={"14px"}
        />
        <Typography
          text={el?.created_at ? dayjs(el?.created_at).format("HH:MM") : ""}
          margin={"0 0 0 4px"}
          variant={"p"}
          size={"14px"}
          color={"#8E9BA7"}
        />
      </Flex>
    ),
    edit: (
      <Flex alignItems="center">
        <div
          style={{ marginRight: 16 }}
          onClick={() => {
            setEditableItem(el);
            setEditStatus(true);
          }}
        >
          <EditIcon />
        </div>
        <OnexTooltip direction="bottom" content="Dublicate">
          <div onClick={() => handleDublicate(el)}>
            <DublicateIcon />
          </div>
        </OnexTooltip>
      </Flex>
    ),
  }));
  return (
    <Flex
      justifyContent="space-between"
      className={"table-wrapper"}
      direction={"column"}
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        style={{ width: "100%" }}
        pagination={false}
      />
      <RoleEditModal
        status={editStatus}
        setStatus={setEditStatus}
        item={editableItem}
        refetchRoles={refetchRoles}
      />
    </Flex>
  );
};
