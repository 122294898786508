import styled from "styled-components";
import { useState } from "react";
import ReceiveTopActions from "./TopActions.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import NotFoundReceivedIcon from "../../components/SvgComponents/NotFoundReceivedIcon.jsx";
import Flex from "../../components/general/Flex";
import ReceivedUserInfo from "./UserInfo.jsx";
import { Alert } from "antd";
import ReceivedTable from "./Table";
import useGetOrders from "../../hooks/orders/useGetOrders.hook.jsx";
import Loader from "../../components/moleculs/Loader";
import ModalRefillBalance from "./ModalRefillBalance.jsx";

const StyledReceive = styled.div`
  padding-top: 24px;
  min-height: 100vh;
`;

export default function ReceiveOrders({ permissions }) {
  const [user, setUser] = useState(null);
  const [fillBalanceStatus, setFillBalanceStatus] = useState(false);
  const [ordersParams, setOrdersParams] = useState(null);
  const { orders, isLoading } = useGetOrders(ordersParams);
  return (
    <>
      <StyledReceive>
        <div className="page-title">
          <Typography
            text="Receive orders"
            color="rgba(38, 38, 38, 1)"
            weight={500}
            size="24px"
            variant="h2"
            margin="0 0 24px 0"
          />
        </div>
        <ReceiveTopActions
          setOrdersParams={(val) => setOrdersParams(val)}
          setUser={setUser}
        />
        {user ? (
          <div>
            <ReceivedUserInfo
              setFillBalanceStatus={setFillBalanceStatus}
              user={user}
            />
            {!user?.document_number ? (
              <Alert
                message={"Passport details are not filled"}
                type={"warning"}
                showIcon
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {isLoading ? (
          <Loader />
        ) : orders?.data?.length > 0 ? (
          <ReceivedTable
            orders={orders}
            id={user?.user?.id}
            setOrdersParams={setOrdersParams}
            permissions={permissions}
            handleSetUser={(val) => {
              setUser({
                ...user,
                user: val,
              });
            }}
          />
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            direction="column"
            style={{ height: "80vh" }}
          >
            <NotFoundReceivedIcon />
            <Typography
              variant="h2"
              text="Here you can find information about received orders"
              size="20px"
              weight="500"
              lineHeight={"30px"}
              color="#8E9BA7"
              padding="16px 0 0 0"
              style={{ maxWidth: 400 }}
              align="center"
            />
          </Flex>
        )}
      </StyledReceive>
      <ModalRefillBalance
        user={user?.user}
        handleSetUser={(val) => {
          setUser({
            ...user,
            user: val,
          });
        }}
        fillBalanceStatus={fillBalanceStatus}
        setFillBalanceStatus={setFillBalanceStatus}
      />
    </>
  );
}
