import { Divider, Form, Input, Modal } from "antd";
import { RefundedCircleIcon } from "../../components/SvgComponents/RefundedCircleIcon.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import { useIsRefundPayment } from "../../hooks/OnlinePaymen/useIsRefundPayment.hook.js";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import { useEffect } from "react";

export const RefundPaymentModal = ({ refund, setRefund = () => {} }) => {
  const [form] = Form.useForm();
  const { mutate } = useIsRefundPayment(
    () => {
      openNotification("Refund", "Refund success");
      form.resetFields();
      setRefund(false);
    },
    () => openNotificationError("Refund", "something went wrong")
  );
  const onFinish = (values) => {
    mutate({ id: refund?.id, ...values });
  };
  useEffect(() => {
    if (refund) {
      form.setFieldValue(
        "amount",
        +refund?.request_amount - +refund?.refund_amount
      );
    }
  }, [refund]);
  return (
    <Modal
      title={<RefundedCircleIcon />}
      open={refund}
      onCancel={() => setRefund(false)}
      footer={false}
    >
      <Typography
        variant={"h3"}
        text={"Refund to customer bank card"}
        weight={500}
        size={"18px"}
      />
      <Divider />
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="amount"
          label="Refound amount"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={"Refound amount"} suffix="₾" />
        </Form.Item>
        <Flex>
          <ButtonSecondary
            text={"Cancel"}
            width={"100%"}
            height={"36px"}
            click={() => {
              setRefund(false);
              form.resetFields();
            }}
          />
          <ButtonPrimary
            text={"Refound"}
            htmlType={"submit"}
            width={"100%"}
            height={"36px"}
            margin={"0 0 0 12px"}
          />
        </Flex>
      </Form>
    </Modal>
  );
};
