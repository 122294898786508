import { Pagination, Table } from "antd";
import React, { useState } from "react";
import Flex from "../../components/general/Flex.jsx";
import EditIcon from "../../components/SvgComponents/EditIcon.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import { Dropify } from "../../components/SvgComponents/shop-features/dropify.jsx";
import { BuyForMe } from "../../components/SvgComponents/shop-features/buy-for-me.jsx";
import { Special } from "../../components/SvgComponents/shop-features/special.jsx";
import { Video } from "../../components/SvgComponents/shop-features/video.jsx";
import { Blog } from "../../components/SvgComponents/shop-features/blog.jsx";
import { OneClick } from "../../components/SvgComponents/shop-features/one-click.jsx";
import OnexTooltip from "../../helpers/tooltip.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import { SortModal } from "./SortModal.jsx";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const columns = [
  {
    title: "Shop",
    dataIndex: "shop",
    key: "shop",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Features",
    dataIndex: "features",
    key: "features",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 120,
  },
  {
    title: " ",
    dataIndex: "edit",
    key: "edit",
    width: 100,
  },
];

const TableShops = ({
  data,
  setShop = () => {},
  setFilterData = () => {},
  filters,
  meta,
  permissions,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const dataSource = data?.map((shop, i) => ({
    key: i,
    shop: (
      <Flex alignItems={"center"}>
        <img src={shop?.logo} alt={shop?.name} width={60} height={50} />

        <Typography
          margin={"0 0 0 12px"}
          text={shop?.name || ""}
          variant={"p"}
          size={"14px"}
        />
      </Flex>
    ),
    country: (
      <Flex alignItems={"center"}>
        <img
          src={`https://devbackadmin.onex.ge/storage/images/warehouses/${shop?.warehouse?.round_flag}`}
          alt={shop?.name}
          width={28}
          height={28}
        />

        <Typography
          margin={"0 0 0 12px"}
          text={shop?.warehouse?.country || ""}
          variant={"p"}
          size={"14px"}
        />
      </Flex>
    ),
    features: (
      <Flex>
        {!!shop?.is_special && (
          <OnexTooltip content={"Special"} direction={"bottom"}>
            <Special />
          </OnexTooltip>
        )}
        {!!shop?.is_buyforme && (
          <OnexTooltip content={"Buy For Me"} direction={"bottom"}>
            <BuyForMe />
          </OnexTooltip>
        )}
        {!!shop?.is_dropify && (
          <OnexTooltip content={"Dropify"} direction={"bottom"}>
            <Dropify />
          </OnexTooltip>
        )}
        {!!shop?.vlog && (
          <OnexTooltip content={"Video"} direction={"bottom"}>
            <Video />
          </OnexTooltip>
        )}
        {!!shop?.blog && (
          <OnexTooltip content={"Blog"} direction={"bottom"}>
            <Blog />
          </OnexTooltip>
        )}
        {!!shop?.is_one_click && (
          <OnexTooltip content={"One Click"} direction={"bottom"}>
            <OneClick />
          </OnexTooltip>
        )}
      </Flex>
    ),
    actions: (
      <>
        {!!shop?.for_home_page && (
          <ButtonSecondary
            text={"Sort for homepage"}
            click={() => setOpenModal(shop)}
          />
        )}
      </>
    ),
    status: (
      <Typography
        text={shop?.is_active ? "Active" : "Inactive"}
        className={`role-td ${
          shop?.is_active ? "active-status" : "inactive-status"
        }`}
        align={"center"}
        variant={"p"}
        size={"14px"}
      />
    ),
    edit: PermissionFinder(permissions, "shop-edit") ? (
      <EditIcon style={{ cursor: "pointer" }} onClick={() => setShop(shop)} />
    ) : null,
  }));
  const onShowSizeChange = (current, pageSize) => {
    setFilterData({ ...filters, page: current, per_page: pageSize });
  };
  return (
    <>
      <Table
        scroll={{ y: "63vh" }}
        columns={columns || []}
        dataSource={dataSource}
        pagination={false}
      />
      <div className={"new-pagination"} style={{ padding: 0 }}>
        <Pagination
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={onShowSizeChange}
          defaultPageSize={meta?.per_page || 15}
          defaultCurrent={meta?.current_page || 1}
        />
      </div>
      <SortModal open={openModal} setOpen={setOpenModal} />
    </>
  );
};
export default TableShops;
