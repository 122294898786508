import { AutoComplete, Form, Input } from "antd";
import Flex from "../../../components/general/Flex.jsx";
import Typography from "../../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../../constants.js";
import HeightIcon from "../../../components/SvgComponents/HeightIcon.jsx";
import DepthIcon from "../../../components/SvgComponents/ParcelDepth.jsx";
import LengthIcon from "../../../components/SvgComponents/LengthIcon.jsx";
import { GetParcels } from "../../../services/parcels.js";
import { useState } from "react";

export default function Information({ order }) {
  const [selectedParcel, setSelectedParcel] = useState();
  const [parcelOptions, setParcelOptions] = useState();

  const handleSearchParcel = (value) => {
    setParcelOptions([]);
    if (value) {
      GetParcels({ name: value }).then((res) => {
        let newOptions = res.data.data.data.map((parcel) => {
          return {
            label: parcel.name,
            value: parcel.id,
            key: parcel.id,
          };
        });
        setParcelOptions(newOptions);
      });
    }
  };

  return (
    <div>
      <Flex justifyContent="space-between">
        <div className="info-block">
          <Flex>
            <div className="info-block-item">
              <Form.Item name={"weight"} label={"Weight"}>
                <Input suffix="kg" style={{ width: 100 }} />
              </Form.Item>
            </div>
            <div className="info-block-item">
              <Form.Item label={"V Weight"} name={"v_weight"}>
                <Input suffix="kg" style={{ width: 100 }} />
              </Form.Item>
            </div>
            <div className="info-block-item">
              <Form.Item name={"cost"} label={"Cost"}>
                <Input suffix={"₾"} style={{ width: 100 }} />
              </Form.Item>
            </div>
            {/* <div className="info-block-item"> */}
            {/*   <Typography */}
            {/*     text="Tarif" */}
            {/*     variant="p" */}
            {/*     color="#3B5166" */}
            {/*     size="14px" */}
            {/*     weight="500" */}
            {/*     padding="0 0 5px 0" */}
            {/*   /> */}
            {/*   <Input value="400" suffix="֏" style={{ width: 100 }} /> */}
            {/* </div> */}
          </Flex>
        </div>
        <div className="info-block">
          <Flex>
            <div className="info-block-item">
              <div className="info-block-item">
                <Form.Item name={"parcel_id"} label={"Parcel ID"}>
                  <AutoComplete
                    placeholder="Parcel"
                    onSearch={handleSearchParcel}
                    options={parcelOptions}
                    onSelect={(val, all) => setSelectedParcel(all)}
                    style={{ width: 150, height: 36 }}
                    value={order?.parcel_id}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="info-block-item">
              <Form.Item name={"box_id"} label={"Box ID"}>
                <Input style={{ width: 150 }} placeholder={"Box ID"} />
              </Form.Item>
            </div>
            <div className="info-block-item">
              <Typography
                text="Estimated"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                variant="p"
                text={order?.estimated_date_to}
                size="14px"
                weight="400"
                color={primaryTextColor}
                padding="10px 0 0 0"
              />
            </div>
          </Flex>
        </div>
      </Flex>
      <Flex justifyContent="space-between">
        <div className="info-block" style={{ minHeight: 138 }}>
          <Flex>
            <div className="info-block-item">
              <Typography
                text="Pickup Point"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 5px 0"
              />
              <Typography
                text={order?.pickup_point?.address}
                variant="p"
                color="#3B5166"
                size="12px"
                weight="400"
                padding="0 0 5px 0"
              />
            </div>
            {/* <div className="info-block-item"> */}
            {/*   <Typography */}
            {/*     text="Destination" */}
            {/*     variant="p" */}
            {/*     color="#3B5166" */}
            {/*     size="14px" */}
            {/*     weight="500" */}
            {/*     padding="0 0 5px 0" */}
            {/*   /> */}
            {/*   <Flex style={{ paddingTop: 10 }}> */}
            {/*     <LockerIcon /> */}
            {/*     <Typography */}
            {/*       variant="p" */}
            {/*       text="Megamall" */}
            {/*       size="14px" */}
            {/*       weight="400" */}
            {/*       color={primaryTextColor} */}
            {/*       padding="0 0 0 4px" */}
            {/*     /> */}
            {/*   </Flex> */}
            {/* </div> */}
          </Flex>
        </div>
        <div className="info-block">
          <Flex>
            <div className="info-block-item">
              <Form.Item name={"height"} label={"Height"}>
                <Input
                  className="input-prefix-suffix"
                  value="0.4"
                  prefix={<HeightIcon />}
                  suffix="mm"
                  style={{ width: 120 }}
                />
              </Form.Item>
            </div>
            <div className="info-block-item">
              <Form.Item name={"width"} label={"Width"}>
                <Input
                  className="input-prefix-suffix"
                  value="0.4"
                  prefix={<DepthIcon />}
                  suffix="mm"
                  style={{ width: 120 }}
                />
              </Form.Item>
            </div>
            <div className="info-block-item">
              <Form.Item name={"length"} label={"Length"}>
                <Input
                  className="input-prefix-suffix"
                  value="0.4"
                  prefix={<LengthIcon />}
                  suffix="mm"
                  style={{ width: 120 }}
                />
              </Form.Item>
            </div>
          </Flex>
          {/* <div style={{ marginTop: 16 }}> */}
          {/*   <Typography */}
          {/*     text="Sizes" */}
          {/*     variant="p" */}
          {/*     color="#3B5166" */}
          {/*     size="14px" */}
          {/*     weight="500" */}
          {/*     padding="0 0 5px 0" */}
          {/*   /> */}
          {/*   <Select style={{ width: 200 }} defaultValue="Not Standart Size"> */}
          {/*     <Select.Option> */}
          {/*       <Tag */}
          {/*         text="Not Standart Size" */}
          {/*         background="#EDF5FB" */}
          {/*         color="#4889C5" */}
          {/*       /> */}
          {/*     </Select.Option> */}
          {/*   </Select> */}
          {/* </div> */}
        </div>
      </Flex>
    </div>
  );
}
