import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import { useFetchRoles } from "../../hooks/members-and-roles/useFetchRoles.hook.jsx";
import { RolesTable } from "./RolesTable.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import Flex from "../../components/general/Flex";
import { useState } from "react";
import CreateRoleModal from "./CreateRoleModal.jsx";
import { Pagination } from "antd";

const StyledCustomers = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;

  .total {
    color: #5dba2f;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
  }

  .customers-top {
    @media (max-width: 10010px) {
      flex-wrap: wrap !important;
    }
  }

  .ant-table-thead > tr > th {
    background: #e7e9ec;
  }

  .table-wrapper {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    max-height: 70vh;
    overflow: auto;
    width: 100%;
    background: #ffffff;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .role-td {
    background: #edf5fb;
    border-radius: 16px;
    width: min-content;
    padding: 2px 10px 2px 10px;
    white-space: nowrap;
  }

  .inactive-status {
    background: #ffeced;
    color: #fc4447;
  }

  .active-status {
    background: #eff8ea;
    color: #5dba2f;
  }
`;

export default function Roles({ permissions }) {
  const [query, setQuery] = useState({});
  const { roles, refetch, meta } = useFetchRoles(query);
  const [createRole, setCreateRole] = useState(false);

  return (
    <StyledCustomers>
      <div className="page-title">
        <Typography
          text="Roles"
          color="#262626"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <Flex justifyContent={"end"}>
        <ButtonSecondary
          text="Create role"
          style={{ height: 35 }}
          click={() => setCreateRole(true)}
        />
      </Flex>
      <RolesTable rolesList={roles?.data} refetchRoles={refetch} />
      <div className={"new-pagination"}>
        <Pagination
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page) => setQuery({ ...query, page })}
          defaultPageSize={meta?.per_page || 10}
          defaultCurrent={meta?.current_page || 1}
        />
      </div>
      <CreateRoleModal createRole={createRole} setCreateRole={setCreateRole} />
    </StyledCustomers>
  );
}
