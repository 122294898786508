import { useMutation, useQueryClient } from "react-query";
import { testUserApi } from "../../services/test-users";

export const deleteTestUserHook = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const client = useQueryClient();
  return useMutation(testUserApi.deleteTestUsers, {
    onSuccess,
    onError,
    onSettled: async () => {
      await client.invalidateQueries(["delete-expected"]);
    },
  });
};
