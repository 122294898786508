import styled from "styled-components";
import { Spin } from "antd";

const StyledLoader = styled.div`
  margin-top: 20px;
  height: ${({ height }) => height || "95vh"};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default function Loader(props) {
  return (
    <StyledLoader {...props}>
      <Spin size={"large"} />
    </StyledLoader>
  );
}
