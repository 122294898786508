import { Pagination, Table } from "antd";
import React from "react";
import Flex from "../../components/general/Flex.jsx";
import EditIcon from "../../components/SvgComponents/EditIcon.jsx";
import dayjs from "dayjs";
import Typography from "../../components/moleculs/Typography.jsx";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const columns = [
  {
    key: "sort",
    dataIndex: "sort",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: "40%",
  },
  {
    title: "Language",
    dataIndex: "language",
    key: "language",
  },
  {
    title: "Created date",
    dataIndex: "created_date",
    key: "created_date",
  },
  {
    title: "Updated date",
    dataIndex: "updated_date",
    key: "updated_date",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: " ",
    dataIndex: "edit",
    key: "edit",
  },
];

const TableBanners = ({
  data = [],
  setBanner = () => {},
  setFilters = () => {},
  meta,
  languages,
  filters,
  permissions,
}) => {
  const openModal = (item, index) => {
    const newData = { ...item, language_id: index + 1 };
    setBanner(newData);
  };

  const newData = data?.map((el, i) => ({
    key: el?.id,
    sort: el?.id,
    title: (
      <Flex>
        <div
          dangerouslySetInnerHTML={{ __html: el?.text || "" }}
          style={{ fontSize: "14px", fontFamily: "Montserrat arm2" }}
          // variant={"p"}
          // size={"14px"}
        />
      </Flex>
    ),
    language: (
      <Flex>
        {languages?.length > 0
          ? languages?.map((ml, index) => (
              <img
                alt={ml.code}
                key={ml.id}
                onClick={() => openModal(el, index)}
                src={ml.flag}
                style={{
                  marginRight: "10px",
                  opacity: el?.details?.find((d) => d.language_id === ml.id)
                    ? "1"
                    : "0.2",
                  cursor: "pointer",
                }}
                width="24"
              />
            ))
          : ""}
      </Flex>
    ),

    created_date: (
      <Flex>
        <Typography
          text={
            el?.created_at
              ? dayjs(el?.created_at).format("DD.MM.YYYY") + ","
              : ""
          }
          variant={"p"}
          size={"14px"}
        />
        <Typography
          text={el?.created_at ? dayjs(el?.created_at).format("HH:mm") : ""}
          margin={"0 0 0 4px"}
          variant={"p"}
          size={"14px"}
          color={"#8E9BA7"}
        />
      </Flex>
    ),
    updated_date: (
      <Flex>
        <Typography
          text={
            el?.updated_at
              ? dayjs(el?.updated_date).format("DD.MM.YYYY") + ","
              : ""
          }
          variant={"p"}
          size={"14px"}
        />
        <Typography
          text={el?.updated_at ? dayjs(el?.updated_at).format("HH:mm") : "-"}
          margin={"0 0 0 4px"}
          variant={"p"}
          size={"14px"}
          color={"#8E9BA7"}
        />
      </Flex>
    ),
    status: (
      <Typography
        text={el?.is_active ? "Active" : "Inactive"}
        className={`role-td ${
          el?.is_active ? "active-status" : "inactive-status"
        }`}
        align={"center"}
        variant={"p"}
        size={"14px"}
      />
    ),
    edit: PermissionFinder(permissions, "header-message-edit") ? (
      <EditIcon style={{ cursor: "pointer" }} onClick={() => openModal(el)} />
    ) : null,
  }));

  return (
    <>
      <Table
        dataSource={newData}
        columns={columns}
        style={{ width: "100%" }}
        pagination={false}
      />
      <div className={"new-pagination"} style={{ padding: 0 }}>
        <Pagination
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={(page) => setFilters({ ...filters, page })}
          defaultPageSize={meta?.per_page || 15}
          defaultCurrent={meta?.current_page || 1}
        />
      </div>
    </>
  );
};
export default TableBanners;
