import styled from "styled-components";
import Flex from "../../../components/general/Flex";
import Typography from "../../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../../constants.js";
import Tag from "../../../components/moleculs/Tag.jsx";

const StyledCosts = styled.div`
  margin: 16px 0;
  background: white;
  padding: 20px;
  border-radius: 12px;
  .cost-items {
    .cost-item {
      padding-left: 10px;
      margin-right: 50px;
    }
    .cost-item:not(:first-child) {
      border-left: 1px solid #e7e9ec;
    }
  }
`;

export default function OrderDetailsCosts({ order }) {
  return (
    <StyledCosts>
      <Flex justifyContent="space-between">
        <Flex className={"cost-items"}>
          <div className="cost-item">
            <Typography
              variant="p"
              text="Cost"
              color="#3B5166"
              size="14"
              weight="500"
            />
            <Typography
              variant="p"
              text={`${order.cost} ₾`}
              color={primaryTextColor}
              size="14"
              weight="400"
            />
          </div>
          <div className="cost-item">
            <Typography
              variant="p"
              text="Additional cost"
              color="#3B5166"
              size="14"
              weight="500"
            />
            <Typography
              variant="p"
              text={order.additional_cost}
              color={primaryTextColor}
              size="14"
              weight="400"
            />
          </div>
          {/* <div className="cost-item"> */}
          {/*   <Typography */}
          {/*     variant="p" */}
          {/*     text="Add. charge cost" */}
          {/*     color="#3B5166" */}
          {/*     size="14" */}
          {/*     weight="500" */}
          {/*   /> */}
          {/*   <Typography */}
          {/*     variant="p" */}
          {/*     text="0" */}
          {/*     color={primaryTextColor} */}
          {/*     size="14" */}
          {/*     weight="400" */}
          {/*   /> */}
          {/* </div> */}
          {/* <div className="cost-item"> */}
          {/*   <Typography */}
          {/*     variant="p" */}
          {/*     text="UPS cost" */}
          {/*     color="#3B5166" */}
          {/*     size="14" */}
          {/*     weight="500" */}
          {/*   /> */}
          {/*   <Typography */}
          {/*     variant="p" */}
          {/*     text="0" */}
          {/*     color={primaryTextColor} */}
          {/*     size="14" */}
          {/*     weight="400" */}
          {/*   /> */}
          {/* </div> */}
        </Flex>
        <Flex>
          {order?.invoice ? (
            <a href={order.invoice.file} target="_blank" download={true}>
              <Tag
                text="Invoice"
                background="#EDFBFE"
                color="#47BFE1"
                margin="0 16px 0 0"
              />
            </a>
          ) : null}

          {/* <Tag text="Registered" background="#FFF5EB" color="#E58C35" /> */}
        </Flex>
      </Flex>
    </StyledCosts>
  );
}
