import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import styled from "styled-components";
import ReceiveTableContent from "./ReceiveTableContent";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import filledInfoIcon from "../../assets/images/filled-info-icon.png";
import Modal from "../../components/modals/Modal.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import { useState } from "react";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import { useReceiveOrders } from "../../hooks/receiveOrders/useReceiveOrders.hook.js";
import { Input, Tabs } from "antd";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const { Search } = Input;

const StyledOrdersCheck = styled.div`
  //margin: 24px 0 0 24px;
  padding-top: 24px;
  .search-tracking {
    margin-top: 16px;
    margin-right: 20px;
    input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      height: 36px;
    }
    button {
      background: #5b6d7f;
      border-color: transparent;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      height: 35px;
      outline: none;
    }
  }
  .tabs {
    margin-top: 4px;
  }
  .tab-content {
    text-align: center;
    margin-left: 10px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background: #eff8ea;
    color: #5dba2f;
    font-weight: 500;
  }
`;
export default function ReceivedTable({
  orders,
  id,
  handleSetUser,
  setOrdersParams,
  permissions,
}) {
  const [ordersData, setOrdersData] = useState(orders?.data || []);
  const [receiveModalStatus, setReceiveModalStatus] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tabKey, setTabKey] = useState("All");
  const [searchTracking, setSearchTracking] = useState("");
  const checkedData = orders?.data?.filter(function (item) {
    return selectedRowKeys?.length > 0
      ? selectedRowKeys?.includes(item?.id)
      : false;
  });
  const uncheckedData = orders?.data?.filter(function (item) {
    return selectedRowKeys?.length > 0
      ? !selectedRowKeys?.includes(item?.id)
      : true;
  });
  const onChange = (key) => {
    setTabKey(+key);
    if (+key === 1) {
      setTabKey("All");
      setOrdersData(orders?.data);
    } else if (+key === 2) {
      setTabKey("Checked");
      setOrdersData(checkedData);
    } else if (+key === 3) {
      setTabKey("Unchecked");
      setOrdersData(uncheckedData);
    }
  };
  const handleSearchByTracking = (key) => {
    if (key === 13) {
      if (searchTracking) {
        const searchData = orders?.data.find((el) => {
          return el?.tracking_code
            ?.toString()
            .toLocaleUpperCase()
            .includes(searchTracking.toString().toLocaleUpperCase());
        });
        if (searchData) {
          setSelectedRowKeys([...selectedRowKeys, searchData?.id]);
        }
      }
    }
  };
  let totalPrice = 0;
  let totalWeight = 0;
  ordersData?.forEach((el) => {
    if (el.cost) {
      totalPrice += Number(el.cost);
    }
    if (el.weight) {
      totalWeight += Number(el.weight);
    }
  });
  const items = [
    {
      key: "1",
      label: (
        <Flex>
          All{" "}
          <span className={"tab-content"}>{orders?.data?.length || "0"}</span>
        </Flex>
      ),
    },
    {
      key: "2",
      label: (
        <Flex>
          Checked{" "}
          <span className={"tab-content"}>{checkedData?.length || "0"}</span>
        </Flex>
      ),
    },
    {
      key: "3",
      label: (
        <Flex>
          Unchecked{" "}
          <span className={"tab-content"}>{uncheckedData?.length || "0"}</span>
        </Flex>
      ),
    },
  ];
  const { mutate: receiveOrders } = useReceiveOrders(
    (res) => {
      openNotification("Receive orders", "successful");
      handleSetUser(res?.data?.data);
      setOrdersParams({
        recipient_id: id,
        ready_for_pickup: 1,
        order_status: "in_georgia",
        per_page: 1000,
      });
      setReceiveModalStatus(false);
    },
    (e) => {
      setReceiveModalStatus(false);
      openNotificationError(
        "Receive orders",
        `${
          e?.response?.data?.message
            ? "Insufficient balance, please check customer balance."
            : e?.response?.data?.data?.order_ids[0]
            ? e?.response?.data?.data?.order_ids[0]
            : "something went wrong"
        } `
      );
    }
  );
  const handleReceive = async () => {
    if (!selectedRowKeys?.length > 0) {
      openNotificationError("Receive orders", "no order selected");
      return;
    }
    receiveOrders({ id: id, order_ids: selectedRowKeys });
  };
  return (
    <StyledOrdersCheck className="orders">
      <Flex>
        <Search
          className="search-tracking"
          enterButton="Check"
          placeholder="Tracking code"
          onSearch={() => handleSearchByTracking(13)}
          onChange={(e) => setSearchTracking(e.target.value)}
          onKeyDown={(e) => handleSearchByTracking(e.keyCode)}
          style={{ width: 330 }}
        />
        <Flex
          style={{ width: "100%", marginBottom: "15px" }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Tabs
            className="tabs"
            items={items}
            onChange={onChange}
            defaultActiveKey="1"
            style={{ width: "50%" }}
          />
          {PermissionFinder(permissions, "order-edit") ? (
            <ButtonPrimary
              text={"Receive selected orders"}
              click={() => setReceiveModalStatus(true)}
            />
          ) : null}
        </Flex>
      </Flex>
      <ReceiveTableContent
        tabKey={tabKey}
        setReceiveModalStatus={setReceiveModalStatus}
        orders={ordersData}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        totalPrice={totalPrice}
        totalWeight={totalWeight}
      />
      <Modal
        icon={<img src={filledInfoIcon} />}
        width={400}
        status={receiveModalStatus}
        setStatus={setReceiveModalStatus}
      >
        <Typography
          text={"Receive orders"}
          variant={"h4"}
          size={"18px"}
          weight={"500"}
          color={"#101828"}
          padding={"0 0 20px 0"}
        />
        <Typography
          text={"Are you sure you want to make orders status receive"}
          variant={"h4"}
          size={"14px"}
          weight={"400"}
          color={"#5B6D7F"}
          padding={"0 0 20px 0"}
        />
        <Flex justifyContent={"space-between"}>
          <ButtonSecondary
            text={"Cancel"}
            width={"48%"}
            click={() => setReceiveModalStatus(false)}
          />
          <ButtonPrimary text={"Receive"} width={"48%"} click={handleReceive} />
        </Flex>
      </Modal>
    </StyledOrdersCheck>
  );
}
