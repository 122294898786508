import { createSlice } from "@reduxjs/toolkit"
import { getOrders } from "../../actions/ordersAction"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

const initialState = {
  orders: [],
  isLoading: false,
}
export const ordersSlice = createSlice({
  name: "pokemon",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.orders = action.payload.data
    })

    builder.addCase(getOrders.rejected, (state, action) => {
      console.log(action.payload)
    })
  },
})

const ordersReducer = ordersSlice.reducer
export default ordersReducer
