import { AutoComplete, Col, DatePicker, Form, Input, Row, Select } from "antd";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import { useSubjectTypes } from "../../hooks/useActionsHistory.hook.js";
import dayjs from "dayjs";
import { useState } from "react";
import Tag from "../../components/moleculs/Tag.jsx";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";

export default function ActionSearch({ setFilter = () => {} }) {
  const { subject_types = [] } = useSubjectTypes();
  const [form] = Form.useForm();
  const [searchBy, setSearchBy] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [selectId, setSelectId] = useState(null);
  const { usersList = [] } = usersAutocomplete(
    userInfo && userInfo?.user_info?.trim()?.length === 0 ? null : userInfo
  );
  const onFinish = (values) => {
    let newValues = {};
    Object.entries(values).forEach(([key, val]) => {
      if (val) {
        if (key === "date") {
          newValues["start_date"] = dayjs(val[0]).format("YYYY-MM-DD");
          newValues["end_date"] = dayjs(val[1]).format("YYYY-MM-DD");
        } else {
          newValues[key] = val;
        }
        if (selectId) {
          newValues["subject_id"] = selectId;
        }
      }
    });
    setFilter(newValues);
  };
  const reset = () => {
    form.resetFields();
    setFilter({});
    setSearchBy(null);
    setSelectId(null);
  };
  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Row gutter={16} align={"bottom"}>
        <Col>
          <Input.Group
            compact
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <Form.Item label={"Search by"} name={"subject_type"}>
              <Select
                defaultValue="All"
                style={{ width: 180, height: 36 }}
                onChange={(value) => {
                  setSearchBy(value?.split("\\")?.pop());
                  setSelectId(null);
                  form.setFieldValue("subject_id", "");
                }}
                options={[{ value: "", label: "All" }, ...subject_types]}
              />
            </Form.Item>
            <Form.Item name={"subject_id"} style={{ width: 290, height: 36 }}>
              {searchBy === "Recipient" ? (
                <AutoComplete
                  className={"_auto-complete"}
                  placeholder="Search by User Code"
                  onSearch={(val, el) => setUserInfo({ user_info: val })}
                  onSelect={(_, el) => setSelectId(el.user_code)}
                  options={usersList}
                />
              ) : (
                <Input
                  placeholder={`Search by ${
                    searchBy === "Order"
                      ? "Tracking code"
                      : searchBy === "Recipient"
                      ? "User Code"
                      : "Id"
                  }`}
                  disabled={!searchBy}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      // handleSearch();
                    }
                  }}
                />
              )}
            </Form.Item>
          </Input.Group>
        </Col>
        <Col lg={2}>
          <Form.Item name={"event"}>
            <Select
              style={{ height: 36 }}
              placeholder={"All Actions"}
              options={[
                { value: null, label: "All Actions" },
                {
                  value: "created",
                  label: (
                    <Tag
                      text={"Created"}
                      background={"#EFF8EA"}
                      color={"#5DBA2F"}
                    />
                  ),
                },
                {
                  value: "updated",
                  label: (
                    <Tag
                      text={"Updated"}
                      background={"#EDF5FB"}
                      color={"#4F96D9"}
                      transform={"capitalize"}
                    />
                  ),
                },
                {
                  value: "deleted",
                  label: (
                    <Tag
                      text={"Deleted"}
                      background={"#FFECED"}
                      color={"#FC4447"}
                      transform={"capitalize"}
                    />
                  ),
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={"date"}>
            <DatePicker.RangePicker
              onChange={(val) => {
                if (!val) {
                  setFilter((pre) => ({
                    ...pre,
                    end_date: null,
                    start_date: null,
                  }));
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <ButtonSecondary
              text="Reset"
              click={() => reset()}
              height={"35px"}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <ButtonPrimary
              height={"35px"}
              icon={<SearchIcon />}
              text="Search"
              htmlType={"submit"}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
