import { Checkbox, Form, Input } from "antd";
import TabList from "../../../components/tab/TabList.jsx";

export const DetailsParts = ({
  languages = [],
  languageId,
  setLanguageId,
  form,
  showVlog,
  setShowVlog = () => {},
}) => {
  return (
    <Form.List name={"details"}>
      {(fields, { add, remove }) => {
        return (
          <div>
            <TabList
              data={languages}
              onChange={(key) => {
                setLanguageId(key);
                form.setFieldValue(["details", key - 1, "language_id"], key);
              }}
            />

            <div className={"style_form"}>
              <Form.Item
                name={[languageId - 1, "description"]}
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Missing Blog Title.",
                  },
                ]}
              >
                <Input
                  style={{ minHeight: "52px" }}
                  placeholder={"Description"}
                  maxLength={255}
                  showCount={true}
                />
              </Form.Item>
              <div
                className={"checked-item"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox onChange={(e) => setShowVlog(e.target.checked)}>
                  Video
                </Checkbox>

                <Form.Item
                  name={[languageId - 1, "vlog"]}
                  className={`blog_select ${!showVlog && "inactive"}`}
                >
                  <Input placeholder={"Video URL "} />
                </Form.Item>
              </div>
            </div>
          </div>
        );
      }}
    </Form.List>
  );
};
