import styled from "styled-components";
import { Input, Select } from "antd";
import { useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import Flex from "../../components/general/Flex";
import { useNavigate } from "react-router-dom";

const StyledSearch = styled.div`
  .search-btn {
    margin-left: 16px;
  }
`;

export default function CustomersSingleSearch({}) {
  const [searchValue, setSearchValue] = useState();
  const [searchKey, setSearchKey] = useState("user_info");
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/customers?searchkey=${searchKey}&searchValue=${searchValue}`);
  };

  return (
    <StyledSearch>
      <Typography
        text="Search by"
        variant="span"
        color="#3B5166"
        size="14px"
        weight="500"
        padding="0 0 5px 0"
      />
      <Flex alignItems="center">
        <div>
          <Input.Group compact>
            <Select
              defaultValue="user_info"
              style={{ width: 180, height: 36 }}
              onChange={(value) => setSearchKey(value)}
            >
              <Select.Option value="user_info">Full name or GA</Select.Option>
              <Select.Option value="phone">Phone</Select.Option>
              <Select.Option value="user_id">ID</Select.Option>
              <Select.Option value="address">Address</Select.Option>
              <Select.Option value="email">Email</Select.Option>
              <Select.Option value="passport_details">
                Passport details
              </Select.Option>
            </Select>
            <Input
              style={{ width: 290 }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSearch();
                }
              }}
            />
          </Input.Group>
        </div>
        <div className="search-btn">
          <ButtonPrimary
            icon={<SearchIcon />}
            text="Search"
            click={() => handleSearch()}
          />
        </div>
      </Flex>
    </StyledSearch>
  );
}
