import { Alert, Input } from "antd";
import styled from "styled-components";
import { useState } from "react";
import Flex from "../../../components/general/Flex";
import CancelIcon from "../../../components/SvgComponents/CancelIcon";
import AcceptIcon from "../../../components/SvgComponents/AcceptIcon";

const StyledComment = styled.div`
  margin: 16px 0;

  .ant-alert-warning {
    width: 93%;
  }
`;

const StyledEditComment = styled.div`
  margin: 16px 0;
`;

export default function OrdersDetailsComment({ comment, category }) {
  const [editStatus, setEditStatus] = useState(false);

  return (
    <>
      {!editStatus ? (
        <StyledComment>
          <Flex justifyContent="space-between">
            <Alert
              type="warning"
              showIcon
              message={comment}
              style={{ width: "100%" }}
            />
            {/*<div onClick={() => setEditStatus(true)}>*/}
            {/*  <IconButton icon={<EditIcon />} />*/}
            {/*</div>*/}
          </Flex>
        </StyledComment>
      ) : (
        <StyledEditComment>
          <Flex>
            <Input value={comment} />
            <div style={{ marginLeft: 16 }}>
              <Flex>
                <div style={{ marginRight: 8 }}>
                  <div className="cancel" onClick={() => setEditStatus(false)}>
                    <CancelIcon />
                  </div>
                </div>
                <div className="accept" onClick={() => setEditStatus(false)}>
                  <AcceptIcon />
                </div>
              </Flex>
            </div>
          </Flex>
        </StyledEditComment>
      )}
    </>
  );
}
