import { Form, Input, Select, Upload } from "antd";
import Flex from "../../../components/general/Flex.jsx";
import Typography from "../../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../../constants.js";
import FileIcon from "../../../components/SvgComponents/FileIcon.jsx";
import QuestionIcon from "../../../components/SvgComponents/QuestionIcon.jsx";
import MissingIcon from "../../../components/SvgComponents/MissingIcon.jsx";
import AlertCircleIcon from "../../../components/SvgComponents/AlertCircleIcon.jsx";

import styled from "styled-components";
import useGetCurrencies from "../../../hooks/useGetCurrencies.hooks.jsx";
import Loader from "../../../components/moleculs/Loader";
import { useState } from "react";

const StyledEditOrderHeader = styled.div`
  .ant-input-group-addon {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
`;

export default function Header({ warehouses, order, form }) {
  const { currencies } = useGetCurrencies();
  const [dispatch, setDispatch] = useState(null);

  const selectAfterDeclarated = (
    <Select defaultValue="Cur" style={{ width: 70 }}>
      {currencies &&
        Object.values(currencies).map((item, index) => {
          return (
            <Select.Option key={index} value={item}>
              {item}
            </Select.Option>
          );
        })}
      {/*{currencies?.map((currency, index) => (*/}
      {/*  */}
      {/*))}*/}
    </Select>
  );
  return (
    <StyledEditOrderHeader>
      {currencies ? (
        <Flex
          onClick={(e) => e.preventDefault()}
          justifyContent="space-between"
          style={{ alignItems: "center" }}
        >
          <div>
            <Flex>
              <Form.Item name="warehouse_id" label={"Whouse"}>
                <Select
                  style={{ width: 200, height: 36 }}
                  className="icon-select"
                  defaultValue={order?.warehouse.id}
                  onSelect={(_, el) => {
                    setDispatch(el?.dispatches);
                    form.setFieldValue("dispatch_type", null);
                  }}
                  options={warehouses}
                />
              </Form.Item>

              <Form.Item
                name="dispatch_type"
                label={"Dispatch"}
                style={{ margin: "0px 16px" }}
              >
                <Select style={{ width: 120, height: 36 }}>
                  {dispatch?.map((item, index) => (
                    <Select.Option value={item.type} key={index}>
                      <div style={{ paddingTop: 2 }}>
                        <Flex alignItems={"center"}>
                          <img src={item?.icon} alt="dispatch" width={24} />
                          <span style={{ paddingLeft: 5 }}>{item.name}</span>
                        </Flex>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="tracking_code"
                style={{ marginRight: 16 }}
                label={"Tracking code"}
              >
                <Input style={{ width: 360 }} disabled />
              </Form.Item>
              <Form.Item name="status" label={"Status"}>
                <Select
                  style={{ width: 160, height: 36 }}
                  className="two-select-1"
                  placeholder="In Armenia"
                >
                  <Select.Option value="at_warehouse">
                    At Warehouse
                  </Select.Option>
                  <Select.Option value="on_the_way">On the way</Select.Option>
                  <Select.Option value="scan">Scanned</Select.Option>
                  <Select.Option value="in_georgia">In georgia</Select.Option>
                  <Select.Option value="received">Received</Select.Option>
                  <Select.Option value="return">Return</Select.Option>
                  <Select.Option value="refund">Refunded</Select.Option>
                </Select>
              </Form.Item>
            </Flex>
            <Flex>
              <div className="input-with-dropdown">
                <Form.Item
                  name={"declaration_price"}
                  label={"Declarated price"}
                >
                  <Input
                    addonAfter={
                      <Form.Item name={"declaration_currency"}>
                        {selectAfterDeclarated}
                      </Form.Item>
                    }
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={"invoice"}
                label={"Invoice"}
                style={{ marginLeft: 16 }}
              >
                <div className="edit-item">
                  <div className="invoice-file">
                    <Upload name="invoice">
                      <label htmlFor="invoice">
                        <Flex>
                          <FileIcon size={22} />
                          <Typography
                            variant="span"
                            text={order?.invoice?.name}
                            color={primaryTextColor}
                            size="14px"
                            weight="400"
                            padding="0 0 0 10px"
                          />
                        </Flex>
                      </label>
                    </Upload>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name={"tag"} label={"Tag"}>
                <>
                  <Select
                    style={{ width: 222, height: 36 }}
                    defaultValue="Additional Info Text"
                  >
                    <Select.Option value="additional">
                      <Flex alignItems="center">
                        <QuestionIcon />
                        <Typography
                          text="Additional Info text"
                          variant="span"
                          color="#5B6D7F"
                          size="14px"
                          weight="400"
                          padding="3px 0 0 8px"
                        />
                      </Flex>
                    </Select.Option>
                    <Select.Option value="missing">
                      <Flex alignItems="center">
                        <MissingIcon />
                        <Typography
                          text="Missing"
                          variant="span"
                          color="#5B6D7F"
                          size="14px"
                          weight="400"
                          padding="3px 0 0 8px"
                        />
                      </Flex>
                    </Select.Option>
                    <Select.Option value="is_dangerous">
                      <Flex alignItems="center">
                        <AlertCircleIcon />
                        <Typography
                          text="Dangerous"
                          variant="span"
                          color="#5B6D7F"
                          size="14px"
                          weight="400"
                          padding="3px 0 0 8px"
                        />
                      </Flex>
                    </Select.Option>
                  </Select>
                </>
              </Form.Item>
            </Flex>
          </div>
        </Flex>
      ) : (
        <Loader />
      )}
    </StyledEditOrderHeader>
  );
}
