export default function WayIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.65313 3.03851H7.07243C10.0137 3.03851 11.4844 3.03851 12.0427 3.56678C12.5252 4.02341 12.7391 4.69609 12.6088 5.34756C12.4581 6.10122 11.2574 6.95047 8.85607 8.64897L4.93282 11.424C2.53149 13.1225 1.33082 13.9717 1.1801 14.7254C1.04981 15.3768 1.26367 16.0495 1.74623 16.5061C2.3045 17.0344 3.77515 17.0344 6.71646 17.0344H7.61837"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0309 14.7674C13.7179 14.7674 14.2748 14.2105 14.2748 13.5235C14.2748 12.8365 13.7179 12.2796 13.0309 12.2796C12.3439 12.2796 11.787 12.8365 11.787 13.5235C11.787 14.2105 12.3439 14.7674 13.0309 14.7674Z"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0309 18.4991C14.6894 16.8406 16.3479 15.3555 16.3479 13.5235C16.3479 11.6916 14.8629 10.2065 13.0309 10.2065C11.199 10.2065 9.71387 11.6916 9.71387 13.5235C9.71387 15.3555 11.3724 16.8406 13.0309 18.4991Z"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="2.55326"
        cy="2.83927"
        rx="1.41069"
        ry="1.41069"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
