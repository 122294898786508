import styled from "styled-components";
import { AutoComplete, DatePicker, Form, Input, Select } from "antd";
import Flex from "../../components/general/Flex";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import { useState } from "react";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";
import dayjs from "dayjs";
import { useGetBonusTypeLis } from "../../hooks/bonus/useGetBonusTypeList.hook.jsx";

const { RangePicker } = DatePicker;

const StyledBalanceTopActions = styled.div`
  margin-top: 24px;
  padding: 16px;
  background: #ffffff;
  border-radius: 12px;

  .item {
    margin-right: 16px;
  }

  .ipt {
    min-width: 300px;
  }
`;

export default function BonusTopActions({ setFilterData }) {
  const [form] = Form.useForm();
  const [userInfo, setUserInfo] = useState(null);
  const [selectId, setSelectId] = useState(null);
  const { usersList = [] } = usersAutocomplete(
    userInfo && userInfo?.user_info?.trim()?.length === 0 ? null : userInfo
  );
  const { bonusTypeList = [] } = useGetBonusTypeLis();
  const onFinish = (values) => {
    let newValues = {};
    Object.entries(values).forEach(([key, val]) => {
      if (val) {
        if (key === "date") {
          newValues["given_date"] = dayjs(val[0]).format("YYYY-MM-DD");
          newValues["given_date_end"] = dayjs(val[1]).format("YYYY-MM-DD");
        } else {
          newValues[key] = val;
        }
        if (selectId) {
          newValues["user_id"] = selectId;
        }
      }
    });
    setFilterData(newValues);
  };

  const onReset = () => {
    form.resetFields();
    setFilterData({});
    setSelectId(null);
  };
  return (
    <StyledBalanceTopActions>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        {" "}
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          flexWrap={"wrap"}
        >
          <Flex flexWrap={"wrap"}>
            <div className="item ipt">
              <Form.Item name={"user_id"}>
                <AutoComplete
                  placeholder="Full name"
                  onSearch={(val, el) => setUserInfo({ user_info: val })}
                  onSelect={(_, el) => setSelectId(el.user_id)}
                  options={usersList}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </div>
            <div className="item ipt">
              <Form.Item name={"sum"}>
                <Input placeholder="Bonus amount" />
              </Form.Item>
            </div>
            <div className="item ipt">
              <Form.Item name="date">
                <RangePicker
                  format={"DD.MM.YYYY"}
                  placeholder={["Given date from", "Given date to"]}
                />
              </Form.Item>
            </div>
            <div className="item ipt">
              <Form.Item name="bonus_type_id">
                <Select
                  placeholder={"Bonus type"}
                  options={bonusTypeList}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </div>
          </Flex>
          <Flex>
            <div className="item">
              <ButtonSecondary
                text="Reset"
                htmlType={"button"}
                click={onReset}
              />
            </div>
            <div className="item">
              <ButtonPrimary
                text="Search"
                htmlType={"submit"}
                icon={<SearchIcon />}
              />
            </div>
          </Flex>
        </Flex>
      </Form>
    </StyledBalanceTopActions>
  );
}
