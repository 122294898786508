export default function BfmIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4039_17637)">
        <path
          d="M5.45312 5.78247H6.10638C6.22942 5.78247 6.29094 5.78247 6.34045 5.8051C6.38408 5.82504 6.42105 5.8571 6.44696 5.89747C6.47636 5.94328 6.48506 6.00419 6.50246 6.12599L6.73917 7.78299M6.73917 7.78299L7.26526 11.6497C7.33202 12.1404 7.3654 12.3857 7.4827 12.5704C7.58607 12.7331 7.73426 12.8625 7.90944 12.943C8.10825 13.0344 8.35585 13.0344 8.85106 13.0344H13.1311C13.6025 13.0344 13.8382 13.0344 14.0308 12.9495C14.2007 12.8748 14.3464 12.7542 14.4516 12.6014C14.571 12.428 14.6151 12.1965 14.7033 11.7334L15.3653 8.25796C15.3963 8.09498 15.4118 8.01348 15.3893 7.94978C15.3696 7.89391 15.3307 7.84685 15.2795 7.81701C15.2211 7.78299 15.1381 7.78299 14.9722 7.78299H6.73917Z"
          stroke="#5B6D7F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.95423 15.7852C9.23045 15.7852 9.45436 15.5613 9.45436 15.285C9.45436 15.0088 9.23045 14.7849 8.95423 14.7849C8.67802 14.7849 8.4541 15.0088 8.4541 15.285C8.4541 15.5613 8.67802 15.7852 8.95423 15.7852Z"
          fill="#5B6D7F"
        />
        <path
          d="M12.9553 15.7852C13.2315 15.7852 13.4554 15.5613 13.4554 15.285C13.4554 15.0088 13.2315 14.7849 12.9553 14.7849C12.6791 14.7849 12.4551 15.0088 12.4551 15.285C12.4551 15.5613 12.6791 15.7852 12.9553 15.7852Z"
          fill="#5B6D7F"
        />
        <path
          d="M8.95423 15.7852C9.23045 15.7852 9.45436 15.5613 9.45436 15.285C9.45436 15.0088 9.23045 14.7849 8.95423 14.7849C8.67802 14.7849 8.4541 15.0088 8.4541 15.285C8.4541 15.5613 8.67802 15.7852 8.95423 15.7852Z"
          stroke="#5B6D7F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9553 15.7852C13.2315 15.7852 13.4554 15.5613 13.4554 15.285C13.4554 15.0088 13.2315 14.7849 12.9553 14.7849C12.6791 14.7849 12.4551 15.0088 12.4551 15.285C12.4551 15.5613 12.6791 15.7852 12.9553 15.7852Z"
          stroke="#5B6D7F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="10"
          cy="10"
          r="9"
          stroke="#5B6D7F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4039_17637">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
