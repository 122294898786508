import styled from "styled-components";

const StyledButtonPrimary = styled.button`
  background: #ffffff;
  color: #3b5166;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat arm2";
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  margin: ${({ margin }) => margin};
  border: 1px solid #e7e9ec;
`;

export default function DeleteButton({ text, margin, icon, click, ...props }) {
  return (
    <StyledButtonPrimary onClick={() => click()} margin={margin} {...props}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
        <span style={{ margin: "0 0 0 8px" }}>{text}</span>
      </div>
    </StyledButtonPrimary>
  );
}
