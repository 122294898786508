import React from "react";
import { Tabs } from "antd";
import Flex from "../general/Flex";
import { AroundCheckedIcon } from "../SvgComponents/AroundCheckedIcon.jsx";

const TabList = ({ data, value, isTrueAllItems, onChange = () => {} }) => (
  <Tabs
    onChange={(val) => onChange(val)}
    activeKey={value?.toString()}
    items={data.map((el, i) => {
      const id = String(i + 1);
      return {
        label: (
          <Flex>
            <img
              alt={"language_image"}
              style={{ margin: "0 12px 0 0" }}
              src={el?.flag}
              width={"20px"}
              height={"20px"}
            />
            {!!isTrueAllItems?.[i] && (
              <AroundCheckedIcon
                size={28}
                style={{ position: "absolute", top: 6 }}
              />
            )}
            {el?.name}
          </Flex>
        ),
        key: id,
      };
    })}
  />
);
export default TabList;
