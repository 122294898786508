import Flex from "../../../components/general/Flex.jsx";
import Typography from "../../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../../constants.js";
import HeightIcon from "../../../components/SvgComponents/HeightIcon.jsx";
import DepthIcon from "../../../components/SvgComponents/ParcelDepth.jsx";
import LengthIcon from "../../../components/SvgComponents/LengthIcon.jsx";
import Tag from "../../../components/moleculs/Tag.jsx";

export default function OrderDetailsInfo({ order }) {
  return (
    <div>
      <Flex justifyContent="space-between" style={{ marginTop: 16 }}>
        <div className="info-block">
          <Flex>
            <div className="info-block-item">
              <Typography
                text="Weight"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                variant="p"
                text={`${order?.weight} kg`}
                size="14px"
                weight="400"
                color={primaryTextColor}
              />
            </div>
            <div className="info-block-item">
              <Typography
                text="V Weight"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                variant="p"
                text={`${order?.v_weight} kg`}
                size="14px"
                weight="400"
                color={primaryTextColor}
              />
            </div>
            <div className="info-block-item">
              <Typography
                text="Cost"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                variant="p"
                text={`${order?.cost} ₾`}
                size="14px"
                weight="400"
                color={primaryTextColor}
              />
            </div>
          </Flex>
        </div>
        <div className="info-block">
          <Flex>
            <div className="info-block-item">
              <Typography
                text="Parcel ID"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                variant="p"
                text={order?.parcel?.name}
                size="14px"
                weight="400"
                color={primaryTextColor}
              />
            </div>
            <div className="info-block-item">
              <Typography
                text="Box ID"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                variant="p"
                text={order?.box?.reference_id}
                size="14px"
                weight="400"
                color={primaryTextColor}
              />
            </div>
            <div className="info-block-item">
              <Typography
                text="Estimated"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                variant="p"
                text={order?.estimated_date_to}
                size="14px"
                weight="400"
                color={primaryTextColor}
              />
            </div>
          </Flex>
        </div>
      </Flex>
      <Flex justifyContent="space-between">
        <div className="info-block">
          <Flex>
            <div className="info-block-item">
              <Typography
                text="Pickup Point"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 5px 0"
              />
              <Typography
                variant="p"
                text={order?.pickup_point?.address}
                size="14px"
                weight="400"
                color={primaryTextColor}
              />
            </div>
            <div className="info-block-item">
              {/* <Typography */}
              {/*   text="Destination" */}
              {/*   variant="p" */}
              {/*   color="#3B5166" */}
              {/*   size="14px" */}
              {/*   weight="500" */}
              {/*   padding="0 0 5px 0" */}
              {/* /> */}
              {/* <Flex> */}
              {/*   <LockerIcon /> */}
              {/*   <Typography */}
              {/*     variant="p" */}
              {/*     text="Megamall" */}
              {/*     size="14px" */}
              {/*     weight="400" */}
              {/*     color={primaryTextColor} */}
              {/*     padding="0 0 0 4px" */}
              {/*   /> */}
              {/* </Flex> */}
            </div>
          </Flex>
        </div>
        <div className="info-block">
          <Flex>
            <div className="info-block-item">
              <Typography
                text="Height"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 5px 0"
              />
              <Flex alignItems="center">
                <HeightIcon />
                <Typography
                  variant="p"
                  text={order.height}
                  size="14px"
                  weight="400"
                  color={primaryTextColor}
                  padding="0 0 0 9px"
                />
                <Typography
                  variant="p"
                  text="mm"
                  size="14px"
                  weight="400"
                  color="#8E9BA7"
                  padding="0 0 0 8px"
                />
              </Flex>
            </div>
            <div className="info-block-item">
              <Typography
                text="Depth"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 5px 0"
              />
              <Flex alignItems="center">
                <DepthIcon />
                <Typography
                  variant="p"
                  text={order.width}
                  size="14px"
                  weight="400"
                  color={primaryTextColor}
                  padding="0 0 0 9px"
                />
                <Typography
                  variant="p"
                  text="mm"
                  size="14px"
                  weight="400"
                  color="#8E9BA7"
                  padding="0 0 0 8px"
                />
              </Flex>
            </div>
            <div className="info-block-item">
              <Typography
                text="Length"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
                padding="0 0 5px 0"
              />
              <Flex alignItems="center">
                <LengthIcon />
                <Typography
                  variant="p"
                  text={order.length}
                  size="14px"
                  weight="400"
                  color={primaryTextColor}
                  padding="0 0 0 9px"
                />
                <Typography
                  variant="p"
                  text="mm"
                  size="14px"
                  weight="400"
                  color="#8E9BA7"
                  padding="0 0 0 8px"
                />
              </Flex>
            </div>
          </Flex>
          {order?.size_type && order?.size_type === "not_locker_size" ? (
            <div style={{ marginTop: 16 }}>
              <Tag
                text="Not Locker Size"
                background="#EDF5FB"
                color="#4889C5"
              />
            </div>
          ) : order?.size_type && order?.size_type === "not_standard_size" ? (
            <div style={{ marginTop: 16 }}>
              <Tag
                text="Not Standart Size"
                background="#EDF5FB"
                color="#4889C5"
              />
            </div>
          ) : null}
        </div>
      </Flex>
    </div>
  );
}
