import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import React, { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core.js";
import * as am4charts from "@amcharts/amcharts4/charts.js";
import useDashboardData from "../../hooks/Dashboard/useDashboardData.hook.jsx";
import Loader from "../../components/moleculs/Loader";
import Flex from "../../components/general/Flex.jsx";
import LeftBottomArrowIcon from "../../components/SvgComponents/LeftBottomArrow.jsx";
import TopRightArrowIcon from "../../components/SvgComponents/TopRightArrow.jsx";

const StyledRegistrationType = styled.div`
  padding: 24px;
  background: white;
  border-radius: 12px;
  position: relative;
  .filter {
    position: absolute;
    top: 24px;
    right: 24px;
    color: #b3bbc4;
    @media (max-width: 768px) {
      top: 10px;
      right: 10px;
    }
  }
  #registrationByType {
    @media (max-width: 768px) {
      height: auto !important;
    }
  }
  .legend {
    .georgian {
      background: #4889c5;
    }
    .foreigner {
      background: #4ed2f7;
    }
  }
`;
export default function RegistrationType({
  dashboardData,
  isLoading,
  filterType,
}) {
  useLayoutEffect(() => {
    const chart = am4core.create("registrationByType", am4charts.PieChart);

    if (dashboardData) {
      // Add data
      chart.data = [
        {
          category: "Georgian",
          litres: dashboardData?.data?.registration_by_nationality_info?.georgian_total,
          color: "#0A94FF",
        },
        {
          category: "Foreigner",
          litres: dashboardData?.data?.registration_by_nationality_info?.foreigner_total,
          color: "#4ED2F7",
        },
      ];

      // Add and configure Series
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "category";
      pieSeries.dataFields.hidden = "hidden";
      pieSeries.slices.template.propertyFields.fill = "color";

      // Let's cut a hole in our Pie chart the size of 40% the radius
      chart.innerRadius = am4core.percent(40);
      pieSeries.slices.template.tooltipText = "Ruben";

      // Disable ticks and labels
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      // Disable tooltips
      pieSeries.slices.template.tooltipText = "{category}: {litres}";
      //
      // // Add a legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "right";
    }
  }, [dashboardData]);

  return (
    <StyledRegistrationType>
      <div className="filter">
        <Typography
          xsSize={"12px"}
          text={`This ${filterType}`}
          variant={"p"}
          size={"14px"}
          weight={"400"}
          color={"#B3BBC4"}
        />
      </div>
      <Typography
        variant={"h5"}
        text={"Residency"}
        color={"#262626"}
        size={"24px"}
        weight={"500"}
        padding={"0 0 15px 0"}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Flex alignItems={"center"}>
          <div
            id="registrationByType"
            style={{ width: "60%", height: "280px" }}
          ></div>
          <div className={"legends"}>
            <div className="legend">
              <Flex alignItems={"center"}>
                <div className="legend-icon georgian"></div>
                <p>
                  Georgian
                  <span>
                    {dashboardData?.data?.registration_by_nationality_info?.georgian_total}
                  </span>
                  <span className={"percent"}>
                    (
                    {
                      dashboardData?.data?.registration_by_nationality_info
                        ?.georgian_percentage_in_completed
                    }
                    %)
                  </span>
                </p>
              </Flex>
            </div>
            <div className="legend">
              <Flex alignItems={"center"}>
                <div className="legend-icon foreigner"></div>
                <p>
                  Foreigner
                  <span>
                    {dashboardData?.data?.registration_by_nationality_info?.foreigner_total}
                  </span>
                  <span className={"percent"}>
                    (
                    {
                      dashboardData?.data?.registration_by_nationality_info
                        ?.foreigner_percentage_in_completed
                    }
                    %)
                  </span>
                </p>
              </Flex>
            </div>
          </div>
        </Flex>
      )}
    </StyledRegistrationType>
  );
}
