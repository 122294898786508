import styled from "styled-components";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import Flex from "../../components/general/Flex";
import ShowLessIcon from "../../components/SvgComponents/ShowLessIcon";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import useGetWarehouses from "../../hooks/warehouses/useWarehouses.hook.jsx";

const { RangePicker } = DatePicker;
const StyledConsignmentTopActions = styled.div`
  background: white;
  border-radius: 12px;
  width: 100%;

  .cons_top {
    padding: 16px;
  }

  .fly-sea {
    span.ant-select-selection-placeholder {
      padding-top: 5px;
    }
  }

  .show-less {
    background: transparent;
    border: none;
    outline: none;
  }

  .actions-btns {
    display: flex;
    align-items: center;
    justify-content: end;

    button {
      margin: 0 8px;
    }
  }

  .row {
    height: ${({ more }) => (more ? "100%" : " 47px")};
    overflow: hidden;
  }

  .show-more-icon {
    svg {
      transform: ${({ more }) => (more ? "rotate(0)" : "rotate(180deg)")};
    }
  }

  .ant-select-selection-placeholder {
    padding-top: 0 !important;
  }
`;

export default function ConsignmentTopActions({ setFilterData }) {
  const [more, setMore] = useState(false);
  const [warehouse, setWarehouse] = useState(null);
  const { data: warehouses } = useGetWarehouses();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    let newValues = {};
    Object.entries(values).forEach(([key, val]) => {
      if (val) {
        newValues[key] = val;
      }
    });
    setFilterData(newValues);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <StyledConsignmentTopActions more={more}>
      <div className={"cons_top"}>
        <Form
          labelCol={8}
          wrapperCol={4}
          layout="vertical"
          onFinish={onFinish}
          form={form}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={16} className="row">
            <Col lg={4}>
              <Form.Item name="name">
                <Input placeholder="Consignment name" />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="parcel_id">
                <Input placeholder="Consignment ID" />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="air_waybill">
                <Input placeholder="Air way bill" />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="freight_forwarder">
                <Input placeholder="Freight forwarder" />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item>
                {/*<RangePicker*/}
                {/*  defaultValue={[*/}
                {/*    dayjs("2015/01/01", dateFormat),*/}
                {/*    dayjs("2015/01/01", dateFormat),*/}
                {/*  ]}*/}
                {/*  format={dateFormat}*/}
                {/*/>*/}
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Form.Item name="warehouse_id">
                <Select
                  style={{ height: 36 }}
                  placeholder="All Warehouse"
                  onChange={(val) => {
                    setWarehouse(warehouses?.find((el) => el?.value === val));
                    form.setFieldValue("dispatch_id", null);
                  }}
                  options={warehouses}
                />
              </Form.Item>
            </Col>
            <Col lg={3} className="fly-sea">
              <Form.Item name="dispatch_id">
                <Select placeholder={"Select dispatch"} style={{ height: 36 }}>
                  {warehouse?.dispatches?.map((dispatch, i) => (
                    <Select.Option key={i} value={dispatch?.type}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {
                          <img
                            alt={"dispatch"}
                            src={dispatch?.icon}
                            width={24}
                            style={{ margin: "0 8px 0 0" }}
                          />
                        }
                        {dispatch?.name}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="total_weight">
                <Input placeholder="Weight" />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="comment">
                <Input placeholder="Onex comment" />
              </Form.Item>
            </Col>
          </Row>
          <div className="actions-btns">
            <button className="show-less" onClick={() => setMore(!more)}>
              <Flex alignItems="center">
                <div className="show-more-icon">
                  <ShowLessIcon />
                </div>
                <Typography
                  variant="span"
                  text={more ? "Show less" : "Show more"}
                  color="rgba(91, 109, 127, 1)"
                  padding="0 0 0 15px"
                />
              </Flex>
            </button>
            <ButtonSecondary text="Reset" htmlType="button" click={onReset} />
            <ButtonPrimary
              text="Search"
              htmlType="submit"
              icon={<SearchIcon />}
            />
          </div>
        </Form>
      </div>
    </StyledConsignmentTopActions>
  );
}
