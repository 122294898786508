export default function AndroidIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86971 2.42777C8.50012 2.07567 9.22663 1.875 10 1.875C10.7734 1.875 11.4999 2.07567 12.1303 2.42777L12.5915 1.96653C12.7136 1.84449 12.9114 1.84449 13.0335 1.96653C13.1555 2.08857 13.1555 2.28643 13.0335 2.40847L12.6632 2.77872C13.7041 3.57849 14.375 4.83589 14.375 6.25H5.625C5.625 4.83589 6.29591 3.57849 7.33678 2.77872L6.96653 2.40847C6.84449 2.28643 6.84449 2.08857 6.96653 1.96653C7.08857 1.84449 7.28643 1.84449 7.40847 1.96653L7.86971 2.42777ZM8.75 4.375C8.75 4.72018 8.47018 5 8.125 5C7.77982 5 7.5 4.72018 7.5 4.375C7.5 4.02982 7.77982 3.75 8.125 3.75C8.47018 3.75 8.75 4.02982 8.75 4.375ZM11.875 5C12.2202 5 12.5 4.72018 12.5 4.375C12.5 4.02982 12.2202 3.75 11.875 3.75C11.5298 3.75 11.25 4.02982 11.25 4.375C11.25 4.72018 11.5298 5 11.875 5Z"
        fill="#87C527"
      />
      <path
        d="M3.125 7.8125C3.125 7.29473 3.54473 6.875 4.0625 6.875C4.58027 6.875 5 7.29473 5 7.8125V11.5625C5 12.0803 4.58027 12.5 4.0625 12.5C3.54473 12.5 3.125 12.0803 3.125 11.5625V7.8125Z"
        fill="#87C527"
      />
      <path
        d="M7.5 15V17.1875C7.5 17.7053 7.91973 18.125 8.4375 18.125C8.95527 18.125 9.375 17.7053 9.375 17.1875V15H10.625V17.1875C10.625 17.7053 11.0447 18.125 11.5625 18.125C12.0803 18.125 12.5 17.7053 12.5 17.1875V15H13.125C13.8154 15 14.375 14.4404 14.375 13.75V6.875H5.625V13.75C5.625 14.4404 6.18464 15 6.875 15H7.5Z"
        fill="#87C527"
      />
      <path
        d="M15 7.8125C15 7.29473 15.4197 6.875 15.9375 6.875C16.4553 6.875 16.875 7.29473 16.875 7.8125V11.5625C16.875 12.0803 16.4553 12.5 15.9375 12.5C15.4197 12.5 15 12.0803 15 11.5625V7.8125Z"
        fill="#87C527"
      />
    </svg>
  );
}
