import axios from "axios";

async function createStories(stories, edit_id, parent_id) {
  const formData = new FormData();

  if (edit_id) formData.append("_method", "put");
  stories.main.forEach((main, index) => {
    formData.append(`details[${index}][image]`, main.image);
    formData.append(`details[${index}][language_id]`, main.language_id);
    if (main.description)
      formData.append(`details[${index}][description]`, main.description);
    if (main.button_text)
      formData.append(`details[${index}][button_text]`, main.button_text);
    if (main.url) formData.append(`details[${index}][url]`, main.url);
  });
  if (parent_id && !edit_id) formData.append("parent_id", parent_id);

  const response = await axios
    .post(edit_id ? `/stories/${edit_id}` : "/stories", formData)
    .then(async ({ data }) => {
      const obj = {};
      for (let key in stories) {
        if (key !== "main" && stories[key]) {
          for (let [index, value] of stories[key]?.entries()) {
            obj[index] = {
              data: obj?.[index]?.data ? [...obj?.[index]?.data] : [],
              edit_id: undefined,
            };
            if (edit_id) {
              obj[index].edit_id = value.story_id;
            }
            obj[index].data?.push(value);
          }
        }
      }
      for (let key in obj) {
        createStories(
          { main: obj[key]?.data },
          obj[key]?.edit_id,
          data.data.id
        );
      }
    });
  return response;
}

export const storyApi = {
  async getStories() {
    const { data } = await axios("/stories");
    return data.data.data;
  },
  async sortStores(story_sort) {
    return await axios.post("/stories/sort-priority", {
      story_sort,
    });
  },
  createStories,
  async getStoryById(id) {
    if (!id) return {};
    const { data } = await axios(`/stories/${id}`);
    return data.data;
  },
  async deleteStory(id) {
    if (!id) return false;
    const { data } = await axios.delete(`/stories/${id}`);
    return data.data;
  },
  async hideStory(id, hideOrShow) {
    if (!id) return false;
    const { data } = await axios.post(`/stories/is-hide/${id}`, {
      _method: "put",
      is_hide: hideOrShow,
    });
    return data.data;
  },
};
