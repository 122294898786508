import refillIcon from "../../assets/images/refill-icon.png";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import { Input, Radio, Space } from "antd";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import Modal from "../../components/modals/Modal.jsx";
import { useRefillBalance } from "../../hooks/receiveOrders/useRefillBalance.hook.js";
import { memo, useEffect, useState } from "react";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";

const ModalRefillBalance = ({
  fillBalanceStatus,
  setFillBalanceStatus,
  user,
  handleSetUser = () => {},
}) => {
  const [refillAmount, setRefillAmount] = useState(+user?.bonus || "");
  const { mutate: refillBalance } = useRefillBalance(
    (data) => {
      handleSetUser(data?.data?.data);
      openNotification(
        "Refill balance",
        "Balance top-up completed successfully"
      );
      setFillBalanceStatus(false);
    },
    (e) => {
      openNotificationError(
        "Refill balance",
        e?.response?.data?.data?.amount[0] || ""
      );
    }
  );
  useEffect(() => {
    if (fillBalanceStatus) {
      setRefillAmount(+user?.bonus || "");
    } else {
      setRefillAmount("");
    }
  }, [fillBalanceStatus]);
  const refillUserBalance = () => {
    refillBalance({ user_id: user?.id, amount: refillAmount });
  };
  return (
    <Modal
      icon={<img src={refillIcon} />}
      width={450}
      status={fillBalanceStatus}
      setStatus={setFillBalanceStatus}
    >
      <Typography
        text={"Fill balance"}
        variant={"h4"}
        size={"18px"}
        weight={"500"}
        color={"#101828"}
        padding={"0 0 20px 0"}
      />
      <Flex justifyContent={"space-between"}>
        {/*<div style={{ width: "48%" }}>*/}
        {/*  <Typography*/}
        {/*    variant={"label"}*/}
        {/*    text={"Select date"}*/}
        {/*    color={"#3B5166"}*/}
        {/*    size={"14px"}*/}
        {/*    weight={"500"}*/}
        {/*    padding={"0 0 4px 0"}*/}
        {/*  />*/}
        {/*  <DatePicker style={{ width: "100%" }} />*/}
        {/*</div>*/}
        <div style={{ width: "100%" }}>
          <Typography
            variant={"label"}
            text={"Amount"}
            color={"#3B5166"}
            size={"14px"}
            weight={"500"}
            padding={"0 0 4px 0"}
          />
          <Input
            type={"number"}
            value={refillAmount}
            onChange={(e) => setRefillAmount(e.target.value)}
            suffix="₾"
          />
        </div>
      </Flex>
      <Radio.Group className={"mt-16"} value={2}>
        <Space direction={"vertical"}>
          {/*<Radio value={1}>*/}
          {/*  <Select style={{ width: 425 }} placeholder={"Choose method"}>*/}
          {/*    <Select.Option>test</Select.Option>*/}
          {/*  </Select>*/}
          {/*</Radio>*/}
          <Radio value={2} className={"mt-16"}>
            <Flex
              justifyContent={"space-between"}
              style={{ width: 410 }}
              alignItems={"center"}
            >
              <Typography
                text={"Apply bonus"}
                variant={"p"}
                size={"14px"}
                weight={"400"}
                color={"#262626"}
              />
              <Typography
                variant={"p"}
                text={(user?.bonus || "") + " ₾"}
                size={"14px"}
                weight={"400"}
                color={"#8E9BA7"}
              />
            </Flex>
          </Radio>
        </Space>
      </Radio.Group>
      <Flex className={"mt-16"} justifyContent={"space-between"}>
        <ButtonSecondary
          text={"Cancel"}
          width={"48%"}
          padding={"10px"}
          click={() => setFillBalanceStatus(false)}
        />
        <ButtonSecondary
          text={"Fill"}
          click={refillUserBalance}
          // background={"#B3BBC4"}
          // disabled={true}
          background="#5B6D7F"
          color={"white"}
          width={"48%"}
          padding={"10px"}
        />
      </Flex>
    </Modal>
  );
};
export default memo(ModalRefillBalance);
