import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import NotificationsFilters from "./Filters";
import NotificationsCreate from "./Create.jsx";
import { Col, Row } from "antd";
import Preview from "./Preview";
import { useState } from "react";

const StyledNotifications = styled.div`
  padding-top: 24px;
  min-height: 96vh;
`;

export default function Notifications() {
  const [squarePhoto, setSquarePhoto] = useState();
  const [imageForWeb, setImageForWeb] = useState();
  const [imageForPush, setImageForPush] = useState();
  const [filterModalStatus, setFilterModalStatus] = useState(false);

  const [formData, setFormData] = useState({
    en_title: "",
    ru_title: "",
    ge_title: "",
    content: "",
    pusht_title: "",
  });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <StyledNotifications>
      <div className="page-title">
        <Flex justifyContent={"space-between"}>
          <Typography
            text="Notifications"
            color="#262626"
            weight={500}
            size="24px"
            variant="h2"
            margin="0 0 24px 0"
          />
          <ButtonSecondary
            text={"Send notification"}
            color={"white"}
            background={"#B3BBC4"}
          />
        </Flex>
      </div>
      <NotificationsFilters
        filterModalStatus={filterModalStatus}
        setFilterModalStatus={setFilterModalStatus}
      />
      <Row gutter={[36]}>
        <Col lg={12}>
          <NotificationsCreate
            squarePhoto={squarePhoto}
            setSquarePhoto={setSquarePhoto}
            imageForWeb={imageForWeb}
            setImageForWeb={setImageForWeb}
            imageForPush={imageForPush}
            setImageForPush={setImageForPush}
            formData={formData}
            setFormData={setFormData}
            handleChangeInput={handleChangeInput}
            filterModalStatus={filterModalStatus}
            setFilterModalStatus={setFilterModalStatus}
          />
        </Col>
        <Col lg={12}>
          <Preview formData={formData} imageForWeb={imageForWeb} />
        </Col>
      </Row>
    </StyledNotifications>
  );
}
