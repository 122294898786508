import styled from "styled-components";
import { Pagination, Table, Tag } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import Loader from "../../components/moleculs/Loader.jsx";
import { balanceApi } from "../../services/balance.js";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon.jsx";
import OnexTooltip from "../../helpers/tooltip.jsx";
import OrderStatusIcon from "../Orders/OrderStatusIcon.jsx";
import dayjs from "dayjs";
import { SendToIcon } from "../../assets/images/send-to-icon.jsx";
import { useState } from "react";
import { SendToHubFailedOrderModal } from "./SendToHubModal.jsx";
import { ShowReasonModal } from "./ShowReasonModal.jsx";

const StyledBalanceTable = styled.div`
  .ant-table {
    border: 1px solid #e7e9ec;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    overflow: auto;
    max-height: 70vh;
  }

  &
    :where(.css-dev-only-do-not-override-1ipetx6).ant-table-wrapper
    .ant-table-tbody
    > tr
    > th {
    background: #e7e9ec;
  }

  & .ant-table-thead > tr > th {
    color: #5b6d7f;
    font-size: 12px;
    font-family: "Montserrat arm2";
    font-weight: 400;
  }

  .download-excel {
    margin-top: 16px;
  }

  .tag {
    border: none;
    border-radius: 16px;
    padding: 2px 10px;
    background: #f5f5f5;
    color: #262626;
    font-size: 14px;
    font-family: "Montserrat arm2";
    //max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tag_details {
    cursor: pointer;

    :hover {
      background: #b6b1b1;
    }
  }
`;

export default function FailedOrdersTable({
  setFilters,
  filters,
  orders = [],
  meta,
  isLoading,
}) {
  const [sendToHub, setSendToHub] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const onShowSizeChange = (current, pageSize) => {
    setFilters({ ...filters, page: current, per_page: pageSize });
  };

  const columns = [
    {
      key: "index",
      dataIndex: "index",
      title: "",
      width: "50px",
    },
    {
      key: "tracking",
      dataIndex: "tracking",
      title: "Tracking",
    },

    {
      key: "status",
      dataIndex: "status",
      title: "Status",
    },
    {
      key: "reason",
      dataIndex: "reason",
      title: "Reason",
      width: "40%",
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Action",
      width: 200,
    },
  ];

  const dataSource = orders?.map((order, index) => ({
    key: index,
    index: order.id,
    tracking: (
      <div
        className={"c-pointer"}
        onClick={() => {
          navigator.clipboard.writeText(order?.order?.tracking_code || "");
          messageApi.success("Copied");
        }}
      >
        <Flex>
          <OnexTooltip content={"Click to copy"} direction={"bottom"}>
            <Typography
              variant="p"
              text={order?.order?.tracking_code || ""}
              color="#262626"
              size="14px"
              weight="400"
            />
          </OnexTooltip>
        </Flex>
      </div>
    ),
    status: (
      <Flex alignItems="center" flexWrap={"wrap"}>
        <OrderStatusIcon status={"at_warehouse"} />
        <Typography
          variant="p"
          text={"At Warehouse"}
          color="#5B6D7F"
          size="14px"
          weight="400"
          margin="0 0 0 5px"
        />

        <Typography
          variant="p"
          text={
            order?.order?.histories?.length > 0
              ? dayjs(order?.order?.histories[0]?.date).format("DD.MM.YYYY")
              : ""
          }
          color="#262626"
          size="14px"
          weight="400"
          margin="0 0 0 5px"
        />
      </Flex>
    ),
    reason: (
      <Flex style={{ width: "100%", overflow: "hidden" }} flexWrap={"wrap"}>
        {order?.note?.split("|")?.length < 4 ? (
          order?.note?.split("|")?.map((not, i) => (
            <Tag
              className={"tag"}
              style={{ maxWidth: `${100 / order?.note?.split("|")?.length}%` }}
              key={i}
            >
              {not}
            </Tag>
          ))
        ) : (
          <>
            {order?.note
              ?.split("|")
              .slice(0, 3)
              ?.map((not, i) => (
                <Tag
                  className={"tag"}
                  style={{
                    maxWidth: `${100 / 3.5}%`,
                  }}
                  key={i}
                >
                  {not}
                </Tag>
              ))}
            <Tag
              className={"tag tag_details"}
              onClick={() => setShowReason(order?.note?.split("|"))}
            >
              +{order?.note?.split("|")?.length - 3}
            </Tag>
          </>
        )}
      </Flex>
    ),
    action: (
      <ButtonSecondary
        click={() => setSendToHub(order?.order)}
        text="Send to hub"
        icon={<SendToIcon />}
        style={{ marginRight: 10 }}
      />
    ),
  }));

  if (isLoading) return <Loader />;
  return (
    <StyledBalanceTable>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ y: "60vh", x: 1300 }}
      />
      <Flex
        style={{ padding: "30px 0" }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <ButtonSecondary
          disabled={true}
          style={{ marginLeft: 10 }}
          text={"Excel"}
          icon={<DownloadIcon />}
          click={() => balanceApi.exportExelList(null)}
        />
        {/*list?.user_id*/}
        <Pagination
          showSizeChanger
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={onShowSizeChange}
          defaultPageSize={meta?.per_page || 15}
          defaultCurrent={meta?.current_page || 1}
        />
      </Flex>
      <SendToHubFailedOrderModal
        setSendToHub={setSendToHub}
        sendToHub={sendToHub}
      />
      <ShowReasonModal open={showReason} setOpen={setShowReason} />
    </StyledBalanceTable>
  );
}
