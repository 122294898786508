import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import UploadImages from "../../components/moleculs/UploadImages.jsx";
import { Checkbox, Input, Form } from "antd";
import TextArea from "antd/es/input/TextArea.js";
import OnexTooltip from "../../helpers/tooltip.jsx";
import InfoIcon from "../../components/SvgComponents/InfoIcon.jsx";

export default function NotificationForm({
  language,
  squarePhoto,
  setSquarePhoto,
  imageForWeb,
  setImageForWeb,
  imageForPush,
  setImageForPush,
  formData,
  handleChangeInput,
}) {
  return (
    <div>
      <div className="style_form">
        <Flex justifyContent={"space-between"}>
          <Form.Item name={"image"}>
            <div className="d-block">
              <Flex>
                <Typography
                  text={"Image square"}
                  variant={"p"}
                  color={"#3B5166"}
                  size={"16px"}
                  padding={"0 0 10px 0"}
                  weight={"500"}
                />
                <OnexTooltip
                  content={
                    <span>
                      Dimensions{" "}
                      <span style={{ fontWeight: 700 }}>100px × 100px</span>{" "}
                      <br />
                      SVG, JPG, PNG
                    </span>
                  }
                  direction={"right"}
                >
                  <InfoIcon color={"#5B6D7F"} style={{ margin: "0 0 0 4px" }} />
                </OnexTooltip>
              </Flex>
              <UploadImages
                imageUrl={squarePhoto ? squarePhoto : ""}
                setImageUrl={(img) => {
                  setSquarePhoto(img);
                }}
              />
            </div>
          </Form.Item>
          <Form.Item name={"image"}>
            <div className="d-block">
              <Flex>
                <Typography
                  text={"Image for web"}
                  variant={"p"}
                  color={"#3B5166"}
                  size={"16px"}
                  padding={"0 0 10px 0"}
                  weight={"500"}
                />
                <OnexTooltip
                  content={
                    <span>
                      Dimensions{" "}
                      <span style={{ fontWeight: 700 }}>681 × 315px</span>{" "}
                      <br />
                      SVG, JPG, PNG
                    </span>
                  }
                  direction={"right"}
                >
                  <InfoIcon color={"#5B6D7F"} style={{ margin: "0 0 0 4px" }} />
                </OnexTooltip>
              </Flex>
              <UploadImages
                maxWidth={"1500"}
                maxHeight={"575"}
                imageUrl={imageForWeb ? imageForWeb : ""}
                setImageUrl={(img) => {
                  setImageForWeb(img);
                }}
              />
            </div>
          </Form.Item>
          <Form.Item name={"image"}>
            <div className="d-block">
              <Flex>
                <Typography
                  text={"Image for mobile"}
                  variant={"p"}
                  color={"#3B5166"}
                  size={"16px"}
                  padding={"0 0 10px 0"}
                  weight={"500"}
                />
                <OnexTooltip
                  content={
                    <span>
                      Dimensions{" "}
                      <span style={{ fontWeight: 700 }}>360px × 132px</span>
                      <br />
                      SVG, JPG, PNG
                    </span>
                  }
                  direction={"right"}
                >
                  <InfoIcon color={"#5B6D7F"} style={{ margin: "0 0 0 4px" }} />
                </OnexTooltip>
              </Flex>
              <UploadImages
                imageUrl={squarePhoto ? squarePhoto : ""}
                setImageUrl={(img) => {
                  setSquarePhoto(img);
                }}
              />
            </div>
          </Form.Item>
        </Flex>
      </div>
      <div className={"style_form"}>
        <Form.Item label={"Title"}>
          <Input
            name={`${language}_title`}
            placeholder={"Title"}
            value={formData.title}
            onChange={handleChangeInput}
            showCount
            maxLength={100}
          />
        </Form.Item>
        <Form.Item label={"Content"}>
          <TextArea
            size={"large"}
            name={"content"}
            value={formData.content}
            onChange={handleChangeInput}
            maxLength={400}
            showCount={true}
          />
        </Form.Item>
      </div>
      <div className={"style_form"}>
        <Form.Item name={"push_title"} label={"Push Title"}>
          <Input placeholder={"Push Title"} maxLength={45} showCount={true} />
        </Form.Item>
        <Form.Item name={"push_content"} label={"Push Content"}>
          <Input placeholder={"Push Content"} maxLength={45} showCount={true} />
        </Form.Item>
      </div>

      <div className={"style_form"}>
        <Form.Item name={"in_app_link_check"} label={"In app link"}>
          <Checkbox />
        </Form.Item>
        <Form.Item label={"URL"} name={"url"}>
          <Input placeholder={"URL"} />
        </Form.Item>
      </div>
    </div>
  );
}
