import { useState, useRef, useMemo } from "react";
import styled from "styled-components";
import { Col, Form, Input, Row, Modal } from "antd";
import { TableDots } from "../../components/SvgComponents/TableDots";
import UploadImages from "../../components/moleculs/UploadImages.jsx";
import Flex from "../../components/general/Flex.jsx";
import DeleteIcon from "../../components/SvgComponents/DeleteIcon";
import EyeIcon from "../../components/SvgComponents/EyeIcon";
import CropModal from "./CropModal";
const StyledCreateChildStory = styled.div`
  background: white;
  border-radius: 12px;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 126px;
`;

export default function CreateChildStory({
  onDelete,
  index,
  form,
  language_id,
}) {
  const uploadRef = useRef(null);

  const [src, setSrc] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleReplace = () => {
    uploadRef.current.click();
    setOpenModal(false);
  };

  const initialImage = form.getFieldValue([language_id])?.[index]?.image;

  return (
    <>
      <Form.Item
        hidden
        name={[index, "language_id"]}
        initialValue={language_id}
      />
      <StyledCreateChildStory className={"create-child-story"}>
        <Row style={{ alignItems: "center" }} gutter={25}>
          <Col lg={1}>
            <TableDots />
          </Col>
          <Col lg={3}>
            <Form.Item
              name={[index, "image"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <UploadImages
                maxWidth={"1500"}
                maxHeight={"575"}
                initialImage={initialImage}
                imageUrl={src || ""}
                ref={uploadRef}
                setImageUrl={(img) => {
                  setSrc(img);
                  setOpenModal(true);
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              label={"URL"}
              name={[index, "url"]}
              rules={[
                { type: "url", message: "must be a valid url." },
                { required: true, message: "required" },
              ]}
            >
              <Input style={{ width: "100%" }} placeholder={"URL"} />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              label={"Button text"}
              name={[index, "button_text"]}
              rules={[{ required: true, message: "required" }]}
            >
              <Input style={{ width: "100%" }} placeholder={"Button text"} />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Flex>
              <div className={"mr-16"} onClick={onDelete}>
                <DeleteIcon />
              </div>
              <div>
                <EyeIcon />
              </div>
            </Flex>
          </Col>
        </Row>
      </StyledCreateChildStory>
      <Modal
        footer={false}
        title="Select photo crop"
        open={openModal}
        maskClosable={false}
        onCancel={() => {
          setSrc(null);
          setOpenModal(false);
        }}
        width="316px"
      >
        <CropModal
          src={src}
          onDone={() => {
            setOpenModal(false);
          }}
          onCancel={() => {
            form.setFieldValue(["children", index, "image"], null);
            setSrc(null);
            setOpenModal(false);
          }}
          onReplace={handleReplace}
        />
      </Modal>
    </>
  );
}
