import {
  AutoComplete,
  Card,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  Row,
} from "antd";
import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import NotRolesResult from "../../components/SvgComponents/NotRolesResult.jsx";
import { useFetchRoles } from "../../hooks/members-and-roles/useFetchRoles.hook.jsx";
import { useEffect, useState } from "react";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";
import { useAddNewMember } from "../../hooks/members-and-roles/useAddNewMember.hook.js";
import DeleteButton from "../../components/moleculs/DeleteButton.jsx";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import { useUpdateToggle } from "../../hooks/members-and-roles/useUpdateToggle.hook.js";
import DeleteIcon from "../../components/SvgComponents/DeleteIcon.jsx";
import DeleteModal from "../../components/modals/DeleteModal.jsx";

const WrapperModal = styled.div`
  .auto_comp {
    width: 100%;
  }

  .ant-form-item-label {
    padding: 0;
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }

  label {
    font-size: 14px;
    color: #3b5166 !important;

    :before {
      display: none !important;
    }
  }

  .selected-card {
    box-shadow: none;

    .ant-card-body {
      padding: 16px;
    }
  }

  .no-roles-card {
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px;
  }
`;

export default function AddMembersModal({
  addMembers,
  setAddMembers,
  setCreateRole,
  refetch,
}) {
  const [form] = Form.useForm();
  const { roles } = useFetchRoles();
  const [checkedList, setCheckedList] = useState([]);
  const [userInfo, setUserInfo] = useState({ is_parent: 1 });
  const [userId, setUserId] = useState(null);
  const [status, setStatus] = useState(false);
  const { usersList = [] } = usersAutocomplete(userInfo);
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const { mutate } = useAddNewMember(
    (data) => {
      setCheckedList([]);
      setDeleteModalStatus(false);
      setUserId(null);
      openNotification(
        "Member",
        typeof addMembers === "object"
          ? "Successfully updated"
          : "successfully added"
      );
      refetch();
      form.setFieldValue("full_name", "");
      setAddMembers(false);
    },
    (e) => {
      openNotificationError("Member", "something went wrong");
    }
  );
  const { mutate: mutateToggle, isLoading } = useUpdateToggle(
    (data) => {
      setStatus(!status);
      refetch();
    },
    (e) => openNotificationError("Activate", "something went wrong")
  );
  useEffect(() => {
    if (typeof addMembers === "object") {
      setStatus(addMembers?.is_active_admin);
      form.setFieldValue("full_name", addMembers?.full_name);
      setUserId(addMembers?.id);
      setCheckedList([addMembers?.role[0].id]);
    } else {
      form.setFieldValue("full_name", "");
      setCheckedList([]);
    }
  }, [addMembers]);
  const onFinish = () => {
    if (!userId) {
      form.setFields([
        {
          name: "full_name",
          errors: ["please select a member"],
        },
      ]);
      return;
    }
    mutate({ id: userId, roles: checkedList });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleDeleteUser = (e) => {
    mutate({ id: userId });
  };

  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => setAddMembers(false)}
      open={addMembers}
      width={1112}
      header={false}
    >
      <DeleteModal
        status={deleteModalStatus}
        setStatus={setDeleteModalStatus}
        title="Delete member"
        description="Are you sure want to delete member?"
        setDelete={handleDeleteUser}
      >
        test
      </DeleteModal>
      <WrapperModal>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Typography
              variant={"h2"}
              text={
                typeof addMembers === "object" ? "Edit member" : "Add member"
              }
              weight={"500"}
              size={"22px"}
            />
            <Flex alignItems={"center"}>
              {typeof addMembers === "object" ? (
                <>
                  <DeleteButton
                    text="Delete"
                    icon={<DeleteIcon />}
                    click={() => setDeleteModalStatus(true)}
                    type="button"
                  />
                  {/* <Typography
                    variant={"p"}
                    text={status ? "Active" : "Inactive"}
                    margin={"0 8px 0 0"}
                    size={"14px"}
                  />
                  <Switch
                    checked={status}
                    onChange={(val) =>
                      mutateToggle({ users: [userId], status: val })
                    }
                    loading={isLoading}
                  /> */}
                </>
              ) : (
                ""
              )}
              <ButtonSecondary
                text="Cancel"
                background="#FFECED"
                color="#FC4447"
                border={"none"}
                htmlType="reset"
                style={{ margin: "0 16px" }}
                click={() => setAddMembers(false)}
              />
              <ButtonPrimary text="Save" border={"none"} htmlType="submit" />
            </Flex>
          </Flex>

          <Card
            className={"selected-card"}
            style={{ margin: "24px 0" }}
            bordered={false}
          >
            <Form.Item
              name="full_name"
              rules={[{ required: true }]}
              label={"Full name"}
            >
              <AutoComplete
                className={"auto_comp"}
                placeholder="Full name"
                onSearch={(val) =>
                  setUserInfo(
                    val ? { is_parent: 1, user_info: val } : { is_parent: 1 }
                  )
                }
                onSelect={(_, val) => {
                  form.setFieldValue("full_name", val.label);
                  setUserId(val.user_id);
                }}
                options={usersList}
              />
            </Form.Item>
          </Card>
        </Form>
        {roles?.data?.length > 0 ? (
          <Card bordered={false} className={"selected-card"}>
            <Flex alignItems={"center"}>
              <Checkbox indeterminate={true} checked={false} />
              <Typography
                variant={"p"}
                text={"Roles"}
                size={"14px"}
                weight={"500"}
                color={"#3B5166"}
                margin={"0 0 0 8px"}
              />
            </Flex>
            <Divider style={{ margin: "8px 0 -8px 0" }} />

            <Row style={{ width: "100%" }}>
              {roles?.data?.map((el, i) => (
                <Col span={8} style={{ marginTop: 16 }} key={i}>
                  <Checkbox
                    checked={el?.id === checkedList[0]}
                    onClick={() => setCheckedList([el?.id])}
                  >
                    <Typography
                      variant={"p"}
                      text={el?.name.replaceAll("-", " ")}
                      size={"14px"}
                    />
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Card>
        ) : (
          <div className={"no-roles-card"}>
            <NotRolesResult style={{ marginBottom: 10 }} />
            <Typography
              text={"No roles yet"}
              variant={"h3"}
              color={"#3B5166"}
              weight={"500"}
              size={"20px"}
              lineHeight={"30px"}
            />
            <Typography
              text={"Create roles to give members"}
              variant={"p"}
              color={"#5B6D7F"}
            />
            <ButtonSecondary
              text="Create role"
              style={{ marginTop: 24 }}
              height={"35px"}
              click={() => {
                setCreateRole(true);
                setAddMembers(false);
              }}
            />
          </div>
        )}
      </WrapperModal>
    </Drawer>
  );
}
