export const Special = ({ size = "24", ...props }) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z"
        stroke="#3B5166"
        strokeWidth="1.5"
      />
      <path
        d="M11.5715 7.71191C11.7659 7.389 12.234 7.389 12.4284 7.71191L13.5365 9.55331C13.6063 9.66931 13.7202 9.75205 13.8521 9.78259L15.9458 10.2675C16.313 10.3525 16.4577 10.7978 16.2106 11.0824L14.8018 12.7053C14.713 12.8076 14.6695 12.9414 14.6812 13.0763L14.867 15.2174C14.8996 15.5929 14.5209 15.8681 14.1739 15.721L12.195 14.8827C12.0703 14.8299 11.9296 14.8299 11.8049 14.8827L9.82603 15.721C9.47901 15.8681 9.10027 15.5929 9.13286 15.2174L9.31868 13.0763C9.33038 12.9414 9.28689 12.8076 9.19813 12.7053L7.7893 11.0824C7.54225 10.7978 7.68691 10.3525 8.05407 10.2675L10.1478 9.78259C10.2797 9.75205 10.3936 9.66931 10.4634 9.55331L11.5715 7.71191Z"
        stroke="#3B5166"
        strokeWidth="1.5"
      />
    </svg>
  );
};
