import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import DeliveryTopActions from "./TopActions";
import DeliveryTable from "./Table";

const StyledDelivery = styled.div`
  padding-top: 24px;
`;

export default function Delivery() {
  return (
    <>
      <StyledDelivery>
        <div className="overlay" />
        <div className="page-title">
          <Typography
            text="Delivery"
            color="rgba(38, 38, 38, 1)"
            weight={500}
            size="24px"
            variant="h2"
            margin="0 0 24px 0"
          />
        </div>
      </StyledDelivery>
      <DeliveryTopActions />
      <DeliveryTable />
    </>
  );
}
