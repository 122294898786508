export default function SettingsBtnIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82924 16.1426L7.31628 17.238C7.46106 17.564 7.69734 17.8411 7.99647 18.0355C8.29559 18.2299 8.6447 18.3334 9.00146 18.3333C9.35822 18.3334 9.70734 18.2299 10.0065 18.0355C10.3056 17.8411 10.5419 17.564 10.6866 17.238L11.1737 16.1426C11.3471 15.7539 11.6387 15.4299 12.007 15.2167C12.3777 15.0029 12.8065 14.9118 13.232 14.9565L14.4237 15.0833C14.7784 15.1208 15.1364 15.0547 15.4543 14.8928C15.7721 14.7309 16.0362 14.4803 16.2144 14.1713C16.3929 13.8625 16.4779 13.5086 16.4592 13.1524C16.4405 12.7962 16.3188 12.4532 16.1089 12.1648L15.4033 11.1954C15.1521 10.8476 15.0178 10.429 15.02 9.99999C15.0199 9.57215 15.1554 9.15528 15.407 8.80925L16.1126 7.8398C16.3225 7.55145 16.4442 7.20838 16.4629 6.85222C16.4816 6.49605 16.3966 6.14211 16.2181 5.83332C16.0399 5.52435 15.7758 5.27373 15.458 5.11185C15.1401 4.94996 14.7821 4.88377 14.4274 4.92129L13.2357 5.04814C12.8102 5.09283 12.3814 5.00176 12.0107 4.78795C11.6417 4.57353 11.35 4.24779 11.1774 3.8574L10.6866 2.76203C10.5419 2.43597 10.3056 2.15892 10.0065 1.96449C9.70734 1.77006 9.35822 1.6666 9.00146 1.66666C8.6447 1.6666 8.29559 1.77006 7.99647 1.96449C7.69734 2.15892 7.46106 2.43597 7.31628 2.76203L6.82924 3.8574C6.65668 4.24779 6.36497 4.57353 5.99591 4.78795C5.62525 5.00176 5.19647 5.09283 4.77091 5.04814L3.57554 4.92129C3.22081 4.88377 2.86282 4.94996 2.54497 5.11185C2.22711 5.27373 1.96305 5.52435 1.7848 5.83332C1.60632 6.14211 1.52128 6.49605 1.54002 6.85222C1.55876 7.20838 1.68046 7.55145 1.89035 7.8398L2.59591 8.80925C2.84753 9.15528 2.98302 9.57215 2.98295 9.99999C2.98302 10.4278 2.84753 10.8447 2.59591 11.1907L1.89035 12.1602C1.68046 12.4485 1.55876 12.7916 1.54002 13.1478C1.52128 13.5039 1.60632 13.8579 1.7848 14.1667C1.96323 14.4755 2.22732 14.726 2.54513 14.8878C2.86294 15.0497 3.22084 15.116 3.57554 15.0787L4.76721 14.9518C5.19276 14.9072 5.62155 14.9982 5.99221 15.212C6.36265 15.4258 6.65571 15.7517 6.82924 16.1426Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99997 12.5C10.3807 12.5 11.5 11.3807 11.5 9.99999C11.5 8.61928 10.3807 7.49999 8.99997 7.49999C7.61926 7.49999 6.49998 8.61928 6.49998 9.99999C6.49998 11.3807 7.61926 12.5 8.99997 12.5Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
