import styled from "styled-components";
import { Link } from "react-router-dom";
import Flex from "../../components/general/Flex";
import UserIcon from "../../components/SvgComponents/UserIcon";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import PrimeIcon from "../../components/SvgComponents/primeIcon";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import RightArrowIcon from "../../components/SvgComponents/RightArrowIcon";

const StyledUserInfo = styled.div`
  margin: 16px 0;
  padding: 20px 16px;
  background: white;
  border-radius: 12px;
  .balance-item {
    border-left: 1px solid #e7e9ec;
    padding-left: 10px;
  }
  .balances {
    .balance-item {
      &:nth-child(2) {
        margin-right: 50px;
      }
    }
  }
  .user-actions {
    margin-top: 10px;
    .user-action {
      margin-right: 16px;
      border-radius: 16px;
      display: inline-block;
      padding: 2px 10px;
      text-decoration: none;
      background: #f5f5f5;
      &:first-child {
        background: #eff8ea !important;
      }
    }
  }
`;

export default function ReceivedUserInfo({ setFillBalanceStatus, user }) {
  return (
    <StyledUserInfo>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className={"mb-16"}
      >
        <Flex>
          <div className="p-r-16">
            <UserIcon />
          </div>
          <div>
            <Typography
              variant="p"
              text={`ID ${user?.id || ""}`}
              color="#B3BBC4"
              size="14px"
              weight="400"
            />
            <Flex>
              <Typography
                variant="p"
                text={user?.label || ""}
                color={primaryTextColor}
                size="20px"
                weight="500"
                padding="0 5px 10px 0"
              />
              {user?.user?.is_prime ? <PrimeIcon /> : ""}
            </Flex>
            <Flex className={"user-actions"}>
              <Link to={`/customer/${user.user.id}`} className="user-action">
                <Flex alignItems="center">
                  <Typography
                    text="View customer"
                    variant="span"
                    color="#5DBA2F"
                    size="14px"
                    weight="500"
                    padding="0 5px 0 0"
                  />
                  <RightArrowIcon color="#5DBA2F" />
                </Flex>
              </Link>
              <Link
                to={`/orders?user_info=${user?.user_code.substr(
                  2,
                  user?.user_code?.length - 1
                )}`}
                className="user-action"
              >
                <Flex alignItems="center">
                  <Typography
                    text="View orders"
                    variant="span"
                    color="#3B5166"
                    size="14px"
                    weight="500"
                    padding="0 5px 0 0"
                  />
                  <RightArrowIcon color="#3B5166" />
                </Flex>
              </Link>
              <Link
                to={`/orders?status=received&user_info=${user?.user_code.substr(
                  2,
                  user?.user_code?.length - 1
                )}`}
                className="user-action"
              >
                <Flex alignItems="center">
                  <Typography
                    text="View received orders"
                    variant="span"
                    color="#3B5166"
                    size="14px"
                    weight="500"
                    padding="0 5px 0 0"
                  />
                  <RightArrowIcon color="#3B5166" />
                </Flex>
              </Link>
            </Flex>
          </div>
        </Flex>
        <Flex className="balances" alignItems="center">
          <div className="balance-item">
            <Typography
              variant="p"
              text="Bonus"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 6px 5px 0"
            />
            <Typography
              variant="p"
              text={`${user?.user?.bonus || "0"}  ₾`}
              color={primaryTextColor}
              size="20px"
              weight="500"
              padding="0 26px 4px 0"
            />
          </div>
          <div className="balance-item">
            <Typography
              variant="p"
              text="Balance"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 6px 5px 0"
            />
            <Typography
              variant="p"
              text={`${user?.user?.balance || "0"}  ₾`}
              color={primaryTextColor}
              size="20px"
              weight="500"
              padding="0 0 4px 0"
            />
          </div>
          <div>
            <ButtonSecondary
              text="Refill balance"
              // disabled={true}
              background="#5B6D7F"
              color="white"
              click={() => setFillBalanceStatus(true)}
            />
          </div>
        </Flex>
      </Flex>
    </StyledUserInfo>
  );
}
