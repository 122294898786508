import styled from "styled-components";
import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import Flex from "../../components/general/Flex";
import ShowLessIcon from "../../components/SvgComponents/ShowLessIcon";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import useCategories from "../../hooks/categories/useCategories.hook.jsx";
import useGetWarehouses from "../../hooks/warehouses/useWarehouses.hook.jsx";

const StyledOrdersTopActions = styled.div`
  background: white;
  border-radius: 12px;
  margin-top: 24px;
  width: 100%;

  .shop_top {
    padding: 16px;
  }

  &
    :where(.css-dev-only-do-not-override-1ipetx6).ant-select-multiple
    .ant-select-selection-overflow {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  & .checked-item {
    background: #ffffff;
    padding: 8px 16px 0 0;

    & label {
      font-family: "Montserrat arm2";
      font-size: 14px;
    }
  }

  .show-less {
    background: transparent;
    border: none;
    outline: none;
  }

  .actions-btns {
    display: flex;
    align-items: center;
    justify-content: end;
    //margin-top: -45px;
    button {
      margin: 0 0 0 8px;
    }
  }

  .row {
    height: ${({ more }) => (more ? "100%" : " 52px")};
    width: 100%;
    overflow: hidden;
  }

  .show-more-icon {
    svg {
      transition: 200ms;
      transform: ${({ more }) => (more ? "rotate(0)" : "rotate(180deg)")};
    }
  }
`;

export default function ShopsTopSearchActions({ setFilters, filters }) {
  const [more, setMore] = useState(false);
  const { categories } = useCategories("shop");
  const checkedData = [
    { name: "is_special", label: "Special" },
    {
      name: "is_buyforme",
      label: "Buy for me",
    },
    { name: "is_dropify", label: "Dropify" },
    { name: "is_one_click", label: "One click" },
    { name: "for_home_page", label: "Show on homepage" },
  ];
  const [form] = Form.useForm();
  const { data: warehouses = [] } = useGetWarehouses();

  const handleFinish = (values) => {
    let newValues = {};
    Object.entries(values).forEach(([key, val]) => {
      if (val) {
        newValues[key] = val;
      }
    });

    setFilters(newValues);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleResetForm = () => {
    form.resetFields();
    setFilters({});
  };

  return (
    <StyledOrdersTopActions more={more}>
      <div className={"shop_top"}>
        <Form
          form={form}
          labelCol={8}
          wrapperCol={4}
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={24} className="row">
            <Col lg={4}>
              <Form.Item name="name">
                <Input
                  placeholder={"Search by shop name"}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="warehouse_id">
                <Select
                  placeholder="Country"
                  options={warehouses}
                  style={{ height: 36 }}
                />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="price">
                <Select
                  style={{ height: 36 }}
                  placeholder="Price"
                  options={[
                    { value: 1, label: "$" },
                    { value: 2, label: "$$" },
                    { value: 3, label: "$$$" },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col lg={4}>
              <Form.Item name="category_ids">
                <Select
                  style={{ height: 36 }}
                  placeholder="Category"
                  options={categories}
                  mode="multiple"
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Flex flexWrap={"wrap"}>
                {checkedData?.map((item, i) => (
                  <Form.Item
                    name={item?.name}
                    key={i}
                    className={"checked-item"}
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={(e) =>
                        form.setFieldValue(item?.name, e.target.checked ? 1 : 0)
                      }
                    >
                      {item?.label}
                    </Checkbox>
                  </Form.Item>
                ))}
              </Flex>
            </Col>
          </Row>
          <div className="actions-btns">
            <button
              className="show-less"
              type={"button"}
              onClick={() => setMore(!more)}
            >
              <Flex alignItems="center">
                <div className="show-more-icon">
                  <ShowLessIcon />
                </div>
                <Typography
                  variant="span"
                  text={more ? "Show less" : "Show more"}
                  color="rgba(91, 109, 127, 1)"
                  padding="0 0 0 15px"
                />
              </Flex>
            </button>
            <ButtonSecondary text="Reset" click={() => handleResetForm()} />
            <ButtonPrimary
              text="Search"
              htmlType="submit"
              icon={<SearchIcon />}
            />
          </div>
        </Form>
      </div>
    </StyledOrdersTopActions>
  );
}
