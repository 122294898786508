import { AutoComplete, Drawer, Form } from "antd";
import styled from "styled-components";
import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import { primeUsersAutocomplete } from "../../hooks/usePrimeUsersAutocomplete.hook.js";
import { useState } from "react";
import { updatePrimeUserStatusHook } from "../../hooks/updatePrimeUserStatus.hook.js";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import {
  mail_icon,
  order_icon,
  phone_icon,
  recipients_icon,
  user_icon,
  year_icon,
} from "../../assets/images/prime/index.js";
import { analyzeDay } from "../../helpers/AnalyzeDayOfYear.js";

const WrapperModal = styled.div`
  .auto_comp {
    width: 100%;
  }

  background: #f9fafb;
`;

export const AddPrimeUsersModal = ({
  refetch = () => {},
  addPrime,
  setAddPrime = () => {},
  userInfo,
  setUserInfo = () => {},
  setFilters = () => {},
}) => {
  const [form] = Form.useForm();
  const [prime, setPrime] = useState(null);
  const [user, setUser] = useState({});

  const { usersList = [] } = primeUsersAutocomplete(
    userInfo && userInfo?.user_info?.trim()?.length === 0 ? null : userInfo
  );

  const onFinish = () => {
    if (user.length) {
      const newValues = { status: prime, id: user[0]?.id };
      mutate(newValues);
      setFilters({ is_prime: 1 });
    }
  };

  const closeModal = () => {
    setAddPrime(false);
    setPrime(null);
    setUserInfo(null);
    form.resetFields();
    setUser({});
  };

  const { mutate } = updatePrimeUserStatusHook(
    (data) => {
      openNotification("Make Prime", "Prime successfully created");
      setAddPrime(false);
      form.resetFields();
      setUserInfo(null);
      refetch();
    },
    (e) => {
      openNotificationError("Make Prime", "something went wrong");
    }
  );

  const registeredInterval = analyzeDay(user[0]?.registered_days);

  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => closeModal()}
      open={addPrime}
      width={600}
      header={false}
    >
      <WrapperModal>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Typography
              variant={"h2"}
              weight={"500"}
              text={"Add prime"}
              size={"22px"}
            />
            <Flex alignItems={"center"}>
              <ButtonSecondary
                text="Cancel"
                background="#FFECED"
                color="#FC4447"
                border={"none"}
                htmlType="reset"
                style={{ margin: "0 16px" }}
                click={() => closeModal()}
              />
              <ButtonPrimary
                text="Make prime"
                border={"none"}
                htmlType="submit"
                click={() => setPrime(1)}
              />
            </Flex>
          </Flex>
          <Flex
            style={{
              padding: "16px",
              background: "#ffffff",
              margin: "24px 0",
              borderRadius: "12px",
            }}
          >
            <Form.Item name="user_info" label={"Full name or user code"}>
              <AutoComplete
                style={{ width: "520px" }}
                placeholder="Full name or GA"
                value={userInfo}
                onSearch={(val) => {
                  setUserInfo({ user_info: val, is_prime: 0 });
                }}
                onSelect={(user) => {
                  setUser(usersList.filter((el) => el.value === user));
                }}
                options={usersList || []}
              />
            </Form.Item>
          </Flex>
        </Form>
        {user?.length ? (
          <Flex
            style={{
              padding: "16px",
              background: "#ffffff",
              margin: "24px 0",
              borderRadius: "12px",
            }}
            direction={"column"}
          >
            <Flex justifyContent={"center"} alignItems={"center"}>
              <img alt={"user"} src={user_icon} />
              <Typography
                margin={"0 0 0 8px"}
                weight={500}
                variant={"p"}
                text={user[0]?.full_name + " " + user[0]?.recipient?.user_code}
              />
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              style={{ margin: "18px 0 0 0" }}
            >
              <img alt={"user"} src={year_icon} />
              <Typography
                margin={"0 16px 0 8px"}
                weight={400}
                variant={"p"}
                color={"#5B6D7F"}
                text={registeredInterval}
                size={"14px"}
              />
              <img alt={"order"} src={order_icon} />
              <Typography
                margin={"0 0 0 8px"}
                weight={400}
                variant={"p"}
                color={"#5B6D7F"}
                text={user[0]?.orders_count || 0}
                size={"14px"}
              />
              {user[0]?.orders_count_by_country?.map((el, index) => (
                <Flex
                  key={index}
                  alignItems={"center"}
                  style={{ margin: "0 0 0 16px" }}
                >
                  <img
                    alt={`country${index}`}
                    width={24}
                    height={24}
                    src={`https://devbackadmin.onex.ge/storage/images/warehouses/${el?.round_flag}`}
                  />
                  <Typography
                    margin={"0 0 0 8px"}
                    weight={400}
                    variant={"p"}
                    color={"#5B6D7F"}
                    text={el?.total}
                    size={"14px"}
                  />
                </Flex>
              ))}
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              style={{ margin: "18px 0 0 0" }}
            >
              <img alt={"recipients"} src={recipients_icon} />
              <Typography
                margin={"0 0 0 8px"}
                weight={400}
                variant={"p"}
                text={
                  (user[0]?.recipients?.length === 1
                    ? user[0]?.recipients?.length + " " + "recipient"
                    : user[0]?.recipients?.length + " " + "recipients") || 0
                }
                size={"14px"}
              />
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              style={{ margin: "18px 0 0 0" }}
            >
              <img alt={"phone"} src={phone_icon} />
              <Typography
                margin={"0 0 0 8px"}
                weight={400}
                variant={"p"}
                text={user[0]?.phone || ""}
                size={"14px"}
              />
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              style={{ margin: "18px 0 0 0" }}
            >
              <img alt={"email"} src={mail_icon} />
              <Typography
                margin={"0 0 0 8px"}
                weight={400}
                variant={"p"}
                text={user[0]?.email || ""}
                size={"14px"}
              />
            </Flex>
          </Flex>
        ) : null}
      </WrapperModal>
    </Drawer>
  );
};
