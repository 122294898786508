import axios from "../libs/axios.js";

export const slidesApi = {
  async sortSlides(data) {
    return await axios.post("/sliders/sort-priority", data);
  },
  async fetchSlides() {
    return await axios.get("/sliders");
  },
  async fetchLanguages() {
    return await axios.get("/languages");
  },

  async isActiveSlide(data) {
    return await axios.put(`sliders/is-active/${data?.id}`, {
      is_active: data?.is_active,
    });
  },
  async deleteSlider(id) {
    return await axios.delete(`sliders/${id}`);
  },
};

export const CreateSlide = async ({ id, formData }) => {
  let result;
  if (id) {
    const res = await axios.post(`/sliders/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    result = res;
  } else {
    const res = await axios.post("/sliders", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    result = res;
  }
  return result;
};
