import axios from "../libs/axios.js";

export const shopsApi = {
  async sortShop(data) {
    return await axios.post("/shops/sort-priority", data);
  },

  async fetchShops(params) {
    return await axios.get("/shops", { params: { ...params } });
  },

  async isActiveShops(data) {
    return await axios.put(`shops/is-active/${data?.id}`, {
      is_active: data?.is_active,
    });
  },
  async deleteShops(id) {
    return await axios.delete(`shops/${id}`);
  },
  async createAndEditShops({ id, formData }) {
    if (id) {
      return await axios.post(`/shops/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      return await axios.post("/shops", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
  },
};
