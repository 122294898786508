import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import DashboardTopActions from "./TopActions";
import { Col, notification, Row, Skeleton } from "antd";
import DashboardTotalCards from "./TotalCards";
import DashboardAppDownloads from "./AppDownloads";
import DashboardRegistration from "./Registrations";
import RegistrationType from "./RegistrationType";
import UsersByGender from "./UsersByGender";
import OrdersByCountry from "./OrdersByCountry";
import useDashboardData from "../../hooks/Dashboard/useDashboardData.hook.jsx";
import { useEffect, useState } from "react";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import Flex from "../../components/general/Flex";
import RegistrationIsPerson from "./RegistrationIsPerson";
import MapChart from "./DashboardMap.jsx";
import { weekDays } from "../../constants.js";
import Loader from "../../components/moleculs/Loader";
import DashboardCharts from "./Charts";

const StyledDashboard = styled.div`
  padding-top: 24px;

  .page-title {
    @media (max-width: 768px) {
      margin-bottom: 16px;
      div {
        justify-content: space-between !important;
      }
    }
  }
`;

export default function Dashboard() {
  const [filterType, setFilterType] = useState("day");

  const [filters, setFilters] = useState();

  const { dashboardData, isLoading, refetch } = useDashboardData(filters);
  const [customRange, setCustomRange] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [currentStartDate, setCurrentStartDate] = useState();
  const [currentEndDate, setCurrentEndDate] = useState();
  const [currentWeekDay, setCurrentWeekDay] = useState(
    weekDays[new Date().getDay()]
  );

  useEffect(() => {
    if (!customRange) {
      setFilters({ filter_type: filterType });
    } else {
      setFilters({ start_date: customRange[0], end_date: customRange[1] });
    }
  }, [filterType, customRange]);

  useEffect(() => {
    if (dashboardData) {
      setCurrentStartDate(dashboardData?.data?.current_start_date);
      setCurrentEndDate(dashboardData?.data?.current_end_date);
    }
  }, [dashboardData]);

  const handleRefetch = () => {
    setLoadingRefresh(true);
    refetch()
      .then(() => {
        api.success({
          message: `Success!`,
          description: "Dashboard data refreshed",
          placement: "topLeft",
        });
      })
      .finally(() => {
        setLoadingRefresh(false);
      });
  };

  return (
    <>
      <StyledDashboard>
        {contextHolder}
        <div className="overlay" />
        <div className="page-title">
          <Flex alignItems={"center"}>
            <Typography
              text="Dashboard"
              color="rgba(38, 38, 38, 1)"
              weight={500}
              size="24px"
              variant="h2"
            />
            {loadingRefresh ? (
              <Skeleton.Button style={{ marginLeft: 10 }} active={true} />
            ) : (
              <ButtonPrimary
                text={"Refresh"}
                style={{ marginLeft: "10px", padding: "8px 12px" }}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                }
                click={() => handleRefetch()}
              />
            )}
          </Flex>
        </div>

        <DashboardTopActions
          filterType={filterType}
          setFilterType={setFilterType}
          refetch={refetch}
          setCustomRange={setCustomRange}
          dashboardData={dashboardData}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
        />
        <DashboardCharts customRange={customRange} />
        {dashboardData ? (
          <>
            <Row gutter={24}>
              <Col lg={8} md={24} xs={24}>
                <DashboardTotalCards
                  dashboardData={dashboardData}
                  isLoading={isLoading}
                  filterType={filterType}
                  customRange={customRange}
                  filters={filters}
                  currentWeekDay={currentWeekDay}
                />
              </Col>
              <Col lg={16} md={24} xs={24}>
                <OrdersByCountry
                  dashboardData={dashboardData}
                  filterType={filterType}
                  currentWeekDay={currentWeekDay}
                />
              </Col>
            </Row>
            <DashboardRegistration
              dashboardData={dashboardData}
              filterType={filterType}
            />
            <MapChart dashboardData={dashboardData} filterType={filterType} />
            <Row gutter={[40, 40]}>
              <Col lg={12} md={24} xs={24}>
                <DashboardAppDownloads
                  dashboardData={dashboardData}
                  isLoading={isLoading}
                  filterType={filterType}
                />
              </Col>
              <Col lg={12} md={24} xs={24}>
                <RegistrationIsPerson
                  dashboardData={dashboardData}
                  isLoading={isLoading}
                  filterType={filterType}
                />
              </Col>
              <Col lg={12} md={24} xs={24}>
                <RegistrationType
                  dashboardData={dashboardData}
                  isLoading={isLoading}
                  filterType={filterType}
                />
              </Col>
              <Col lg={12} md={24} xs={24}>
                <UsersByGender
                  dashboardData={dashboardData}
                  isLoading={isLoading}
                  filterType={filterType}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Loader />
        )}
      </StyledDashboard>
    </>
  );
}
