import { useMutation, useQueryClient } from "react-query";
import { onlinePaymentApi } from "../../services/online-payment.js";

export const useIsRefundPayment = (
  onSuccess = () => {},
  onError = () => {}
) => {
  const client = useQueryClient();
  return useMutation(onlinePaymentApi.refundPayment, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["refund-payment"]);
    },
  });
};
