import { useQuery } from "react-query";
import { GetTransactions } from "../../services/customers.js";

export default function useGetTransactions(id) {
  const { data: transactions } = useQuery(
    ["get-transactions", id],
    () => GetTransactions(id),
    { staleTime: Infinity }
  );

  return { transactions };
}
