import axios from "../libs/axios.js";

export const bannersApi = {
  async fetchBanners(params) {
    return await axios.get("/header-messages", { params: { ...params } });
  },
  async createAndUpdateBanner({ id, formData }) {
    if (id) {
      return await axios.post(`/header-messages/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      return await axios.post(`/header-messages`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
  },

  async isActiveBanner(data) {
    return await axios.put(`/header-messages/is-active/${data?.id}`, {
      is_active: data?.is_active,
    });
  },
  async deleteBanner(id) {
    return await axios.delete(`/header-messages/${id}`);
  },
};
