import React from "react";
import { Row } from "antd";
import ButtonCancel from "../../components/moleculs/ButtonCancel";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import styled from "styled-components";
import SwitchHorizontal from "../../components/SvgComponents/SwitchHorizontal";

const CropModal = ({ src, onCancel, onReplace, onDone }) => {
  return (
    <>
      <CropContainer>
        <img src={src} />
      </CropContainer>
      <StyledCropModal justifyContent="space-between">
        <ButtonSecondary
          icon={<SwitchHorizontal />}
          text={"Replace"}
          onClick={onReplace}
        />
        <div>
          <ButtonCancel text={"Cancel"} onClick={onCancel} />
          <ButtonPrimary text={"Done"} onClick={() => onDone(src)} />
        </div>
      </StyledCropModal>
    </>
  );
};
const StyledCropModal = styled(Row)`
  width: 100%;
  gap: 12px;
  margin-top: 12px;
  > button {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 8px;
    > div {
      display: inline-block;
    }
  }
  > div {
    display: flex;
    width: 100%;
    gap: 12px;
    > button {
      width: 50%;
    }
  }
`;
const CropContainer = styled.div`
  background-color: #e7e9ec;
  width: 100%;
  height: 476px;
  max-width: 260px;
  overflow: hidden;
  border-radius: 16px;
  display: grid;
  place-items: center;
`;
export default CropModal;
