import styled from "styled-components";
import { DatePicker, Select } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon";
import SettingsBtnIcon from "../../components/SvgComponents/SettingsBtnIcon";

const StyledDeliveryTopActions = styled.div`
  margin-top: 24px;
  .item {
    margin-right: 16px;
    &.group-select {
      .ant-select:first-child {
        border-radius: 8px 0 0 8px !important;
      }
      .ant-select:last-child {
        border-radius: 0 8px 8px 0 !important;
      }
    }
    .accepted {
      background: #e7e9ec;
      border-radius: 16px;
      padding: 0 5px;
      width: 86px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
    }
  }
  .info-block {
    background: #ffffff;
    border-radius: 12px;
    padding: 16px;
    margin: 16px 0;
    .update {
      background: #5b6d7f;
      border-radius: 8px;
      color: white;
      border: none;
      outline: none;
      padding: 6px 16px;
    }
    .settings-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 10px;
      background: #5b6d7f;
      border-radius: 8px;
      border: none;
      outline: none;
    }
  }
`;

export default function DeliveryTopActions() {
  return (
    <StyledDeliveryTopActions>
      <Typography
        variant="p"
        text="Filters"
        color="#3B5166"
        size="14px"
        weight="500"
        padding="0 0 4px 0"
      />
      <Flex justifyContent="space-between">
        <Flex>
          <div className="item">
            <DatePicker inputReadOnly={true} />
          </div>
          <div className="item">
            <Select defaultValue="All time" style={{ width: 115 }}>
              <Select.Option>All Time</Select.Option>
            </Select>
          </div>
          <div className="item">
            <Select defaultValue="Choose courier" style={{ width: 165 }}>
              <Select.Option>All Time</Select.Option>
            </Select>
          </div>
          <div className="item group-select">
            <Select defaultValue="Select region" style={{ width: 165 }}>
              <Select.Option>All Time</Select.Option>
            </Select>
            <Select
              defaultValue="Select community"
              disabled
              style={{ width: 165 }}
            >
              <Select.Option>All Time</Select.Option>
            </Select>
          </div>
          <div className="item">
            <Select style={{ width: 145 }} defaultValue="accepted">
              <Select.Option value="accepted">
                <div className="accepted">Accepted</div>
              </Select.Option>
            </Select>
          </div>
        </Flex>
        <Flex alignItems="center">
          <ButtonSecondary text="Reset" />
          <ButtonPrimary
            text="Search"
            icon={<SearchIcon />}
            className="ml-16 mr-16"
          />
          <Flex alignItems="center">
            <DownloadIcon />
            <Typography
              variant="span"
              text="Excel"
              color="#3B5166"
              size="14px"
              weight="400"
              padding="0 0 0 4px"
            />
          </Flex>
        </Flex>
      </Flex>
      <div className="info-block">
        <Typography
          variant="p"
          text="Actions"
          color="#3B5166"
          size="14px"
          weight="500"
          padding="0 0 4px 0"
        />
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <div className="item">
              <Select defaultValue="Select status" style={{ width: 148 }}>
                <Select.Option>Test</Select.Option>
              </Select>
            </div>
            <div className="item">
              <Select defaultValue="Choose courier" style={{ width: 164 }}>
                <Select.Option>Test</Select.Option>
              </Select>
            </div>
            <div className="item">
              <button className="update">Update</button>
            </div>
          </Flex>
          <Flex>
            <ButtonPrimary text="Delivered" className="mr-16" />
            <button className="settings-btn">
              <SettingsBtnIcon />
            </button>
          </Flex>
        </Flex>
      </div>
    </StyledDeliveryTopActions>
  );
}
