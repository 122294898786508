import Flex from "../../../components/general/Flex.jsx";
import Typography from "../../../components/moleculs/Typography.jsx";
import {
  greenColor,
  primaryTextColor,
  secondaryTextColor,
} from "../../../constants.js";
import ReadyIcon from "../../../components/SvgComponents/ReadyIcon.jsx";
import MoneyIcon from "../../../components/SvgComponents/MoneyIcon.jsx";
import FileIcon from "../../../components/SvgComponents/FileIcon.jsx";
import ButtonSecondary from "../../../components/moleculs/ButtonSecondary.jsx";
import EditIcon from "../../../components/SvgComponents/EditIcon.jsx";
import { statusTexts } from "../../../utils/statusTexts.js";
import CategoryIcon from "../../../components/SvgComponents/CategoryIcon.jsx";
import { useState } from "react";
import { SendToIcon } from "../../../assets/images/send-to-icon.jsx";
import { SendToHubModal } from "./SendToHubModal.jsx";
import { PermissionFinder } from "../../../hooks/permissionFinder.hook.js";
import { RefundModal } from "./RefundModal";
import RefoundIcon from "../../../components/SvgComponents/RefoundIcon.jsx";

export default function OrdersDetailsHeader({
  setEditStatus,
  order,
  category,
  permissions,
  refetch,
  refetchOrders,
}) {
  const [sendToHubStatus, setSendToHubStatus] = useState(false);
  const [refundStatus, setRefundStatus] = useState(false);

  return (
    <header>
      <Flex justifyContent="space-between">
        <div>
          <Flex alignItems={"center"}>
            <div className="flag">
              <img src={order?.warehouse.round_flag} alt="" />
            </div>
            <div className="dispatch">
              <img src={order?.dispatch?.icon} alt="" />
            </div>
            <Typography
              text={order?.tracking_code}
              variant="p"
              color={primaryTextColor}
              size="22px"
              weight="500"
              padding="0 12px 0 0"
            />
            <Typography
              variant="p"
              text={statusTexts[order?.status]}
              color="#5B6D7F"
              size="14px"
              weight="400"
              padding="0 12px 0 0"
            />
            {order?.ready_for_pickup ? (
              <Flex>
                <ReadyIcon />
                <Typography
                  variant="span"
                  text="Ready"
                  padding="0 0 0 6px"
                  color={greenColor}
                  size="12px"
                  weight="400"
                />
              </Flex>
            ) : null}
          </Flex>

          <Flex>
            <div className="description">
              <Typography
                text={order?.customer_comment}
                variant="p"
                color={secondaryTextColor}
                size="14px"
                weight="400"
                padding="0 17px 0 0"
              />
              <Flex alignItems="center">
                <MoneyIcon />
                <Typography
                  variant="span"
                  text={`${
                    order?.declaration_price
                      ? order?.declaration_price +
                        " " +
                        order?.declaration_currency
                      : "Not declarated"
                  }`}
                  color={primaryTextColor}
                  size="14px"
                  wight={400}
                  padding="0 17px 0 8px"
                />
              </Flex>
              {order?.order_type ? (
                <Flex className={"mr-16"} alignItems={"center"}>
                  <Typography
                    text={
                      order?.order_type === "online_shopping"
                        ? "Online shop"
                        : "Personal items"
                    }
                    variant={"p"}
                    size={"14px"}
                    color={"#262626"}
                    weight={"400"}
                  />
                </Flex>
              ) : null}

              <Flex className={"mr-16"} alignItems={"center"}>
                <CategoryIcon />
                <Typography
                  text={category?.name}
                  variant={"p"}
                  size={"14px"}
                  color={"#262626"}
                  weight={"400"}
                  padding={"0 0 0 5px"}
                />
              </Flex>
              {order?.invoice ? (
                <a href={order.invoice.file} target={"_blank"}>
                  <Flex alignItems="center">
                    <FileIcon />
                    <Typography
                      variant="span"
                      text="Invoice"
                      color={primaryTextColor}
                      size="14px"
                      wight={400}
                      padding="0 0 0 8px"
                    />
                  </Flex>
                </a>
              ) : null}

              {/* <Flex alignItems="center" style={{ paddingLeft: 16 }}> */}
              {/*   <QuestionIcon /> */}
              {/*   <Typography */}
              {/*     variant="span" */}
              {/*     text="Additional info text" */}
              {/*     color={primaryTextColor} */}
              {/*     size="14px" */}
              {/*     wight={400} */}
              {/*     padding="0 0 0 8px" */}
              {/*   /> */}
              {/* </Flex> */}
            </div>
          </Flex>
        </div>
        <SendToHubModal
          setSendToHubStatus={setSendToHubStatus}
          sendToHubStatus={sendToHubStatus}
          order={order}
        />
        <RefundModal
          status={refundStatus}
          setStatus={setRefundStatus}
          order={order}
          refetch={refetch}
          refetchOrders={refetchOrders}
        />
        <Flex alignItems={"center"} className="edit-btn">
          {!order?.refund_date ? (
            <ButtonSecondary
              click={() => setRefundStatus(true)}
              text="Refund"
              icon={<RefoundIcon />}
              style={{ marginRight: 10 }}
            />
          ) : null}

          <ButtonSecondary
            click={() => setSendToHubStatus(true)}
            text="Send to hub"
            icon={<SendToIcon />}
            style={{ marginRight: 10 }}
          />
          {PermissionFinder(permissions, "order-edit") ? (
            <ButtonSecondary
              click={() => setEditStatus(true)}
              text="Edit"
              icon={<EditIcon />}
            />
          ) : null}
        </Flex>
      </Flex>
    </header>
  );
}
