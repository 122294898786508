import { useQuery } from "react-query";
import { blogApi } from "../../services/blog.js";

export const useFetchLanguages = () => {
  const {
    isError,
    isLoading,
    refetch,
    data: languages,
  } = useQuery("get-languages", () => blogApi.fetchLanguages(), {
    staleTime: Infinity,
    select: ({ data }) => {
      return data?.data?.data;
    },
  });
  return { isLoading, languages, refetch };
};
