import styled from "styled-components";
import { Spin } from "antd";
import Flex from "../general/Flex";

const StyledSecondaryButton = styled.button`
  background: ${({ background }) => background || "#f5f5f5"};
  color: ${({ color }) => color || "#0a2540"};
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat arm2";
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
`;

export default function ButtonGray({
  text,
  icon,
  click,
  loading = false,
  ...props
}) {
  return (
    <StyledSecondaryButton onClick={() => click()} {...props}>
      {icon ? (
        <Flex alignItems="center">
          {icon}
          <span style={{ paddingLeft: 8 }}>{text}</span>
        </Flex>
      ) : (
        <>{loading ? <Spin /> : text}</>
      )}
    </StyledSecondaryButton>
  );
}
