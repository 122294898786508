import { Table } from "antd";
import styled from "styled-components";
import Flex from "../../components/general/Flex.jsx";
import UsFlag from "../../components/SvgComponents/UsFlag.jsx";
import FlyIcon from "../../components/SvgComponents/FlyIcon.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import InArmeniaIcon from "../../components/SvgComponents/InArmeniaIcon";
import ReadyIcon from "../../components/SvgComponents/ReadyIcon";
import LockerIcon from "../../components/SvgComponents/LockerIcon";

const StyledCaption = styled.div`
  .caption-item {
    display: flex;
  }
`;

const StyledTable = styled.div`
  thead {
    display: none;
  }

  .ant-table-title {
    background: #f5f5f5;
    padding: 8px !important;
    border-radius: 12px 12px 0 0;
  }
`;
export default function CheckTable() {
  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "",
    },
    {
      key: "country",
      dataIndex: "country",
      title: "",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "",
    },
    {
      key: "tracking",
      dataIndex: "tracking",
      title: "",
    },
    {
      key: "weight",
      dataIndex: "weight",
      title: "",
    },
    {
      key: "price",
      dataIndex: "price",
      title: "",
    },
    {
      key: "status",
      dataIndex: "status",
      title: "",
    },
    {
      key: "pickup_point",
      dataIndex: "pickup_point",
      title: "",
    },
  ];

  const data = [
    {
      id: 1,
      country: (
        <div>
          <Flex>
            <UsFlag />
            <div
              style={{ paddingLeft: 8 }}
              id="dispatch"
              data-tip="React-tooltip"
            >
              <FlyIcon />
            </div>
          </Flex>
          <Typography
            variant="span"
            text="UK 336"
            color="#262626"
            size="14px"
            weight={500}
            padding="8px 0 0 0"
          />
        </div>
      ),
      name: (
        <Flex>
          <Typography
            text="Harutyun Abrahamyan ARM750350"
            variant="p"
            size="14px"
            weight="500"
            color=" #262626"
          />
        </Flex>
      ),
      tracking: (
        <div>
          <Typography
            variant="p"
            text="4201980493612896810158"
            color="#262626"
            size="14px"
            weight="400"
          />
          <Flex>
            <div className="product-description">
              <Typography
                variant="p"
                text="Lorem Ipsum is simply dummy text"
                color="#5B6D7F"
                size="12px"
                weight={400}
                padding="10px 0 0 0 "
              />
            </div>
          </Flex>
        </div>
      ),
      weight: (
        <>
          <div>
            <Typography
              text="0.4 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
            />
          </div>
          <div>
            <Typography
              text="0.8 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
              padding="10px 0 0 0 "
            />
          </div>
        </>
      ),
      price: (
        <>
          <Typography
            variant="p"
            text="1400 ֏"
            color="#262626"
            weight="400"
            size="14px"
            padding="0 7px 0 0"
          />
          <Typography
            variant="p"
            text="60 $"
            color="#5B6D7F"
            weight="400"
            size="14px"
            padding="10px 0 0 0"
          />
        </>
      ),
      status: (
        <div>
          <Flex alignItems="center">
            <InArmeniaIcon />
            <Flex>
              <Typography
                variant="p"
                text="In Armenia"
                color="#5B6D7F"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
              <Typography
                variant="p"
                text="23.08.2022"
                color="#262626"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
            </Flex>
          </Flex>
          <Flex alignItems="center" style={{ paddingTop: 10 }}>
            <ReadyIcon />
            <Typography
              variant="p"
              text="Ready"
              color="#5DBA2F"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </div>
      ),
      pickup_point: (
        <>
          <Typography variant="p" text="Citadel" size="14px" weight="400" />
          <Flex style={{ marginTop: 10 }}>
            <LockerIcon />
            <Typography
              variant="p"
              text="Megamall"
              color="#262626"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </>
      ),
    },
    {
      id: 2,
      country: (
        <div>
          <Flex>
            <UsFlag />
            <div
              style={{ paddingLeft: 8 }}
              id="dispatch"
              data-tip="React-tooltip"
            >
              <FlyIcon />
            </div>
          </Flex>
          <Typography
            variant="span"
            text="UK 336"
            color="#262626"
            size="14px"
            weight={500}
            padding="8px 0 0 0"
          />
        </div>
      ),
      name: (
        <Flex>
          <Typography
            text="Harutyun Abrahamyan ARM750350"
            variant="p"
            size="14px"
            weight="500"
            color=" #262626"
          />
        </Flex>
      ),
      tracking: (
        <div>
          <Typography
            variant="p"
            text="4201980493612896810158"
            color="#262626"
            size="14px"
            weight="400"
          />
          <Flex>
            <div className="product-description">
              <Typography
                variant="p"
                text="Lorem Ipsum is simply dummy text"
                color="#5B6D7F"
                size="12px"
                weight={400}
                padding="10px 0 0 0 "
              />
            </div>
          </Flex>
        </div>
      ),
      weight: (
        <>
          <div>
            <Typography
              text="0.4 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
            />
          </div>
          <div>
            <Typography
              text="0.8 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
              padding="10px 0 0 0 "
            />
          </div>
        </>
      ),
      price: (
        <>
          <Typography
            variant="p"
            text="1400 ֏"
            color="#262626"
            weight="400"
            size="14px"
            padding="0 7px 0 0"
          />
          <Typography
            variant="p"
            text="60 $"
            color="#5B6D7F"
            weight="400"
            size="14px"
            padding="10px 0 0 0"
          />
        </>
      ),
      status: (
        <div>
          <Flex alignItems="center">
            <InArmeniaIcon />
            <Flex>
              <Typography
                variant="p"
                text="In Armenia"
                color="#5B6D7F"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
              <Typography
                variant="p"
                text="23.08.2022"
                color="#262626"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
            </Flex>
          </Flex>
          <Flex alignItems="center" style={{ paddingTop: 10 }}>
            <ReadyIcon />
            <Typography
              variant="p"
              text="Ready"
              color="#5DBA2F"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </div>
      ),
      pickup_point: (
        <>
          <Typography variant="p" text="Citadel" size="14px" weight="400" />
          <Flex style={{ marginTop: 10 }}>
            <LockerIcon />
            <Typography
              variant="p"
              text="Megamall"
              color="#262626"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </>
      ),
    },
    {
      id: 3,
      country: (
        <div>
          <Flex>
            <UsFlag />
            <div
              style={{ paddingLeft: 8 }}
              id="dispatch"
              data-tip="React-tooltip"
            >
              <FlyIcon />
            </div>
          </Flex>
          <Typography
            variant="span"
            text="UK 336"
            color="#262626"
            size="14px"
            weight={500}
            padding="8px 0 0 0"
          />
        </div>
      ),
      name: (
        <Flex>
          <Typography
            text="Harutyun Abrahamyan ARM750350"
            variant="p"
            size="14px"
            weight="500"
            color=" #262626"
          />
        </Flex>
      ),
      tracking: (
        <div>
          <Typography
            variant="p"
            text="4201980493612896810158"
            color="#262626"
            size="14px"
            weight="400"
          />
          <Flex>
            <div className="product-description">
              <Typography
                variant="p"
                text="Lorem Ipsum is simply dummy text"
                color="#5B6D7F"
                size="12px"
                weight={400}
                padding="10px 0 0 0 "
              />
            </div>
          </Flex>
        </div>
      ),
      weight: (
        <>
          <div>
            <Typography
              text="0.4 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
            />
          </div>
          <div>
            <Typography
              text="0.8 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
              padding="10px 0 0 0 "
            />
          </div>
        </>
      ),
      price: (
        <>
          <Typography
            variant="p"
            text="1400 ֏"
            color="#262626"
            weight="400"
            size="14px"
            padding="0 7px 0 0"
          />
          <Typography
            variant="p"
            text="60 $"
            color="#5B6D7F"
            weight="400"
            size="14px"
            padding="10px 0 0 0"
          />
        </>
      ),
      status: (
        <div>
          <Flex alignItems="center">
            <InArmeniaIcon />
            <Flex>
              <Typography
                variant="p"
                text="In Armenia"
                color="#5B6D7F"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
              <Typography
                variant="p"
                text="23.08.2022"
                color="#262626"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
            </Flex>
          </Flex>
          <Flex alignItems="center" style={{ paddingTop: 10 }}>
            <ReadyIcon />
            <Typography
              variant="p"
              text="Ready"
              color="#5DBA2F"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </div>
      ),
      pickup_point: (
        <>
          <Typography variant="p" text="Citadel" size="14px" weight="400" />
          <Flex style={{ marginTop: 10 }}>
            <LockerIcon />
            <Typography
              variant="p"
              text="Megamall"
              color="#262626"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </>
      ),
    },
    {
      id: 4,
      country: (
        <div>
          <Flex>
            <UsFlag />
            <div
              style={{ paddingLeft: 8 }}
              id="dispatch"
              data-tip="React-tooltip"
            >
              <FlyIcon />
            </div>
          </Flex>
          <Typography
            variant="span"
            text="UK 336"
            color="#262626"
            size="14px"
            weight={500}
            padding="8px 0 0 0"
          />
        </div>
      ),
      name: (
        <Flex>
          <Typography
            text="Harutyun Abrahamyan ARM750350"
            variant="p"
            size="14px"
            weight="500"
            color=" #262626"
          />
        </Flex>
      ),
      tracking: (
        <div>
          <Typography
            variant="p"
            text="4201980493612896810158"
            color="#262626"
            size="14px"
            weight="400"
          />
          <Flex>
            <div className="product-description">
              <Typography
                variant="p"
                text="Lorem Ipsum is simply dummy text"
                color="#5B6D7F"
                size="12px"
                weight={400}
                padding="10px 0 0 0 "
              />
            </div>
          </Flex>
        </div>
      ),
      weight: (
        <>
          <div>
            <Typography
              text="0.4 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
            />
          </div>
          <div>
            <Typography
              text="0.8 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
              padding="10px 0 0 0 "
            />
          </div>
        </>
      ),
      price: (
        <>
          <Typography
            variant="p"
            text="1400 ֏"
            color="#262626"
            weight="400"
            size="14px"
            padding="0 7px 0 0"
          />
          <Typography
            variant="p"
            text="60 $"
            color="#5B6D7F"
            weight="400"
            size="14px"
            padding="10px 0 0 0"
          />
        </>
      ),
      status: (
        <div>
          <Flex alignItems="center">
            <InArmeniaIcon />
            <Flex>
              <Typography
                variant="p"
                text="In Armenia"
                color="#5B6D7F"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
              <Typography
                variant="p"
                text="23.08.2022"
                color="#262626"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
            </Flex>
          </Flex>
          <Flex alignItems="center" style={{ paddingTop: 10 }}>
            <ReadyIcon />
            <Typography
              variant="p"
              text="Ready"
              color="#5DBA2F"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </div>
      ),
      pickup_point: (
        <>
          <Typography variant="p" text="Citadel" size="14px" weight="400" />
          <Flex style={{ marginTop: 10 }}>
            <LockerIcon />
            <Typography
              variant="p"
              text="Megamall"
              color="#262626"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </>
      ),
    },
    {
      id: 5,
      country: (
        <div>
          <Flex>
            <UsFlag />
            <div
              style={{ paddingLeft: 8 }}
              id="dispatch"
              data-tip="React-tooltip"
            >
              <FlyIcon />
            </div>
          </Flex>
          <Typography
            variant="span"
            text="UK 336"
            color="#262626"
            size="14px"
            weight={500}
            padding="8px 0 0 0"
          />
        </div>
      ),
      name: (
        <Flex>
          <Typography
            text="Harutyun Abrahamyan ARM750350"
            variant="p"
            size="14px"
            weight="500"
            color=" #262626"
          />
        </Flex>
      ),
      tracking: (
        <div>
          <Typography
            variant="p"
            text="4201980493612896810158"
            color="#262626"
            size="14px"
            weight="400"
          />
          <Flex>
            <div className="product-description">
              <Typography
                variant="p"
                text="Lorem Ipsum is simply dummy text"
                color="#5B6D7F"
                size="12px"
                weight={400}
                padding="10px 0 0 0 "
              />
            </div>
          </Flex>
        </div>
      ),
      weight: (
        <>
          <div>
            <Typography
              text="0.4 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
            />
          </div>
          <div>
            <Typography
              text="0.8 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
              padding="10px 0 0 0 "
            />
          </div>
        </>
      ),
      price: (
        <>
          <Typography
            variant="p"
            text="1400 ֏"
            color="#262626"
            weight="400"
            size="14px"
            padding="0 7px 0 0"
          />
          <Typography
            variant="p"
            text="60 $"
            color="#5B6D7F"
            weight="400"
            size="14px"
            padding="10px 0 0 0"
          />
        </>
      ),
      status: (
        <div>
          <Flex alignItems="center">
            <InArmeniaIcon />
            <Flex>
              <Typography
                variant="p"
                text="In Armenia"
                color="#5B6D7F"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
              <Typography
                variant="p"
                text="23.08.2022"
                color="#262626"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
            </Flex>
          </Flex>
          <Flex alignItems="center" style={{ paddingTop: 10 }}>
            <ReadyIcon />
            <Typography
              variant="p"
              text="Ready"
              color="#5DBA2F"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </div>
      ),
      pickup_point: (
        <>
          <Typography variant="p" text="Citadel" size="14px" weight="400" />
          <Flex style={{ marginTop: 10 }}>
            <LockerIcon />
            <Typography
              variant="p"
              text="Megamall"
              color="#262626"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </>
      ),
    },
    {
      id: 6,
      country: (
        <div>
          <Flex>
            <UsFlag />
            <div
              style={{ paddingLeft: 8 }}
              id="dispatch"
              data-tip="React-tooltip"
            >
              <FlyIcon />
            </div>
          </Flex>
          <Typography
            variant="span"
            text="UK 336"
            color="#262626"
            size="14px"
            weight={500}
            padding="8px 0 0 0"
          />
        </div>
      ),
      name: (
        <Flex>
          <Typography
            text="Harutyun Abrahamyan ARM750350"
            variant="p"
            size="14px"
            weight="500"
            color=" #262626"
          />
        </Flex>
      ),
      tracking: (
        <div>
          <Typography
            variant="p"
            text="4201980493612896810158"
            color="#262626"
            size="14px"
            weight="400"
          />
          <Flex>
            <div className="product-description">
              <Typography
                variant="p"
                text="Lorem Ipsum is simply dummy text"
                color="#5B6D7F"
                size="12px"
                weight={400}
                padding="10px 0 0 0 "
              />
            </div>
          </Flex>
        </div>
      ),
      weight: (
        <>
          <div>
            <Typography
              text="0.4 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
            />
          </div>
          <div>
            <Typography
              text="0.8 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
              padding="10px 0 0 0 "
            />
          </div>
        </>
      ),
      price: (
        <>
          <Typography
            variant="p"
            text="1400 ֏"
            color="#262626"
            weight="400"
            size="14px"
            padding="0 7px 0 0"
          />
          <Typography
            variant="p"
            text="60 $"
            color="#5B6D7F"
            weight="400"
            size="14px"
            padding="10px 0 0 0"
          />
        </>
      ),
      status: (
        <div>
          <Flex alignItems="center">
            <InArmeniaIcon />
            <Flex>
              <Typography
                variant="p"
                text="In Armenia"
                color="#5B6D7F"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
              <Typography
                variant="p"
                text="23.08.2022"
                color="#262626"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
            </Flex>
          </Flex>
          <Flex alignItems="center" style={{ paddingTop: 10 }}>
            <ReadyIcon />
            <Typography
              variant="p"
              text="Ready"
              color="#5DBA2F"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </div>
      ),
      pickup_point: (
        <>
          <Typography variant="p" text="Citadel" size="14px" weight="400" />
          <Flex style={{ marginTop: 10 }}>
            <LockerIcon />
            <Typography
              variant="p"
              text="Megamall"
              color="#262626"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </>
      ),
    },
    {
      id: 7,
      country: (
        <div>
          <Flex>
            <UsFlag />
            <div
              style={{ paddingLeft: 8 }}
              id="dispatch"
              data-tip="React-tooltip"
            >
              <FlyIcon />
            </div>
          </Flex>
          <Typography
            variant="span"
            text="UK 336"
            color="#262626"
            size="14px"
            weight={500}
            padding="8px 0 0 0"
          />
        </div>
      ),
      name: (
        <Flex>
          <Typography
            text="Harutyun Abrahamyan ARM750350"
            variant="p"
            size="14px"
            weight="500"
            color=" #262626"
          />
        </Flex>
      ),
      tracking: (
        <div>
          <Typography
            variant="p"
            text="4201980493612896810158"
            color="#262626"
            size="14px"
            weight="400"
          />
          <Flex>
            <div className="product-description">
              <Typography
                variant="p"
                text="Lorem Ipsum is simply dummy text"
                color="#5B6D7F"
                size="12px"
                weight={400}
                padding="10px 0 0 0 "
              />
            </div>
          </Flex>
        </div>
      ),
      weight: (
        <>
          <div>
            <Typography
              text="0.4 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
            />
          </div>
          <div>
            <Typography
              text="0.8 kg"
              variant="p"
              color="#262626"
              weight="400"
              size="14px"
              padding="10px 0 0 0 "
            />
          </div>
        </>
      ),
      price: (
        <>
          <Typography
            variant="p"
            text="1400 ֏"
            color="#262626"
            weight="400"
            size="14px"
            padding="0 7px 0 0"
          />
          <Typography
            variant="p"
            text="60 $"
            color="#5B6D7F"
            weight="400"
            size="14px"
            padding="10px 0 0 0"
          />
        </>
      ),
      status: (
        <div>
          <Flex alignItems="center">
            <InArmeniaIcon />
            <Flex>
              <Typography
                variant="p"
                text="In Armenia"
                color="#5B6D7F"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
              <Typography
                variant="p"
                text="23.08.2022"
                color="#262626"
                size="14px"
                weight="400"
                margin="0 0 0 5px"
              />
            </Flex>
          </Flex>
          <Flex alignItems="center" style={{ paddingTop: 10 }}>
            <ReadyIcon />
            <Typography
              variant="p"
              text="Ready"
              color="#5DBA2F"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </div>
      ),
      pickup_point: (
        <>
          <Typography variant="p" text="Citadel" size="14px" weight="400" />
          <Flex style={{ marginTop: 10 }}>
            <LockerIcon />
            <Typography
              variant="p"
              text="Megamall"
              color="#262626"
              size="12px"
              weight="400"
              margin="0 0 0 5px"
            />
          </Flex>
        </>
      ),
    },
  ];
  const rowSelection = {
    onChange: (value) => {
      console.log(value);
    },
  };

  return (
    <StyledTable>
      <Table
        columns={columns || []}
        dataSource={data}
        pagination={false}
        rowSelection={rowSelection}
        title={() => (
          <StyledCaption>
            <Flex style={{ width: 850 }}>
              <Typography
                variant="p"
                text="All"
                size="14px"
                weight="500"
                color="#5B6D7F"
                padding="0 16px 0 0"
              />
              <div className="caption-item">
                <Typography
                  variant="p"
                  text="Packages"
                  size="14px"
                  weight="400"
                  color="#8E9BA7"
                  padding="0 8px 0 0"
                />
                <Typography
                  variant="p"
                  text="26"
                  size="14px"
                  weight="400"
                  color="#5B6D7F"
                  padding="0 8px 0 0"
                />
              </div>
              <div className="caption-item">
                <Typography
                  variant="p"
                  text="Total weight"
                  size="14px"
                  weight="400"
                  color="#8E9BA7"
                  padding="0 8px 0 0"
                />
                <Typography
                  variant="p"
                  text="0.4 kg"
                  size="14px"
                  weight="400"
                  color="#5B6D7F"
                  padding="0 8px 0 0"
                />
              </div>
              <div className="caption-item">
                <Typography
                  variant="p"
                  text="Total price"
                  size="14px"
                  weight="400"
                  color="#8E9BA7"
                  padding="0 8px 0 0"
                />
                <Typography
                  variant="p"
                  text="5464 ֏"
                  size="14px"
                  weight="400"
                  color="#5B6D7F"
                  padding="0 8px 0 0"
                />
              </div>
            </Flex>
          </StyledCaption>
        )}
      />
    </StyledTable>
  );
}
