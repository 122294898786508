import styled from "styled-components";
import { Input, notification, Select } from "antd";
import { useEffect, useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import SearchIcon from "../../components/SvgComponents/SearchIcon";
import Flex from "../../components/general/Flex";

const StyledSearch = styled.div`
  .search-btn {
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  .customer-search-input {
    @media (max-width: 768px) {
      width: 150px !important;
    }
  }

  .customer-search-box {
    @media (max-width: 768px) {
      flex-wrap: wrap !important;
      max-width: 287px;
    }

    &-first-line {
      @media (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
  }
`;

export default function CustomersSearch({
  searchValue,
  setSearchKey,
  setSearchValue,
  handleSearch,
  refetch,
  setQuery = () => {},
}) {
  const [api, contextHolder] = notification.useNotification();
  const [searchKeyState, setSearchKeyState] = useState("user_info");

  useEffect(() => {
    let search = window.location.search;
    if (search) {
      const searchKeys = search.split("=");
      const searchKeyFromUrl = searchKeys[1].split("&")[0];
      const searchValueFromUrl = searchKeys[2];
      setSearchKeyState(searchKeyFromUrl);
      setSearchKey(searchKeyFromUrl);
      setSearchValue(searchValueFromUrl);
    }
  }, [window.location.search]);

  const handleRefresh = () => {
    refetch().then(() => {
      setQuery({ is_parent: 1 });
      setSearchValue("");
      api.success({
        message: `Success!`,
        description: "Customers list refreshed",
        placement: "topLeft",
      });
    });
  };

  return (
    <StyledSearch>
      {contextHolder}
      <Typography
        text="Search by"
        variant="span"
        color="#3B5166"
        size="14px"
        weight="500"
        padding="0 0 5px 0"
      />
      <Flex alignItems="center" className={"customer-search-box"}>
        <div className={"customer-search-box-first-line"}>
          <Input.Group compact>
            <Select
              defaultValue={searchKeyState}
              style={{ width: 180, height: 36 }}
              onChange={(value) => setSearchKey(value)}
            >
              <Select.Option value="user_info">Full name or GA</Select.Option>
              <Select.Option value="phone">Phone</Select.Option>
              <Select.Option value="user_id">ID</Select.Option>
              <Select.Option value="address">Address</Select.Option>
              <Select.Option value="email">Email</Select.Option>
              <Select.Option value="passport_details">
                Passport details
              </Select.Option>
            </Select>
            <Input
              className={"customer-search-input"}
              style={{ width: 290, height: 36 }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSearch();
                }
              }}
            />
          </Input.Group>
        </div>
        <div className="search-btn">
          <ButtonPrimary
            icon={<SearchIcon />}
            text="Search"
            click={() => handleSearch()}
          />
        </div>
        <ButtonPrimary
          text={"Refresh"}
          click={() => handleRefresh()}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>
          }
        />
      </Flex>
    </StyledSearch>
  );
}
