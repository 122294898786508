import styled from "styled-components";
import { useState } from "react";
import Typography from "../../components/moleculs/Typography.jsx";
import OrdersFailedTopActions from "./TopActions.jsx";
import useGetFailedOrders from "../../hooks/orders/useGetFailedOrders.hook.jsx";
import FailedOrdersTable from "./TableFailed.jsx";

const StyledOrders = styled.div`
  padding-top: 24px;
  min-height: 97vh;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    background: rgb(92 92 92 / 56%);
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: none;

    &.active {
      display: block;
    }
  }
`;
export default function OrdersFailed() {
  const [filters, setFilters] = useState({});
  const { failedOrders, meta } = useGetFailedOrders(filters);
  return (
    <StyledOrders className="orders">
      <div className="page-title">
        <Typography
          text="Failed orders"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <OrdersFailedTopActions setFilters={setFilters} />
      <FailedOrdersTable
        meta={meta}
        orders={failedOrders}
        filters={filters}
        setFilters={setFilters}
      />
    </StyledOrders>
  );
}
