import styled from "styled-components";
import Typography from "../../../components/moleculs/Typography.jsx";
import Flex from "../../../components/general/Flex.jsx";
import { Image } from "antd";
import Tag from "../../../components/moleculs/Tag.jsx";

const StyledSmartService = styled.div`
  margin: 16px 0;
  background: white;
  padding: 20px;
  border-radius: 12px;
  .inside-photo-icon {
    display: inline-flex;
    align-items: center;
    background: #e7e9ec;
    padding: 2px 10px;
    border-radius: 16px;
    margin-top: 5px;
  }
  .tag_status {
    margin-left: 40px;
    border-left: 1px solid #e7e9ec;
    padding-left: 8px;
  }
  .img_details {
    height: 100px;
    width: 100px;
    border-radius: 12px;
    background: #d9d9d9;
    margin: 10px 16px 0 0;
  }

  & .ant-image-mask {
    height: 100px;
    width: 100px;
    margin-top: 10px;
    border-radius: 12px;
  }
`;

export default function OrderDetailsSmartService({ service }) {
  return (
    <StyledSmartService>
      <div>
        <Flex>
          <Flex direction={"column"}>
            <Typography
              variant="p"
              text="Smart Service"
              color="#3B5166"
              size="14"
              weight="500"
            />
            <div className="inside-photo-icon">
              <img src={service?.smart_service?.image} width="24" />
              <Typography
                color="#3B5166"
                size="14px"
                weight="400"
                text={service.smart_service?.current_smart_service?.name}
                variant="span"
                padding="0 0 0 9px"
              />
            </div>
          </Flex>
          <Flex direction={"column"} className={"tag_status"}>
            {" "}
            <Typography
              variant="p"
              text="Smart Service"
              color="#3B5166"
              size="14"
              weight="500"
            />{" "}
            <Tag
              text={service?.status || ""}
              background={service?.status === "done" ? "#EFF8EA" : "#EDFBFE"}
              color={service?.status === "done" ? "#5DBA2F" : "#47BFE1"}
              margin={"5px 0 0 0"}
            />
          </Flex>
        </Flex>
        <Flex>
          {service?.files?.map((img, i) => (
            <Image
              className={"img_details"}
              src={img?.file}
              key={i}
              style={{ marginRight: 20 }}
            />
          ))}
        </Flex>
      </div>
    </StyledSmartService>
  );
}
