export default function RightBottomArrow() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.84212 1.94952C10.6223 1.20708 13.7116 1.9264 15.8926 4.10748C19.147 7.36185 19.147 12.6382 15.8926 15.8926C12.6383 19.147 7.36189 19.147 4.10753 15.8926C1.92645 13.7115 1.20713 10.6222 1.94957 7.84208M12.5002 7.50004V12.5M12.5002 12.5H7.5002M12.5002 12.5L4.16671 4.16666"
        stroke="#8E9BA7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
