export const AroundCheckedIcon = ({ size, ...props }) => {
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 29 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5554 27.25C24.0072 27.25 26.8054 24.4518 26.8054 21C26.8054 17.5482 24.0072 14.75 20.5554 14.75C17.1036 14.75 14.3054 17.5482 14.3054 21C14.3054 24.4518 17.1036 27.25 20.5554 27.25Z"
        fill="#5DBA2F"
      />
      <path
        d="M18.0554 21L19.9304 22.875L23.6804 19.125"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_77_10422">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
