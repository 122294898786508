import Flex from "../../components/general/Flex";
import { Tabs } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import styled from "styled-components";
import PreviewIcon from "../../components/SvgComponents/PreviewIcon";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import mobilePreviewFrame from "../../assets/images/mobile-preview.png";
import { useState } from "react";

const StyledPreview = styled.div`
  margin-top: 80px;
  border-radius: 12px;
  background: #ffffff;
  padding: 16px;
  margin-bottom: 16px;

  main {
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .preview {
      &-image {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      .preview-web {
        img {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          width: 100%;
        }
      }
      .preview-mobile {
        &-image {
          img {
            width: 100%;
            height: 120px;
            object-fit: cover;
          }
        }
      }

      &-content {
        padding: 0 15px 25px 15px;
        background: #f9fafb;
        box-shadow: 0px 6.98824px 6.98824px -3.49412px rgba(16, 24, 40, 0.03),
          0px 17.47059px 20.96471px -3.49412px rgba(16, 24, 40, 0.08);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
      &-mobile {
        &-frame {
          width: 327px;
          height: 500px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          margin-top: 50px;
          position: relative;
          overflow: hidden;
          background-color: #00000026;
          border-bottom-left-radius: 52px;
          border-bottom-right-radius: 59px;
          .bottom-sheet {
            width: 96%;
            height: 88%;
            position: absolute;
            bottom: 5px;
            left: 7px;
            background: white;
            overflow: hidden;
            border-bottom-left-radius: 49px;
            border-bottom-right-radius: 49px;
            border-top-left-radius: 35px;
            border-top-right-radius: 35px;
          }
        }
      }
    }
  }
`;

export default function Preview({ formData, imageForWeb }) {
  const [activeTab, setActiveTab] = useState("web");

  const types = [
    { label: "Web", key: "web" },
    { label: "Mobile", key: "mobile" },
    { label: "Push", key: "push" },
  ];

  return (
    <StyledPreview>
      <Flex justifyContent={"space-between"}>
        <Tabs
          items={types}
          style={{ width: "50%" }}
          onChange={(val) => setActiveTab(val)}
        />
        <Typography
          text={"Preview"}
          variant={"span"}
          color={"#8E9BA7"}
          size={"12px"}
        />
      </Flex>
      <main style={{ height: formData?.title ? "auto" : "500px" }}>
        {!imageForWeb ? (
          <div className={"empty-preview"}>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
            >
              <PreviewIcon />
              <Typography
                text={"Device preview"}
                variant={"span"}
                color={"#3B5166"}
                size={"20px"}
                weight={"500"}
                padding={"10px 0 10px 0"}
              />
              <Typography
                text={
                  "Once you create notifications, you will see a preview of them here"
                }
                variant={"span"}
                color={"#3B5166"}
                size={"16px"}
                weight={"400"}
              />
            </Flex>
          </div>
        ) : (
          <div className="preview">
            {activeTab === "web" ? (
              <div className="preview-web">
                <div className="preview-image">
                  <img src={imageForWeb} alt="" />
                </div>
                <div className="preview-content">
                  <Typography
                    text={formData?.title}
                    variant={"h2"}
                    color={"#101828"}
                    size={"24px"}
                    padding={"10px 0 12px 0"}
                    lineHeight={"30px"}
                    weight={"500"}
                  />
                  <Typography
                    text={"Today at 21:41"}
                    variant={"h2"}
                    color={"#8E9BA7"}
                    size={"12px"}
                    padding={"0 0 12px 0"}
                  />
                  <Typography
                    text={formData?.content}
                    variant={"p"}
                    color={"#6E6E6E"}
                    size={"16px"}
                    lineHeight={"28px"}
                  />
                  <Flex justifyContent={"flex-end"} style={{ marginTop: 20 }}>
                    <ButtonPrimary text={"Learn more"} />
                  </Flex>
                </div>
              </div>
            ) : (
              <div className="preview-mobile">
                <div
                  className={"preview-mobile-frame"}
                  style={{ backgroundImage: `url(${mobilePreviewFrame})` }}
                >
                  <div className="bottom-sheet">
                    <div className="preview-mobile-image">
                      <img src={imageForWeb} alt="" />
                    </div>
                    <div className="preview-content">
                      <Typography
                        text={formData?.title}
                        variant={"h2"}
                        color={"#101828"}
                        size={"24px"}
                        padding={"10px 0 12px 0"}
                        lineHeight={"30px"}
                        weight={"500"}
                      />
                      <Typography
                        text={"Today at 21:41"}
                        variant={"h2"}
                        color={"#8E9BA7"}
                        size={"12px"}
                        padding={"0 0 12px 0"}
                      />
                      <Typography
                        text={formData?.content}
                        variant={"p"}
                        color={"#6E6E6E"}
                        size={"16px"}
                        lineHeight={"28px"}
                      />
                      <Flex
                        justifyContent={"flex-end"}
                        style={{ marginTop: 20 }}
                      >
                        <ButtonPrimary text={"Learn more"} />
                      </Flex>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </main>
    </StyledPreview>
  );
}
