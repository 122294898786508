import { useQuery } from "react-query";
import { bonusApi } from "../../services/bonus.js";

export const useGetBonusLis = (params) => {
  const { isError, isLoading, data, refetch } = useQuery(
    ["bonus-list", params],
    () => bonusApi.fetchBonusList(params),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        let sumIn = 0;
        let sumOut = 0;
        data?.data?.data?.forEach((el) => {
          if (el.type == "in" && el?.sum) {
            sumIn += Number(el.sum);
          }
          if (el.type == "out" && el?.sum) {
            sumOut += Number(el.sum);
          }
        });

        return {
          bonusList: data.data.data,
          meta: data.data.meta,
          sumIn,
          sumOut,
        };
      },
    }
  );
  return {
    isLoading,
    bonusList: data?.bonusList,
    meta: data?.meta,
    sumIn: Math.round(data?.sumIn * 100) / 100,
    sumOut: Math.round(data?.sumOut * 100) / 100,
    refetch,
  };
};
