import { Drawer, Input, Select } from "antd";
import styled from "styled-components";
import { useState } from "react";
import Flex from "../../components/general/Flex.jsx";
import UsFlag from "../../components/SvgComponents/UsFlag.jsx";
import FlyIcon from "../../components/SvgComponents/FlyIcon.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import MoneyIcon from "../../components/SvgComponents/MoneyIcon.jsx";
import FileIcon from "../../components/SvgComponents/FileIcon.jsx";
import CloseIcon from "../../components/SvgComponents/CloseIcon.jsx";
import ListIcon from "../../components/SvgComponents/ListIcon.jsx";
import ShopIcon from "../../components/SvgComponents/ShopIcon.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import ExpectedIcon from "../../components/SvgComponents/ExpectedIcon.jsx";
import XCircle from "../../components/SvgComponents/XCircle.jsx";
import InfoIcon from "../../components/SvgComponents/InfoIcon.jsx";
import BackArrowIcon from "../../components/SvgComponents/BackArrowIcon.jsx";
import ButtonCancel from "../../components/moleculs/ButtonCancel";
import useSingleExpected from "../../hooks/expected/useSingleExpected.hook.jsx";
import Loader from "../../components/moleculs/Loader";
import { DeleteSmartService } from "../../services/expected.js";
import Tag from "../../components/moleculs/Tag.jsx";
import { openNotificationError } from "../../helpers/notification.js";

const StyledDrawer = styled.div`
  .remove-invoice {
    background: #e7e9ec;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .info-block {
    background: white;
    padding: 16px;
    border-radius: 12px;
    margin: 16px 0;

    &.grey {
      background: #f5f5f5;
    }

    .expected {
      svg {
        margin-right: 12px;

        path {
          stroke: #5dba2f;
        }
      }
    }

    .information {
      .item {
        padding-left: 10px;
        border-left: 1px solid #e7e9ec;

        &:not(:first-child) {
          margin-left: 50px;
        }
      }
    }
  }

  .deactive-service {
    border: 1px solid #e7e9ec;
    border-radius: 8px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  .deactivate-modal {
    background: #ffffff;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    padding: 24px;
    position: fixed;
    top: 56%;
    left: 60%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    display: none;

    &.active {
      display: block;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .info {
        background: #fff5eb;
        width: 42px;
        height: 42px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-footer {
      button {
        width: 100%;
      }
    }
  }
`;

const StyledFooter = styled.div`
  margin-top: 55px;

  .time {
    padding-right: 16px;
    border-right: 1px solid #ddd;
  }

  .id-number {
    padding-left: 16px;
  }
`;

export default function ExpectedDetaildDrawer({
  status,
  setStatus,
  deactivateModalStatus,
  setDeactivateModalStatus,
  setSmartServiceStatus,
  smartServiceStatus,
  id,
}) {
  const [editable, setEditable] = useState(false);
  const { data, isLoading, refetch } = useSingleExpected(id);

  const selectAfterDeclarated = (
    <Select defaultValue="$" style={{ width: 70 }}>
      <Select.Option>AMD</Select.Option>
    </Select>
  );

  const handleDeleteService = () => {
    DeleteSmartService(id)
      .then((res) => {
        setDeactivateModalStatus(false);
        refetch();
      })
      .catch((e) => {
        openNotificationError(
          "Delete smart service",
          e?.response?.data?.message
        );
        setDeactivateModalStatus(false);
      });
  };

  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => setStatus(false)}
      open={status}
      width="80%"
      header={false}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Flex
          direction="column"
          justifyContent="space-between"
          alignItems="normal"
          style={{ height: "95vh" }}
        >
          <StyledDrawer>
            <Flex justifyContent="space-between">
              <Flex>
                <div className="flag" style={{ marginRight: 8 }}>
                  {editable ? (
                    <>
                      <Typography
                        text="Whouse"
                        variant="p"
                        size="14px"
                        weight="500"
                        color="#3B5166"
                        padding="0 0 4px 0"
                      />
                      <Select
                        placeholder={
                          <div style={{ marginTop: 4 }}>
                            <UsFlag />
                          </div>
                        }
                        style={{ width: 75 }}
                      >
                        <Select.Option>
                          <UsFlag />
                        </Select.Option>
                      </Select>
                    </>
                  ) : (
                    <img src={data?.dispatch?.warehouse?.round_flag} alt="" />
                  )}
                </div>
                <div className="dispatch" style={{ marginRight: 10 }}>
                  {editable ? (
                    <>
                      <Typography
                        text="Dispatch"
                        variant="p"
                        size="14px"
                        weight="500"
                        color="#3B5166"
                        padding="0 0 4px 0"
                      />
                      <Select
                        placeholder={
                          <div style={{ marginTop: 4 }}>
                            <FlyIcon />
                          </div>
                        }
                        style={{ width: 75 }}
                      >
                        <Select.Option>
                          <FlyIcon />
                        </Select.Option>
                      </Select>
                    </>
                  ) : (
                    <img src={data?.dispatch?.icon} alt="" />
                  )}
                </div>
                <div>
                  {editable ? (
                    <>
                      <Typography
                        text="Tracking"
                        variant="p"
                        size="14px"
                        weight="500"
                        color="#3B5166"
                        padding="0 0 4px 0"
                      />
                      <Input
                        value={data?.tracking_code}
                        style={{ width: 300 }}
                      />
                    </>
                  ) : (
                    <Typography
                      text={data?.tracking_code}
                      variant="p"
                      color={primaryTextColor}
                      size="22px"
                      weight="500"
                      padding="0 12px 0 0"
                    />
                  )}
                  <div>
                    {!editable && (
                      <>
                        <Flex alignItems="center">
                          {data?.customer_comment ? (
                            <Typography
                              text={data?.customer_comment}
                              variant="p"
                              color="#5B6D7F"
                              size="14px"
                              weight="400"
                              padding="8px 17px 0 0"
                            />
                          ) : null}
                          <Flex alignItems="center">
                            <MoneyIcon />
                            <Typography
                              text={`${data?.declaration_price} ${data?.declaration_currency}`}
                              variant="p"
                              color="#262626"
                              size="14px"
                              weight="400"
                              padding="4px 18px 0 9px"
                            />
                          </Flex>
                          {data?.invoice && (
                            <a href={data?.invoice?.file} target={"_blank"}>
                              <Flex alignItems="center">
                                <FileIcon />
                                <Typography
                                  text="Invoice"
                                  variant="p"
                                  color="#262626"
                                  size="14px"
                                  weight="400"
                                  padding="4px 12px 0 9px"
                                />
                              </Flex>
                            </a>
                          )}
                        </Flex>
                        <Flex alignItems="center" style={{ marginTop: 10 }}>
                          <Flex alignItems="center">
                            <ListIcon />
                            <Typography
                              text={data?.category?.name}
                              variant="p"
                              color="#262626"
                              size="14px"
                              weight="400"
                              padding="0 0 0 7px"
                            />
                          </Flex>
                          <Flex alignItems="center" className="ml-16">
                            <ShopIcon />
                            <Typography
                              text={data?.shop_name}
                              variant="p"
                              color="#262626"
                              size="14px"
                              weight="400"
                              padding="0 0 0 7px"
                            />
                          </Flex>
                          {data?.order_type ? (
                            <Flex alignItems="center" className="ml-16">
                              <Typography
                                text={
                                  data?.order_type === "online_shopping"
                                    ? "Online shop"
                                    : "Personal items"
                                }
                                variant="p"
                                color="#262626"
                                size="14px"
                                weight="400"
                              />
                            </Flex>
                          ) : null}
                        </Flex>
                      </>
                    )}
                  </div>
                </div>
              </Flex>
              <Flex>
                {editable ? (
                  <>
                    <ButtonSecondary icon={<XCircle />} text="Deactivate" />
                    <ButtonCancel
                      text="Cancel"
                      margin="0 16px 0 16px"
                      click={() => setEditable(false)}
                    />
                    <ButtonPrimary
                      text="Save"
                      click={() => setEditable(false)}
                    />
                  </>
                ) : (
                  <>
                    {/* <ButtonPrimary */}
                    {/*   text="Add smart service" */}
                    {/*   style={{ marginRight: 16 }} */}
                    {/*   click={() => setSmartServiceStatus(true)} */}
                    {/*   disabled={!!data?.order_smart_services} */}
                    {/* /> */}
                    {/* <ButtonSecondary */}
                    {/*   text="Edit" */}
                    {/*   click={() => setEditable(true)} */}
                    {/*   icon={<EditIcon />} */}
                    {/* /> */}
                  </>
                )}
              </Flex>
            </Flex>
            {editable && (
              <div>
                <Flex className="mt-16">
                  <div>
                    <Typography
                      text="Declarated price"
                      variant="p"
                      size="14px"
                      weight="500"
                      color="#3B5166"
                      padding="0 0 4px 0"
                    />
                    <div className="input-with-select">
                      <Input
                        value="6"
                        style={{ width: 140 }}
                        addonAfter={selectAfterDeclarated}
                      />
                    </div>
                  </div>
                  <div className="ml-16" style={{ width: 180 }}>
                    <Typography
                      text="Invoice"
                      variant="p"
                      size="14px"
                      weight="500"
                      color="#3B5166"
                      padding="0 0 4px 0"
                    />
                    <div className="input-file-invoice">
                      <Flex alignItems="center">
                        <FileIcon />
                        <Typography
                          text="Attach Invoice"
                          variant="p"
                          size="14px"
                          weight="400"
                          color="#8E9BA7"
                        />
                      </Flex>
                    </div>
                  </div>
                  <div>
                    <div className="ml-16">
                      <Typography
                        text="Category"
                        variant="p"
                        size="14px"
                        weight="500"
                        color="#3B5166"
                        padding="0 0 4px 0"
                      />
                      <div>
                        <Select
                          defaultValue="Video equipment"
                          style={{ width: 180 }}
                        >
                          <Select.Option>Test</Select.Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </Flex>
              </div>
            )}
            <div className="info-block">
              <Typography
                text={`The receiver in Georgia`}
                variant="p"
                size="14px"
                weight="500"
                color="#3B5166"
                padding="0 0 5px 0"
              />
              {editable ? (
                <Input
                  value="Harutyun Abrahamyan ARM750350"
                  style={{ width: 300 }}
                />
              ) : (
                <Flex>
                  <Typography
                    text={`${data?.recipient?.first_name} ${data?.recipient?.last_name} ${data?.recipient?.user_code}`}
                    variant="p"
                    size="14px"
                    weight="500"
                    color="#262626"
                    padding="0 5px 0 0"
                  />
                  {/* <PrimeIcon /> */}
                </Flex>
              )}
            </div>
            <div className="info-block">
              <Flex alignItems="center" className="expected">
                <ExpectedIcon />
                <Flex direction="column">
                  <Typography
                    text="Expected"
                    variant="p"
                    size="14px"
                    weight="500"
                    color="#5DBA2F"
                  />
                  <Typography
                    text={data?.created_at}
                    variant="p"
                    size="14px"
                    weight="400"
                    color="#5DBA2F"
                  />
                </Flex>
              </Flex>
            </div>
            {data?.order_smart_services && (
              <div className="info-block">
                <Flex className="information" justifyContent="space-between">
                  <Flex>
                    <div className="item">
                      <Flex direction="column">
                        <Typography
                          text="Smart Service"
                          variant="p"
                          size="14px"
                          weight="500"
                          padding="0 0 4px 0"
                          color="#3B5166"
                        />
                        <Flex alignItems="center">
                          <img
                            src={data?.order_smart_services.smart_service.image}
                            width="25"
                          />
                          <Flex alignItems="center">
                            <Typography
                              variant="p"
                              text={
                                data?.order_smart_services.smart_service
                                  ?.current_smart_service?.name
                              }
                              color="#5B6D7F"
                              size="14px"
                              weight="400"
                              margin="0 0 0 5px"
                            />
                            <Tag
                              text={data?.order_smart_services?.status}
                              background="#EDFBFE"
                              color="#47BFE1"
                              margin="0 0 0 8px"
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </div>
                    <div className="item">
                      <Flex direction="column">
                        <Typography
                          text="Added date"
                          variant="p"
                          size="14px"
                          weight="500"
                          padding="0 0 4px 0"
                          color="#3B5166"
                        />
                        <Typography
                          text="23.08.2022, 12:04"
                          variant="p"
                          size="14px"
                          weight="500"
                          color="#262626"
                        />
                      </Flex>
                    </div>
                    <div className="item">
                      <Flex direction="column">
                        <Typography
                          text="Note"
                          variant="p"
                          size="14px"
                          weight="500"
                          padding="0 0 4px 0"
                          color="#3B5166"
                        />
                        <Typography
                          text={data?.customer_comment}
                          variant="p"
                          size="14px"
                          weight="500"
                          padding="0 0 4px 0"
                          color="#3B5166"
                        />

                        <Typography
                          text={
                            data?.order_smart_services?.smart_service
                              ?.description
                          }
                          variant="p"
                          size="14px"
                          weight="500"
                          color="#262626"
                        />
                      </Flex>
                    </div>
                    <div className="item">
                      <Flex direction="column">
                        <Typography
                          text="Cost"
                          variant="p"
                          size="14px"
                          weight="500"
                          padding="0 0 4px 0"
                          color="#3B5166"
                        />
                        <Typography
                          text={`${data?.order_smart_services?.smart_service?.cost} ₾`}
                          variant="p"
                          size="14px"
                          weight="500"
                          color="#262626"
                        />
                      </Flex>
                    </div>
                  </Flex>
                  <button
                    type="button"
                    className="deactive-service"
                    onClick={() => setDeactivateModalStatus(true)}
                  >
                    <XCircle />
                  </button>
                </Flex>
              </div>
            )}
            <div
              className={`deactivate-modal ${
                deactivateModalStatus ? "active" : ""
              }`}
            >
              <div className="deactivate-modal-header">
                <div className="info">
                  <InfoIcon />
                </div>
                <div onClick={() => setDeactivateModalStatus(false)}>
                  <CloseIcon />
                </div>
              </div>
              <div className="deactivate-modal-body">
                <Typography
                  text="Deactivate service"
                  variant="p"
                  color="#101828"
                  size="18px"
                  weight="500"
                  padding="0 0 8px 0"
                />
                <Typography
                  text="Are you sure you want to deactivate service"
                  variant="p"
                  color="#5B6D7F"
                  size="14px"
                  weight="400"
                  padding="0 0 24px 0"
                />
              </div>
              <div className="deactivate-modal-footer">
                <Flex>
                  <ButtonSecondary
                    text="Cancel"
                    click={() => setDeactivateModalStatus(false)}
                  />
                  <ButtonPrimary
                    text="Delete"
                    style={{ marginLeft: 16 }}
                    click={() => handleDeleteService()}
                  />
                </Flex>
              </div>
            </div>
          </StyledDrawer>
          <StyledFooter>
            <Flex justifyContent="space-between">
              <div className="icon" onClick={() => setStatus(false)}>
                <BackArrowIcon />
              </div>
              <div className="id">
                <Flex>
                  <div className="time">
                    <Flex>
                      <Typography
                        text="Updated by Name at "
                        variant="p"
                        color="#B3BBC4"
                        size="14px"
                        weight="400"
                      />
                      <Typography
                        text="2016-11-20, 12:04"
                        variant="p"
                        color="#8E9BA7"
                        size="14px"
                        weight="400"
                        padding="0 0 0 8px"
                      />
                    </Flex>
                  </div>
                  <div className="id-number">
                    <Typography
                      text="ID 1774569"
                      variant="p"
                      color="#B3BBC4"
                      size="14px"
                      weight="400"
                    />
                  </div>
                </Flex>
              </div>
            </Flex>
          </StyledFooter>
        </Flex>
      )}
    </Drawer>
  );
}
