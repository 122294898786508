import { Drawer, Form } from "antd";
import Flex from "../../components/general/Flex";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonCancel from "../../components/moleculs/ButtonCancel";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import styled from "styled-components";
import { useFetchLanguages } from "../../hooks/slides/useFetchLanguages.hook";
import { Tabs } from "antd";
import Loader from "../../components/moleculs/Loader";
import TabItem from "./TabItem";
import { storyApi } from "../../services/stories.js";
import { useEffect, useState } from "react";
import check from "../../assets/images/check-circle.svg";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import EyeIcon from "../../components/SvgComponents/EyeIcon.jsx";
import XCircle from "../../components/SvgComponents/XCircle.jsx";
import EyeHidden from "../../components/SvgComponents/EyeHidden.jsx";
import DeleteModal from "./DeleteModal.jsx";
const StyledActions = styled.div`
  button {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const StyledForm = styled(Form)`
  .ant-tabs {
    margin-top: 24px;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .ant-tabs-tab-btn {
    position: relative;
    .check-mark {
      position: absolute;
      bottom: -5px;
      left: 12px;
    }
  }
`;

export default function CreateStory({
  setOpen,
  open,
  editId,
  onEditFinished,
  setEditId,
  refetch,
  handleDelete,
}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { languages } = useFetchLanguages();
  const [form] = Form.useForm();
  const [is_hide, setisHide] = useState(null);
  const handleGetStory = () => {
    if (editId)
      storyApi.getStoryById(editId).then((data) => {
        if (data) {
          setisHide(data.is_hide);
          const initial = {
            main: [],
            [1]: [],
            [2]: [],
            [3]: [],
          };
          for (let main of data.storiesMl) {
            initial.main.push(main);
          }
          for (let child of data.child_stories)
            for (let ml of child.storiesMl) {
              initial[ml?.language_id].push(ml);
            }

          for (let key in initial) {
            if (!initial[key]?.length) delete initial[key];
          }
          form.setFieldsValue(initial);
        }
      });
  };

  useEffect(() => {
    handleGetStory();
  }, [editId]);
  const handleFinish = (dontFetchStories) => {
    setEditId(null);
    onEditFinished();
    setOpen(false);
    form.resetFields();
    if (!dontFetchStories) setTimeout(() => refetch(), 2000);
  };
  return (
    <>
      <Drawer open={open} onClose={() => handleFinish(true)} width="90%">
        <StyledForm
          layout="vertical"
          form={form}
          onFinish={(data) => {
            storyApi.createStories(data, editId).then(() => handleFinish());
          }}
        >
          <Flex justifyContent="space-between" alignItems={"center"}>
            <Typography
              text={"Create Story"}
              variant={"p"}
              color={"#262626"}
              size={"22px"}
              weight={"500"}
            />
            <StyledActions className="actions">
              <Flex>
                {editId && (
                  <>
                    <ButtonSecondary
                      icon={is_hide ? <EyeHidden /> : <EyeIcon />}
                      text={is_hide ? "Hide" : "Show"}
                      onClick={() => {
                        storyApi
                          .hideStory(editId, !Boolean(is_hide))
                          .then(() => handleGetStory());
                      }}
                    />
                    <ButtonSecondary
                      text={"Delete"}
                      icon={<XCircle />}
                      onClick={() => setOpenDeleteModal(true)}
                    />
                  </>
                )}
                <ButtonCancel
                  text={"Cancel"}
                  onClick={() => handleFinish(false)}
                />
                {editId ? (
                  <ButtonPrimary text={"Save"} htmlType="submit" />
                ) : (
                  <ButtonPrimary text={"Create"} htmlType="submit" />
                )}
              </Flex>
            </StyledActions>
          </Flex>
          {languages ? (
            <Tabs
              items={languages?.map((language, index) => ({
                key: language?.id,
                label: (
                  <Flex alignItems={"center"}>
                    <img src={language?.flag} alt="" />
                    <Typography
                      variant={"p"}
                      text={language?.name}
                      color={"#3B5166"}
                      size={"14px"}
                      weight={"500"}
                      padding={"0 0 0 7px"}
                    />
                  </Flex>
                ),
                children: (
                  <TabItem
                    index={index}
                    language_id={language.id}
                    form={form}
                    editId={editId}
                  />
                ),
              }))}
            />
          ) : (
            <Loader />
          )}
        </StyledForm>
      </Drawer>
      <DeleteModal
        open={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        onDelete={() => {
          handleDelete(editId);
          setOpenDeleteModal(false);
          handleFinish();
        }}
      />
    </>
  );
}
