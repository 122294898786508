import React from "react";
import { Modal, Row } from "antd";
import warning_icon from "../../assets/images/warning-icon.svg";
import styled from "styled-components";
import ButtonCancel from "../../components/moleculs/ButtonCancel";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
const DeleteModal = ({ onCancel, onDelete, open }) => {
  return (
    <Modal
      footer={false}
      title={<img src={warning_icon} />}
      open={open}
      onCancel={onCancel}
      width="316px"
    >
      <StyledDelete>
        <div>
          <span>Delete story</span>
          <span>Are you sure you want to delete story</span>
        </div>
        <div>
          <ButtonCancel text={"Cancel"} onClick={onCancel} />
          <ButtonPrimary text={"Done"} onClick={onDelete} />
        </div>
      </StyledDelete>
    </Modal>
  );
};

const StyledDelete = styled.div`
  > div:first-child {
    display: grid;
    margin-bottom: 24px;
    > span:first-child {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: #101828;
      margin-bottom: 8px;
    }
    > span:last-child {
      color: #5b6d7f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  > div:last-child {
    display: flex;
    gap: 12px;
    > button {
      width: 50%;
    }
  }
`;
export default DeleteModal;
