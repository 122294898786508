export default function LocationIcon() {
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4343 10.8182C20.4343 18.4545 10.6952 25 10.6952 25C10.6952 25 0.956055 18.4545 0.956055 10.8182C0.956055 8.21424 1.98214 5.71694 3.80858 3.87568C5.63502 2.03441 8.11221 1 10.6952 1C13.2782 1 15.7553 2.03441 17.5818 3.87568C19.4082 5.71694 20.4343 8.21424 20.4343 10.8182Z"
        stroke="#0A2540"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6954 14.332C13.0006 14.332 14.8693 12.5412 14.8693 10.332C14.8693 8.12289 13.0006 6.33203 10.6954 6.33203C8.39021 6.33203 6.52148 8.12289 6.52148 10.332C6.52148 12.5412 8.39021 14.332 10.6954 14.332Z"
        stroke="#0A2540"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
