import { useQuery } from "react-query";
import { reportsApi } from "../../services/reports.js";

export const useGetReportsTypeLis = (type, params) => {
  const { isError, isLoading, data, refetch } = useQuery(
    ["reports-type-list", type, params],
    () => reportsApi.fetchReportsList({ type: type, ...params }),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        return data?.data;
      },
      enabled: !!type,
    }
  );
  return {
    isLoading,
    reportsTypeList: data,
    refetch,
  };
};
