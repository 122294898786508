import styled from "styled-components";
import iherb from "../../assets/images/iherb.png";
import Flex from "../../components/general/Flex";
import Typography from "../../components/moleculs/Typography.jsx";
import UsFlag from "../../components/SvgComponents/UsFlag";
import FlyIcon from "../../components/SvgComponents/FlyIcon";
import { primaryTextColor } from "../../constants.js";
import PrimeIcon from "../../components/SvgComponents/primeIcon";
import LocationIcon from "../../components/SvgComponents/LocationIcon";

const StyledSingleScanContent = styled.div`
  margin-top: 24px;
  width: 100%;
  background: white;
  border-radius: 12px;
  height: 70vh;
  .p-16 {
    padding: 16px;
    width: 100%;
  }
  .location {
    background: #e7e9ec;
    border-radius: 16px;
    display: inline-block;
    padding: 6px 15px;
    align-items: center;
    svg {
      padding-right: 5px;
    }
  }
  .footer {
    width: 100%;
    padding-top: 16px;
    border-top: 1px solid #e7e9ec;
    .region {
      background: #e7e9ec;
      padding: 28px 16px;
    }
  }
`;

export default function SingleScanContent() {
  return (
    <StyledSingleScanContent>
      <Flex
        direction="column"
        style={{ height: "70vh" }}
        justifyContent="space-between"
      >
        <div className="p-16">
          <Flex justifyContent="space-between" alignItems="center">
            <img src={iherb} />
            <Typography
              variant="span"
              text="ID 17774569"
              size="14px"
              weight="400"
              color="#B3BBC4"
            />
          </Flex>
          <Flex alignItems="center" style={{ padding: "20px 0" }}>
            <Typography
              variant="span"
              text="User changed"
              size="14px"
              weight="400"
              color="#B3BBC4"
              padding="0 8px 0 0"
            />
            <Typography
              variant="span"
              text="23.08.2022"
              size="14px"
              weight="400"
              color="#B3BBC4"
              padding="0 8px 0 0"
            />
            <Typography
              variant="span"
              text="|"
              size="14px"
              weight="400"
              color="#B3BBC4"
              padding="0 8px 0 0"
            />
            <Typography
              variant="span"
              text="Changed by"
              size="14px"
              weight="400"
              color="#B3BBC4"
              padding="0 8px 0 0"
            />
            <Typography
              variant="span"
              text="Name Surname"
              size="14px"
              weight="400"
              color="#B3BBC4"
              padding="0 8px 0 0"
            />
          </Flex>
          <Flex alignItems="center" style={{ paddingBottom: 18 }}>
            <UsFlag />
            <div style={{ paddingLeft: 10, paddingRight: 17 }}>
              <FlyIcon />
            </div>
            <Typography
              text="UK 336"
              variant="span"
              color={primaryTextColor}
              size="18px"
              weight="500"
            />
          </Flex>
          <Typography
            variant="p"
            text="420198049361289681015807602222229638"
            size="22px"
            weight="500"
            padding="0 0 16px 0"
            color={primaryTextColor}
          />
          <Flex>
            <Typography
              variant="p"
              text="Harut Abrahamyan ARM750350"
              color={primaryTextColor}
              size="18px"
              weight="400"
              padding="0 8px 16px 0"
            />
            <PrimeIcon />
          </Flex>
          <Typography
            variant="p"
            text="Name Surname ARM750350"
            color="#5B6D7F"
            size="18px"
            weight="400"
            padding="0 0 16px 0"
          />
          <Typography
            variant="p"
            text="Citadel"
            color={primaryTextColor}
            size="16px"
            weight="400"
            padding="0 0 16px 0"
          />
          <div className="location">
            <Flex alignItems="center">
              <LocationIcon />
              <Typography
                variant="span"
                text="Region"
                color="#0A2540"
                size="16px"
                weight="400"
              />
            </Flex>
          </div>
        </div>
        <div className="footer">
          <Typography
            variant="p"
            text="10+ packages"
            color="#0A2540"
            size="18px"
            padding="16px"
            weight={400}
          />
          <div className="region">
            <Typography
              variant="p"
              size="18px"
              text="Region"
              color="#0A2540"
              weight="400"
            />
          </div>
        </div>
      </Flex>
    </StyledSingleScanContent>
  );
}
