export default function NotSlidesResult(props) {
  return (
    <svg
      {...props}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="21" fill="#E7E9EC" />
      <g clipPath="url(#clip0_4176_19847)">
        <path
          d="M25.6668 9.33325H16.3335C11.6668 9.33325 9.3335 11.6666 9.3335 16.3333V31.4999C9.3335 32.1416 9.8585 32.6666 10.5002 32.6666H25.6668C30.3335 32.6666 32.6668 30.3333 32.6668 25.6666V16.3333C32.6668 11.6666 30.3335 9.33325 25.6668 9.33325Z"
          stroke="#3B5166"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.0618 16.1466L16.0067 22.2016C15.7734 22.4349 15.5518 22.8899 15.5051 23.2166L15.1784 25.5266C15.0618 26.3666 15.6451 26.9499 16.4851 26.8333L18.7951 26.5066C19.1217 26.4599 19.5768 26.2383 19.8101 26.0049L25.8651 19.9499C26.9034 18.9116 27.4051 17.6983 25.8651 16.1583C24.3251 14.6066 23.1118 15.0966 22.0618 16.1466Z"
          stroke="#3B5166"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.1982 17.01C21.7116 18.8417 23.1466 20.2883 24.9899 20.8017"
          stroke="#3B5166"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4176_19847">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
