import { useState } from "react";
import "./Tooltip.css";

const OnexTooltip = (props) => {
  let timeout;

  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 100);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {active && (
        <div
          className={`Tooltip-Tip ${props.direction || "top"}`}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span>{props.content}</span>
          {props.content2 && (
            <span style={{ paddingTop: 10 }}>{props.content2}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default OnexTooltip;
