import NotSlidesResult from "../../components/SvgComponents/NotSlidesResult";
import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import ButtonGray from "../../components/moleculs/ButtonGray";
import filter_search from "../../assets/images/filter-search.svg";

const NoShops = ({ isFiltered }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
      style={{ height: "40vh", width: "100%" }}
    >
      {!isFiltered ? <img src={filter_search} /> : <NotSlidesResult />}
      <Typography
        text={`No shops ${!isFiltered ? "found" : "yet"}`}
        color="#3B5166"
        weight={500}
        size="20px"
        variant="h2"
        margin="16px 0 4px 0"
      />
      <Typography
        text={
          !isFiltered
            ? "Your search  did not match any information. Please try again."
            : "Once there will be shops they will appear here"
        }
        color="#3B5166"
        weight={400}
        size="16px"
        variant="h4"
        margin="0 0 24px 0"
      />
      {isFiltered && (
        <ButtonGray
          text={"Create shops"}
          background={"#5B6D7F"}
          color={"#FFFFFF"}
        />
      )}
    </Flex>
  );
};

export default NoShops;
