import Flex from "../../components/general/Flex.jsx";
import { DatePicker, Segmented } from "antd";
import styled from "styled-components";
import DownloadIcon from "../../components/SvgComponents/DownloadIcon";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import dayjs from "dayjs";
import { reportsApi } from "../../services/reports.js";
import { useState } from "react";

const { RangePicker } = DatePicker;
const StyledTopActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    justify-content: start !important;
  }

  .filters {
    @media (max-width: 768px) {
      flex-direction: column !important;
      width: 100%;
      .filter-by-date {
        width: 100%;

        .ant-segmented-group {
          justify-content: center !important;
        }
      }

      .picker-filter {
        margin-left: 0 !important;
        margin-top: 16px;
        width: 100%;

        .ant-picker {
          width: 100%;
        }
      }
    }
  }
`;

export default function ReportTopActions({ setFilters = () => {}, filters }) {
  const [date, setDate] = useState([null, null]);
  const [activeTab, setActiveTab] = useState("All time");
  const { start_date = null, end_date = null } = filters;
  return (
    <StyledTopActions>
      <Flex justifyContent={"space-between"} className={"filters"}>
        <Segmented
          className={"filter-by-date"}
          options={[
            "All time",
            "Today",
            "This week",
            "This month",
            "This year",
          ]}
          value={activeTab}
          onChange={(val) => {
            setActiveTab(val);
            const currentTime = new Date();
            const year = currentTime.getFullYear();
            if (val === "All time") {
              setFilters({
                type: "general",
              });
              setDate([null, null]);
            }
            if (val === "This year") {
              setFilters({
                ...filters,
                start_date: year + "-01-01",
                end_date: dayjs(currentTime).format("YYYY-MM-DD"),
              });
              setDate([dayjs(year + "-01-01"), dayjs(currentTime)]);
            }
            if (val === "This month") {
              setFilters({
                ...filters,
                start_date: dayjs(currentTime).format("YYYY-MM-01"),
                end_date: dayjs(currentTime).format("YYYY-MM-DD"),
              });
              setDate([
                dayjs(dayjs(currentTime).format("YYYY-MM-01")),
                dayjs(currentTime),
              ]);
            }
            if (val === "Today") {
              setFilters({
                ...filters,
                start_date: dayjs(currentTime).format("YYYY-MM-DD"),
                end_date: dayjs(currentTime).format("YYYY-MM-DD"),
              });
              setDate([dayjs(currentTime), dayjs(currentTime)]);
            }
            if (val === "This week") {
              setFilters({
                ...filters,
                start_date: dayjs().startOf("week").format("YYYY-MM-DD"),
                end_date: dayjs(currentTime).format("YYYY-MM-DD"),
              });
              setDate([dayjs().startOf("week"), dayjs(currentTime)]);
            }
          }}
        />
        <div className={"ml-16 picker-filter"}>
          <RangePicker
            value={date}
            onChange={(_, values) => {
              if (_ === null) {
                setActiveTab("All time");
              }
              setFilters({
                ...filters,
                start_date: values[0],
                end_date: values[1],
              });
              setDate(_);
            }}
          />
        </div>
        <ButtonSecondary
          text={"Excel"}
          icon={<DownloadIcon fill={"white"} />}
          background={"#5B6D7F"}
          color={"white"}
          style={{ marginLeft: 16 }}
          click={() =>
            reportsApi.exportReportsExelList({ start_date, end_date })
          }
        />
      </Flex>
    </StyledTopActions>
  );
}
