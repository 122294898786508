import styled from "styled-components";
import { Button, Checkbox, Col, Form, Input, Row, Select, Tabs } from "antd";
import UploadImages from "../../components/moleculs/UploadImages.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import { useFetchLanguages } from "../../hooks/blog/useFetchLanguages.hook.js";
import Loader from "../../components/moleculs/Loader";
import NotificationForm from "./Form";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary";
import { useState } from "react";

const StyledCreate = styled.div`
  margin-top: 16px;

  .style_form {
    border-radius: 12px;
    background: #ffffff;
    padding: 16px;
    margin-bottom: 16px;
  }

  .filter-modal {
    position: absolute;
    top: 0;
    left: 50px;
    background: white;
    z-index: 9999;
    border: 1px solid #e7e9ec;
    border-radius: 12px;
    box-shadow: 0px 4px 9px -2px rgba(16, 24, 40, 0.03),
      0px 12px 20px -4px rgba(16, 24, 40, 0.08);
    header {
      border-bottom: 1px solid #e7e9ec;
      padding: 12px;
    }

    form {
      padding: 16px;
    }
    footer {
      padding-top: 12px;
      border-top: 1px solid #ddd;
    }
  }
`;

export default function NotificationsCreate({
  squarePhoto,
  setSquarePhoto,
  imageForWeb,
  setImageForWeb,
  imageForPush,
  setImageForPush,
  formData,
  setFormData,
  handleChangeInput,
  filterModalStatus,
  setFilterModalStatus,
}) {
  const { languages, isLoading } = useFetchLanguages();

  // const languages = [
  //   { label: "English", key: "en" },
  //   { label: "Georgian", key: "ge" },
  //   { label: "Russian", key: "ru" },
  // ];

  const tabsContent = languages?.map((language, index) => ({
    label: (
      <Flex>
        <img src={language?.flag} alt="" />
        <Typography text={language?.name} padding={"0 0 0 5px"} variant={"p"} />
      </Flex>
    ),
    key: language?.code,
    children: (
      <NotificationForm
        language={language?.code}
        squarePhoto={squarePhoto}
        setSquarePhoto={setSquarePhoto}
        imageForWeb={imageForWeb}
        setImageForWeb={setImageForWeb}
        imageForPush={imageForPush}
        setImageForPush={setImageForPush}
        formData={formData}
        handleChangeInput={handleChangeInput}
      />
    ),
  }));

  const handleFinish = (values) => {
    console.log(formData);
  };

  return (
    <StyledCreate>
      {isLoading ? <Loader /> : <Tabs items={tabsContent} />}
      {filterModalStatus ? (
        <div className="filter-modal">
          <header>
            <Typography
              text={"Filter Options"}
              variant={"span"}
              color={"#5B6D7F"}
              size={"16px"}
            />
          </header>
          <Form layout={"vertical"}>
            <Flex>
              <Form.Item style={{ marginRight: 10 }}>
                <Checkbox />
                <label style={{ marginLeft: 5 }}>In app</label>
              </Form.Item>
              <Form.Item>
                <Checkbox />
                <label htmlFor="" style={{ marginLeft: 5 }}>
                  Push
                </label>
              </Form.Item>
            </Flex>
            <Form.Item label={"GA code"}>
              <Select mode={"tags"} style={{ width: "100%" }}></Select>
            </Form.Item>
            <Row gutter={12}>
              <Col lg={12}>
                <Form.Item label={"Parcel"} name={"parcel"}>
                  <Input placeholder={"Parcel"} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label={"Tracking"}>
                  <Input placeholder={"Tracking"} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col lg={8}>
                <Form.Item label={"Pickup point"}>
                  <Input placeholder={"Pickup point"} />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item label={"Community"}>
                  <Select placeholder={"Community"}></Select>
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item label={"Region"}>
                  <Input placeholder={"Region"} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col lg={12}>
                <Form.Item label={"Gender"}>
                  <Select placeholder={"Gender"}></Select>
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label={"Status"}>
                  <Select placeholder={"Status"}></Select>
                </Form.Item>
              </Col>
            </Row>
            <Flex>
              <Form.Item style={{ marginRight: 10 }}>
                <Checkbox />
                <label htmlFor="" style={{ marginLeft: 10 }}>
                  Cargo
                </label>
              </Form.Item>
              <Form.Item>
                <Checkbox />
                <label htmlFor="" style={{ marginLeft: 10 }}>
                  Has Tax
                </label>
              </Form.Item>
            </Flex>
            <footer>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Flex>
                  <Typography
                    text={"Total results"}
                    color={"#5B6D7F"}
                    size={"16px"}
                    weight={"400"}
                    padding={"0 8px 0 0"}
                    variant={"p"}
                  />
                  <Typography
                    text={"25"}
                    color={"#0A2540"}
                    size={"16px"}
                    weight={"500"}
                    variant={"p"}
                  />
                </Flex>
                <Flex>
                  <ButtonSecondary text={"Reset"} />
                  <ButtonPrimary text={"Apply"} margin={"0 0 0 16px"} />
                </Flex>
              </Flex>
            </footer>
          </Form>
        </div>
      ) : null}
      <Form
        layout={"vertical"}
        getValueFromEvent={(e) => console.log(e)}
        onFinish={handleFinish}
      >
        <Form.Item>
          <Button htmlType={"submit"}>Submit</Button>
        </Form.Item>
      </Form>
    </StyledCreate>
  );
}
