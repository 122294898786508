import { Table } from "antd";
import Flex from "../../components/general/Flex.jsx";
import dayjs from "dayjs";
import Typography from "../../components/moleculs/Typography.jsx";
import Tag from "../../components/moleculs/Tag.jsx";
import { useState } from "react";

export const TableActions = ({ actionList }) => {
  const [see, setSee] = useState(null);
  const columns = [
    {
      title: "Changed by",
      dataIndex: "changed_by",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Type ID",
      dataIndex: "type_id",
      key: "type_id",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
    {
      title: "Updated date",
      dataIndex: "updated_date",
      key: "updated_date",
    },
  ];
  const dataSource = actionList?.map((el, i) => {
    const str = el?.log_name?.split(" ")?.pop() || "";
    const subject_type = el?.subject_type?.split("\\")?.pop() || "";

    const desc = !el?.properties?.old
      ? ""
      : Object.entries(el?.properties?.old).map(([key, val], index) => {
          const to = el?.properties?.attributes?.[key];
          const info = ` ${key?.replaceAll("_", " ")} changed from ${
            val || "0.00"
          } to ${to || "0.00"}.`;

          return (
            <Flex key={index}>
              <Typography
                text={subject_type || ""}
                variant={"p"}
                color={"#5B6D7F"}
                size={"12px"}
              />
              <Typography
                margin={"0 0 0 8px"}
                text={info || ""}
                variant={"p"}
                size={"12px"}
              />
            </Flex>
          );
        });
    return {
      key: i,
      changed_by: (
        <Flex style={{ flexDirection: "column" }}>
          <Typography
            text={el?.causer?.user_info || ""}
            variant={"p"}
            size={"14px"}
          />
          <Tag
            margin={"6px 0 0 0"}
            text={str}
            color={str === "admin" ? "#0A2540" : "#E58C35"}
            background={str === "admin" ? "#E7E9EC" : "#FFF5EB"}
            transform={"capitalize"}
            size={"12px"}
          />
        </Flex>
      ),
      type: (
        <Typography
          text={el?.morph_to?.model || ""}
          className={"role-td"}
          align={"center"}
          variant={"p"}
          size={"12px"}
          color={"#3B5166"}
          backgroundTd={"#E7E9EC"}
        />
      ),
      type_id: (
        <Flex>
          <Typography text={el?.subject_id || ""} variant={"p"} size={"14px"} />
          <Typography
            text={
              el?.user?.created_et
                ? dayjs(el?.user?.created_et).format("HH:MM")
                : ""
            }
            margin={"0 0 0 4px"}
            variant={"p"}
            size={"14px"}
            color={"#8E9BA7"}
          />
        </Flex>
      ),
      description: (
        <>
          <Flex
            direction={"column"}
            style={{
              maxHeight: see === i ? 800 : 20,
              overflow: "hidden",
              transition: "max-height 500ms ease-in-out",
            }}
          >
            {desc}
          </Flex>
          {see === i ? (
            <Typography
              text={`See less`}
              variant={"p"}
              size={"12px"}
              color={"#5dba2f"}
              onClick={() => setSee(null)}
            />
          ) : (
            <Typography
              text={`See more ${desc?.length} items`}
              variant={"p"}
              size={"12px"}
              color={"#5dba2f"}
              onClick={() => setSee(i)}
            />
          )}
        </>
      ),
      actions: (
        <Tag
          text={el?.event || ""}
          background={
            el?.event === "updated"
              ? "#EDF5FB"
              : el?.event === "created"
              ? "#EFF8EA"
              : "#FFECED"
          }
          color={
            el?.event === "updated"
              ? "#4F96D9"
              : el?.event === "created"
              ? "#5DBA2F"
              : "#FC4447"
          }
          transform={"capitalize"}
        />
      ),
      updated_date: (
        <Flex>
          <Typography
            text={
              el?.created_at
                ? dayjs(el?.created_at).format("DD.MM.YYYY") + ","
                : ""
            }
            variant={"p"}
            size={"14px"}
          />
          <Typography
            text={el?.created_at ? dayjs(el?.created_at).format("HH:mm") : ""}
            margin={"0 0 0 4px"}
            variant={"p"}
            size={"14px"}
            color={"#8E9BA7"}
          />
        </Flex>
      ),
    };
  });
  return (
    <Flex
      justifyContent="space-between"
      className={"table-wrapper"}
      direction={"column"}
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        style={{ width: "100%" }}
        pagination={false}
        scroll={{ y: 591 }}
      />
    </Flex>
  );
};
