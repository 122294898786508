import axios from "../libs/axios.js";

export const membersAndRoles = {
  async fetchRolesPermissions() {
    return await axios.get("/permissions");
  },
  async fetchPermissionsByRole(filters) {
    return await axios.get(`/permissions`, { params: filters });
  },
  async updateRole(params) {
    return await axios.put(`/roles/${params.id}`, {
      name: params.name,
      permissions: params.permissions,
    });
  },
  async deleteRole(params) {
    return await axios.delete(`/roles/${params.id}`);
  },
  async createNewRoles(data) {
    return await axios.post("/roles", data);
  },
  async getRoles(query) {
    return await axios.get("/roles", {
      params: query,
    });
  },
  async createNewMember({ id, ...data }) {
    return await axios.patch(`/user/${id}/roles/sync`, data);
  },
  async updateActivateToggle(data) {
    return await axios.post(`/user/roles/toggle`, data);
  },
};
