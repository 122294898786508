export default function DownloadIcon({ fill = "#5B6D7F" }) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66659 1.8913V5.33341C9.66659 5.80012 9.66659 6.03348 9.75741 6.21174C9.83731 6.36854 9.96479 6.49602 10.1216 6.57592C10.2999 6.66675 10.5332 6.66675 10.9999 6.66675H14.442M5.49992 12.5L7.99992 15M7.99992 15L10.4999 12.5M7.99992 15L7.99992 10M9.66659 1.66669H5.33325C3.93312 1.66669 3.23306 1.66669 2.69828 1.93917C2.22787 2.17885 1.84542 2.56131 1.60574 3.03171C1.33325 3.56649 1.33325 4.26656 1.33325 5.66669V14.3334C1.33325 15.7335 1.33325 16.4336 1.60574 16.9683C1.84542 17.4387 2.22787 17.8212 2.69828 18.0609C3.23306 18.3334 3.93312 18.3334 5.33325 18.3334H10.6666C12.0667 18.3334 12.7668 18.3334 13.3016 18.0609C13.772 17.8212 14.1544 17.4387 14.3941 16.9683C14.6666 16.4336 14.6666 15.7335 14.6666 14.3334V6.66669L9.66659 1.66669Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
