import styled from "styled-components";
import { Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Flex from "../../general/Flex";
import GetAccountDeails from "../../../hooks/customers/useGetAccountDetails.hook.jsx";
import ButtonCancel from "../../moleculs/ButtonCancel.jsx";
import ButtonPrimary from "../../moleculs/ButtonPrimary.jsx";
import { UpdateUserInfo } from "../../../services/customers.js";
import dayjs from "dayjs";

const StyledEditPersonalInfo = styled.div`
  margin-top: 35px;
  width: 100%;

  .info-item {
    input[type="text"] {
      width: 100%;
    }
  }

  .ant-input-group {
    .ant-select {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .ant-input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
`;

export default function EditPersonalInfo({ setEditPersonalInfoStatus }) {
  const { id } = useParams();
  const { accountDeails, refetch } = GetAccountDeails(id);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (accountDeails) {
      const isPersonData = accountDeails?.recipient?.is_person
        ? {
            first_name: accountDeails?.recipient?.first_name || "",
            last_name: accountDeails?.recipient?.last_name || "",
            georgian_first_name:
              accountDeails?.recipient?.georgian_first_name || "",
            georgian_last_name:
              accountDeails?.recipient?.georgian_last_name || "",
          }
        : {
            georgian_company_name:
              accountDeails?.recipient?.georgian_company_name || "",
            company_name: accountDeails?.recipient?.company_name || "",
          };
      form.setFieldsValue({
        email: accountDeails?.email,
        phone: accountDeails?.phone,
        address: accountDeails?.recipient?.address,
        document_type: accountDeails?.recipient?.document_type,
        document_number: accountDeails?.recipient?.document_number,
        gender: accountDeails?.recipient?.gender,
        birthday_date: accountDeails?.birthday_date
          ? dayjs(accountDeails?.birthday_date)
          : "",
        ...isPersonData,
      });
    }
  }, []);

  const handleSave = (values) => {
    setLoading(true);
    if (values) {
      const data = {
        account: {
          email: values.email,
          birthday_date: dayjs(values?.birthday_date).format("YYYY-MM-DD"),
        },
        recipient: accountDeails?.recipient?.is_person
          ? {
              first_name: values.first_name,
              last_name: values.last_name,
              georgian_first_name: values.georgian_first_name,
              georgian_last_name: values.georgian_last_name,
              document_number: values.document_number,
              document_type: values.document_type,
              address: values.address,
              phone: values.phone,
              gender: values.gender,
            }
          : {
              company_name: values.company_name,
              georgian_first_name: values.georgian_first_name,
              georgian_last_name: values.georgian_last_name,
              document_number: values.document_number,
              document_type: values.document_type,
              address: values.address,
              phone: values.phone,
              gender: values.gender,
            },
      };
      UpdateUserInfo(id, data)
        .then((res) => {})
        .finally(() => {
          setLoading(false);
          refetch();
          setEditPersonalInfoStatus(false);
          message.success("Saved");
        });
    }
  };

  return (
    <StyledEditPersonalInfo>
      <Form
        name="editPersonalInfo"
        onFinish={handleSave}
        onFinishFailed={handleSave}
        layout="vertical"
        form={form}
      >
        <div className="actions">
          <Flex>
            <ButtonCancel
              text="Cancel"
              click={() => setEditPersonalInfoStatus(false)}
              margin="0 16px 0 0"
            />
            <ButtonPrimary htmlType="submit" text="Save" loading={loading} />
          </Flex>
        </div>
        <Row gutter={16}>
          {accountDeails?.recipient?.is_person ? (
            <Col lg={8}>
              <Form.Item name="first_name" label={"Name"}>
                <Input />
              </Form.Item>

              <Form.Item name="last_name" label={"Surname"}>
                <Input />
              </Form.Item>

              <Form.Item
                name="georgian_first_name"
                label={"First Name in Georgian"}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="georgian_last_name"
                label={"Last  Name in Georgian"}
              >
                <Input />
              </Form.Item>
            </Col>
          ) : (
            <Col lg={8}>
              <Form.Item name="company_name" label={"Company name"}>
                <Input />
              </Form.Item>

              <Form.Item
                name="georgian_company_name"
                label={"Company name (Georgian)"}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
          <Col lg={8}>
            <Form.Item name="email" label={"Email"}>
              <Input />
            </Form.Item>
            <Form.Item name="phone" label={"Phone"}>
              <Input />
            </Form.Item>

            <Form.Item name="address" label={"Address"}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Input.Group compact>
              <Flex alignItems={"flex-end"}>
                <Form.Item name="document_type" label={"Passport details"}>
                  <Select
                    defaultValue={accountDeails?.recipient?.document_type || ""}
                    style={{ width: 220, height: 36 }}
                  >
                    <Select.Option value="passport">Passport</Select.Option>
                    <Select.Option value="id">ID</Select.Option>
                    <Select.Option value="foreign_citizen">
                      Foreigh Citizen
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="document_number">
                  <Input />
                </Form.Item>
              </Flex>
            </Input.Group>
            <Form.Item name="gender" label={"Gender"}>
              <Select
                defaultValue={accountDeails?.recipient?.gender || ""}
                style={{ height: 36 }}
              >
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="birthday_date" label={"Birthday"}>
              <DatePicker style={{ width: "100%" }} inputReadOnly={true} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledEditPersonalInfo>
  );
}
