import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import Flex from "../../components/general/Flex.jsx";
import ExpectedIcon from "../../components/SvgComponents/ExpectedIcon.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import WerHouseIcon from "../../components/SvgComponents/werhouse.jsx";
import OnTheWayIcon from "../../components/SvgComponents/OnTheWayIcon.jsx";
import InArmeniaIcon from "../../components/SvgComponents/InArmeniaIcon.jsx";
import ReceivedPrimaryIcon from "../../components/SvgComponents/ReceivedPrimaryIcon";
import GetAccountDeails from "../../hooks/customers/useGetAccountDetails.hook.jsx";
import ScannedIcon from "../../components/SvgComponents/ScannedIcon";
import ReceivedIcon from "../../components/SvgComponents/ReceivedIcon";

const StyledSingleCUstomerStatuses = styled.div`
  padding: 28px 16px;
  border-radius: 12px;
  background: white;
  width: calc(80% - 16px);
  margin-right: 16px;
  .count {
    background: #f5f5f5;
    padding: 2px 10px;
    color: #3b5166;
    border-radius: 16px;
    font-weight: 500;
    margin-left: 12px;
  }
  .order-details-statuses__status {
    cursor: pointer;
    &:hover {
      p {
        color: #5dba2f;
      }
    }
  }
`;

const StyledPickupPoint = styled.div`
  margin-bottom: 16px;
  padding: 20px 16px;
  border-radius: 12px;
  background: white;
  width: 20%;
`;

export default function CustomerSingleStatuses() {
  const { id } = useParams();
  const { accountDeails } = GetAccountDeails(id);
  const navigate = useNavigate();

  const statusTexts = {
    scan: "Scanned",
    expected: "Expected",
    at_warehouse: "At Warehouse",
    on_the_way: "On the Way",
    in_georgia: "In Georgia",
    received: "Received",
  };

  const handleToExpected = () => {
    navigate(`/expected?user_info=${accountDeails.full_name}`);
  };

  const handleToOrders = (status) => {
    navigate(
      `/orders?status=${status}&user_info=${accountDeails.recipient.user_code.substr(
        2,
        accountDeails.recipient.user_code.length - 1
      )}`
    );
  };

  return (
    <Flex>
      <StyledSingleCUstomerStatuses className="customer-statuses">
        <Flex
          justifyContent="space-between"
          className="statuses"
          alignItems="center"
        >
          <div
            onClick={() => handleToExpected()}
            className="order-details-statuses__status"
          >
            <Flex alignItems="center">
              <ExpectedIcon />
              <Flex direction="column" style={{ paddingLeft: 10 }}>
                <Flex alignItems="center">
                  <Typography
                    text={"Expected"}
                    variant="p"
                    color="#3B5166"
                    size="14px"
                    weight="500"
                  />
                  <div className="count">
                    {accountDeails?.orders_history.expected || 0}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </div>
          <div
            className="order-details-statuses__status"
            onClick={() => handleToOrders("scan")}
          >
            <Flex alignItems="center">
              <ScannedIcon />
              <Flex direction="column" style={{ paddingLeft: 10 }}>
                <Flex alignItems="center">
                  <Typography
                    text={"Scanned"}
                    variant="p"
                    color="#3B5166"
                    size="14px"
                    weight="500"
                  />
                  <div className="count">
                    {accountDeails?.orders_history.scan || 0}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </div>
          <div
            className="order-details-statuses__status"
            onClick={() => handleToOrders("at_warehouse")}
          >
            <Flex alignItems="center">
              <WerHouseIcon />
              <Flex direction="column" style={{ paddingLeft: 10 }}>
                <Flex alignItems="center">
                  <Typography
                    text={"At warehouse"}
                    variant="p"
                    color="#3B5166"
                    size="14px"
                    weight="500"
                  />
                  <div className="count">
                    {accountDeails?.orders_history.at_warehouse || 0}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </div>
          <div
            className="order-details-statuses__status"
            onClick={() => handleToOrders("on_the_way")}
          >
            <Flex alignItems="center">
              <OnTheWayIcon />
              <Flex direction="column" style={{ paddingLeft: 10 }}>
                <Flex alignItems="center">
                  <Typography
                    text={"On the Way"}
                    variant="p"
                    color="#3B5166"
                    size="14px"
                    weight="500"
                  />
                  <div className="count">
                    {accountDeails?.orders_history.on_the_way || 0}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </div>
          <div
            className="order-details-statuses__status"
            onClick={() => handleToOrders("in_georgia")}
          >
            <Flex alignItems="center">
              <InArmeniaIcon />
              <Flex direction="column" style={{ paddingLeft: 10 }}>
                <Flex alignItems="center">
                  <Typography
                    text={"In Georgia"}
                    variant="p"
                    color="#3B5166"
                    size="14px"
                    weight="500"
                  />
                  <div className="count">
                    {accountDeails?.orders_history.in_georgia || 0}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </div>
          <div
            className="order-details-statuses__status"
            onClick={() => handleToOrders("received")}
          >
            <Flex alignItems="center">
              <ReceivedIcon />
              <Flex direction="column" style={{ paddingLeft: 10 }}>
                <Flex alignItems="center">
                  <Typography
                    text={"Received"}
                    variant="p"
                    color="#3B5166"
                    size="14px"
                    weight="500"
                  />
                  <div className="count">
                    {accountDeails?.orders_history.received || 0}
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </div>
        </Flex>
      </StyledSingleCUstomerStatuses>
      <StyledPickupPoint>
        <Typography
          variant="p"
          text="Main Pickup Point"
          color="#3B5166"
          size="14px"
          weight="500"
          padding="0 0 0 0"
        />
        <Typography
          variant="p"
          text={accountDeails?.user_pickup_point?.pickup_point?.name}
          size="14px"
          weight="400"
          color={primaryTextColor}
        />
      </StyledPickupPoint>
    </Flex>
  );
}
