import Typography from "../../components/moleculs/Typography.jsx";
import styled from "styled-components";
import NoSlides from "./NoSlides.jsx";
import Flex from "../../components/general/Flex.jsx";
import ButtonGray from "../../components/moleculs/ButtonGray.jsx";
import TableSlides from "./TableSlides.jsx";
import { useEffect, useState } from "react";
import CreateSlideDrawer from "./CreateSlide";
import { useFetchSlides } from "../../hooks/slides/useFetchSlides.hook.js";
import Loader from "../../components/moleculs/Loader";
import { useFetchLanguages } from "../../hooks/slides/useFetchLanguages.hook.js";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledSlides = styled.div`
  padding-top: 24px;

  .table-wrapper {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    width: 100%;
    background: #ffffff;
    margin-bottom: 24px;
  }

  .role-td {
    background: #edf5fb;
    border-radius: 16px;
    width: min-content;
    padding: 2px 10px 2px 10px;
    white-space: nowrap;
  }

  .inactive-status {
    background: #ffeced;
    color: #fc4447;
  }

  .active-status {
    background: #eff8ea;
    color: #5dba2f;
  }

  .ant-table-cell {
    font-size: 12px;
    color: #5b6d7f !important;
  }

  .ant-table-wrapper {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .ant-table {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    max-height: 70vh;
    overflow: auto;
    width: 100%;
    background: #ffffff;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export default function Slides({ permissions }) {
  const { slides = [], refetch, isLoading } = useFetchSlides();
  const [dataSource, setDataSource] = useState(null);
  const [isActiveData, setIsActiveData] = useState([]);
  const [createSlider, setCreateSlider] = useState(false);
  const [slider, setSlider] = useState(false);
  const { languages } = useFetchLanguages();

  useEffect(() => {
    setDataSource(slides?.data);
    slides?.data?.map((el) => {
      if (el?.is_active) {
        setIsActiveData((prevState) => [...prevState, el]);
      }
    });
  }, [slides?.data]);

  const openModal = () => {
    setCreateSlider(true);
  };
  return (
    <StyledSlides>
      <div className="page-title">
        <Typography
          text="Slides"
          color="#262626"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <Flex
        alignItems={dataSource?.length > 0 ? "flex-start" : "center"}
        justifyContent={dataSource?.length > 0 ? "flex-start" : "center"}
        direction={"column"}
        style={{
          minHeight: "calc(100vh - 70px)",
          width: "100%",
        }}
      >
        {dataSource?.length > 0 ? (
          PermissionFinder(permissions, "slider-create") ? (
            <ButtonGray
              text={"Create slide"}
              background={"#5B6D7F"}
              color={"#FFFFFF"}
              onClick={() => openModal()}
            />
          ) : null
        ) : null}
        {dataSource?.length > 0 ? (
          <TableSlides
            dataSource={dataSource}
            createSlider={createSlider}
            setCreateSlider={setCreateSlider}
            setDataSource={setDataSource}
            isActiveData={isActiveData}
            setSlider={setSlider}
            languages={languages}
            permissions={permissions}
          />
        ) : isLoading ? (
          <Loader />
        ) : (
          <NoSlides />
        )}
      </Flex>
      <CreateSlideDrawer
        createSlider={createSlider}
        setCreateSlider={setCreateSlider}
        reFetchSlider={refetch}
        setSlider={setSlider}
        slider={slider}
        languages={languages}
        permissions={permissions}
      />
    </StyledSlides>
  );
}
