import Typography from "../../components/moleculs/Typography.jsx";
import styled from "styled-components";
import Flex from "../../components/general/Flex.jsx";
import ButtonGray from "../../components/moleculs/ButtonGray.jsx";
import TableShops from "./TableShops.jsx";
import { useState } from "react";
import CreateShopsDrawer from "./Create/CreateShops.jsx";
import Loader from "../../components/moleculs/Loader";
import NoShops from "./NoShops.jsx";
import { useFetchShops } from "../../hooks/shops/useFetchShops.hook.js";
import ShopsTopSearchActions from "./TopSearchActions.jsx";
import { isEmpty } from "lodash";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const StyledShops = styled.div`
  padding-top: 24px;

  .table-wrapper {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    width: 100%;
    background: #ffffff;
    margin-bottom: 24px;
  }

  .Tooltip-Wrapper {
    margin-left: 7px;
  }

  .role-td {
    background: #edf5fb;
    border-radius: 16px;
    width: min-content;
    padding: 2px 10px 2px 10px;
    white-space: nowrap;
  }

  .inactive-status {
    background: #ffeced;
    color: #fc4447;
  }

  .active-status {
    background: #eff8ea;
    color: #5dba2f;
  }

  .ant-table-cell {
    font-size: 12px;
    color: #5b6d7f !important;
  }

  .ant-table-wrapper {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .ant-table {
    border-radius: 8px;
    border: 1px solid #e7e9ec;
    overflow: auto;
    width: 100%;
    background: #ffffff;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export default function Shops({ permissions }) {
  const [filters, setFilters] = useState();
  const { shops = [], meta, refetch, isLoading } = useFetchShops(filters);
  const [shop, setShop] = useState(false);
  return (
    <StyledShops>
      <div className="page-title">
        <Typography
          text="Shops"
          color="#262626"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <Flex
        // alignItems={shops?.length > 0 ? "flex-start" : "center"}
        // justifyContent={shops?.length > 0 ? "flex-start" : "center"}
        direction={"column"}
        style={{
          minHeight: "calc(100vh - 70px)",
          // width: "100%",
        }}
      >
        {PermissionFinder(permissions, "shop-create") ? (
          <ButtonGray
            text={"Create shop"}
            background={"#5B6D7F"}
            color={"#FFFFFF"}
            onClick={() => setShop(true)}
          />
        ) : null}
        <ShopsTopSearchActions setFilters={setFilters} />
        {shops?.length > 0 ? (
          <>
            <TableShops
              data={shops}
              setShop={setShop}
              meta={meta}
              filters={filters}
              setFilterData={setFilters}
              permissions={permissions}
            />
          </>
        ) : isLoading ? (
          <Loader />
        ) : (
          <NoShops isFiltered={isEmpty(filters)} />
        )}
      </Flex>
      <CreateShopsDrawer
        reFetchShops={refetch}
        setShop={setShop}
        shop={shop}
        permissions={permissions}
      />
    </StyledShops>
  );
}
