import { useQuery } from "react-query";
import { membersAndRoles } from "../../services/members-and-roles.js";

export const useFetchPermissionsByRoles = (filters) => {
  const {
    isLoading,
    data: permissions,
    refetch,
  } = useQuery(
    ["fetch-permissions-by-role", filters],
    () => membersAndRoles.fetchPermissionsByRole(filters),
    {
      // staleTime: Infinity,
      enabled: !!filters,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => data?.data,
    }
  );
  return {
    isLoading,
    permissions,
    refetch,
  };
};
