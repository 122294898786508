import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import image from "../../assets/images/story.png";
import EyeIcon from "../../components/SvgComponents/EyeIcon.jsx";
import DeleteIcon from "../../components/SvgComponents/DeleteIcon.jsx";
import { TableDots } from "../../components/SvgComponents/TableDots.jsx";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DeleteModal from "./DeleteModal";
import { useMemo, useState } from "react";
import styled from "styled-components";
import EyeHidden from "../../components/SvgComponents/EyeHidden.jsx";

export default function ChildStory({
  id,
  item,
  hideStory,
  handleDelete,
  language_index,
}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
          pointerEvents: "none",
          boxShadow: "0px 4px 14px 0px rgba(0, 31, 77, 0.25)",
        }
      : {}),
  };
  const active = useMemo(() => {
    if (language_index === undefined) return item;
    return item?.storiesMl?.[language_index];
  }, [language_index]);
  return (
    <div {...attributes} {...listeners} ref={setNodeRef} style={style}>
      <Container
        justifyContent={"space-between"}
        alignItems={"center"}
        className={"children"}
      >
        <Flex alignItems={"center"}>
          <div>
            <TableDots />
          </div>
          <Typography
            className={"id"}
            variant={"span"}
            text={active?.priority}
            color={"#262626"}
            size={"14px"}
            weight={"500"}
            padding={"0 40px 0 40px"}
          />
          <img src={image} alt="" />
          <div className={"description"}>
            <Typography
              variant={"span"}
              text={active?.description}
              color={"#262626"}
              size={"16px"}
              weight={"500"}
            />
          </div>
          <div className={"description"}>
            <Typography
              variant={"span"}
              text={active?.button_text}
              color={"#262626"}
              size={"16px"}
              weight={"500"}
            />
          </div>

          <div className={"description"}>
            <a href={active?.url} target="_blank" style={{ maxWidth: 250 }}>
              <Typography
                variant={"span"}
                className="story-link"
                text={"Story URL"}
                color={"#262626"}
                size={"16px"}
                weight={"500"}
              />
            </a>
          </div>
        </Flex>
        <Icons alignItems={"center"} className={"actions"}>
          {item.is_hide ? (
            <EyeHidden onClick={() => hideStory(item.id, false)} />
          ) : (
            <EyeIcon onClick={() => hideStory(item.id, true)} />
          )}
          <DeleteIcon onClick={() => setOpenDeleteModal(true)} />
        </Icons>
      </Container>
      <DeleteModal
        open={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        onDelete={() => {
          handleDelete(item.id);
          setOpenDeleteModal(false);
        }}
      />
    </div>
  );
}

const Icons = styled(Flex)`
  svg {
    cursor: pointer;
  }
`;

const Container = styled(Flex)`
  .story-link {
    text-decoration: underline;
  }
`;
