import { useQuery } from "react-query";
import { GetRecipients } from "../../services/recipients.js";

export default function useGetRecipients(query) {
  const { data, refetch, isLoading } = useQuery(
    ["get-recipients", query],
    () => GetRecipients(query),
    {
      staleTime: Infinity,
      select: ({ data }) => data?.data,
    }
  );
  return { recipients: data?.data, meta: data?.meta, refetch, isLoading };
}
