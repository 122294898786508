import { useMutation, useQueryClient } from "react-query";
import { receiveOrdersService } from "../../services/receive-orders.js";

export const useReceiveOrders = (onSuccess = () => {}, onError = () => {}) => {
  const client = useQueryClient();
  return useMutation(receiveOrdersService, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["receive-orders"]);
    },
  });
};
