import { useQuery } from "react-query";
import { appVersionsApi } from "../../services/app-versions.js";

export function useGetAppVersions(filters = {}) {
  const { data, refetch, isLoading } = useQuery(
    ["app-versions", filters],
    () => appVersionsApi.fetchAppVersions(filters),
    {
      staleTime: Infinity,
    }
  );

  if (data) return { ...data?.data?.data, refetch, isLoading };
  return { data: [], meta: {}, refetch, isLoading };
}
