import { useQuery } from "react-query";
import { shopsApi } from "../../services/shops.js";

export const useFetchShops = (params) => {
  const { isLoading, refetch, data } = useQuery(
    ["get-shops", params],
    () => shopsApi.fetchShops(params),
    {
      staleTime: Infinity,
      select: ({ data }) => {
        return data?.data;
      },
    }
  );
  return { isLoading, shops: data?.data, meta: data?.meta, refetch };
};
