import Flex from "../../../components/general/Flex.jsx";
import Typography from "../../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../../constants.js";
import WerHouseIcon from "../../../components/SvgComponents/werhouse.jsx";
import OnTheWayIcon from "../../../components/SvgComponents/OnTheWayIcon.jsx";
import InArmeniaIcon from "../../../components/SvgComponents/InArmeniaIcon.jsx";
import ReceivedIcon from "../../../components/SvgComponents/ReceivedIcon.jsx";
import ScannedIcon from "../../../components/SvgComponents/ScannedIcon";
import ReadyIcon from "../../../components/SvgComponents/ReadyIcon.jsx";
import styled from "styled-components";
import { RefundIcon } from "../../../components/SvgComponents/RefundIcon.jsx";

const StyledOrderStatuses = styled.div`
  .order-statuses-wrapper {
    .order-details-statuses__status {
      &:last-child {
        p {
          color: #5dba2f;
        }
      }
    }
  }
`;

export default function OrderDetailStatuses({ histories }) {
  const statusesKeyValues = {
    scan_date: { label: "Scanned", icon: <ScannedIcon /> },
    repack_date: { label: "Repack", icon: <ReceivedIcon /> },
    at_warehouse_date: { label: "At Warehouse", icon: <WerHouseIcon /> },
    on_way_date: { label: "On the way", icon: <OnTheWayIcon /> },
    in_georgia_date: { label: "In Georgia", icon: <InArmeniaIcon /> },
    ready_for_pickup_date: { label: "Ready", icon: <ReadyIcon /> },
    received_date: { label: "Received", icon: <ReceivedIcon /> },
    refund_date: {
      label: "Refunded",
      icon: <RefundIcon size={24} color={"#5dba2f"} />,
    },
  };

  return (
    <StyledOrderStatuses className="order-details-statuses">
      <Flex className={"order-statuses-wrapper"}>
        {histories?.map((history, index) => (
          <div className="order-details-statuses__status" key={index}>
            <Flex alignItems="center">
              {statusesKeyValues[history.key]?.icon}
              <Flex direction="column" style={{ paddingLeft: 10 }}>
                <Typography
                  text={statusesKeyValues[history.key]?.label}
                  variant="p"
                  color="#3B5166"
                  size="14px"
                  weight="500"
                />
                <Typography
                  text={history.date}
                  variant="p"
                  color={primaryTextColor}
                  size="14px"
                  weight="400"
                />
              </Flex>
            </Flex>
          </div>
        ))}
        {/* <div className="order-details-statuses__status">
          <Flex alignItems="center">
            <ExpectedIcon />
            <Flex direction="column" style={{ paddingLeft: 10 }}>
              <Typography
                text="Expected"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                text="23.08.2022"
                variant="p"
                color={primaryTextColor}
                size="14px"
                weight="400"
              />
            </Flex>
          </Flex>
        </div>
        <div className="order-details-statuses__status">
          <Flex alignItems="center">
            <WerHouseIcon />
            <Flex direction="column" style={{ paddingLeft: 10 }}>
              <Typography
                text="At Warehouse"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                text="23.08.2022"
                variant="p"
                color={primaryTextColor}
                size="14px"
                weight="400"
              />
            </Flex>
          </Flex>
        </div>
        <div className="order-details-statuses__status">
          <Flex alignItems="center">
            <OnTheWayIcon />
            <Flex direction="column" style={{ paddingLeft: 10 }}>
              <Typography
                text="On The Way"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                text="23.08.2022"
                variant="p"
                color={primaryTextColor}
                size="14px"
                weight="400"
              />
            </Flex>
          </Flex>
        </div>
        <div className="order-details-statuses__status">
          <Flex alignItems="center">
            <ScannedIcon />
            <Flex direction="column" style={{ paddingLeft: 10 }}>
              <Typography
                text="Scanned"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                text="23.08.2022"
                variant="p"
                color={primaryTextColor}
                size="14px"
                weight="400"
              />
            </Flex>
          </Flex>
        </div>
        <div className="order-details-statuses__status">
          <Flex alignItems="center">
            <InArmeniaIcon />
            <Flex direction="column" style={{ paddingLeft: 10 }}>
              <Typography
                text="In Armenia"
                variant="p"
                color="#3B5166"
                size="14px"
                weight="500"
              />
              <Typography
                text="23.08.2022"
                variant="p"
                color={primaryTextColor}
                size="14px"
                weight="400"
              />
            </Flex>
          </Flex>
        </div>
        <div className="order-details-statuses__status">
          <Flex alignItems="center">
            <ReceivedIcon />
            <Flex direction="column" style={{ paddingLeft: 10 }}>
              <Typography
                text="Received"
                variant="p"
                color={greenColor}
                size="14px"
                weight="500"
              />
              <Typography
                text="23.08.2022"
                variant="p"
                color={greenColor}
                size="14px"
                weight="400"
              />
            </Flex>
          </Flex>
        </div> */}
      </Flex>
    </StyledOrderStatuses>
  );
}
