import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { notification, Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import Flex from "../../components/general/Flex.jsx";
import EditIcon from "../../components/SvgComponents/EditIcon.jsx";
import dayjs from "dayjs";
import Typography from "../../components/moleculs/Typography.jsx";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useSortBlogs } from "../../hooks/blog/useSortBlogs.hook.js";
import { TableDots } from "../../components/SvgComponents/TableDots.jsx";
import { CSS } from "@dnd-kit/utilities";
import { PermissionFinder } from "../../hooks/permissionFinder.hook.js";

const columns = [
  {
    key: "sort",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Language",
    dataIndex: "language",
    key: "language",
  },
  {
    title: "Created date",
    dataIndex: "created_date",
    key: "created_date",
  },
  {
    title: "Updated date",
    dataIndex: "updated_date",
    key: "updated_date",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: " ",
    dataIndex: "edit",
    key: "edit",
  },
];

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
          pointerEvents: "none",
          boxShadow: "0px 4px 14px 0px rgba(0, 31, 77, 0.25)",
          borderRadius: "12px",
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React?.Children?.map(children, (child) => {
        if (
          child.key === "sort" &&
          child?.props?.record?.status?.props?.text === "Active"
        ) {
          return React.cloneElement(child, {
            children: (
              <div
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              >
                <TableDots />
              </div>
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const TableBlog = ({
  isActiveData,
  dataSource,
  setDataSource = () => {},
  setBlog = () => {},
  setFilterData = () => {},
  filters,
  languages,
  meta,
  permissions,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [isDrag, setIsDrag] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const [overStatus, setOverSatus] = useState(false);
  const { mutate, isLoading } = useSortBlogs(
    (data) => {
      api.success({
        message: `Success`,
        description: data?.data?.message,
        placement: "topRight",
      });
      setIsDrag(false);
    },
    (e) => {
      console.log(e, "error");
    }
  );

  const onDragEnd = ({ active, over }) => {
    setIsDrag(true);
    let localActiveStatus = false;
    let localOverStatus = false;
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => {
          setActiveStatus(i?.is_active);
          localActiveStatus = i?.is_active;
          return i?.is_active && i.id === active.id;
        });

        const overIndex = previous.findIndex((i) => {
          setOverSatus(i.is_active);
          localOverStatus = i?.is_active;
          return i?.is_active && i.id === over?.id;
        });
        if (localActiveStatus && localOverStatus) {
          return arrayMove(previous, activeIndex, overIndex);
        } else {
          return previous;
        }
      });
    }
  };

  useEffect(() => {
    const newData = [];
    if (isDrag && activeStatus && overStatus) {
      dataSource.forEach((el, i) => {
        if (el.is_active) {
          newData.push({
            id: el.id,
            priority: isActiveData?.length - 1 - i + 1,
          });
        }
      });
      mutate({ blog_sort: newData });
    }
  }, [isDrag, activeStatus, overStatus]);

  const openModal = (item, index) => {
    const newData = { ...item, language_id: index + 1 };
    setBlog(newData);
  };

  const newData = dataSource?.map((el, i) => ({
    key: el.id,
    title: (
      <Flex>
        {el?.is_active ? (
          <Typography
            text={i < 9 ? "0" + (i + 1) : i + 1}
            variant={"p"}
            size={"14px"}
            color={"#8E9BA7"}
          />
        ) : null}

        <Typography
          margin={el?.is_active ? "0 0 0 12px" : "0 0 0 28px"}
          text={el?.title || ""}
          variant={"p"}
          size={"14px"}
        />
      </Flex>
    ),
    language: (
      <Flex>
        {languages?.length > 0
          ? languages?.map((ml, index) => (
              <img
                alt={ml.code}
                key={ml.id}
                onClick={() => openModal(el, index)}
                src={ml.flag}
                style={{
                  marginRight: "10px",
                  opacity: el?.blogsMl?.find((d) => d.language_id === ml.id)
                    ? "1"
                    : "0.2",
                  cursor: "pointer",
                }}
                width="24"
              />
            ))
          : ""}
      </Flex>
    ),

    created_date: (
      <Flex>
        <Typography
          text={
            el?.created_at
              ? dayjs(el?.created_at).format("DD.MM.YYYY") + ","
              : ""
          }
          variant={"p"}
          size={"14px"}
        />
        <Typography
          text={el?.created_at ? dayjs(el?.created_at).format("HH:mm") : ""}
          margin={"0 0 0 4px"}
          variant={"p"}
          size={"14px"}
          color={"#8E9BA7"}
        />
      </Flex>
    ),
    updated_date: (
      <Flex>
        <Typography
          text={
            el?.updated_at
              ? dayjs(el?.updated_date).format("DD.MM.YYYY") + ","
              : ""
          }
          variant={"p"}
          size={"14px"}
        />
        <Typography
          text={el?.updated_at ? dayjs(el?.updated_at).format("HH:mm") : "-"}
          margin={"0 0 0 4px"}
          variant={"p"}
          size={"14px"}
          color={"#8E9BA7"}
        />
      </Flex>
    ),
    status: (
      <Typography
        text={el?.is_active ? "Active" : "Inactive"}
        className={`role-td ${
          el?.is_active ? "active-status" : "inactive-status"
        }`}
        align={"center"}
        variant={"p"}
        size={"14px"}
      />
    ),
    edit: PermissionFinder(permissions, "blog-edit") ? (
      <EditIcon style={{ cursor: "pointer" }} onClick={() => openModal(el)} />
    ) : null,
  }));
  const onShowSizeChange = (current, pageSize) => {
    setFilterData({ ...filters, page: current, per_page: pageSize });
  };
  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      {contextHolder}
      <SortableContext
        // rowKey array
        items={dataSource?.map((i) => i.id) || []}
        strategy={verticalListSortingStrategy}
      >
        <Table
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey="key"
          columns={columns || []}
          dataSource={newData}
          pagination={false}
        />
      </SortableContext>
      <div className={"new-pagination"} style={{ padding: 0 }}>
        <Pagination
          total={meta?.total || 1}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={onShowSizeChange}
          defaultPageSize={meta?.per_page || 15}
          defaultCurrent={meta?.current_page || 1}
        />
      </div>
    </DndContext>
  );
};
export default TableBlog;
