import { useQuery } from "react-query";
import { GetOrdersService } from "../../services/orders.js";

export default function useGetOrders(filters) {
  const { data, isLoading, refetch } = useQuery(
    ["get-orders", filters],
    () => GetOrdersService(filters),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {
        let totalPrice = 0;
        let totalWeight = 0;
        data?.data?.data?.forEach((el) => {
          if (el.cost) {
            totalPrice += Number(el.cost);
          }
          if (el.weight) {
            totalWeight += Number(el.weight);
          }
        });
        let orders = data?.data;
        return { orders, totalPrice, totalWeight };
      },
      enabled: !!filters,
    }
  );
  return {
    orders: data?.orders,
    totalPrice: data?.totalPrice,
    totalWeight: data?.totalWeight,
    isLoading,
    refetch,
  };
}
