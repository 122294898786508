import styled from "styled-components";
import { Col, Row } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import { languagesKeyValues, primaryTextColor } from "../../constants.js";
import { useParams } from "react-router-dom";
import GetAccountDeails from "../../hooks/customers/useGetAccountDetails.hook.jsx";

const StyledNotificationsSettings = styled.div`
  margin-top: 20px;
  .item-block {
    margin-bottom: 24px;
    &-second {
      margin-top: 15px;
      margin-bottom: 40px;
    }
  }
`;

export default function CustomerNotificationSettings() {
  const { id } = useParams();

  const { accountDeails } = GetAccountDeails(id);
  return (
    <StyledNotificationsSettings>
      <Row>
        <Col lg={8}>
          <div className="item-block">
            <Typography
              variant="p"
              text="Marketing Notifications"
              color="#5B6D7F"
              size="12px"
              weight="400"
              padding="0 0 8px 0"
            />
            <Typography
              variant="p"
              text="News"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Typography
              variant="p"
              text="Enabled"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
            />
          </div>
          <div className="item-block">
            <Typography
              variant="p"
              text="Smart Wall settings"
              color="#5B6D7F"
              size="12px"
              weight="400"
              padding="0 0 8px 0"
            />
            <Typography
              variant="p"
              text="Smart Wall voice"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Typography
              variant="p"
              text="Enabled"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
            />
          </div>
          <div className="item-block">
            <Typography
              variant="p"
              text="Status Notifications"
              color="#5B6D7F"
              size="12px"
              weight="400"
              padding="0 0 8px 0"
            />
            <Typography
              variant="p"
              text="In Warehouse"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Typography
              variant="p"
              text="Enabled"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
            />
          </div>
          <div className="item-block">
            <Typography
              variant="p"
              text="Transaction Notifications"
              color="#5B6D7F"
              size="12px"
              weight="400"
              padding="0 0 8px 0"
            />
            <Typography
              variant="p"
              text="Fill balance"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Typography
              variant="p"
              text="Enabled"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
            />
          </div>
        </Col>
        <Col lg={8}>
          <div className="item-block-second">
            <Typography
              variant="p"
              text="Events"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Typography
              variant="p"
              text="Disabled"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
            />
          </div>
          <div className="item-block-second">
            <Typography
              variant="p"
              text="Smart Wall Language"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Typography
              variant="p"
              text="English"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
            />
          </div>
          <div className="item-block-second">
            <Typography
              variant="p"
              text="On the way"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Typography
              variant="p"
              text="Enabled"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
            />
          </div>
          <div className="item-block-second">
            <Typography
              variant="p"
              text="Bonus"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Typography
              variant="p"
              text="Disabled"
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
            />
          </div>
        </Col>
        <Col lg={8}>
          <div className="item-block-second">
            <Typography
              variant="p"
              text="Marketing Notifications Language"
              color="#3B5166"
              size="14px"
              weight="500"
              padding="0 0 4px 0"
            />
            <Typography
              variant="p"
              text={
                languagesKeyValues[accountDeails?.settings?.notification_lang]
              }
              color={primaryTextColor}
              size="14px"
              weight="400"
              padding="0 0 4px 0"
            />
          </div>
        </Col>
      </Row>
    </StyledNotificationsSettings>
  );
}
