export default function ReceivedIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.23844 21.2674H8.74432C9.07104 21.2674 9.39577 21.2998 9.7125 21.3646L12.3603 21.9008C12.9348 22.0174 13.5333 22.0287 14.1138 21.9348L17.0413 21.4601C17.8146 21.3346 18.526 21.026 19.0836 20.5741L21.1548 18.895C21.7463 18.4163 21.7463 17.6396 21.1548 17.1601C20.6223 16.7284 19.779 16.6798 19.1765 17.0459L16.7625 18.5135C16.4168 18.7241 15.9962 18.8375 15.5635 18.8375H13.2325L14.7163 18.8375C15.5526 18.8375 16.23 18.2883 16.23 17.6104V17.365C16.23 16.8021 16.237 16.1705 15.5635 16.0345L12.7939 15.711C12.4212 15.6357 12.0395 15.5977 11.6559 15.5977C10.7296 15.5977 9.05306 16.2367 9.05306 16.2367L6.23844 17.2176M2.39844 16.8777L2.39844 21.5177C2.39844 21.9657 2.39844 22.1897 2.50307 22.3608C2.59511 22.5114 2.74197 22.6338 2.92261 22.7105C3.12796 22.7977 3.39679 22.7977 3.93444 22.7977H4.70244C5.24009 22.7977 5.50891 22.7977 5.71427 22.7105C5.8949 22.6338 6.04177 22.5114 6.1338 22.3608C6.23844 22.1897 6.23844 21.9657 6.23844 21.5177V16.8777C6.23844 16.4296 6.23844 16.2056 6.1338 16.0345C6.04177 15.8839 5.8949 15.7616 5.71427 15.6849C5.50891 15.5977 5.24009 15.5977 4.70244 15.5977L3.93444 15.5977C3.39679 15.5977 3.12796 15.5977 2.92261 15.6849C2.74197 15.7615 2.59511 15.8839 2.50307 16.0345C2.39844 16.2056 2.39844 16.4296 2.39844 16.8777Z"
        stroke="rgb(91, 109, 127)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2969 4.32448L16.1969 7.1992M16.1969 7.1992L11.0969 4.32448M16.1969 7.1992L16.1969 12.9825M21.5969 9.66992V4.72852C21.5969 4.51993 21.5969 4.41564 21.5666 4.32262C21.5398 4.24033 21.496 4.16479 21.4381 4.10106C21.3727 4.02902 21.2828 3.97837 21.1031 3.87707L16.6631 1.37437C16.4929 1.27846 16.4078 1.2305 16.3177 1.2117C16.238 1.19506 16.1558 1.19506 16.076 1.2117C15.9859 1.2305 15.9008 1.27846 15.7307 1.37437L11.2907 3.87707C11.1109 3.97837 11.0211 4.02902 10.9557 4.10106C10.8978 4.16479 10.854 4.24033 10.8272 4.32262C10.7969 4.41564 10.7969 4.51993 10.7969 4.72852V9.66992C10.7969 9.87851 10.7969 9.9828 10.8272 10.0758C10.854 10.1581 10.8978 10.2336 10.9557 10.2974C11.0211 10.3694 11.1109 10.4201 11.2907 10.5214L15.7307 13.0241C15.9008 13.12 15.9859 13.1679 16.076 13.1867C16.1558 13.2034 16.238 13.2034 16.3177 13.1867C16.4078 13.1679 16.4929 13.12 16.6631 13.0241L21.1031 10.5214C21.2828 10.4201 21.3727 10.3694 21.4381 10.2974C21.496 10.2336 21.5398 10.1581 21.5666 10.0758C21.5969 9.9828 21.5969 9.87851 21.5969 9.66992Z"
        stroke="rgb(91, 109, 127)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
