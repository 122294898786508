import styled from "styled-components";
import { AutoComplete, Input, Select } from "antd";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex";
import { useState } from "react";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";

const StyledScanTopActions = styled.div`
  margin-top: 24px;
  padding: 16px;
  background: #ffffff;
  border-radius: 12px;

  .ant-input-group-compact {
    margin-right: 16px;
    //&.ant-select-auto-complete {
    //  border-top-left-radius: 8px !important;
    //  border-bottom-left-radius: 8px !important;
    //}
  }

  .auto_comp {
    border-radius: 0 8px 8px 0 !important;
  }
`;
export default function ReceiveTopActions({
  setOrdersParams,
  setUser,
  loading,
}) {
  const [searchKey, setSearchKey] = useState("user_info");
  const [userInfo, setUserInfo] = useState(null);
  const [userId, setUserId] = useState(null);
  const { usersList = [] } = usersAutocomplete(
    userInfo && userInfo?.user_info?.trim()?.length === 0 ? null : userInfo
  );
  return (
    <StyledScanTopActions>
      <Typography
        text="Search by"
        variant="p"
        size="14px"
        weight="500"
        color="#3B5166"
        padding="0 0 4px 0"
      />
      <Flex>
        <Input.Group compact>
          <Select
            defaultValue="user_info"
            style={{ width: 150, height: 36 }}
            onChange={(value) => setSearchKey(value)}
          >
            <Select.Option value="user_info">Full name or GA</Select.Option>
            <Select.Option value="phone">Phone</Select.Option>
            <Select.Option value="user_id">ID</Select.Option>
            <Select.Option value="address">Address</Select.Option>
            <Select.Option value="email">Email</Select.Option>
            <Select.Option value="passport_details">
              Passport details
            </Select.Option>
          </Select>
          {/*<Input*/}
          {/*  style={{ width: 290 }}*/}
          {/*  value={tracking}*/}
          {/*  autoFocus*/}
          {/*  placeholder="Search"*/}
          {/*/>*/}
          <AutoComplete
            style={{
              width: 290,
              height: 36,
            }}
            className={"auto_comp"}
            placeholder="Search"
            onSearch={(val) => setUserInfo({ [searchKey]: val })}
            onSelect={(_, val) => {
              setUserId(val?.id);
              setUser(val);
              setOrdersParams({
                recipient_id: val?.id,
                ready_for_pickup: 1,
                order_status: "in_georgia",
                per_page: 1000,
              });
            }}
            options={usersList}
          />
        </Input.Group>
        {/*<ButtonPrimary*/}
        {/*  icon={<SearchIcon />}*/}
        {/*  text="Search"*/}
        {/*  click={() =>*/}
        {/*    setOrdersParams({*/}
        {/*      recipient_id: userId,*/}
        {/*      ready_for_pickup: 1,*/}
        {/*      order_status: "in_georgia",*/}
        {/*    })*/}
        {/*  }*/}
        {/*  loading={loading}*/}
        {/*/>*/}
      </Flex>
    </StyledScanTopActions>
  );
}
