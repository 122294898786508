import Flex from "../../components/general/Flex.jsx";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import ButtonCancel from "../../components/moleculs/ButtonCancel";
import styled from "styled-components";
import { Checkbox, DatePicker, Form, Image, Input, Select } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Loader from "../../components/moleculs/Loader";
import { useMutation } from "react-query";
import { consignmentApi } from "../../services/consignment.js";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";

const { RangePicker } = DatePicker;
const StyledEdit = styled.div`
  header {
    margin-bottom: 17px;
  }

  .info-block {
    background: #ffffff;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    width: 100%;
  }
`;

export default function EditConsignment({
  editParcel,
  setEditable,
  consignment,
  reFetchConsignment = () => {},
}) {
  const [isLoad, setIsLoad] = useState(true);
  const [form] = Form.useForm();
  const { mutate, isLoading } = useMutation(consignmentApi.editConsignment, {
    onSuccess: (data) => {
      editParcel(data?.data?.data);
      setEditable(false);
      reFetchConsignment();
      openNotification("Edit orders", "Editing done successfully");
    },
    onError: (e) => {
      openNotificationError("editing orders", "something went wrong");
    },
  });

  useEffect(() => {
    if (consignment) {
      setIsLoad(false);
      form.setFieldsValue({
        warehouse_id: consignment?.warehouse_id,
        air_waybill: consignment?.air_waybill || null,
        comment: consignment?.comment || null,
        dispatch_id: consignment?.dispatch ? consignment?.dispatch?.id : null,
        estimation_data:
          consignment?.dispatch?.type === "air"
            ? dayjs(consignment?.estimated_date_to || new Date())
            : [
                consignment?.estimated_date_from
                  ? dayjs(consignment?.estimated_date_from)
                  : null,
                consignment?.estimated_date_to
                  ? dayjs(consignment?.estimated_date_to)
                  : null,
              ],
        freight_forwarder: consignment?.freight_forwarder || null,
        name: consignment?.name || null,
        notify_by_app: false,
        notify_by_email: false,
        total_v_weight: consignment?.total_v_weight || null,
        total_weight: consignment?.total_weight || null,
      });
    }
  }, [consignment]);
  const onFinish = (values) => {
    let newValues = {
      parcel_data: {
        warehouse_id: consignment?.warehouse_id,
        dispatch_id: values.dispatch_id,
        name: values.name,
        air_waybill: values?.air_waybill,
        freight_forwarder: values?.freight_forwarder,
        comment: values?.comment,
        total_weight: values?.total_weight,
        total_v_weight: values?.total_v_weight,
      },
      estimation_data:
        typeof values?.estimation_data === "object"
          ? {
              estimated_date_to: dayjs(values.estimation_data).format(
                "YYYY-MM-DD"
              ),
            }
          : {
              estimated_date_to: dayjs(values.estimation_data[1]).format(
                "YYYY-MM-DD"
              ),
              estimated_date_from: dayjs(values.estimation_data[0]).format(
                "YYYY-MM-DD"
              ),
            },
      notification_instructions: {
        notify_by_app: values?.notify_by_app,
        notify_by_email: values?.notify_by_email,
      },
      id: consignment?.id,
    };

    mutate(newValues);
  };
  return !isLoad ? (
    <Form onFinish={onFinish} form={form}>
      <StyledEdit>
        <header>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex>
              <Typography
                text="Consignment"
                variant="p"
                size="22px"
                weight="500"
                color={primaryTextColor}
                padding="0 8px 0 13px"
              />
              <Typography
                text={consignment?.id || ""}
                variant="p"
                size="22px"
                weight="500"
                color="#3B5166"
              />
            </Flex>
            <Flex>
              <Form.Item name={"notify_by_email"} valuePropName="checked">
                <Checkbox>Mail</Checkbox>
              </Form.Item>
              <Form.Item name={"notify_by_app"} valuePropName="checked">
                <Checkbox>Notifications</Checkbox>
              </Form.Item>
              <div className="mr-16">
                <ButtonCancel text="Cancel" click={() => setEditable(false)} />
              </div>
              <ButtonPrimary text={"Save"} htmlType={"submit"} />
            </Flex>
          </Flex>
        </header>
        <div className="info-block">
          <Flex justifyContent={"space-between"}>
            <div>
              <Typography
                variant={"p"}
                text={"Dispatch"}
                color={"#3B5166"}
                size={"14px"}
                weight={"500"}
                padding={"0 0 4px 0"}
              />
              <Form.Item
                name={"dispatch_id"}
                rules={[
                  { required: true, message: "Please select dispatch id" },
                ]}
              >
                <Select style={{ width: "100px" }}>
                  {consignment?.warehouse?.dispatches?.map((dispatch, i) => (
                    <Select.Option value={dispatch?.id} key={i}>
                      {" "}
                      <Flex alignItems="center">
                        <Image src={dispatch?.icon} width={24} />
                        {dispatch?.name}
                      </Flex>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Typography
                variant={"p"}
                text={"Consignment name"}
                color={"#3B5166"}
                size={"14px"}
                weight={"500"}
                padding={"0 0 4px 0"}
              />
              <Form.Item
                name={"name"}
                rules={[{ required: true, message: "Field is required" }]}
              >
                <Input style={{ width: 222 }} />
              </Form.Item>
            </div>
            <div>
              <Typography
                variant={"p"}
                text={"New Date"}
                color={"#3B5166"}
                size={"14px"}
                weight={"500"}
                padding={"0 0 4px 0"}
              />
              <Form.Item
                name={"estimation_data"}
                rules={[{ required: true, message: "Please select date" }]}
              >
                {consignment?.dispatch?.type === "air" ? (
                  <DatePicker format="DD.MM.YYYY" inputReadOnly={true} />
                ) : (
                  <RangePicker
                    defaultValue={[
                      dayjs(consignment?.estimated_date_from),
                      dayjs(consignment?.estimated_date_to),
                    ]}
                    format={"DD.MM.YYYY"}
                  />
                )}
              </Form.Item>
            </div>
            <div>
              <Typography
                variant={"p"}
                text={"Air way bill"}
                color={"#3B5166"}
                size={"14px"}
                weight={"500"}
                padding={"0 0 4px 0"}
              />
              <Form.Item
                name={"air_waybill"}
                // rules={[{ required: true, message: "Field is required" }]}
              >
                <Input style={{ width: 222 }} />
              </Form.Item>
            </div>
          </Flex>
        </div>

        <Flex justifyContent={"space-between"}>
          <div className="info-block" style={{ width: "49%", marginRight: 16 }}>
            <Flex justifyContent={"space-between"}>
              <div className={"w-50-16"}>
                <Typography
                  variant={"p"}
                  text={"Freight Forwarder"}
                  color={"#3B5166"}
                  size={"14px"}
                  weight={"500"}
                  padding={"0 0 4px 0"}
                />
                <Form.Item
                  name={"freight_forwarder"}
                  // rules={[{ required: true, message: "Please select" }]}
                >
                  <Input style={{ width: "100%" }} />
                  {/*<Select style={{ width: "100%" }}>*/}
                  {/*  <Select.Option value={"American Global CARS"}>*/}
                  {/*    American Global CARS*/}
                  {/*  </Select.Option>*/}
                  {/*</Select>*/}
                </Form.Item>
              </div>
              <div className={"w-50-16"}>
                <Typography
                  variant={"p"}
                  text={"Comment"}
                  color={"#3B5166"}
                  size={"14px"}
                  weight={"500"}
                  padding={"0 0 4px 0"}
                />
                <Form.Item
                  name={"comment"}
                  // rules={[{ required: true, message: "Field is required" }]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </Flex>
          </div>
          <div className="info-block" style={{ width: "49%" }}>
            <Flex justifyContent={"space-between"}>
              <div className={"w-50-16"}>
                <Typography
                  variant={"p"}
                  text={"Weight"}
                  color={"#3B5166"}
                  size={"14px"}
                  weight={"500"}
                  padding={"0 0 4px 0"}
                />
                <Form.Item
                  name={"total_weight"}
                  // rules={[{ required: true, message: "Field is required" }]}
                >
                  <Input suffix={"kg"} style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className={"w-50-16"}>
                <Typography
                  variant={"p"}
                  text={"V Weight"}
                  color={"#3B5166"}
                  size={"14px"}
                  weight={"500"}
                  padding={"0 0 4px 0"}
                />
                <Form.Item
                  name={"total_v_weight"}
                  // rules={[{ required: true, message: "Field is required" }]}
                >
                  <Input suffix={"kg"} style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </Flex>
          </div>
        </Flex>
      </StyledEdit>
    </Form>
  ) : (
    <Loader />
  );
}
