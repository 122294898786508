import { useMutation, useQueryClient } from "react-query";
import { blogApi } from "../../services/blog.js";

export const useSortBlogs = (onSuccess = () => {}, onError = () => {}) => {
  const client = useQueryClient();
  return useMutation(blogApi.sortBlog, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (e) => {
      onError(e);
    },
    onSettled: async () => {
      await client.invalidateQueries(["sort-blogs"]);
    },
  });
};
