import { Form, Input, Table } from "antd";
import styled from "styled-components";
import { useState } from "react";
import IconButton from "../../components/moleculs/IconButton";
import EditIcon from "../../components/SvgComponents/EditIcon";
import Typography from "../../components/moleculs/Typography.jsx";
import { primaryTextColor } from "../../constants.js";
import CancelIcon from "../../components/SvgComponents/CancelIcon.jsx";
import CheckIcon from "../../components/SvgComponents/CheckIcon.jsx";
import Flex from "../../components/general/Flex";
import VWeightInput from "../../components/moleculs/VWeightInput";
import { useMutation } from "react-query";
import { consignmentApi } from "../../services/consignment.js";
import { openNotification } from "../../helpers/notification.js";

const StyledTable = styled.div`
  .ant-table-thead > tr > th {
    background: #f5f5f5 !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 12px !important;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 12px !important;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none !important;
  }

  tbody {
    .ant-table-cell {
      padding: 0 !important;

      .table-item {
        padding: 16px !important;

        &.editable {
          //background: #f9f9f9;
        }
      }
    }
  }
`;

export default function ConsignmentDetailsTable({
  boxDetails = [],
  reFetchConsignment = () => {},
  reFetchList = () => {},
}) {
  const [form] = Form.useForm();
  const [editable, setEditable] = useState(false);
  const [id, setId] = useState(null);

  const { mutate, isLoading } = useMutation(consignmentApi.editConsignmentBox, {
    onSuccess: (data) => {
      boxDetails?.forEach((order, i) => {
        if (order.id === data?.data?.data.id) {
          return (boxDetails[i] = data?.data?.data);
        }
      });
      reFetchConsignment();
      reFetchList();
      openNotification(
        "Box Orders",
        "Box Order information updated successfully"
      );
      setEditable(false);
    },
    onError: (e) => {
      console.log(e, "errrr");
    },
  });
  const onReset = () => {
    form.resetFields();
    setEditable(false);
  };
  const edit = (el) => {
    setId(el.id);
    form.setFieldsValue({
      tracking_code: el?.tracking_code,
      total_weight: el?.total_weight,
      total_v_weight: el?.total_v_weight,
      width: el?.width,
      length: el?.length,
      height: el?.height,
    });
    setEditable(true);
  };
  const save = async () => {
    const row = await form.validateFields();
    mutate({ id: id, ...row });
  };
  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "ID",
      render: (_, record) => <div className="table-item">{record.id}</div>,
    },
    {
      key: "packages",
      dataIndex: "packages",
      title: "Packages",
      render: (_, record) => (
        <div className="table-item">{record.packages}</div>
      ),
    },
    {
      key: "dimensions",
      dataIndex: "dimensions",
      title: "Dimensions",
      render: (_, record) => (
        <div className="table-item">{record.dimensions}</div>
      ),
    },
    {
      key: "tracking",
      dataIndex: "tracking",
      title: "Tracking",
      render: (_, record) => (
        <div className="table-item editable">{record.tracking}</div>
      ),
    },
    {
      key: "weight",
      dataIndex: "Weight",
      title: "Weight",
      render: (_, record) => (
        <div className="table-item editable">{record.weight}</div>
      ),
    },
    {
      key: "v_weight",
      dataIndex: "v_weight",
      title: "V weight",
      render: (_, record) => (
        <div className="table-item editable">{record.v_weight}</div>
      ),
    },
    {
      key: "edit",
      dataIndex: "edit",
      title: " ",
    },
  ];
  const data = boxDetails?.map((el, i) => ({
    key: i,
    id: (
      <Typography
        variant="p"
        text={el?.reference_id || ""}
        size="14px"
        weight="400"
        color={primaryTextColor}
      />
    ),
    packages: (
      <Typography
        variant="p"
        text={el?.orders_count || ""}
        size="14px"
        weight="400"
        color={primaryTextColor}
      />
    ),
    dimensions: (
      <Typography
        variant="p"
        text={`${el?.width} x ${el?.height} x ${el?.length}`}
        size="14px"
        weight="400"
        color={primaryTextColor}
      />
    ),
    tracking:
      editable && el?.id === id ? (
        <Form.Item name="tracking_code">
          <Input />
        </Form.Item>
      ) : (
        <Typography
          variant="p"
          text={el?.tracking_code || ""}
          size="14px"
          weight="400"
          color={primaryTextColor}
        />
      ),
    weight:
      editable && el?.id === id ? (
        <Form.Item name="total_weight">
          <Input />
        </Form.Item>
      ) : (
        <Typography
          variant="p"
          text={el?.total_weight || ""}
          size="14px"
          weight="400"
          color={primaryTextColor}
        />
      ),
    v_weight:
      editable && el?.id === id ? (
        <VWeightInput form={form} />
      ) : (
        <Typography
          variant="p"
          text={el?.total_v_weight || ""}
          size="14px"
          weight="400"
          color={primaryTextColor}
        />
      ),
    edit: (
      <div>
        {editable && el?.id === id ? (
          <Flex>
            <div style={{ marginRight: 8 }} onClick={onReset}>
              <CancelIcon />
            </div>
            <IconButton
              icon={<CheckIcon />}
              click={save}
              background="#EFF8EA"
            />
          </Flex>
        ) : (
          <IconButton click={() => edit(el)} icon={<EditIcon />} />
        )}
      </div>
    ),
  }));

  return (
    <StyledTable>
      <Form form={form} component={false}>
        <Table columns={columns} dataSource={data} pagination={false} />
      </Form>
    </StyledTable>
  );
}
