export const shopsInitialValue = {
  for_home_page: 0,
  is_buyforme: 0,
  is_dropify: 0,
  is_one_click: 0,
  is_special: 0,
  category_ids: [],
  details: [
    {
      language_id: 1,
      description: "",
      vlog: "",
    },
    {
      language_id: "",
      description: "",
      vlog: "",
    },
    {
      language_id: "",
      description: "",
      vlog: "",
    },
  ],
};
