export default function WerHouseIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1429 8.57143V16.5714C17.1429 17.676 15.7723 18.5714 14.0817 18.5714H5.9184C4.22773 18.5714 2.85718 17.676 2.85718 16.5714V8.57143"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1.91992 6.16978L9.6367 1.47316C9.77177 1.39096 9.83931 1.34985 9.90755 1.33346C9.96817 1.3189 10.0291 1.3189 10.0897 1.33346C10.158 1.34985 10.2255 1.39096 10.3606 1.47316L18.0774 6.16978"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0077 9.51999L10.0635 11.7113M10.0635 11.7113L6.11921 9.51999M10.0635 11.7113L10.0635 16.1196M14.2398 13.5946V9.82797C14.2398 9.66897 14.2398 9.58947 14.2163 9.51857C14.1956 9.45584 14.1617 9.39826 14.117 9.34969C14.0664 9.29477 13.9969 9.25617 13.8579 9.17895L10.4241 7.27127C10.2925 7.19815 10.2267 7.1616 10.157 7.14727C10.0953 7.13458 10.0317 7.13458 9.97001 7.14727C9.90033 7.1616 9.83453 7.19815 9.70292 7.27127L6.26909 9.17895C6.1301 9.25617 6.06061 9.29477 6.01001 9.34969C5.96524 9.39827 5.93136 9.45584 5.91063 9.51857C5.88721 9.58947 5.88721 9.66897 5.88721 9.82797V13.5946C5.88721 13.7536 5.88721 13.8331 5.91063 13.904C5.93136 13.9667 5.96524 14.0243 6.01001 14.0728C6.06061 14.1278 6.1301 14.1664 6.26909 14.2436L9.70292 16.1513C9.83453 16.2244 9.90033 16.2609 9.97001 16.2753C10.0317 16.2879 10.0953 16.2879 10.157 16.2753C10.2267 16.2609 10.2925 16.2244 10.4241 16.1513L13.8579 14.2436C13.9969 14.1664 14.0664 14.1278 14.117 14.0728C14.1617 14.0243 14.1956 13.9667 14.2163 13.904C14.2398 13.8331 14.2398 13.7536 14.2398 13.5946Z"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1516 10.5509L7.97534 8.23076"
        stroke="#5B6D7F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
