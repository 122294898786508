import { DatePicker, Form } from "antd";
import Flex from "../../../components/general/Flex.jsx";
import Typography from "../../../components/moleculs/Typography.jsx";
import dayjs from "dayjs";

export default function Times({order}) {
  return (
    <div className="info-block" style={{ width: "100%" }}>
      <Flex
        justifyContent="space-between"
        className="status-times-items-wrapper"
      >
        {
          order?.expected ? (
            <div className="status-times-item">
              <Form.Item name={"expected_date"} label={"Expected"}>
                <DatePicker inputReadOnly={true} />
              </Form.Item>
            </div>
          ) : null
        }

        <div className="status-times-item">
          <Form.Item label={"At Warehouse"} name={"at_warehouse_date"}>
            <DatePicker inputReadOnly={true} />
          </Form.Item>
        </div>

        <div className="status-times-item">
          <Form.Item name={"on_way_date"} label={"On the Way"}>
            <DatePicker inputReadOnly={true} />
          </Form.Item>
        </div>

        <div className="status-times-item">
          <Form.Item name={"in_georgia_date"} label={"In Georgia"}>
            <DatePicker inputReadOnly={true} />
          </Form.Item>
        </div>

        <div className="status-times-item">
          <Form.Item name={"received_date"} label={"Received"}>
            <DatePicker inputReadOnly={true} />
          </Form.Item>
        </div>
      </Flex>
    </div>
  );
}
