import { Button, Popover } from "antd";
import Flex from "../../components/general/Flex.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import InfoIcon from "../../components/SvgComponents/InfoIcon.jsx";
import { useSendToHub } from "../../hooks/orders/useSendToHub.hook.js";
import { useState } from "react";
import { SendToIcon } from "../../assets/images/send-to-icon.jsx";

export const SendToHubPopover = ({ id, refetch = () => {} }) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [isError, setIsError] = useState(false);
  const { mutate, isLoading } = useSendToHub(
    () => {
      setOpenPopover(false);
      refetch();
    },
    () => setIsError(true)
  );
  return (
    <Popover
      content={
        <Flex justifyContent={"center"}>
          <ButtonSecondary
            width={"100px"}
            text="Cancel"
            click={() => {
              setOpenPopover(false);
              setIsError(false);
            }}
            style={{ marginRight: 10 }}
          />
          <Button
            style={{ width: 100 }}
            type="primary"
            loading={isLoading}
            onClick={() => mutate({ parcel_id: id })}
          >
            {isLoading ? "Loading" : "Yes"}
          </Button>
        </Flex>
      }
      title={
        <Flex
          alignItems={"center"}
          style={{ color: isError ? "#FC4447" : "#FC9A3A" }}
        >
          <InfoIcon style={{ margin: "0 10px  0" }} />
          {isError ? "Something went wrong" : "Sure to accept"}
        </Flex>
      }
      trigger="click"
      open={openPopover}
      onOpenChange={(newOpen) => {
        setOpenPopover(newOpen);
        setIsError(false);
      }}
    >
      <ButtonSecondary
        click={() => setSendToHubStatus(true)}
        text="Send to hub"
        icon={<SendToIcon />}
        style={{ marginRight: 10 }}
      />
    </Popover>
  );
};
