import axios from "axios";

export const getCustomers = async () => {
  const { data } = await axios.get(`/recipients`);
  return data;
};
export const useAuthCustomers = async (data) => {
  return await axios.post(`/login-as/user`, data);
};

export const createCustomers = async (userInfo) => {
  const { data } = await axios.post(`/recipients`, userInfo);
  return data;
};

export const updateCustomer = async (customerInfo) => {
  const { id, fieldsValues } = customerInfo;
  const { data } = await axios.put(`/recipients/${id}`, fieldsValues);
  return data;
};

export const GetAccountDetails = async (id) => {
  const { data } = await axios.get(`/user/${id}/account-details`);
  return data.data;
};

export const GetTransactions = async (id) => {
  const { data } = await axios.get(`/balances?user_id=${id}`);
  return data.data.data;
};

export const BlockUserService = async (id, reason) => {
  const data = await axios.post("/blocked-account", {
    user_id: id,
    reason,
  });
  return data;
};

export const UnblockUserService = async (id) => {
  const data = await axios.delete(`/blocked-account/${id}`);
  return data;
};

export const UpdateUserInfo = async (user, info) => {
  const data = await axios.patch(`/user/${user}/account-details/update`, info);
  return data;
};

export const ChangePasswordService = async (id) => {
  const data = await axios.patch(`/user/${id}/change-password`);
  return data;
};
