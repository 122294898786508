export const handleDragEnd = (event, array, callback) => {
  const { active, over } = event;
  const active_index = array.findIndex((item) => item.id === active.id);
  const over_index = array.findIndex((item) => item.id === over.id);
  if (active.id !== over.id) {
    const new_array = JSON.parse(JSON.stringify(array));
    const active_priority = new_array[active_index].priority;
    new_array[active_index].priority = new_array[over_index].priority;
    new_array[over_index].priority = active_priority;
    callback(new_array, active_index, over_index);
  }
};
